import React, { FC, useState, useEffect } from 'react';
import { IconType } from 'react-icons';
import { MdOutlinedFlag, MdOutlineBookmarkAdd } from 'react-icons/md';
import { FactsStatsActionItemsReview } from './FactsStatsActionItemsReview';
import { FactsStatsGoals } from './FactsStatsGoals';
import { FactsStatsMetrics } from './FactsStatsMetrics';
import { useTeamMetricsFeatureState, TeamMetricsState } from '@spoke/metrics';
import {
  MetricsIcon,
  VStack,
  HStack,
  Heading,
  SpkTabs,
  SpkTabsElement,
} from '@spoke/common';

enum Tab {
  Metrics = 'METRICS',
  Goals = 'GOALS',
  ActionItems = 'ACTION_ITEMS',
}

export const FactsStats: FC = () => {
  const [selectedTabKey, setSelectedTabKey] = useState(Tab.Goals);

  const tabs: SpkTabsElement[] = [
    {
      key: Tab.Goals,
      label: `Team goals`,
      Icon: MdOutlinedFlag,
      iconH: 5,
      iconW: 5,
      page: <FactsStatsGoals />,
    },
  ].filter(Boolean);

  return (
    <VStack
      w="full"
      alignItems="center"
      mx="auto"
      px={100}
      pt={10}
      mt={4}
      maxW={1400}
      spacing={6}
    >
      <HStack justify="center">
        <Heading fontSize={24} textAlign="center">
          Hey team, let&#39;s review what happened since our last retro.
        </Heading>
      </HStack>

      <SpkTabs
        selectedTabKey={selectedTabKey}
        onSelect={(key) => setSelectedTabKey(key as Tab)}
        elements={tabs}
        maxW={1000}
        w="full"
      />
    </VStack>
  );
};
