import { FC } from 'react';
import {
  Box,
  Divider,
  Flex,
  FlexProps,
  HStack,
  Text,
  VStack,
  Link,
  Icon,
  SpkTime,
  useToast,
} from '@spoke/common';
import { Board, Takeaway, TakeawayType, Team } from '@spoke/graphql';
import { BsClipboard } from 'react-icons/bs';
import { UserCog, Users } from 'lucide-react';

type FeedbackTakeawaysProps = {
  takeaways: Omit<Takeaway, 'id' | 'createdAt' | 'archived'>[];
} & FlexProps;

export const FeedbackTakeaways: FC<FeedbackTakeawaysProps> = ({
  takeaways,
  ...props
}) => {
  const [toast] = useToast();

  const teamTakeAways =
    takeaways?.filter((takeaway) => takeaway?.type === TakeawayType.Team) ?? [];
  const managerTakeaways =
    takeaways?.filter((takeaway) => takeaway?.type === TakeawayType.Manager) ??
    [];

  const copyTakeaways = (takeawayType: TakeawayType) => {
    const filteredTakeaways = takeaways
      ?.filter(({ type }) => type === takeawayType)
      .slice(0, 5);
    const takeawayText = filteredTakeaways
      ?.map(({ text }, index) => `${index + 1}. ${text}`)
      .join('\n');

    const takeawayTypeText =
      takeawayType === TakeawayType.Team ? 'the team' : 'leadership';

    const summaryText = `These were the main takeaways for ${takeawayTypeText}:\n\n${takeawayText}`;

    navigator.clipboard
      .writeText(summaryText)
      .then(() => {
        toast({
          description: 'Summary copied to clipboard',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          description: 'Failed to copy summary to clipboard',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <Flex w="full" gap={4} {...props}>
      <Box
        borderWidth={1}
        borderRadius="lg"
        borderColor="gray.200"
        my={4}
        p={4}
        w="50%"
      >
        <VStack align="start" w="full">
          <HStack spacing={2} alignItems="center" mb={2}>
            <Icon w={5} h={5} as={Users} />
            <Text fontWeight={500}>
              {teamTakeAways?.length
                ? 'What the team needs to know:'
                : 'The team did not have enough feedback to generate a summary.'}
            </Text>
          </HStack>
          {teamTakeAways?.length ? (
            teamTakeAways.slice(0, 5).map((takeaway, index) => (
              <VStack key={index} w="full">
                {Boolean(index) && <Divider my={4} />}
                <HStack w="full">
                  <Text fontWeight="bold" fontSize={14}>
                    {index + 1}.
                  </Text>
                  <Text fontSize={14}>{takeaway?.text}</Text>
                </HStack>
              </VStack>
            ))
          ) : (
            <Text textAlign="center" w="full" my={6}>
              Not enough feedback to generate the key points for the team.
            </Text>
          )}
          {Boolean(teamTakeAways?.length) && (
            <Flex
              justifyContent="flex-end"
              align="center"
              gap={2}
              mt={5}
              w="full"
              style={{ marginTop: '20px' }}
            >
              <Icon as={BsClipboard} color="primary.500" w={5} h={5} />
              <Link
                onClick={() => copyTakeaways(TakeawayType.Team)}
                fontSize={16}
                fontWeight={500}
                textDecoration="underline"
                color="primary.500"
              >
                Copy summary
              </Link>
            </Flex>
          )}
        </VStack>
      </Box>
      <Box
        borderWidth={1}
        borderRadius="lg"
        borderColor="gray.200"
        my={4}
        p={4}
        w="50%"
      >
        <VStack align="start" w="full">
          <HStack spacing={2} alignItems="center" mb={2}>
            <Icon w={5} h={5} as={UserCog} />
            <Text my={2} fontWeight={500}>
              What leadership needs to know:
            </Text>
          </HStack>
          {managerTakeaways?.length ? (
            managerTakeaways.slice(0, 5).map((takeaway, index) => (
              <VStack key={index} w="full">
                {Boolean(index) && <Divider my={4} />}
                <HStack w="full">
                  <Text fontWeight="bold" fontSize={14}>
                    {' '}
                    {index + 1}.
                  </Text>
                  <Text fontSize={14}>{takeaway?.text}</Text>
                </HStack>
              </VStack>
            ))
          ) : (
            <Text textAlign="center" w="full" my={6}>
              Not enough feedback to generate the key points for the leadership.
            </Text>
          )}
          {Boolean(managerTakeaways?.length) && (
            <Flex
              justifyContent="flex-end"
              align="center"
              gap={2}
              mt={5}
              w="full"
              style={{ marginTop: '20px' }}
            >
              <Icon as={BsClipboard} color="primary.500" w={5} h={5} />
              <Link
                onClick={() => copyTakeaways(TakeawayType.Manager)}
                fontSize={16}
                fontWeight={500}
                textDecoration="underline"
                color="primary.500"
              >
                Copy summary
              </Link>
            </Flex>
          )}
        </VStack>
      </Box>
    </Flex>
  );
};
