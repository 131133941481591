import { BiUser, BiWallet } from 'react-icons/bi';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import {
  MdOutlinedFlag,
  MdOutlineIntegrationInstructions,
  MdOutlineStickyNote2,
} from 'react-icons/md';
import { RiBarChartFill } from 'react-icons/ri';
import { TbReportAnalytics } from 'react-icons/tb';
import {
  setDashboardSidebarIsCollapsed,
  useDashboardSidebarState,
} from '../../util/dashboardSidebar';
import { DashboardSidebarItem } from './DashboardSidebarItem';
import {
  useCurrentOrgLockdown,
  useCurrentTeam,
  useCurrentTeamMembers,
  useTeamType,
} from '@spoke/user';
import {
  compareRoutes,
  Divider,
  Flex,
  hardRedirect,
  Icon,
  MetricsIcon,
  Spacer,
  SquareGridIcon,
  TeamsIcon,
  useRouter,
  useRoutes,
} from '@spoke/common';

export const DashboardSidebar = () => {
  const router = useRouter();
  const routes = useRoutes();
  const [currentTeam] = useCurrentTeam();
  const [teamMembers] = useCurrentTeamMembers();
  const [isOrgLocked] = useCurrentOrgLockdown();

  const { capitalizedTeamType, isOrg, isProgram } = useTeamType();

  const teamMembersCount = teamMembers?.length || 0;

  const { sidebarIsCollapsed } = useDashboardSidebarState();

  const programTeamCount = isProgram ? currentTeam?.children?.length : null;

  return (
    <Flex
      bg="white"
      p={4}
      pr={5}
      boxShadow="md"
      zIndex={1}
      w={sidebarIsCollapsed ? '68px' : 64}
      transition="width 0.2s ease-out"
      overflow="hidden"
    >
      <Flex
        flexDir="column"
        alignItems="flex-start"
        h="full"
        w="full"
        id="dashboard-sidebar"
        gap={1}
      >
        {/* <DashboardSidebarItem
          isSelected={compareRoutes(router.route, routes.Dashboard)}
          onClick={() => router.push(routes.Dashboard)}
          icon={<Icon as={SquareGridIcon} mb="5px" w={5} h={5} />}
          text="Dashboard"
          isCollapsed={sidebarIsCollapsed}
        /> */}

        <DashboardSidebarItem
          isSelected={compareRoutes(router.route, routes.ImpactReport)}
          onClick={() => router.push(routes.ImpactReport)}
          icon={<Icon as={SquareGridIcon} mb="5px" w={5} h={5} />}
          text="Dashboard"
          isCollapsed={sidebarIsCollapsed}
        />
        <DashboardSidebarItem
          isSelected={compareRoutes(router.route, routes.ImprovementGoals)}
          onClick={() => router.push(routes.ImprovementGoals)}
          icon={<Icon as={MdOutlinedFlag} mb="3px" w={5} h={5} />}
          text="Goals"
          isCollapsed={sidebarIsCollapsed}
        />

        <DashboardSidebarItem
          isSelected={compareRoutes(router.route, routes.Retrospectives)}
          onClick={() => router.push(routes.Retrospectives)}
          icon={<Icon as={MdOutlineStickyNote2} mb="3px" w={5} h={5} />}
          text="Team Feedback"
          isCollapsed={sidebarIsCollapsed}
        />
        {/* <DashboardSidebarItem
          isSelected={compareRoutes(router.route, routes.Metrics)}
          onClick={() => router.push(routes.Metrics)}
          icon={<Icon as={MetricsIcon} mb="3px" w={5} h={5} />}
          text="Metrics"
          isCollapsed={sidebarIsCollapsed}
        /> */}

        <DashboardSidebarItem
          isSelected={compareRoutes(router.route, routes.Reports)}
          onClick={() => router.push(routes.Reports)}
          icon={<Icon as={TbReportAnalytics} mb="3px" w={5} h={5} />}
          text="Reports"
          isCollapsed={sidebarIsCollapsed}
        />

        {/* <DashboardSidebarItem
          icon={<Icon as={TeamPulseIcon} mb="3px" w={5} h={5} />}
          onClick={() => hardRedirect('/team-pulse')}
          text={`${capitalizedTeamType} Pulse`}
          isCollapsed={sidebarIsCollapsed}
        /> */}
        <Divider my={1} />
        {Boolean(isOrg || isProgram) && (
          <DashboardSidebarItem
            isSelected={compareRoutes(router.route, routes.ProgramTeams)}
            icon={<Icon as={TeamsIcon} mb="3px" w={5} h={5} />}
            badge={programTeamCount}
            text={isOrg ? 'Teams' : 'Program Teams'}
            isCollapsed={sidebarIsCollapsed}
            onClick={() =>
              isOrg
                ? hardRedirect(routes.OrgTeams)
                : router.push(routes.ProgramTeams)
            }
          />
        )}
        <DashboardSidebarItem
          isSelected={compareRoutes(router.route, routes.TeamMembers)}
          icon={<Icon as={BiUser} mb="3px" w={5} h={5} />}
          text={
            isOrg ? 'Users' : isProgram ? 'Program Members' : 'Team Members'
          }
          badge={isOrg ? null : teamMembersCount}
          isCollapsed={sidebarIsCollapsed}
          onClick={() =>
            isOrg ? hardRedirect(routes.Users) : router.push(routes.TeamMembers)
          }
        />
        {Boolean(isOrg) && (
          <DashboardSidebarItem
            isSelected={compareRoutes(router.route, routes.Integrations)}
            icon={
              <Icon
                as={MdOutlineIntegrationInstructions}
                mb="3px"
                w={5}
                h={5}
              />
            }
            text="Integrations"
            onClick={() => router.push(routes.Integrations)}
            isCollapsed={sidebarIsCollapsed}
          />
        )}
        {Boolean(isOrg || isOrgLocked) && (
          <DashboardSidebarItem
            isSelected={compareRoutes(router.route, routes.Billing)}
            icon={<Icon as={BiWallet} mb="3px" w={5} h={5} />}
            text="Billing"
            onClick={() => router.push(routes.Billing)}
            isCollapsed={sidebarIsCollapsed}
          />
        )}
        <DashboardSidebarItem
          isSelected={compareRoutes(router.route, routes.TeamSettings)}
          icon={<Icon as={FiSettings} mb="3px" w={5} h={5} />}
          text={`${capitalizedTeamType} Settings`}
          onClick={() =>
            isOrg
              ? hardRedirect(routes.OrgSettings)
              : router.push(routes.TeamSettings)
          }
          isCollapsed={sidebarIsCollapsed}
        />

        <Spacer />
        <DashboardSidebarItem
          icon={
            <Icon
              as={sidebarIsCollapsed ? FaChevronRight : FaChevronLeft}
              mb="3px"
              w={4}
              h={4}
            />
          }
          text="Collapse"
          tooltipText="Expand"
          isCollapsed={sidebarIsCollapsed}
          onClick={() => setDashboardSidebarIsCollapsed(!sidebarIsCollapsed)}
        />
      </Flex>
    </Flex>
  );
};
