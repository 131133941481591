import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: number;
  JSON: any;
  Long: number;
};

export type ActionItem = {
  __typename?: 'ActionItem';
  archived: Scalars['Boolean'];
  assignees?: Maybe<Array<Maybe<User>>>;
  author?: Maybe<Author>;
  authorId: Scalars['String'];
  board?: Maybe<Board>;
  boardId?: Maybe<Scalars['String']>;
  children: Array<ActionItemChild>;
  comments: Array<ActionItemComment>;
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  improvementGoals?: Maybe<Array<Maybe<ImprovementGoal>>>;
  jiraIssueLink?: Maybe<ActionItemJiraLink>;
  resolvedAt?: Maybe<Scalars['Date']>;
  status: ActionItemStatus;
  team?: Maybe<Team>;
  teamId: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ActionItemAnalyticsFilter = {
  endDate?: InputMaybe<Scalars['Date']>;
  goals?: InputMaybe<Array<Scalars['ID']>>;
  recursive: Scalars['Boolean'];
  startDate?: InputMaybe<Scalars['Date']>;
  teams: Array<Scalars['ID']>;
};

export type ActionItemAnalyticsResults = {
  __typename?: 'ActionItemAnalyticsResults';
  averageCreatedCount: Scalars['Int'];
  averageResolvedCount: Scalars['Int'];
  averageTimeToResolve: Scalars['Float'];
  averageUnresolvedCount: Scalars['Int'];
  createdCount: Scalars['Int'];
  resolvedCount: Scalars['Int'];
  unresolvedCount: Scalars['Int'];
};

export type ActionItemChild = {
  __typename?: 'ActionItemChild';
  id: Scalars['String'];
  text: Scalars['String'];
};

export type ActionItemComment = Comment & {
  __typename?: 'ActionItemComment';
  actionItem: ActionItem;
  actionItemId: Scalars['ID'];
  archived: Scalars['Boolean'];
  author: User;
  authorId: Scalars['String'];
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  text: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type ActionItemJiraLink = {
  __typename?: 'ActionItemJiraLink';
  jiraIssueId?: Maybe<Scalars['String']>;
  jiraIssueKey?: Maybe<Scalars['String']>;
  jiraIssueUrl?: Maybe<Scalars['String']>;
  jiraProjectKey?: Maybe<Scalars['String']>;
};

export type ActionItemReviewAnalytics = {
  __typename?: 'ActionItemReviewAnalytics';
  averageTimeToResolveActionItemsInDays?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  improvementGoalDistribution: Array<ImprovementGoalDistribution>;
};

export enum ActionItemStatus {
  All = 'ALL',
  Resolved = 'RESOLVED',
  Unresolved = 'UNRESOLVED',
}

export type Activity = {
  __typename?: 'Activity';
  actionItem?: Maybe<ActionItem>;
  board?: Maybe<Board>;
  bodyText: Scalars['String'];
  comments: Array<Maybe<ActivityFeedComment>>;
  createdAt: Scalars['Date'];
  ctaLink?: Maybe<Scalars['String']>;
  ctaText?: Maybe<Scalars['String']>;
  goal?: Maybe<ImprovementGoal>;
  headerTitle: Scalars['String'];
  hideReaction: Scalars['Boolean'];
  id: Scalars['ID'];
  insight?: Maybe<TeamInsight>;
  parkingLot?: Maybe<ParkingLotItem>;
  reactions: Array<Maybe<ActivityFeedReaction>>;
  team?: Maybe<Team>;
  teamId?: Maybe<Scalars['String']>;
  type: ActivityType;
  updatedAt: Scalars['Date'];
  user?: Maybe<User>;
  verb: ActivityVerb;
};

export type ActivityFeed = {
  __typename?: 'ActivityFeed';
  activities: Array<Activity>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type ActivityFeedComment = {
  __typename?: 'ActivityFeedComment';
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  text: Scalars['String'];
  updatedAt: Scalars['Date'];
  user: User;
  userId: Scalars['String'];
};

export type ActivityFeedReaction = {
  __typename?: 'ActivityFeedReaction';
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  kind: ActivityReactionKind;
  updatedAt: Scalars['Date'];
  user: User;
  userId: Scalars['String'];
};

export enum ActivityReactionKind {
  Dislike = 'dislike',
  Kudos = 'kudos',
  Like = 'like',
}

export enum ActivityType {
  Account = 'account',
  ActionItem = 'actionItem',
  Announcements = 'announcements',
  BasicMessage = 'basicMessage',
  Goal = 'goal',
  Insight = 'insight',
  Metrics = 'metrics',
  ParkingLot = 'parkingLot',
  Program = 'program',
  ProgramStats = 'programStats',
  Retrospective = 'retrospective',
  Team = 'team',
  TeamStats = 'teamStats',
  User = 'user',
}

export enum ActivityVerb {
  ActionItemCreated = 'actionItemCreated',
  ActionItemCreatedByTeamsOverview = 'actionItemCreatedByTeamsOverview',
  ActionItemDueNDays = 'actionItemDueNDays',
  ActionItemDueNDaysByTeams = 'actionItemDueNDaysByTeams',
  ActionItemLate = 'actionItemLate',
  ActionItemLateByTeams = 'actionItemLateByTeams',
  ActionItemResolved = 'actionItemResolved',
  ActionItemResolvedByTeams = 'actionItemResolvedByTeams',
  GeneralMessage = 'generalMessage',
  GoalCreated = 'goalCreated',
  GoalNotUpdated = 'goalNotUpdated',
  GoalNotUpdatedByTeamType = 'goalNotUpdatedByTeamType',
  GoalProgressUpdate = 'goalProgressUpdate',
  GoalProgressUpdateByTeamType = 'goalProgressUpdateByTeamType',
  InsightCreated = 'insightCreated',
  OrganizationCreated = 'organizationCreated',
  OrganizationTeamsAdded = 'organizationTeamsAdded',
  ProgramAddedOrganization = 'programAddedOrganization',
  ProgramCreated = 'programCreated',
  RetropsectiveSummariesByTeams = 'retropsectiveSummariesByTeams',
  RetrospectiveCompleted = 'retrospectiveCompleted',
  RetrospectiveCompletedByTeams = 'retrospectiveCompletedByTeams',
  RetrospectiveNotHeldNDays = 'retrospectiveNotHeldNDays',
  RetrospectiveNotHeldNDaysByTeams = 'retrospectiveNotHeldNDaysByTeams',
  RetrospectiveSummaryCreated = 'retrospectiveSummaryCreated',
  TeamAddedProgram = 'teamAddedProgram',
  TeamCreated = 'teamCreated',
  TrialEndsNDays = 'trialEndsNDays',
  UserAddedToTeam = 'userAddedToTeam',
}

export type AddUnresolvedActionItemsAsBoardColumnInput = {
  boardId: Scalars['String'];
};

export type AddUnresolvedActionItemsAsBoardColumnOutput = {
  __typename?: 'AddUnresolvedActionItemsAsBoardColumnOutput';
  board: Board;
};

export type AnalyticsFilters = {
  endDate?: InputMaybe<Scalars['Date']>;
  goals?: InputMaybe<Array<Scalars['ID']>>;
  startDate?: InputMaybe<Scalars['Date']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Author = {
  __typename?: 'Author';
  name: Scalars['String'];
};

export type BaseTeamInsight = {
  date: Scalars['Date'];
  id: Scalars['ID'];
  rated: Scalars['Boolean'];
  seen: Scalars['Boolean'];
  team: Team;
  teamId: Scalars['String'];
};

export type BaseWorkItem = {
  createdAt: Scalars['Date'];
  description: Scalars['String'];
  externalStatusName: Scalars['String'];
  externalUrl: Scalars['String'];
  origin: WorkItemOrigin;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export enum BetaFeature {
  BitbucketMetrics = 'BITBUCKET_METRICS',
  GithubMetrics = 'GITHUB_METRICS',
  JiraMetrics = 'JIRA_METRICS',
  TeamInsights = 'TEAM_INSIGHTS',
}

export type BillingDetails = {
  __typename?: 'BillingDetails';
  canceled?: Maybe<Scalars['Boolean']>;
  currentPeriodEndAt?: Maybe<Scalars['Date']>;
  nextBillingDate?: Maybe<Scalars['Date']>;
  period?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  subscriptionStartsAt?: Maybe<Scalars['Date']>;
};

export type BitbucketProject = {
  __typename?: 'BitbucketProject';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BitbucketRepository = {
  __typename?: 'BitbucketRepository';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Board = {
  __typename?: 'Board';
  archived: Scalars['Boolean'];
  boardAsyncScheduleId?: Maybe<Scalars['String']>;
  cardBeingDiscussed?: Maybe<Card>;
  cards?: Maybe<Array<Maybe<Card>>>;
  cardsOnDiscussion?: Maybe<Array<Maybe<Card>>>;
  createdAt?: Maybe<Scalars['Date']>;
  discussReadyUserIds: Array<Maybe<Scalars['String']>>;
  facilitators?: Maybe<Array<Maybe<Scalars['String']>>>;
  format: RetrospectiveFormat;
  formatSlug: Scalars['String'];
  id: Scalars['ID'];
  lists?: Maybe<Array<Maybe<List>>>;
  name: Scalars['String'];
  onlineUserIds: Array<Maybe<Scalars['String']>>;
  ownerId: Scalars['String'];
  participationRate?: Maybe<Scalars['Float']>;
  public: Scalars['Boolean'];
  state: BoardState;
  takeaways?: Maybe<Array<Takeaway>>;
  team: Team;
  teamId: Scalars['String'];
  tooltipHintsEnabled: Scalars['Boolean'];
  totalCards: Scalars['Int'];
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type BoardAnalyticsFilter = {
  endDate?: InputMaybe<Scalars['Date']>;
  recursive: Scalars['Boolean'];
  startDate?: InputMaybe<Scalars['Date']>;
  teams: Array<Scalars['ID']>;
};

export type BoardAnalyticsResults = {
  __typename?: 'BoardAnalyticsResults';
  averageBoardsCreated: Scalars['Float'];
  averageParticipationRate: Scalars['Float'];
  averageTimeBetweenRetros: Scalars['Float'];
  sumBoardsCreated: Scalars['Int'];
};

export enum BoardAsyncCollectionType {
  Email = 'EMAIL',
  Slack = 'SLACK',
}

export enum BoardAsyncFeedbackType {
  CheckIn = 'CHECK_IN',
  PostMortem = 'POST_MORTEM',
  Release = 'RELEASE',
  Retrospective = 'RETROSPECTIVE',
  Standup = 'STANDUP',
  Survey = 'SURVEY',
}

export enum BoardAsyncQuestionType {
  AutoGenerate = 'AUTO_GENERATE',
  Custom = 'CUSTOM',
  Template = 'TEMPLATE',
}

export type BoardAsyncSchedule = {
  __typename?: 'BoardAsyncSchedule';
  areQuestionsOptional: Scalars['Boolean'];
  collectionTime: Scalars['Date'];
  customQuestions?: Maybe<Array<BoardCustomQuestion>>;
  feedbackCollectionType: BoardAsyncCollectionType;
  feedbackType: BoardAsyncFeedbackType;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  maxQuestions?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  oneTimeSchedule?: Maybe<Scalars['Date']>;
  questionType: BoardAsyncQuestionType;
  retrospectiveFormatId?: Maybe<Scalars['String']>;
  rrule?: Maybe<Scalars['String']>;
  scheduleType: BoardAsyncScheduleType;
  sendReminders: Scalars['Boolean'];
  teamId: Scalars['String'];
};

export type BoardAsyncScheduleCustomQuestionInput = {
  id?: InputMaybe<Scalars['ID']>;
  index: Scalars['Int'];
  isOptional: Scalars['Boolean'];
  question: Scalars['String'];
};

export type BoardAsyncScheduleInput = {
  areQuestionsOptional?: InputMaybe<Scalars['Boolean']>;
  collectionTime: Scalars['Date'];
  feedbackCollectionType: BoardAsyncCollectionType;
  feedbackType: BoardAsyncFeedbackType;
  maxQuestions?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  oneTimeSchedule?: InputMaybe<Scalars['Date']>;
  ownerId: Scalars['String'];
  questionType: BoardAsyncQuestionType;
  retrospectiveFormatId?: InputMaybe<Scalars['String']>;
  rrule?: InputMaybe<Scalars['String']>;
  scheduleType: BoardAsyncScheduleType;
  sendReminders?: InputMaybe<Scalars['Boolean']>;
  teamId: Scalars['String'];
};

export enum BoardAsyncScheduleType {
  OneTime = 'ONE_TIME',
  Recurring = 'RECURRING',
}

export type BoardCustomQuestion = {
  __typename?: 'BoardCustomQuestion';
  id: Scalars['ID'];
  index: Scalars['Int'];
  isOptional: Scalars['Boolean'];
  question: Scalars['String'];
};

export enum BoardDiscussionCardsSortingKey {
  Comments = 'COMMENTS',
  DateAsc = 'DATE_ASC',
  Votes = 'VOTES',
}

export type BoardMessageBroadcast = {
  __typename?: 'BoardMessageBroadcast';
  boardId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  newFacilitatorUserId?: Maybe<Scalars['String']>;
  timeOut?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type BoardMessageBroadcastInput = {
  boardId?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  newFacilitatorUserId?: InputMaybe<Scalars['String']>;
  timeOut?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

export type BoardParticipation = {
  __typename?: 'BoardParticipation';
  isFacilitator: Scalars['Boolean'];
  numberOfCards: Scalars['Int'];
  numberOfVotes: Scalars['Int'];
  participant: User;
  status: BoardParticipationStatus;
  userId: Scalars['String'];
};

export type BoardParticipationChangeEvent = {
  __typename?: 'BoardParticipationChangeEvent';
  boardId: Scalars['String'];
  participationChanges?: Maybe<Array<Maybe<BoardParticipation>>>;
};

export enum BoardParticipationStatus {
  Offline = 'OFFLINE',
  Online = 'ONLINE',
}

export type BoardQueryResults = {
  __typename?: 'BoardQueryResults';
  boards: Array<Maybe<Board>>;
  totalSize: Scalars['Int'];
};

export enum BoardStage {
  Discuss = 'DISCUSS',
  FactsStats = 'FACTS_STATS',
  Finished = 'FINISHED',
  Group = 'GROUP',
  Reflect = 'REFLECT',
  Vote = 'VOTE',
}

export type BoardState = {
  __typename?: 'BoardState';
  discussCurrentCardId?: Maybe<Scalars['String']>;
  discussSortByTags?: Maybe<Scalars['Boolean']>;
  discussionCardsSortingKey?: Maybe<BoardDiscussionCardsSortingKey>;
  isGuided?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  privateCards?: Maybe<Scalars['Boolean']>;
  privateVotes?: Maybe<Scalars['Boolean']>;
  showActionPlan?: Maybe<Scalars['Boolean']>;
  stage?: Maybe<BoardStage>;
  takeAwayStatus?: Maybe<TakeawayStatus>;
  timerStartTimeMiliseconds?: Maybe<Scalars['Float']>;
  timerStopTimeMiliseconds?: Maybe<Scalars['Float']>;
  voteCount?: Maybe<Scalars['Int']>;
  voteCountWasSuggested?: Maybe<Scalars['Boolean']>;
};

export type BoardTotalVoteCounts = {
  __typename?: 'BoardTotalVoteCounts';
  boardId: Scalars['ID'];
  totalVotes: Scalars['Int'];
};

export type BoardVoteCounts = {
  __typename?: 'BoardVoteCounts';
  boardId: Scalars['ID'];
  boardVoteCount: Scalars['Int'];
  userVoteCount: Scalars['Int'];
};

export type BoardsInput = {
  boardDate?: InputMaybe<Scalars['Date']>;
  boardName: Scalars['String'];
  lists?: InputMaybe<Array<InputMaybe<ListInput>>>;
};

export type Card = {
  __typename?: 'Card';
  archived: Scalars['Boolean'];
  author?: Maybe<Author>;
  authorId: Scalars['String'];
  backgroundColor?: Maybe<Scalars['String']>;
  board?: Maybe<Board>;
  boardId?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Maybe<Card>>>;
  comments: Array<CardComment>;
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  index: Scalars['Int'];
  list?: Maybe<List>;
  listId: Scalars['String'];
  organizationId: Scalars['String'];
  originalListBadge?: Maybe<OriginalListBadge>;
  parentId?: Maybe<Scalars['String']>;
  promotedToPrograms: Array<Team>;
  sentiment: Scalars['Float'];
  text: Scalars['String'];
  themes?: Maybe<CardThemes>;
  updatedAt?: Maybe<Scalars['Date']>;
  votes: Array<Scalars['String']>;
  wasDiscussed?: Maybe<Scalars['Boolean']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

export type CardCategory = {
  __typename?: 'CardCategory';
  color: Scalars['String'];
  name: Scalars['String'];
};

export type CardCategoryFilter = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeUncategorized?: InputMaybe<Scalars['Boolean']>;
  showAll?: InputMaybe<Scalars['Boolean']>;
};

export type CardCategoryUpdate = {
  cardId: Scalars['String'];
  category: Scalars['String'];
};

export type CardComment = Comment & {
  __typename?: 'CardComment';
  archived: Scalars['Boolean'];
  author: User;
  authorId: Scalars['String'];
  board?: Maybe<Board>;
  card: Card;
  cardId: Scalars['ID'];
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  text: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export enum CardDiscussionMovement {
  ToDiscussed = 'TO_DISCUSSED',
  ToDiscussion = 'TO_DISCUSSION',
}

export type CardInput = {
  comments?: InputMaybe<Array<InputMaybe<CommentInput>>>;
  groupAnchor?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
  votes?: InputMaybe<Scalars['Int']>;
};

export type CardPosition = {
  __typename?: 'CardPosition';
  id: Scalars['ID'];
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

export type CardThemes = {
  __typename?: 'CardThemes';
  setByModel: Array<Scalars['String']>;
  setByUser: Array<Scalars['String']>;
};

export type Comment = {
  archived: Scalars['Boolean'];
  author: User;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  text: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type CommentInput = {
  text: Scalars['String'];
};

export type ConfluencePageUri = {
  __typename?: 'ConfluencePageURI';
  contentId: Scalars['String'];
  hostname: Scalars['String'];
  spaceKey: Scalars['String'];
};

export type CustomKeyword = {
  __typename?: 'CustomKeyword';
  keyword: Scalars['String'];
};

export enum DisableFeatureState {
  Enabled = 'ENABLED',
  NotUsedByTeam = 'NOT_USED_BY_TEAM',
  Other = 'OTHER',
  SuperiorsWontInstall = 'SUPERIORS_WONT_INSTALL',
  TooExpensive = 'TOO_EXPENSIVE',
}

export type DiscussionAnalyticsFilters = {
  endDate?: InputMaybe<Scalars['Date']>;
  recursive: Scalars['Boolean'];
  startDate?: InputMaybe<Scalars['Date']>;
  teams: Array<Scalars['ID']>;
};

export type DiscussionAnalyticsInsight =
  DiscussionAnalyticsInsightKeywordHighlight;

export type DiscussionAnalyticsInsightKeywordHighlight = {
  __typename?: 'DiscussionAnalyticsInsightKeywordHighlight';
  keyword: Scalars['String'];
  sentiment: Scalars['String'];
};

export type DiscussionAnalyticsResults = {
  __typename?: 'DiscussionAnalyticsResults';
  averageTimeToResolveMs?: Maybe<Scalars['Long']>;
  avgTimeBetweenRetrosMs?: Maybe<Scalars['Long']>;
  insight?: Maybe<DiscussionAnalyticsInsight>;
  negativeCardPercentage: Scalars['Float'];
  negativeKeywordExamples: Array<Scalars['String']>;
  neutralCardPercentage: Scalars['Float'];
  neutralKeywordExamples: Array<Scalars['String']>;
  participationRatePercent: Scalars['Float'];
  positiveCardPercentage: Scalars['Float'];
  positiveKeywordExamples: Array<Scalars['String']>;
};

export type ExistingKeyword = {
  __typename?: 'ExistingKeyword';
  keyword: Scalars['String'];
  recurrence: Scalars['Int'];
};

export type FlowInsight = FlowInsightForecast;

export type FlowInsightForecast = {
  __typename?: 'FlowInsightForecast';
  cycleTimeMs: Scalars['Long'];
  itemsNearCycleTime: Array<WorkItemWithLifecycle>;
  itemsOverCycleTime: Array<WorkItemWithLifecycle>;
};

export type FlowMetrics = {
  __typename?: 'FlowMetrics';
  cycleTimeHistogram: Array<HistogramData>;
  cycleTimeMs: Scalars['Long'];
  insight?: Maybe<FlowInsight>;
  isProcessing: Scalars['Boolean'];
  lastIngestionAt?: Maybe<Scalars['Date']>;
  leadTimeMs: Scalars['Long'];
  predictabilityQuality: QualityName;
  statuses: Array<WorkItemTimeSpentInExternalStatus>;
  workItemsPerDay: Scalars['Float'];
};

export type GenerateReportInput = {
  inputData: Scalars['JSON'];
  teamId: Scalars['ID'];
  type: ReportType;
};

export type GithubRepository = {
  __typename?: 'GithubRepository';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type GoalFocusAnalyticsFilter = {
  teamId: Scalars['ID'];
};

export type GoalFocusAnalyticsResults = {
  __typename?: 'GoalFocusAnalyticsResults';
  orgGoalsFocusData?: Maybe<Array<GoalFocusData>>;
  programGoalsFocusData: Array<GoalFocusData>;
  teamGoalsFocusData: Array<GoalFocusData>;
};

export type GoalFocusData = {
  __typename?: 'GoalFocusData';
  goalColor: Scalars['String'];
  goalName: Scalars['String'];
  offTargetCount: Scalars['Int'];
  offTargetPercentage: Scalars['Float'];
  onTargetCount: Scalars['Int'];
  onTargetPercentage: Scalars['Float'];
};

export type HistogramData = {
  __typename?: 'HistogramData';
  from: Scalars['Long'];
  to: Scalars['Long'];
  value: Scalars['Float'];
};

export type ImpactReport = {
  __typename?: 'ImpactReport';
  badges: Array<ImpactReportBadge>;
  cards: Array<ImpactReportSummaryCard>;
  engagementScoreOverTime: ImpactReportEngagementScoreOverTime;
  insight: Scalars['String'];
  sentimentOverTime: ImpactReportSentimentOverTime;
};

export type ImpactReportBadge = {
  __typename?: 'ImpactReportBadge';
  detail?: Maybe<Scalars['String']>;
  icon: Scalars['String'];
  title: Scalars['String'];
  value: Scalars['String'];
};

export type ImpactReportBoardSummary = {
  __typename?: 'ImpactReportBoardSummary';
  engagementScore: Scalars['Float'];
  issuesInFeedback: Array<ImpactReportSummaryCardItem>;
  negativeCount: Scalars['Int'];
  neutralCount: Scalars['Int'];
  positiveCount: Scalars['Int'];
  sentiment: Scalars['Float'];
  summary: Scalars['String'];
};

export type ImpactReportEngagementScoreOverTime = {
  __typename?: 'ImpactReportEngagementScoreOverTime';
  data: Array<ImpactReportEngagementScoreOverTimeDataPoint>;
  legend: Scalars['String'];
};

export type ImpactReportEngagementScoreOverTimeDataPoint = {
  __typename?: 'ImpactReportEngagementScoreOverTimeDataPoint';
  date: Scalars['Date'];
  value: Scalars['Float'];
};

export type ImpactReportFilter = {
  endDate: Scalars['Date'];
  rollupTeamIds: Array<Scalars['ID']>;
  startDate: Scalars['Date'];
  teamId: Scalars['ID'];
  utcOffsetMs: Scalars['Int'];
};

export type ImpactReportIssue = {
  __typename?: 'ImpactReportIssue';
  color: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  occurrences: Array<ImpactReportIssueOccurrence>;
};

export type ImpactReportIssueCause =
  | ImpactReportIssueCauseInsight
  | ImpactReportIssueCauseTopic;

export type ImpactReportIssueCauseInsight = {
  __typename?: 'ImpactReportIssueCauseInsight';
  origin: Scalars['String'];
  summary: Scalars['String'];
  teamCount: Scalars['Int'];
};

export type ImpactReportIssueCauseTopic = {
  __typename?: 'ImpactReportIssueCauseTopic';
  cards: Array<ImpactReportIssueCauseTopicCard>;
  summary: Scalars['String'];
};

export type ImpactReportIssueCauseTopicCard = {
  __typename?: 'ImpactReportIssueCauseTopicCard';
  badgeColor: Scalars['String'];
  badgeText: Scalars['String'];
  retroDate: Scalars['Date'];
  retroName: Scalars['String'];
  text: Scalars['String'];
};

export type ImpactReportIssueDrilldown = {
  __typename?: 'ImpactReportIssueDrilldown';
  actionItemsCountRelated: Scalars['Int'];
  actionItemsCountResolved: Scalars['Int'];
  color: Scalars['String'];
  estimatedCost: Scalars['Float'];
  id: Scalars['String'];
  impactedTeams: Array<ImpactReportIssueDrilldownImpactedTeam>;
  impactedTeamsSeverity: Scalars['String'];
  keywords: Array<Scalars['String']>;
  mentionCount: Scalars['Int'];
  nature: Scalars['String'];
  negativeMentionCount: Scalars['Int'];
  possibleCauses: Array<ImpactReportIssueCause>;
  recurrenceData: Array<ImpactReportIssueOccurrence>;
  recurrenceSummary: Scalars['String'];
  summary: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type ImpactReportIssueDrilldownImpactedTeam = {
  __typename?: 'ImpactReportIssueDrilldownImpactedTeam';
  id: Scalars['String'];
  name: Scalars['String'];
  occurrenceCount: Scalars['Int'];
};

export type ImpactReportIssueOccurrence = {
  __typename?: 'ImpactReportIssueOccurrence';
  cardCount: Scalars['Int'];
  date: Scalars['Date'];
  teamCount: Scalars['Int'];
};

export type ImpactReportSentimentOverTime = {
  __typename?: 'ImpactReportSentimentOverTime';
  data: Array<ImpactReportSentimentOverTimeDataPoint>;
  legend: Scalars['String'];
};

export type ImpactReportSentimentOverTimeDataPoint = {
  __typename?: 'ImpactReportSentimentOverTimeDataPoint';
  date: Scalars['Date'];
  negativeCount: Scalars['Int'];
  neutralCount: Scalars['Int'];
  positiveCount: Scalars['Int'];
  value: Scalars['Float'];
};

export type ImpactReportSummaryCard = {
  __typename?: 'ImpactReportSummaryCard';
  badges: Array<ImpactReportBadge>;
  description: Scalars['String'];
  items: Array<ImpactReportSummaryCardItem>;
  monetaryImpact: Scalars['Float'];
  summary: Scalars['String'];
  title: Scalars['String'];
};

export type ImpactReportSummaryCardItem = {
  __typename?: 'ImpactReportSummaryCardItem';
  hoursAssociated: Scalars['Float'];
  id: Scalars['String'];
  improvementGoalId?: Maybe<Scalars['String']>;
  monetaryImpact: Scalars['Float'];
  occurrenceCount: Scalars['Int'];
  severity: Scalars['String'];
  summary: Scalars['String'];
  teamCount: Scalars['Int'];
  title: Scalars['String'];
  topics: Array<Scalars['String']>;
  type: Scalars['String'];
};

export type ImprovementGoal = {
  __typename?: 'ImprovementGoal';
  actionsTakenCount: Scalars['Int'];
  archived: Scalars['Boolean'];
  color: Scalars['String'];
  createdAt: Scalars['Date'];
  data: Array<ImprovementGoalData>;
  goalIssues: Array<Issue>;
  id: Scalars['ID'];
  ongoingActionsCount: Scalars['Int'];
  paused: Scalars['Boolean'];
  target: Scalars['Float'];
  team: Team;
  timesUsed: Scalars['Int'];
  type: ImprovementGoalType;
  updatedAt: Scalars['Date'];
  user: User;
  userReminders: Array<ImprovementGoalUserReminder>;
};

export type ImprovementGoalDataArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  reportingDataTeamIds?: Array<ImprovementGoalTeam>;
  takeLastNPeriodsWithData?: Scalars['Int'];
  toDate?: InputMaybe<Scalars['Date']>;
  utcOffsetMs?: Scalars['Int'];
};

export type ImprovementGoalGoalIssuesArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  reportingDataTeamIds?: Array<ImprovementGoalTeam>;
  takeLastNPeriodsWithData?: Scalars['Int'];
  toDate?: InputMaybe<Scalars['Date']>;
};

export enum ImprovementGoalAggregationFunction {
  Avg = 'AVG',
  Sum = 'SUM',
}

export enum ImprovementGoalAggregationPeriod {
  Day = 'DAY',
  Week = 'WEEK',
}

export type ImprovementGoalData = {
  __typename?: 'ImprovementGoalData';
  archived: Scalars['Boolean'];
  children: Array<Maybe<ImprovementGoalData>>;
  createdAt: Scalars['Date'];
  date: Scalars['Date'];
  id: Scalars['ID'];
  improvementGoalId: Scalars['ID'];
  origin?: Maybe<ImprovementGoalDataOrigin>;
  reportingTeamId?: Maybe<Scalars['ID']>;
  value: Scalars['Float'];
};

export type ImprovementGoalDataImportInput = {
  date: Scalars['Date'];
  value: Scalars['Int'];
};

export enum ImprovementGoalDataOrigin {
  Compressed = 'COMPRESSED',
  LivePoll = 'LIVE_POLL',
  Manual = 'MANUAL',
}

export type ImprovementGoalDistribution = {
  __typename?: 'ImprovementGoalDistribution';
  goal?: Maybe<ImprovementGoal>;
  percentage: Scalars['Float'];
};

export type ImprovementGoalInput = {
  paused: Scalars['Boolean'];
  target: Scalars['Int'];
  typeId?: InputMaybe<Scalars['String']>;
};

export type ImprovementGoalInsight = ImprovementGoalInsightFocus;

export type ImprovementGoalInsightFocus = {
  __typename?: 'ImprovementGoalInsightFocus';
  goalName: Scalars['String'];
  percentage: Scalars['Float'];
};

export type ImprovementGoalOnOffTeam = {
  __typename?: 'ImprovementGoalOnOffTeam';
  lastValue: Scalars['Float'];
  team: Team;
};

export type ImprovementGoalTeam = {
  teamId: Scalars['ID'];
  teamType?: InputMaybe<ImprovementGoalTeamType>;
};

export enum ImprovementGoalTeamType {
  Organization = 'ORGANIZATION',
  Program = 'PROGRAM',
  Team = 'TEAM',
}

export type ImprovementGoalType = {
  __typename?: 'ImprovementGoalType';
  aggregationFunction: ImprovementGoalAggregationFunction;
  aggregationPeriod: ImprovementGoalAggregationPeriod;
  categories: Array<ImprovementGoalTypeCategory>;
  color: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isPollable: Scalars['Boolean'];
  learnMoreUrl?: Maybe<Scalars['String']>;
  maxValue?: Maybe<Scalars['Float']>;
  minValue?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  subCategory?: Maybe<Scalars['String']>;
  targetType: ImprovementGoalTypeTargetType;
  unit: ImprovementGoalUnit;
};

export enum ImprovementGoalTypeCategory {
  Agile = 'AGILE',
  Bitbucket = 'BITBUCKET',
  Dora = 'DORA',
  Github = 'GITHUB',
  Jira = 'JIRA',
  Scatterspoke = 'SCATTERSPOKE',
  Space = 'SPACE',
}

export enum ImprovementGoalTypeTargetType {
  Above = 'ABOVE',
  Below = 'BELOW',
}

export enum ImprovementGoalUnit {
  Abstract = 'ABSTRACT',
  BugsByChanges = 'BUGS_BY_CHANGES',
  BugsInProd = 'BUGS_IN_PROD',
  Days = 'DAYS',
  Deployments = 'DEPLOYMENTS',
  Documents = 'DOCUMENTS',
  Dollars = 'DOLLARS',
  Handoffs = 'HANDOFFS',
  Hours = 'HOURS',
  Incidents = 'INCIDENTS',
  Interruptions = 'INTERRUPTIONS',
  Issues = 'ISSUES',
  Meetings = 'MEETINGS',
  Minutes = 'MINUTES',
  NewCostumers = 'NEW_COSTUMERS',
  NpsScore = 'NPS_SCORE',
  Pages = 'PAGES',
  People = 'PEOPLE',
  Percent = 'PERCENT',
  Points = 'POINTS',
  Projects = 'PROJECTS',
  PullRequests = 'PULL_REQUESTS',
}

export type ImprovementGoalUserReminder = {
  __typename?: 'ImprovementGoalUserReminder';
  createdAt: Scalars['Date'];
  frequency: ImprovementGoalUserReminderFrequency;
  improvementGoal: ImprovementGoal;
  improvementGoalId: Scalars['ID'];
  notificationTime: Scalars['Date'];
  updatedAt: Scalars['Date'];
  user: User;
  userId: Scalars['ID'];
};

export enum ImprovementGoalUserReminderFrequency {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  TwiceWeek = 'TWICE_WEEK',
  Weekly = 'WEEKLY',
}

export type ImprovementRating = {
  __typename?: 'ImprovementRating';
  feedbackRating?: Maybe<Scalars['Float']>;
  feedbackRatingChange?: Maybe<Scalars['Float']>;
  followThroughRating?: Maybe<Scalars['Float']>;
  followThroughRatingChange?: Maybe<Scalars['Float']>;
  frequencyRating?: Maybe<Scalars['Float']>;
  frequencyRatingChange?: Maybe<Scalars['Float']>;
  totalRating?: Maybe<Scalars['Float']>;
  totalRatingChange?: Maybe<Scalars['Float']>;
};

export type InFlightWorkItem = BaseWorkItem & {
  __typename?: 'InFlightWorkItem';
  createdAt: Scalars['Date'];
  cycleTimeQuality: QualityName;
  description: Scalars['String'];
  externalStatusName: Scalars['String'];
  externalUrl: Scalars['String'];
  origin: WorkItemOrigin;
  slug: Scalars['String'];
  staleForMs: Scalars['Long'];
  statusAgeQuality: QualityName;
  timeLeftToExternalStatusCycleTimeMs: Scalars['Long'];
  timeLeftToOverallCycleTimeMs: Scalars['Long'];
  title: Scalars['String'];
  wipTimeMs: Scalars['Long'];
};

export type InFlightWorkItemsResult = {
  __typename?: 'InFlightWorkItemsResult';
  insight?: Maybe<FlowInsight>;
  statusNames: Array<Scalars['String']>;
  workItems: Array<InFlightWorkItem>;
};

export type InstalledOrganizationIntegrations = {
  __typename?: 'InstalledOrganizationIntegrations';
  bitbucket: Scalars['Boolean'];
  github: Scalars['Boolean'];
  jira: Scalars['Boolean'];
  slack: Scalars['Boolean'];
  slackHmacPublicDigest: Scalars['String'];
};

export type InstalledUserIntegrations = {
  __typename?: 'InstalledUserIntegrations';
  confluence: Scalars['Boolean'];
  jira: Scalars['Boolean'];
  miro: Scalars['Boolean'];
  trello: Scalars['Boolean'];
};

export type Invoice = {
  __typename?: 'Invoice';
  accountCountry?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  amountDue?: Maybe<Scalars['Int']>;
  amountPaid?: Maybe<Scalars['Int']>;
  amountRemaining?: Maybe<Scalars['Int']>;
  attemptCount?: Maybe<Scalars['Int']>;
  attempted?: Maybe<Scalars['Boolean']>;
  billingReason?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  currency: Scalars['String'];
  customerId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  hostedInvoiceUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  invoicePdfUrl?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['Boolean']>;
  receiptNumber?: Maybe<Scalars['String']>;
  startingBalance?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subtotal?: Maybe<Scalars['Int']>;
  tax?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  webhooksDeliveredAt?: Maybe<Scalars['Date']>;
};

export type Issue = {
  __typename?: 'Issue';
  id: Scalars['ID'];
  shortSummary: Scalars['String'];
  summary: Scalars['String'];
};

export type JiraBoard = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type KeyCount = {
  __typename?: 'KeyCount';
  count: Scalars['Int'];
  name: Scalars['String'];
};

export type KeywordAnalysis = {
  __typename?: 'KeywordAnalysis';
  keyword: Scalars['String'];
  total: Scalars['Int'];
};

export type Keywords = {
  __typename?: 'Keywords';
  customIgnores: Array<CustomKeyword>;
  existing: Array<ExistingKeyword>;
};

export type LinkableJiraBoard = JiraBoard & {
  __typename?: 'LinkableJiraBoard';
  id: Scalars['ID'];
  isSoftwareDevProject: Scalars['Boolean'];
  name: Scalars['String'];
};

export type LinkedJiraBoard = JiraBoard & {
  __typename?: 'LinkedJiraBoard';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type List = {
  __typename?: 'List';
  archived?: Maybe<Scalars['Boolean']>;
  backgroundColor?: Maybe<Scalars['String']>;
  boardId: Scalars['String'];
  cardColor?: Maybe<Scalars['String']>;
  cards?: Maybe<Array<Maybe<Card>>>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  index: Scalars['Int'];
  name: Scalars['String'];
  ownerId: Scalars['String'];
  teamId: Scalars['String'];
};

export type ListInput = {
  cards?: InputMaybe<Array<InputMaybe<CardInput>>>;
  name: Scalars['String'];
};

export type LivePoll = {
  __typename?: 'LivePoll';
  answeredUserIds: Array<Scalars['ID']>;
  board?: Maybe<Board>;
  boardId: Scalars['ID'];
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  improvementGoal?: Maybe<ImprovementGoal>;
  result?: Maybe<Scalars['Float']>;
  skippedUserIds: Array<Scalars['ID']>;
  status: LivePollUserStatus;
  title: Scalars['String'];
};

export type LivePollAnswer = {
  __typename?: 'LivePollAnswer';
  answer?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  livePollId: Scalars['String'];
  skipReason?: Maybe<LivePollSkipReason>;
  userId: Scalars['String'];
};

export enum LivePollSkipReason {
  NotComfortable = 'NOT_COMFORTABLE',
  NotForMe = 'NOT_FOR_ME',
}

export enum LivePollStatus {
  Discarded = 'DISCARDED',
  Finished = 'FINISHED',
  Running = 'RUNNING',
}

export enum LivePollUserStatus {
  Answered = 'ANSWERED',
  Discarded = 'DISCARDED',
  Finished = 'FINISHED',
  Running = 'RUNNING',
  Skipped = 'SKIPPED',
}

export type LoginInput = {
  email?: InputMaybe<Scalars['String']>;
  inviteToken?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type MembershipQueryResults = {
  __typename?: 'MembershipQueryResults';
  memberships: Array<OrganizationUserMembership>;
  totalSize: Scalars['Int'];
};

export enum MembershipRole {
  Admin = 'ADMIN',
  All = 'ALL',
  Member = 'MEMBER',
  Printer = 'PRINTER',
}

export enum MetricsProviderCategory {
  ProjectManagement = 'PROJECT_MANAGEMENT',
  Scatterspoke = 'SCATTERSPOKE',
  SourceControl = 'SOURCE_CONTROL',
}

export type Mutation = {
  __typename?: 'Mutation';
  addCardToGroup?: Maybe<Board>;
  addDomainAccess?: Maybe<Scalars['Boolean']>;
  addImprovementGoalData: ImprovementGoalData;
  addParkingLotList: Board;
  addTeamToProgram?: Maybe<Team>;
  addTeamsToOrganization?: Maybe<Organization>;
  addUserToTeam?: Maybe<Team>;
  addWatchersToTeams?: Maybe<Array<Maybe<Team>>>;
  archiveActionItemComment: ActionItemComment;
  archiveBoardAsyncSchedule: BoardAsyncSchedule;
  archiveCardComment: CardComment;
  archiveCommentForActivityFeed?: Maybe<Activity>;
  archiveImprovementGoalData?: Maybe<ImprovementGoalData>;
  archiveParkingLotItem: ParkingLotItem;
  archiveReport: Report;
  archiveTeam?: Maybe<Team>;
  autoSuggestBoardVoteCount?: Maybe<Board>;
  boardMessageBroadcast?: Maybe<Scalars['Boolean']>;
  cancelSubscription?: Maybe<Organization>;
  changeParkingLotItemText: ParkingLotItem;
  changeTeamOwner?: Maybe<Team>;
  changeUserCurrentTeamAndAddIfNotMember?: Maybe<User>;
  cloneBoard?: Maybe<Board>;
  copyParkingLotItemToTeam: ParkingLotItem;
  createActionItem: ActionItem;
  createActionItemComment: ActionItemComment;
  createBoard?: Maybe<Board>;
  createBoardAsyncSchedule: BoardAsyncSchedule;
  createCard?: Maybe<Card>;
  createCardComment: CardComment;
  createCommentForActivityFeed?: Maybe<Activity>;
  createGroupCard?: Maybe<Board>;
  createImprovementGoal: ImprovementGoal;
  createJiraIssueFromActionItem: ActionItem;
  createJiraIssueFromCard: Card;
  createList?: Maybe<List>;
  createOrganization?: Maybe<User>;
  createParkingLotCard: ParkingLotItem;
  createParkingLotItemFromCardId: ParkingLotItem;
  createTeam?: Maybe<Team>;
  createUser?: Maybe<User>;
  createVerificationToken?: Maybe<Scalars['Boolean']>;
  createWhiteboard?: Maybe<Board>;
  createWhiteboardCard?: Maybe<Card>;
  deleteOwnAccount: Scalars['Boolean'];
  discardLivePollResult: LivePoll;
  duplicateCard?: Maybe<Board>;
  exportTeamInsightToConfluence: ConfluencePageUri;
  finishBoard: Board;
  finishLivePollsByBoardId: Array<LivePoll>;
  forgotPassword?: Maybe<Scalars['Boolean']>;
  generateBoardTakeaways: Board;
  generateReport: Report;
  importBoards?: Maybe<Array<Maybe<Board>>>;
  importImprovementGoalData: ImprovementGoal;
  installBitbucketMetricsIntegration: Organization;
  installGithubIntegration: Organization;
  installJiraMetricsIntegration: Organization;
  inviteToBoardByEmail?: Maybe<Board>;
  inviteToTeamByEmail: Team;
  leaveProgram?: Maybe<Team>;
  leaveTeam?: Maybe<Team>;
  linkTeamInsightActionItemOrigin: TeamInsight;
  login?: Maybe<User>;
  markCardDiscussionReadiness?: Maybe<Board>;
  markTeamInsightAsSeen: TeamInsight;
  moveCardIntoList?: Maybe<Board>;
  notifyTeam: Scalars['Boolean'];
  oneTimePasswordLogin?: Maybe<User>;
  organizationMoreInfo?: Maybe<Array<Maybe<QuestionAnswer>>>;
  pingBoardParticipationAsAuthenticatedUser?: Maybe<PingBoardParticipationAsAuthenticatedUserOutput>;
  promoteCardToPrograms: Card;
  rateTeamInsight: TeamInsight;
  removeImprovementGoal?: Maybe<ImprovementGoal>;
  removeLivePollAnswer: LivePoll;
  removeMembership: OrganizationUserMembership;
  removeWatcherFromTeams?: Maybe<Array<Maybe<Team>>>;
  renewVerificationToken?: Maybe<Scalars['String']>;
  replaceImprovementGoalData: Array<Maybe<ImprovementGoalData>>;
  requestAccessToTeam?: Maybe<Scalars['Boolean']>;
  resetPassword?: Maybe<User>;
  resetVotesBoard?: Maybe<Board>;
  setBoardCardBeingDiscussed: Board;
  setDiscussSort: Board;
  setImprovementGoalUserReminders: ImprovementGoal;
  sortBoard?: Maybe<Array<Maybe<List>>>;
  startGoalsLivePoll: Array<LivePoll>;
  stopBoardParticipation?: Maybe<StopBoardParticipationOutput>;
  submitLivePollAnswer: LivePoll;
  submitLivePollSkipAnswer: LivePoll;
  toggleInviteToken?: Maybe<Team>;
  toggleReaction?: Maybe<Activity>;
  trialRegister?: Maybe<Scalars['String']>;
  trialRegisterQuestions?: Maybe<Scalars['String']>;
  triggerBoardAsyncScheduleById: Scalars['Boolean'];
  uninstallBitbucketMetricsIntegration: Organization;
  uninstallGithubIntegration: Organization;
  uninstallJiraMetricsIntegration: Organization;
  uninstallSlackIntegration: Scalars['Boolean'];
  updateActionItem?: Maybe<ActionItem>;
  updateBoard?: Maybe<Board>;
  updateBoardAsyncSchedule: BoardAsyncSchedule;
  updateBoardStage?: Maybe<UpdateBoardStageOutput>;
  updateCard?: Maybe<Card>;
  updateCardPosition?: Maybe<CardPosition>;
  updateCardText?: Maybe<Card>;
  updateCardUserSetThemes?: Maybe<Scalars['Boolean']>;
  updateIgnoredTopics: Scalars['Boolean'];
  updateImprovementGoal?: Maybe<ImprovementGoal>;
  updateLinkedBitbucketRepositories: Team;
  updateLinkedGithubRepositories: Team;
  updateLinkedJiraBoards: Team;
  updateList?: Maybe<List>;
  updateListOrder?: Maybe<Board>;
  updateLivePollStatus: LivePoll;
  updateMembershipRole: OrganizationUserMembership;
  updateOrganization?: Maybe<Organization>;
  updateTeam?: Maybe<Team>;
  updateUser: User;
  verifyEmail?: Maybe<User>;
  voteCard?: Maybe<Card>;
};

export type MutationAddCardToGroupArgs = {
  cardToAddId?: InputMaybe<Scalars['String']>;
  groupId: Scalars['String'];
  newChildrenIdsOrder: Array<Scalars['String']>;
};

export type MutationAddDomainAccessArgs = {
  domain: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type MutationAddImprovementGoalDataArgs = {
  date: Scalars['Date'];
  improvementGoalId: Scalars['ID'];
  reportingTeamId?: InputMaybe<Scalars['ID']>;
  value: Scalars['Float'];
};

export type MutationAddParkingLotListArgs = {
  boardId: Scalars['ID'];
  parkingLotItemIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type MutationAddTeamToProgramArgs = {
  programId: Scalars['ID'];
  teamId: Scalars['ID'];
};

export type MutationAddTeamsToOrganizationArgs = {
  organizationId: Scalars['String'];
  teamIdsToMigrate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MutationAddUserToTeamArgs = {
  teamId: Scalars['ID'];
  userId: Scalars['String'];
};

export type MutationAddWatchersToTeamsArgs = {
  organizationId: Scalars['ID'];
  teamsIds: Array<InputMaybe<Scalars['ID']>>;
  userId: Scalars['ID'];
  watchersMails: Array<InputMaybe<Scalars['String']>>;
};

export type MutationArchiveActionItemCommentArgs = {
  commentId: Scalars['ID'];
};

export type MutationArchiveBoardAsyncScheduleArgs = {
  id: Scalars['ID'];
};

export type MutationArchiveCardCommentArgs = {
  commentId: Scalars['ID'];
};

export type MutationArchiveCommentForActivityFeedArgs = {
  activityFeedCommentId: Scalars['String'];
  activityId: Scalars['String'];
};

export type MutationArchiveImprovementGoalDataArgs = {
  improvementGoalDataId: Scalars['ID'];
};

export type MutationArchiveParkingLotItemArgs = {
  id: Scalars['ID'];
};

export type MutationArchiveReportArgs = {
  reportId: Scalars['ID'];
};

export type MutationArchiveTeamArgs = {
  teamId: Scalars['ID'];
};

export type MutationAutoSuggestBoardVoteCountArgs = {
  boardId: Scalars['String'];
};

export type MutationBoardMessageBroadcastArgs = {
  boardMessageBroadcastInput: BoardMessageBroadcastInput;
};

export type MutationCancelSubscriptionArgs = {
  organizationId: Scalars['String'];
};

export type MutationChangeParkingLotItemTextArgs = {
  itemId: Scalars['ID'];
  text: Scalars['String'];
};

export type MutationChangeTeamOwnerArgs = {
  newOwnerId: Scalars['ID'];
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationChangeUserCurrentTeamAndAddIfNotMemberArgs = {
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationCloneBoardArgs = {
  boardIdToClone: Scalars['String'];
  nameOfClone: Scalars['String'];
};

export type MutationCopyParkingLotItemToTeamArgs = {
  itemId: Scalars['ID'];
  teamId: Scalars['ID'];
};

export type MutationCreateActionItemArgs = {
  assigneeIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  boardId?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['Date']>;
  groupId?: InputMaybe<Scalars['String']>;
  improvementGoals?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jiraProjectKey?: InputMaybe<Scalars['String']>;
  originalCardId?: InputMaybe<Scalars['String']>;
  teamId: Scalars['String'];
  title: Scalars['String'];
  votes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MutationCreateActionItemCommentArgs = {
  actionItemId: Scalars['ID'];
  text: Scalars['String'];
};

export type MutationCreateBoardArgs = {
  format?: InputMaybe<Scalars['String']>;
  guided: Scalars['Boolean'];
  listNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['String']>;
  ownerId: Scalars['String'];
  parkingLotItems?: InputMaybe<Array<Scalars['ID']>>;
  public: Scalars['Boolean'];
  startOnStage?: InputMaybe<BoardStage>;
  teamId: Scalars['String'];
};

export type MutationCreateBoardAsyncScheduleArgs = {
  customQuestions?: InputMaybe<Array<BoardAsyncScheduleCustomQuestionInput>>;
  data: BoardAsyncScheduleInput;
};

export type MutationCreateCardArgs = {
  listId: Scalars['String'];
  text: Scalars['String'];
};

export type MutationCreateCardCommentArgs = {
  cardId: Scalars['ID'];
  text: Scalars['String'];
};

export type MutationCreateCommentForActivityFeedArgs = {
  activityId: Scalars['String'];
  text: Scalars['String'];
};

export type MutationCreateGroupCardArgs = {
  dragCardId?: InputMaybe<Scalars['String']>;
  dropCardId?: InputMaybe<Scalars['String']>;
};

export type MutationCreateImprovementGoalArgs = {
  input: ImprovementGoalInput;
};

export type MutationCreateJiraIssueFromActionItemArgs = {
  actionItemId: Scalars['ID'];
  projectKey: Scalars['String'];
};

export type MutationCreateJiraIssueFromCardArgs = {
  cardId: Scalars['ID'];
  projectKey: Scalars['String'];
};

export type MutationCreateListArgs = {
  boardId: Scalars['String'];
  name: Scalars['String'];
};

export type MutationCreateOrganizationArgs = {
  accountName: Scalars['String'];
};

export type MutationCreateParkingLotCardArgs = {
  boardId?: InputMaybe<Scalars['ID']>;
  ownerId?: InputMaybe<Scalars['ID']>;
  teamId: Scalars['ID'];
  text: Scalars['String'];
};

export type MutationCreateParkingLotItemFromCardIdArgs = {
  boardId: Scalars['ID'];
  cardId: Scalars['ID'];
  ownerId?: InputMaybe<Scalars['ID']>;
  teamId: Scalars['ID'];
};

export type MutationCreateTeamArgs = {
  teamInput?: InputMaybe<TeamInput>;
};

export type MutationCreateUserArgs = {
  userInput?: InputMaybe<UserCreateInput>;
};

export type MutationCreateVerificationTokenArgs = {
  email: Scalars['String'];
};

export type MutationCreateWhiteboardArgs = {
  format?: InputMaybe<Scalars['String']>;
  guided: Scalars['Boolean'];
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['String']>;
  ownerId: Scalars['String'];
  parkingLotItems?: InputMaybe<Array<Scalars['ID']>>;
  public: Scalars['Boolean'];
  teamId: Scalars['String'];
};

export type MutationCreateWhiteboardCardArgs = {
  boardId: Scalars['String'];
  ownerId?: InputMaybe<Scalars['String']>;
  teamId: Scalars['String'];
  text: Scalars['String'];
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type MutationDiscardLivePollResultArgs = {
  livePollId: Scalars['ID'];
};

export type MutationDuplicateCardArgs = {
  card: Scalars['JSON'];
  oldCardIdsOrder: Array<InputMaybe<Scalars['String']>>;
  ownerId?: InputMaybe<Scalars['String']>;
};

export type MutationExportTeamInsightToConfluenceArgs = {
  confluenceSpaceKey: Scalars['String'];
  teamInsightId: Scalars['ID'];
};

export type MutationFinishBoardArgs = {
  id: Scalars['ID'];
  sendSummary: Scalars['Boolean'];
};

export type MutationFinishLivePollsByBoardIdArgs = {
  boardId: Scalars['ID'];
};

export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
  isChangingProviders?: Scalars['Boolean'];
};

export type MutationGenerateBoardTakeawaysArgs = {
  boardId: Scalars['ID'];
};

export type MutationGenerateReportArgs = {
  input: GenerateReportInput;
};

export type MutationImportBoardsArgs = {
  boardsInput?: InputMaybe<Array<InputMaybe<BoardsInput>>>;
};

export type MutationImportImprovementGoalDataArgs = {
  data: Array<ImprovementGoalDataImportInput>;
  improvementGoalId: Scalars['ID'];
  reportingTeamId?: InputMaybe<Scalars['ID']>;
};

export type MutationInstallBitbucketMetricsIntegrationArgs = {
  state: Scalars['String'];
};

export type MutationInstallGithubIntegrationArgs = {
  state: Scalars['String'];
};

export type MutationInstallJiraMetricsIntegrationArgs = {
  code: Scalars['String'];
};

export type MutationInviteToBoardByEmailArgs = {
  boardId: Scalars['ID'];
  email: Scalars['String'];
};

export type MutationInviteToTeamByEmailArgs = {
  email: Scalars['String'];
  teamId: Scalars['ID'];
};

export type MutationLeaveProgramArgs = {
  programId: Scalars['ID'];
  teamId: Scalars['ID'];
};

export type MutationLeaveTeamArgs = {
  teamId: Scalars['ID'];
  userId: Scalars['String'];
};

export type MutationLinkTeamInsightActionItemOriginArgs = {
  actionItemId: Scalars['ID'];
  teamInsightSolutionId: Scalars['ID'];
};

export type MutationLoginArgs = {
  loginInput?: InputMaybe<LoginInput>;
};

export type MutationMarkCardDiscussionReadinessArgs = {
  boardId: Scalars['String'];
  isReady: Scalars['Boolean'];
};

export type MutationMarkTeamInsightAsSeenArgs = {
  teamInsightId: Scalars['ID'];
};

export type MutationMoveCardIntoListArgs = {
  cardId?: InputMaybe<Scalars['String']>;
  listId: Scalars['String'];
  newCardIdsOrder: Array<InputMaybe<Scalars['String']>>;
};

export type MutationNotifyTeamArgs = {
  boardId: Scalars['ID'];
};

export type MutationOneTimePasswordLoginArgs = {
  oneTimePasswordLoginInput?: InputMaybe<OneTimePasswordLoginInput>;
};

export type MutationOrganizationMoreInfoArgs = {
  questions?: InputMaybe<Array<InputMaybe<QuestionAnswerInput>>>;
};

export type MutationPingBoardParticipationAsAuthenticatedUserArgs = {
  input?: InputMaybe<PingBoardParticipationAsAuthenticatedUserInput>;
};

export type MutationPromoteCardToProgramsArgs = {
  cardId: Scalars['String'];
  programIds: Array<Scalars['String']>;
};

export type MutationRateTeamInsightArgs = {
  rating: QualityName;
  teamInsightId: Scalars['ID'];
};

export type MutationRemoveImprovementGoalArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveLivePollAnswerArgs = {
  livePollId: Scalars['ID'];
};

export type MutationRemoveMembershipArgs = {
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationRemoveWatcherFromTeamsArgs = {
  teamsIds: Array<InputMaybe<Scalars['ID']>>;
  userId: Scalars['ID'];
  watcherId: Scalars['String'];
};

export type MutationRenewVerificationTokenArgs = {
  token: Scalars['String'];
};

export type MutationReplaceImprovementGoalDataArgs = {
  improvementGoalDataId: Scalars['ID'];
  newValue: Scalars['Float'];
};

export type MutationRequestAccessToTeamArgs = {
  teamId: Scalars['ID'];
  userId: Scalars['String'];
};

export type MutationResetPasswordArgs = {
  newPassword: Scalars['String'];
  resetToken: Scalars['String'];
};

export type MutationResetVotesBoardArgs = {
  boardId: Scalars['String'];
};

export type MutationSetBoardCardBeingDiscussedArgs = {
  boardId: Scalars['ID'];
  cardId: Scalars['ID'];
  moveCurrentCard: CardDiscussionMovement;
};

export type MutationSetDiscussSortArgs = {
  boardId: Scalars['ID'];
  sortByTags: Scalars['Boolean'];
  sortKey?: InputMaybe<BoardDiscussionCardsSortingKey>;
};

export type MutationSetImprovementGoalUserRemindersArgs = {
  frequency: ImprovementGoalUserReminderFrequency;
  improvementGoalId: Scalars['ID'];
  notificationTime: Scalars['Date'];
  userIds: Array<Scalars['ID']>;
};

export type MutationSortBoardArgs = {
  boardId: Scalars['String'];
  type: Scalars['String'];
};

export type MutationStartGoalsLivePollArgs = {
  boardId: Scalars['ID'];
  improvementGoalIds: Array<Scalars['ID']>;
};

export type MutationStopBoardParticipationArgs = {
  input?: InputMaybe<StopBoardParticipationInput>;
};

export type MutationSubmitLivePollAnswerArgs = {
  answer: Scalars['Int'];
  livePollId: Scalars['ID'];
};

export type MutationSubmitLivePollSkipAnswerArgs = {
  livePollId: Scalars['ID'];
  reason: LivePollSkipReason;
};

export type MutationToggleInviteTokenArgs = {
  teamId: Scalars['ID'];
};

export type MutationToggleReactionArgs = {
  activityId: Scalars['String'];
  kind: ActivityReactionKind;
};

export type MutationTrialRegisterArgs = {
  email: Scalars['String'];
};

export type MutationTrialRegisterQuestionsArgs = {
  email: Scalars['String'];
  questions?: InputMaybe<Array<InputMaybe<TrialQuestionAnswerInput>>>;
  score: Scalars['Int'];
};

export type MutationTriggerBoardAsyncScheduleByIdArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateActionItemArgs = {
  actionItemId: Scalars['String'];
  fields: Scalars['JSON'];
};

export type MutationUpdateBoardArgs = {
  boardId: Scalars['String'];
  fields: Scalars['JSON'];
};

export type MutationUpdateBoardAsyncScheduleArgs = {
  customQuestions?: InputMaybe<Array<BoardAsyncScheduleCustomQuestionInput>>;
  data: BoardAsyncScheduleInput;
  id: Scalars['ID'];
};

export type MutationUpdateBoardStageArgs = {
  input?: InputMaybe<UpdateBoardStageInput>;
};

export type MutationUpdateCardArgs = {
  cardId: Scalars['String'];
  fields: Scalars['JSON'];
};

export type MutationUpdateCardPositionArgs = {
  cardId: Scalars['String'];
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type MutationUpdateCardTextArgs = {
  cardId: Scalars['String'];
  text: Scalars['String'];
};

export type MutationUpdateCardUserSetThemesArgs = {
  toAdd?: InputMaybe<Array<InputMaybe<CardCategoryUpdate>>>;
  toRemove?: InputMaybe<Array<InputMaybe<CardCategoryUpdate>>>;
};

export type MutationUpdateIgnoredTopicsArgs = {
  teamIds: Array<Scalars['ID']>;
  toIgnore?: InputMaybe<Array<Scalars['String']>>;
  toUnignore?: InputMaybe<Array<Scalars['String']>>;
};

export type MutationUpdateImprovementGoalArgs = {
  id: Scalars['ID'];
  input: ImprovementGoalInput;
};

export type MutationUpdateLinkedBitbucketRepositoriesArgs = {
  bitbucketRepositoryIds: Array<Scalars['ID']>;
  teamId: Scalars['ID'];
};

export type MutationUpdateLinkedGithubRepositoriesArgs = {
  githubRepositoryIds: Array<Scalars['ID']>;
  teamId: Scalars['ID'];
};

export type MutationUpdateLinkedJiraBoardsArgs = {
  jiraBoardIds: Array<Scalars['ID']>;
  teamId: Scalars['ID'];
};

export type MutationUpdateListArgs = {
  fields: Scalars['JSON'];
  listId: Scalars['String'];
};

export type MutationUpdateListOrderArgs = {
  boardId: Scalars['String'];
  listIds: Array<InputMaybe<Scalars['String']>>;
};

export type MutationUpdateLivePollStatusArgs = {
  livePollId: Scalars['ID'];
  pollStatus: LivePollStatus;
};

export type MutationUpdateMembershipRoleArgs = {
  newRole: MembershipRole;
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationUpdateOrganizationArgs = {
  id: Scalars['ID'];
  organizationInput?: InputMaybe<OrganizationInput>;
};

export type MutationUpdateTeamArgs = {
  fields: Scalars['JSON'];
  teamId: Scalars['ID'];
  userId: Scalars['String'];
};

export type MutationUpdateUserArgs = {
  userInput?: InputMaybe<UserUpdateInput>;
};

export type MutationVerifyEmailArgs = {
  email: Scalars['String'];
  verificationToken: Scalars['String'];
};

export type MutationVoteCardArgs = {
  anonymousUser: Scalars['Boolean'];
  boardId?: InputMaybe<Scalars['String']>;
  cardId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
  voted: Scalars['Boolean'];
};

export type OneTimePasswordLoginInput = {
  email?: InputMaybe<Scalars['String']>;
  inviteToken?: InputMaybe<Scalars['String']>;
  otp?: InputMaybe<Scalars['String']>;
};

export type Organization = {
  __typename?: 'Organization';
  adminOnlyTeamCreation: Scalars['Boolean'];
  archived?: Maybe<Scalars['Boolean']>;
  averageTimeBetweenRetrospectives?: Maybe<Scalars['Float']>;
  betaFeatures: Array<BetaFeature>;
  countMemberships: Scalars['Int'];
  domains: Array<Scalars['String']>;
  hasSSO?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  inviteToken?: Maybe<Scalars['String']>;
  isExceedingSeats?: Maybe<Scalars['Boolean']>;
  memberships: MembershipQueryResults;
  name?: Maybe<Scalars['String']>;
  numberOfPrograms?: Maybe<Scalars['Int']>;
  numberOfRetrospectivesHeld?: Maybe<Scalars['Int']>;
  numberOfTeams?: Maybe<Scalars['Int']>;
  owner?: Maybe<User>;
  ownerId?: Maybe<Scalars['ID']>;
  plan?: Maybe<Plan>;
  prepaidSeatCount: Scalars['Int'];
  status?: Maybe<PaymentStatus>;
  subscriptionUserId?: Maybe<Scalars['String']>;
  team?: Maybe<Team>;
  trialEndsAt?: Maybe<Scalars['Date']>;
  userCount?: Maybe<Scalars['Int']>;
};

export type OrganizationCountMembershipsArgs = {
  roleFilter?: InputMaybe<Array<MembershipRole>>;
};

export type OrganizationMembershipsArgs = {
  email?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  roleFilter?: InputMaybe<Array<MembershipRole>>;
  sort?: InputMaybe<Scalars['JSON']>;
};

export type OrganizationInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type OrganizationUserMembership = {
  __typename?: 'OrganizationUserMembership';
  archived: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['Date']>;
  joinedAt?: Maybe<Scalars['Date']>;
  organization: Organization;
  organizationId: Scalars['String'];
  role: MembershipRole;
  seatType: SeatType;
  trialEndsAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  user: User;
};

export type OriginalListBadge = {
  __typename?: 'OriginalListBadge';
  color?: Maybe<Scalars['String']>;
  originalListId?: Maybe<Scalars['ID']>;
  text?: Maybe<Scalars['String']>;
};

export type OverdueActionItemsAnalysis = {
  __typename?: 'OverdueActionItemsAnalysis';
  actionItemId: Scalars['String'];
  actionItemTitle: Scalars['String'];
  dueDate: Scalars['Date'];
  retrospectiveId?: Maybe<Scalars['String']>;
  retrospectiveName: Scalars['String'];
};

export type ParkingLotItem = {
  __typename?: 'ParkingLotItem';
  archived: Scalars['Boolean'];
  author: User;
  children: Array<ParkingLotItem>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  team: Team;
  text: Scalars['String'];
  votes: Scalars['Int'];
};

export type ParticipationChangedInput = {
  boardId: Scalars['String'];
};

export enum PaymentStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  TrialExpired = 'TRIAL_EXPIRED',
}

export type PingBoardParticipationAsAuthenticatedUserInput = {
  boardId: Scalars['String'];
};

export type PingBoardParticipationAsAuthenticatedUserOutput = {
  __typename?: 'PingBoardParticipationAsAuthenticatedUserOutput';
  participations?: Maybe<Array<Maybe<BoardParticipation>>>;
  success: Scalars['Boolean'];
};

export type PingBoardParticipationInput = {
  boardId: Scalars['String'];
};

export type Plan = {
  __typename?: 'Plan';
  blockExceedingSeats?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
  integrationJira?: Maybe<Scalars['Boolean']>;
  integrationMicrosoftTeams?: Maybe<Scalars['Boolean']>;
  programs?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  sso?: Maybe<Scalars['Boolean']>;
  supportLevel?: Maybe<SupportLevel>;
  trialUserTimeSeconds?: Maybe<Scalars['Int']>;
};

export enum QualityName {
  Good = 'GOOD',
  Poor = 'POOR',
  Regular = 'REGULAR',
}

export type Query = {
  __typename?: 'Query';
  actionItem?: Maybe<ActionItem>;
  actionItemAnalytics: ActionItemAnalyticsResults;
  actionItemReviewAnalytics: ActionItemReviewAnalytics;
  actionItemsByBoardIds: Array<ActionItem>;
  actionItemsByFilters: Array<ActionItem>;
  billingDetails?: Maybe<BillingDetails>;
  board?: Maybe<Board>;
  boardAnalytics: BoardAnalyticsResults;
  boardAsyncScheduleById: BoardAsyncSchedule;
  boardAsyncSchedulesByTeamId: Array<BoardAsyncSchedule>;
  boardNameByTeamInviteAndBoardId?: Maybe<Scalars['String']>;
  boardVoteCountByAllUsers?: Maybe<BoardTotalVoteCounts>;
  boardVoteCountByIdAndUserId?: Maybe<BoardVoteCounts>;
  card?: Maybe<Card>;
  countOrganizationSeatsByType: Scalars['Int'];
  discussionAnalytics?: Maybe<DiscussionAnalyticsResults>;
  domainAccessAllowed?: Maybe<Scalars['Boolean']>;
  feed?: Maybe<ActivityFeed>;
  findImprovementGoalById: ImprovementGoal;
  findMembershipByIds?: Maybe<OrganizationUserMembership>;
  flowMetrics: FlowMetrics;
  goalFocusAnalytics: GoalFocusAnalyticsResults;
  impactReport: ImpactReport;
  impactReportBoardSummary: ImpactReportBoardSummary;
  impactReportIssueDrilldown: ImpactReportIssueDrilldown;
  improvementGoalTypes: Array<Maybe<ImprovementGoalType>>;
  improvementGoalsByTeamIds: TeamImprovementGoalResults;
  inFlightWorkItems: InFlightWorkItemsResult;
  installedOrganizationIntegrations: InstalledOrganizationIntegrations;
  installedUserIntegrations: InstalledUserIntegrations;
  isBoardIdPublic?: Maybe<Scalars['Boolean']>;
  isLoggedIn?: Maybe<Scalars['Boolean']>;
  isUserAuthorizedForBoard?: Maybe<Scalars['Boolean']>;
  keywords: Keywords;
  list?: Maybe<List>;
  livePollsByBoardId: Array<LivePoll>;
  onOffTargetTeamsByGoalId: Array<ImprovementGoalOnOffTeam>;
  organizationById?: Maybe<Organization>;
  otp: Scalars['String'];
  parkingLotByTeamId: Array<ParkingLotItem>;
  parkingLotItem?: Maybe<ParkingLotItem>;
  publicBoardNameByBoardId?: Maybe<Scalars['String']>;
  publicOrganizationNameByBoardId?: Maybe<Scalars['String']>;
  reportDetails: Report;
  reportsByTeamId: Array<Report>;
  retrospectiveFormats?: Maybe<Array<Maybe<RetrospectiveFormat>>>;
  rollupAnalyticsByFilters: RollupAnalytics;
  searchTeamMembers: Array<User>;
  searchTeamsByOrganizationIdAndName: TeamQueryResults;
  searchTerm: SearchTermResults;
  searchUsersByOrganizationIdAndEmail?: Maybe<UserQueryResults>;
  sourceControlMetrics: SourceControlMetrics;
  team?: Maybe<Team>;
  teamBoards?: Maybe<Array<Maybe<Board>>>;
  teamBoardsPagination?: Maybe<BoardQueryResults>;
  teamById?: Maybe<Team>;
  teamByInviteToken?: Maybe<Team>;
  teamByPublicBoardId?: Maybe<Team>;
  teamInsightById: TeamInsight;
  teamMembers: Array<User>;
  teamMetricsOverTime: TeamMetricsOverTime;
  teams: Array<Team>;
  teamsBoards?: Maybe<Array<Maybe<Board>>>;
  teamsByOrganizationId?: Maybe<TeamQueryResults>;
  teamsByOrganizationIdAndType: TeamQueryResults;
  teamsByProgramId: Array<Team>;
  teamsWatchers: Array<User>;
  topLevelTeams: Array<TeamTree>;
  topLevelWatchedTeams: Array<TeamTree>;
  user?: Maybe<User>;
  userById?: Maybe<User>;
  userEmailByResetPasswordToken?: Maybe<Scalars['String']>;
  userEmailByVerificationToken?: Maybe<Scalars['String']>;
  usersByIds: Array<User>;
  usersByOrganizationId?: Maybe<UserQueryResults>;
  watchedTeams: Array<Team>;
};

export type QueryActionItemArgs = {
  id: Scalars['ID'];
};

export type QueryActionItemAnalyticsArgs = {
  filter: ActionItemAnalyticsFilter;
};

export type QueryActionItemReviewAnalyticsArgs = {
  teamId: Scalars['String'];
};

export type QueryActionItemsByBoardIdsArgs = {
  boardIds: Array<InputMaybe<Scalars['String']>>;
  resolvedStatus: Scalars['String'];
};

export type QueryActionItemsByFiltersArgs = {
  assigneeIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  boardId?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  endDate?: InputMaybe<Scalars['Date']>;
  improvementGoals?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeBoards?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<ActionItemStatus>;
  teamId: Scalars['String'];
};

export type QueryBillingDetailsArgs = {
  organizationId: Scalars['String'];
};

export type QueryBoardArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryBoardAnalyticsArgs = {
  filter: BoardAnalyticsFilter;
};

export type QueryBoardAsyncScheduleByIdArgs = {
  id: Scalars['String'];
};

export type QueryBoardAsyncSchedulesByTeamIdArgs = {
  teamId: Scalars['String'];
};

export type QueryBoardNameByTeamInviteAndBoardIdArgs = {
  boardId: Scalars['String'];
  teamInviteToken: Scalars['String'];
};

export type QueryBoardVoteCountByAllUsersArgs = {
  id: Scalars['ID'];
};

export type QueryBoardVoteCountByIdAndUserIdArgs = {
  id?: InputMaybe<Scalars['ID']>;
  userId: Scalars['String'];
};

export type QueryCardArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryCountOrganizationSeatsByTypeArgs = {
  organizationId: Scalars['ID'];
  seatType: Scalars['String'];
  userId: Scalars['ID'];
};

export type QueryDiscussionAnalyticsArgs = {
  filter: DiscussionAnalyticsFilters;
};

export type QueryDomainAccessAllowedArgs = {
  domain: Scalars['String'];
};

export type QueryFeedArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  pageSize: Scalars['Int'];
  teamId: Scalars['String'];
  typeFilters?: InputMaybe<Array<ActivityType>>;
};

export type QueryFindImprovementGoalByIdArgs = {
  id: Scalars['ID'];
};

export type QueryFindMembershipByIdsArgs = {
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type QueryFlowMetricsArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
  teamId: Scalars['ID'];
  utcOffsetMs: Scalars['Int'];
};

export type QueryGoalFocusAnalyticsArgs = {
  filter: GoalFocusAnalyticsFilter;
};

export type QueryImpactReportArgs = {
  filter: ImpactReportFilter;
};

export type QueryImpactReportBoardSummaryArgs = {
  boardId: Scalars['ID'];
};

export type QueryImpactReportIssueDrilldownArgs = {
  issueId: Scalars['ID'];
  teamId: Scalars['ID'];
};

export type QueryImprovementGoalsByTeamIdsArgs = {
  improvementGoalTeamIds: Array<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['JSON']>;
};

export type QueryInFlightWorkItemsArgs = {
  teamId: Scalars['ID'];
};

export type QueryInstalledOrganizationIntegrationsArgs = {
  organizationId: Scalars['ID'];
};

export type QueryIsBoardIdPublicArgs = {
  id: Scalars['ID'];
};

export type QueryIsUserAuthorizedForBoardArgs = {
  id: Scalars['ID'];
};

export type QueryKeywordsArgs = {
  teamIds: Array<Scalars['ID']>;
};

export type QueryListArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryLivePollsByBoardIdArgs = {
  boardId: Scalars['ID'];
};

export type QueryOnOffTargetTeamsByGoalIdArgs = {
  fromDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  reportingDataTeamIds: Array<ImprovementGoalTeam>;
  toDate?: InputMaybe<Scalars['Date']>;
};

export type QueryOrganizationByIdArgs = {
  organizationId: Scalars['ID'];
};

export type QueryParkingLotByTeamIdArgs = {
  teamId: Scalars['ID'];
};

export type QueryParkingLotItemArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryPublicBoardNameByBoardIdArgs = {
  boardId: Scalars['String'];
};

export type QueryPublicOrganizationNameByBoardIdArgs = {
  boardId: Scalars['String'];
};

export type QueryReportDetailsArgs = {
  reportId: Scalars['ID'];
};

export type QueryReportsByTeamIdArgs = {
  teamId: Scalars['ID'];
};

export type QueryRollupAnalyticsByFiltersArgs = {
  filters: AnalyticsFilters;
};

export type QuerySearchTeamMembersArgs = {
  teamId: Scalars['String'];
  term?: InputMaybe<Scalars['String']>;
};

export type QuerySearchTeamsByOrganizationIdAndNameArgs = {
  limit: Scalars['Int'];
  name: Scalars['String'];
  offset: Scalars['Int'];
  organizationId: Scalars['String'];
  sort?: InputMaybe<Scalars['JSON']>;
  teamType: TeamType;
};

export type QuerySearchTermArgs = {
  limit: Scalars['Int'];
  teamId: Scalars['ID'];
  term: Scalars['String'];
};

export type QuerySearchUsersByOrganizationIdAndEmailArgs = {
  email: Scalars['String'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  organizationId: Scalars['String'];
  roleFilter?: InputMaybe<MembershipRole>;
  sort?: InputMaybe<Scalars['JSON']>;
};

export type QuerySourceControlMetricsArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
  teamId: Scalars['ID'];
  utcOffsetMs: Scalars['Int'];
};

export type QueryTeamArgs = {
  publicOnlyFields?: InputMaybe<Scalars['Boolean']>;
  teamId?: InputMaybe<Scalars['ID']>;
};

export type QueryTeamBoardsArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  teamId?: InputMaybe<Scalars['String']>;
};

export type QueryTeamBoardsPaginationArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  teamId?: InputMaybe<Scalars['String']>;
};

export type QueryTeamByIdArgs = {
  teamId: Scalars['ID'];
};

export type QueryTeamByInviteTokenArgs = {
  inviteToken: Scalars['String'];
};

export type QueryTeamByPublicBoardIdArgs = {
  boardId: Scalars['String'];
};

export type QueryTeamInsightByIdArgs = {
  teamInsightId: Scalars['ID'];
};

export type QueryTeamMembersArgs = {
  teamId: Scalars['String'];
};

export type QueryTeamMetricsOverTimeArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
  teamId: Scalars['ID'];
  timeWindow: TimeWindow;
  utcOffsetMs: Scalars['Int'];
};

export type QueryTeamsBoardsArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  teamIds: Array<Scalars['String']>;
};

export type QueryTeamsByOrganizationIdArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  organizationId: Scalars['String'];
};

export type QueryTeamsByOrganizationIdAndTypeArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  organizationId: Scalars['String'];
  sort?: InputMaybe<Scalars['JSON']>;
  teamType: TeamType;
};

export type QueryTeamsByProgramIdArgs = {
  programId: Scalars['String'];
};

export type QueryTeamsWatchersArgs = {
  teamsIds: Array<InputMaybe<Scalars['String']>>;
};

export type QueryUserByIdArgs = {
  userId: Scalars['String'];
};

export type QueryUserEmailByResetPasswordTokenArgs = {
  resetPasswordToken: Scalars['String'];
};

export type QueryUserEmailByVerificationTokenArgs = {
  verificationToken: Scalars['String'];
};

export type QueryUsersByIdsArgs = {
  userIds: Array<Scalars['String']>;
};

export type QueryUsersByOrganizationIdArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  organizationId: Scalars['String'];
  roleFilter?: InputMaybe<MembershipRole>;
  sort?: InputMaybe<Scalars['JSON']>;
};

export type QuestionAnswer = {
  __typename?: 'QuestionAnswer';
  answer: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  isSystemQuestion?: Maybe<Scalars['Boolean']>;
  organizationId?: Maybe<Scalars['ID']>;
  question: Scalars['String'];
  userId: Scalars['ID'];
};

export type QuestionAnswerInput = {
  answer: Scalars['String'];
  isSystemQuestion?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  question: Scalars['String'];
  userId: Scalars['ID'];
};

export type Report = {
  __typename?: 'Report';
  archived: Scalars['Boolean'];
  completedAt?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  inputData: Scalars['JSON'];
  reportData?: Maybe<Scalars['JSON']>;
  status: ReportStatus;
  teamId?: Maybe<Scalars['String']>;
  type: ReportType;
  updatedAt: Scalars['Date'];
};

export enum ReportStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
}

export enum ReportType {
  TeamDigest = 'TEAM_DIGEST',
}

export type RetrospectiveFormat = {
  __typename?: 'RetrospectiveFormat';
  archived?: Maybe<Scalars['Boolean']>;
  canvasImageHeight?: Maybe<Scalars['Int']>;
  canvasImageUrl?: Maybe<Scalars['String']>;
  canvasImageWidth?: Maybe<Scalars['Int']>;
  columnNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  detailsMarkup?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  iconImageUrl?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type RetrospectiveSentiment = {
  __typename?: 'RetrospectiveSentiment';
  createdAt: Scalars['Date'];
  retrospectiveName: Scalars['String'];
  totalNegative: Scalars['Int'];
  totalNeutral: Scalars['Int'];
  totalPositive: Scalars['Int'];
};

export type RollupAnalytics = {
  __typename?: 'RollupAnalytics';
  actionItemsResolved: Array<ActionItem>;
  actionItemsUnresolved: Array<ActionItem>;
  averageCardsPerRetrospective?: Maybe<Scalars['Float']>;
  averageTimePerRetrospectiveInDays?: Maybe<Scalars['Float']>;
  averageTimeToResolveActionItemsInDays?: Maybe<Scalars['Float']>;
  keywordAnalysis: Array<KeywordAnalysis>;
  overDueActionItems: Array<OverdueActionItemsAnalysis>;
  sentimentAnalysis: SentimentAnalysis;
  sentimentByTopicAnalysis: SentimentTopicAnalysis;
  topicAnalysis: TopicAnalysis;
  topicAnalysisOverTime: Array<TopicAnalysisOverTime>;
  totalActionItems: Scalars['Int'];
  totalActionItemsChange: Scalars['Float'];
  totalActionItemsResolved: Scalars['Int'];
  totalActionItemsResolvedChange: Scalars['Float'];
  totalCards: Scalars['Int'];
  totalCardsChange: Scalars['Float'];
  totalParticipationRate: Scalars['Float'];
  totalParticipationRateChange: Scalars['Float'];
  totalRetrospectives: Scalars['Int'];
  totalRetrospectivesChange: Scalars['Float'];
};

export type SavedCard = {
  __typename?: 'SavedCard';
  brand?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
};

export type SearchTermResults = {
  __typename?: 'SearchTermResults';
  boards: Array<Board>;
  cards: Array<Card>;
  comments: Array<CardComment>;
};

export enum SeatType {
  Invited = 'INVITED',
  Prepaid = 'PREPAID',
  Regular = 'REGULAR',
  Removed = 'REMOVED',
  Trial = 'TRIAL',
}

export type Sentiment = {
  __typename?: 'Sentiment';
  totalNegative: Scalars['Int'];
  totalNeutral: Scalars['Int'];
  totalPositive: Scalars['Int'];
};

export type SentimentAnalysis = {
  __typename?: 'SentimentAnalysis';
  retrospectiveSentiments: Array<RetrospectiveSentiment>;
  totalNegative: Scalars['Int'];
  totalNeutral: Scalars['Int'];
  totalPositive: Scalars['Int'];
};

export type SentimentAnalyticsFilters = {
  endDate?: InputMaybe<Scalars['Date']>;
  recursive: Scalars['Boolean'];
  startDate?: InputMaybe<Scalars['Date']>;
  teams?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SentimentTopicAnalysis = {
  __typename?: 'SentimentTopicAnalysis';
  people: Sentiment;
  process: Sentiment;
  product: Sentiment;
  systems: Sentiment;
  toolsTech: Sentiment;
  unCategorized: Sentiment;
};

export type SlackSettings = {
  __typename?: 'SlackSettings';
  channelName?: Maybe<Scalars['String']>;
};

export type SourceControlInsight = SourceControlInsightReviewPercent;

export type SourceControlInsightReviewPercent = {
  __typename?: 'SourceControlInsightReviewPercent';
  picUrl?: Maybe<Scalars['String']>;
  userName: Scalars['String'];
  value: Scalars['Int'];
};

export type SourceControlMetrics = {
  __typename?: 'SourceControlMetrics';
  approvalTimeMs: Scalars['Int'];
  consistencyQuality: QualityName;
  cycleTimeMs: Scalars['Int'];
  insight?: Maybe<SourceControlInsight>;
  isProcessing: Scalars['Boolean'];
  mergeTimeMs: Scalars['Int'];
  pickupTimeMs: Scalars['Int'];
  prsPerDay: Scalars['Float'];
  reviewLeaderboard: Array<SourceControlReviewerLeaderboardItem>;
  workItemLeaderboard: Array<SourceControlReviewerLeaderboardItem>;
};

export enum SourceControlOrigin {
  Bitbucket = 'BITBUCKET',
  Github = 'GITHUB',
}

export type SourceControlReviewerLeaderboardItem = {
  __typename?: 'SourceControlReviewerLeaderboardItem';
  count: Scalars['Int'];
  origin: SourceControlOrigin;
  picUrl?: Maybe<Scalars['String']>;
  userName: Scalars['String'];
};

export type StopBoardParticipationInput = {
  boardId: Scalars['String'];
};

export type StopBoardParticipationOutput = {
  __typename?: 'StopBoardParticipationOutput';
  success: Scalars['Boolean'];
};

export type Subscription = {
  __typename?: 'Subscription';
  actionItemReviewAnalytics: ActionItemReviewAnalytics;
  actionItemsByBoardIdsUpdated?: Maybe<ActionItem>;
  actionItemsByFilters?: Maybe<ActionItem>;
  activityFeedNewActivityByTeamId?: Maybe<Activity>;
  activityFeedUpdateByTeamId?: Maybe<Activity>;
  boardCreated: Board;
  boardDiscussStageUpdates?: Maybe<Board>;
  boardMessageBroadcastSubscription?: Maybe<BoardMessageBroadcast>;
  boardUpdate?: Maybe<Board>;
  cardCommentUpdateByBoardId?: Maybe<CardComment>;
  cardCommentUpdateByCardId?: Maybe<CardComment>;
  cardPositionUpdate?: Maybe<CardPosition>;
  cardTextUpdate?: Maybe<Card>;
  cardUpdate?: Maybe<Card>;
  cardVoteUpdatesByBoardId?: Maybe<Card>;
  improvementGoalDataUpdatesByTeamId: Array<ImprovementGoal>;
  improvementGoalDataUpdatesByTeamIds: Array<ImprovementGoal>;
  installedOrganizationIntegrationsUpdate: InstalledOrganizationIntegrations;
  installedUserIntegrationsUpdate: InstalledUserIntegrations;
  livePollUpdatesByBoardId: Array<LivePoll>;
  organizationMembershipUpdate: OrganizationUserMembership;
  parkingLotCardCreated: ParkingLotItem;
  parkingLotCardUpdated: ParkingLotItem;
  participationChanged?: Maybe<BoardParticipationChangeEvent>;
  teamInsightUpdate: Team;
  teamUpdateSubscription?: Maybe<Team>;
  userMessageBroadcastSubscription?: Maybe<UserMessageBroadcast>;
  userUpdateSubscription?: Maybe<User>;
  whiteboardBoardUpdate?: Maybe<Board>;
};

export type SubscriptionActionItemReviewAnalyticsArgs = {
  teamId: Scalars['String'];
};

export type SubscriptionActionItemsByBoardIdsUpdatedArgs = {
  boardIds: Array<InputMaybe<Scalars['String']>>;
  resolvedStatus: Scalars['String'];
};

export type SubscriptionActionItemsByFiltersArgs = {
  boardId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<ActionItemStatus>;
  teamId: Scalars['String'];
};

export type SubscriptionActivityFeedNewActivityByTeamIdArgs = {
  teamId: Scalars['String'];
};

export type SubscriptionActivityFeedUpdateByTeamIdArgs = {
  teamId: Scalars['String'];
};

export type SubscriptionBoardCreatedArgs = {
  teamId: Scalars['ID'];
};

export type SubscriptionBoardDiscussStageUpdatesArgs = {
  boardId: Scalars['ID'];
};

export type SubscriptionBoardMessageBroadcastSubscriptionArgs = {
  boardId: Scalars['ID'];
};

export type SubscriptionBoardUpdateArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type SubscriptionCardCommentUpdateByBoardIdArgs = {
  boardId?: InputMaybe<Scalars['String']>;
};

export type SubscriptionCardCommentUpdateByCardIdArgs = {
  cardId?: InputMaybe<Scalars['String']>;
};

export type SubscriptionCardPositionUpdateArgs = {
  boardId?: InputMaybe<Scalars['String']>;
};

export type SubscriptionCardTextUpdateArgs = {
  boardId?: InputMaybe<Scalars['String']>;
};

export type SubscriptionCardUpdateArgs = {
  boardId?: InputMaybe<Scalars['String']>;
};

export type SubscriptionCardVoteUpdatesByBoardIdArgs = {
  boardId: Scalars['ID'];
};

export type SubscriptionImprovementGoalDataUpdatesByTeamIdArgs = {
  teamId: Scalars['ID'];
};

export type SubscriptionImprovementGoalDataUpdatesByTeamIdsArgs = {
  improvementGoalTeamIds: Array<Scalars['ID']>;
};

export type SubscriptionInstalledOrganizationIntegrationsUpdateArgs = {
  organizationId: Scalars['ID'];
};

export type SubscriptionInstalledUserIntegrationsUpdateArgs = {
  userId: Scalars['ID'];
};

export type SubscriptionLivePollUpdatesByBoardIdArgs = {
  boardId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type SubscriptionOrganizationMembershipUpdateArgs = {
  organizationId: Scalars['ID'];
};

export type SubscriptionParkingLotCardCreatedArgs = {
  teamId: Scalars['ID'];
};

export type SubscriptionParkingLotCardUpdatedArgs = {
  teamId: Scalars['ID'];
};

export type SubscriptionParticipationChangedArgs = {
  input?: InputMaybe<ParticipationChangedInput>;
};

export type SubscriptionTeamInsightUpdateArgs = {
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type SubscriptionTeamUpdateSubscriptionArgs = {
  teamId?: InputMaybe<Scalars['ID']>;
};

export type SubscriptionUserMessageBroadcastSubscriptionArgs = {
  userId: Scalars['ID'];
};

export type SubscriptionUserUpdateSubscriptionArgs = {
  userId?: InputMaybe<Scalars['ID']>;
};

export type SubscriptionWhiteboardBoardUpdateArgs = {
  boardId?: InputMaybe<Scalars['ID']>;
};

export enum SupportLevel {
  Basic = 'BASIC',
  Enterprise = 'ENTERPRISE',
  Premium = 'PREMIUM',
}

export type Takeaway = {
  __typename?: 'Takeaway';
  boardId: Scalars['String'];
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  text: Scalars['String'];
  type: TakeawayType;
};

export enum TakeawayStatus {
  Done = 'DONE',
  Error = 'ERROR',
  Pending = 'PENDING',
}

export enum TakeawayType {
  AspectIssue = 'ASPECT_ISSUE',
  BoardSummary = 'BOARD_SUMMARY',
  Manager = 'MANAGER',
  Team = 'TEAM',
  TeamRollup = 'TEAM_ROLLUP',
}

export type Team = {
  __typename?: 'Team';
  admin?: Maybe<User>;
  archived?: Maybe<Scalars['Boolean']>;
  betaFeatures: Array<BetaFeature>;
  children: Array<Team>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  improvementGoals: TeamImprovementGoalResults;
  insights: Array<TeamInsight>;
  inviteToken?: Maybe<Scalars['String']>;
  linkableJiraBoards: Array<LinkableJiraBoard>;
  linkedBitbucketRepositories: Array<BitbucketRepository>;
  linkedGithubRepositories: Array<GithubRepository>;
  linkedJiraBoards: Array<LinkedJiraBoard>;
  maxActiveImprovementGoals: Scalars['Int'];
  members: Array<User>;
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  parents?: Maybe<Array<Team>>;
  searchLinkableBitbucketRepositories: Array<BitbucketRepository>;
  searchLinkableGithubRepositories: Array<GithubRepository>;
  settings?: Maybe<TeamSettings>;
  slack?: Maybe<SlackSettings>;
  slackChannelName?: Maybe<Scalars['String']>;
  teamCount: Scalars['Int'];
  type: TeamType;
  userCount?: Maybe<Scalars['Int']>;
  watchers: Array<User>;
};

export type TeamImprovementGoalsArgs = {
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  sort?: InputMaybe<Scalars['JSON']>;
};

export type TeamLinkableJiraBoardsArgs = {
  search: Scalars['String'];
};

export type TeamSearchLinkableBitbucketRepositoriesArgs = {
  search: Scalars['String'];
};

export type TeamSearchLinkableGithubRepositoriesArgs = {
  search: Scalars['String'];
};

export enum TeamBusinessType {
  Analytics = 'ANALYTICS',
  Design = 'DESIGN',
  Facilities = 'FACILITIES',
  Finance = 'FINANCE',
  HumanResources = 'HUMAN_RESOURCES',
  It = 'IT',
  Legal = 'LEGAL',
  Marketing = 'MARKETING',
  NotSpecified = 'NOT_SPECIFIED',
  Operations = 'OPERATIONS',
  Other = 'OTHER',
  Personal = 'PERSONAL',
  ProductManagement = 'PRODUCT_MANAGEMENT',
  ServiceManagement = 'SERVICE_MANAGEMENT',
  SoftwareDev = 'SOFTWARE_DEV',
  WorkManagement = 'WORK_MANAGEMENT',
}

export type TeamCreated = {
  __typename?: 'TeamCreated';
  series: Array<Maybe<KeyCount>>;
  totalActiveTeams: Scalars['Int'];
  totalTeams: Scalars['Int'];
  totalTeamsOneMembers: Scalars['Int'];
};

export type TeamImprovementGoalResults = {
  __typename?: 'TeamImprovementGoalResults';
  improvementGoals: Array<ImprovementGoal>;
  insight?: Maybe<ImprovementGoalInsight>;
  totalSize: Scalars['Int'];
};

export type TeamInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  children?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  members?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: Scalars['String'];
  organizationId: Scalars['String'];
  slackChannelName?: InputMaybe<Scalars['String']>;
  type: TeamType;
};

export type TeamInsight = TeamInsightFactCauseSolution;

export type TeamInsightFactCauseSolution = BaseTeamInsight & {
  __typename?: 'TeamInsightFactCauseSolution';
  causes?: Maybe<Array<TeamInsightFactCauseSolutionCause>>;
  date: Scalars['Date'];
  fact: TeamInsightFactCauseSolutionFact;
  id: Scalars['ID'];
  rated: Scalars['Boolean'];
  seen: Scalars['Boolean'];
  solutions?: Maybe<Array<Array<TeamInsightFactCauseSolutionSolution>>>;
  team: Team;
  teamId: Scalars['String'];
};

export type TeamInsightFactCauseSolutionCause =
  | TeamInsightFactCauseSolutionCauseMetricDeterioration
  | TeamInsightFactCauseSolutionCauseOutlierPullRequests
  | TeamInsightFactCauseSolutionCauseOutlierWorkItems
  | TeamInsightFactCauseSolutionCauseTopic
  | TeamInsightFactCauseSolutionCauseUnreviewedPullRequests;

export type TeamInsightFactCauseSolutionCauseMetricDeterioration = {
  __typename?: 'TeamInsightFactCauseSolutionCauseMetricDeterioration';
  history: Array<TimeSeries>;
  id: Scalars['ID'];
  type: TeamInsightMetricType;
};

export type TeamInsightFactCauseSolutionCauseOutlierPullRequest = {
  __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequest';
  externalUrl?: Maybe<Scalars['String']>;
  origin?: Maybe<SourceControlOrigin>;
  slug?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  wipTimeMs?: Maybe<Scalars['Long']>;
};

export type TeamInsightFactCauseSolutionCauseOutlierPullRequests = {
  __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequests';
  id: Scalars['ID'];
  pullRequests: Array<TeamInsightFactCauseSolutionCauseOutlierPullRequest>;
  topic?: Maybe<Scalars['String']>;
};

export type TeamInsightFactCauseSolutionCauseOutlierWorkItems = {
  __typename?: 'TeamInsightFactCauseSolutionCauseOutlierWorkItems';
  id: Scalars['ID'];
  topic?: Maybe<Scalars['String']>;
  workItems: Array<TeamInsightFactCauseSolutionCauseWorkItem>;
};

export type TeamInsightFactCauseSolutionCausePullRequest = {
  __typename?: 'TeamInsightFactCauseSolutionCausePullRequest';
  createdAt: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  externalUrl?: Maybe<Scalars['String']>;
  origin?: Maybe<SourceControlOrigin>;
  slug?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type TeamInsightFactCauseSolutionCauseTopic = {
  __typename?: 'TeamInsightFactCauseSolutionCauseTopic';
  cards: Array<TeamInsightFactCauseSolutionCauseTopicCard>;
  id: Scalars['ID'];
  topic?: Maybe<Scalars['String']>;
};

export type TeamInsightFactCauseSolutionCauseTopicCard = {
  __typename?: 'TeamInsightFactCauseSolutionCauseTopicCard';
  badgeColor: Scalars['String'];
  badgeText: Scalars['String'];
  retroDate: Scalars['Date'];
  retroName: Scalars['String'];
  text: Scalars['String'];
};

export type TeamInsightFactCauseSolutionCauseUnreviewedPullRequests = {
  __typename?: 'TeamInsightFactCauseSolutionCauseUnreviewedPullRequests';
  id: Scalars['ID'];
  pullRequests: Array<TeamInsightFactCauseSolutionCausePullRequest>;
  topic?: Maybe<Scalars['String']>;
};

export type TeamInsightFactCauseSolutionCauseWorkItem = {
  __typename?: 'TeamInsightFactCauseSolutionCauseWorkItem';
  cycleTimeMs?: Maybe<Scalars['Long']>;
  origin?: Maybe<WorkItemOrigin>;
  slug?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  wipTimeMs: Scalars['Long'];
};

export type TeamInsightFactCauseSolutionFact =
  | TeamInsightFactCauseSolutionFactMetricBelowRecommended
  | TeamInsightFactCauseSolutionFactMetricDeterioration
  | TeamInsightFactCauseSolutionFactMetricImprovement
  | TeamInsightFactCauseSolutionFactMetricInadequacy
  | TeamInsightFactCauseSolutionFactOutlierWorkItemTopic;

export type TeamInsightFactCauseSolutionFactMetricBelowRecommended = {
  __typename?: 'TeamInsightFactCauseSolutionFactMetricBelowRecommended';
  history: Array<TimeSeries>;
  id: Scalars['ID'];
  recommended: Scalars['Float'];
  type: TeamInsightMetricType;
};

export type TeamInsightFactCauseSolutionFactMetricDeterioration = {
  __typename?: 'TeamInsightFactCauseSolutionFactMetricDeterioration';
  history: Array<TimeSeries>;
  id: Scalars['ID'];
  type: TeamInsightMetricType;
};

export type TeamInsightFactCauseSolutionFactMetricImprovement = {
  __typename?: 'TeamInsightFactCauseSolutionFactMetricImprovement';
  history: Array<TimeSeries>;
  id: Scalars['ID'];
  type: TeamInsightMetricType;
};

export type TeamInsightFactCauseSolutionFactMetricInadequacy = {
  __typename?: 'TeamInsightFactCauseSolutionFactMetricInadequacy';
  history: Array<TimeSeries>;
  id: Scalars['ID'];
  recommended: Scalars['Float'];
  type: TeamInsightMetricType;
};

export type TeamInsightFactCauseSolutionFactOutlierWorkItemTopic = {
  __typename?: 'TeamInsightFactCauseSolutionFactOutlierWorkItemTopic';
  id: Scalars['ID'];
  topic: Scalars['String'];
};

export type TeamInsightFactCauseSolutionSolution =
  | TeamInsightFactCauseSolutionSolutionActionItem
  | TeamInsightFactCauseSolutionSolutionGoal;

export type TeamInsightFactCauseSolutionSolutionActionItem = {
  __typename?: 'TeamInsightFactCauseSolutionSolutionActionItem';
  id: Scalars['ID'];
  originatedActionItem?: Maybe<ActionItem>;
  text: Scalars['String'];
};

export type TeamInsightFactCauseSolutionSolutionGoal = {
  __typename?: 'TeamInsightFactCauseSolutionSolutionGoal';
  id: Scalars['ID'];
  type: ImprovementGoalType;
};

export type TeamInsightMetricType = {
  __typename?: 'TeamInsightMetricType';
  ideal: Trend;
  name: Scalars['String'];
  providerCategory?: Maybe<MetricsProviderCategory>;
  unit: ImprovementGoalUnit;
};

export type TeamMetricsOverTime = {
  __typename?: 'TeamMetricsOverTime';
  pullRequestCycleTIme: Array<TimeSeries>;
  pullRequestsMergedWithoutReview: Array<TimeSeries>;
  pullRequestsOpen: Array<TimeSeries>;
  workItemCycleTime: Array<TimeSeries>;
  workItemThroughput: Array<TimeSeries>;
  workItemWip: Array<TimeSeries>;
};

export type TeamQueryResults = {
  __typename?: 'TeamQueryResults';
  teams: Array<Maybe<Team>>;
  totalSize: Scalars['Int'];
};

export type TeamSettings = {
  __typename?: 'TeamSettings';
  bitbucketMetricsEnabled: DisableFeatureState;
  businessType: TeamBusinessType;
  cardCategories?: Maybe<Array<Maybe<CardCategory>>>;
  defaultGuidedRetrospectives: Scalars['Boolean'];
  defaultPublicRetrospectives: Scalars['Boolean'];
  defaultVoteCount: Scalars['Int'];
  githubMetricsEnabled: DisableFeatureState;
  id: Scalars['ID'];
  jiraMetricsEnabled: DisableFeatureState;
  tooltipHintsEnabled: Scalars['Boolean'];
};

export type TeamTree = {
  __typename?: 'TeamTree';
  children: Array<TeamTree>;
  isWatcher?: Maybe<Scalars['Boolean']>;
  team: Team;
};

export enum TeamType {
  Organization = 'ORGANIZATION',
  Portfolio = 'PORTFOLIO',
  Program = 'PROGRAM',
  Team = 'TEAM',
}

export type TimeSeries = {
  __typename?: 'TimeSeries';
  date: Scalars['Date'];
  value?: Maybe<Scalars['Float']>;
};

export enum TimeWindow {
  Day = 'DAY',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Week = 'WEEK',
}

export type TopicAnalysis = {
  __typename?: 'TopicAnalysis';
  totalPeople: Scalars['Int'];
  totalProcess: Scalars['Int'];
  totalProduct: Scalars['Int'];
  totalSystems: Scalars['Int'];
  totalToolsTech: Scalars['Int'];
  totalUnprocessed: Scalars['Int'];
};

export type TopicAnalysisOverTime = {
  __typename?: 'TopicAnalysisOverTime';
  monthCreated: Scalars['Date'];
  totalPeople: Scalars['Int'];
  totalProcess: Scalars['Int'];
  totalProduct: Scalars['Int'];
  totalSystems: Scalars['Int'];
  totalToolsTech: Scalars['Int'];
  totalUnprocessed: Scalars['Int'];
};

export enum Trend {
  Down = 'DOWN',
  Stable = 'STABLE',
  Up = 'UP',
}

export type TrialQuestionAnswerInput = {
  answer: Scalars['String'];
  internalName: Scalars['String'];
  question: Scalars['String'];
};

export type UpdateBoardStageInput = {
  boardId: Scalars['String'];
  stage: BoardStage;
};

export type UpdateBoardStageOutput = {
  __typename?: 'UpdateBoardStageOutput';
  board: Board;
};

export type User = {
  __typename?: 'User';
  announcementsModalSeen?: Maybe<Scalars['Boolean']>;
  betaFeatures: Array<BetaFeature>;
  createdAt?: Maybe<Scalars['Date']>;
  email: Scalars['String'];
  facilitatorLabelsShown?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  hubspotToken?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastActiveAt?: Maybe<Scalars['Date']>;
  lastName?: Maybe<Scalars['String']>;
  memberships?: Maybe<Array<OrganizationUserMembership>>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  phone?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  role?: Maybe<MembershipRole>;
  ssoControlled?: Maybe<Scalars['Boolean']>;
  team?: Maybe<Team>;
  teamNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  verified?: Maybe<Scalars['Boolean']>;
  watchedTeams?: Maybe<Array<Maybe<Team>>>;
};

export type UserCreateInput = {
  boardId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  inviteToken?: InputMaybe<Scalars['String']>;
  inviterId?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type UserMessageBroadcast = {
  __typename?: 'UserMessageBroadcast';
  message?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type UserQueryResults = {
  __typename?: 'UserQueryResults';
  totalSize: Scalars['Int'];
  users: Array<Maybe<User>>;
};

export type UserSignups = {
  __typename?: 'UserSignups';
  inviteSeries: Array<Maybe<KeyCount>>;
  originSeries: Array<Maybe<KeyCount>>;
  totalUsers: Scalars['Int'];
};

export type UserUpdateInput = {
  announcementsModalSeen?: InputMaybe<Scalars['Boolean']>;
  boardId?: InputMaybe<Scalars['String']>;
  currentTeamId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  facilitatorLabelsShown?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  inviteToken?: InputMaybe<Scalars['String']>;
  inviterId?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export enum WorkItemOrigin {
  Jira = 'JIRA',
}

export type WorkItemTimeSpentInExternalStatus = {
  __typename?: 'WorkItemTimeSpentInExternalStatus';
  name: Scalars['String'];
  timeSpentMs: Scalars['Long'];
};

export type WorkItemWithLifecycle = BaseWorkItem & {
  __typename?: 'WorkItemWithLifecycle';
  createdAt: Scalars['Date'];
  description: Scalars['String'];
  externalStatusName: Scalars['String'];
  externalStatuses: Array<WorkItemTimeSpentInExternalStatus>;
  externalUrl: Scalars['String'];
  origin: WorkItemOrigin;
  slug: Scalars['String'];
  title: Scalars['String'];
  wipTimeMs: Scalars['Long'];
};

export type ActionItemFragmentFragment = {
  __typename?: 'ActionItem';
  id: string;
  title: string;
  description?: string | null;
  teamId: string;
  resolvedAt?: number | null;
  dueDate?: number | null;
  authorId: string;
  boardId?: string | null;
  status: ActionItemStatus;
  archived: boolean;
  createdAt?: number | null;
  children: Array<{ __typename?: 'ActionItemChild'; id: string; text: string }>;
  author?: { __typename?: 'Author'; name: string } | null;
  assignees?: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
  } | null> | null;
  improvementGoals?: Array<{
    __typename?: 'ImprovementGoal';
    id: string;
    type: { __typename?: 'ImprovementGoalType'; name: string };
  } | null> | null;
  jiraIssueLink?: {
    __typename?: 'ActionItemJiraLink';
    jiraIssueId?: string | null;
    jiraIssueUrl?: string | null;
    jiraIssueKey?: string | null;
    jiraProjectKey?: string | null;
  } | null;
  comments: Array<{
    __typename?: 'ActionItemComment';
    id: string;
    text: string;
    actionItemId: string;
    createdAt: number;
    archived: boolean;
    author: { __typename?: 'User'; id: string; name?: string | null };
  }>;
};

export type ActionItemCommentFragmentFragment = {
  __typename?: 'ActionItemComment';
  id: string;
  text: string;
  actionItemId: string;
  createdAt: number;
  archived: boolean;
  author: { __typename?: 'User'; id: string; name?: string | null };
};

export type CreateActionItemMutationVariables = Exact<{
  boardId?: InputMaybe<Scalars['String']>;
  teamId: Scalars['String'];
  votes?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  title: Scalars['String'];
  comments?: InputMaybe<Scalars['JSON']>;
  groupId?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  assigneeIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  improvementGoals?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  jiraProjectKey?: InputMaybe<Scalars['String']>;
  originalCardId?: InputMaybe<Scalars['String']>;
}>;

export type CreateActionItemMutation = {
  __typename?: 'Mutation';
  createActionItem: {
    __typename?: 'ActionItem';
    id: string;
    title: string;
    description?: string | null;
    teamId: string;
    resolvedAt?: number | null;
    dueDate?: number | null;
    authorId: string;
    boardId?: string | null;
    status: ActionItemStatus;
    archived: boolean;
    createdAt?: number | null;
    children: Array<{
      __typename?: 'ActionItemChild';
      id: string;
      text: string;
    }>;
    author?: { __typename?: 'Author'; name: string } | null;
    assignees?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
    } | null> | null;
    improvementGoals?: Array<{
      __typename?: 'ImprovementGoal';
      id: string;
      type: { __typename?: 'ImprovementGoalType'; name: string };
    } | null> | null;
    jiraIssueLink?: {
      __typename?: 'ActionItemJiraLink';
      jiraIssueId?: string | null;
      jiraIssueUrl?: string | null;
      jiraIssueKey?: string | null;
      jiraProjectKey?: string | null;
    } | null;
    comments: Array<{
      __typename?: 'ActionItemComment';
      id: string;
      text: string;
      actionItemId: string;
      createdAt: number;
      archived: boolean;
      author: { __typename?: 'User'; id: string; name?: string | null };
    }>;
  };
};

export type CreateActionItemCommentMutationVariables = Exact<{
  actionItemId: Scalars['ID'];
  text: Scalars['String'];
}>;

export type CreateActionItemCommentMutation = {
  __typename?: 'Mutation';
  createActionItemComment: {
    __typename?: 'ActionItemComment';
    id: string;
    text: string;
    actionItemId: string;
    createdAt: number;
    archived: boolean;
    author: { __typename?: 'User'; id: string; name?: string | null };
  };
};

export type DeleteActionItemCommentMutationVariables = Exact<{
  commentId: Scalars['ID'];
}>;

export type DeleteActionItemCommentMutation = {
  __typename?: 'Mutation';
  archiveActionItemComment: {
    __typename?: 'ActionItemComment';
    id: string;
    text: string;
    actionItemId: string;
    createdAt: number;
    archived: boolean;
    author: { __typename?: 'User'; id: string; name?: string | null };
  };
};

export type UpdateActionItemMutationVariables = Exact<{
  actionItemId: Scalars['String'];
  fields: Scalars['JSON'];
}>;

export type UpdateActionItemMutation = {
  __typename?: 'Mutation';
  updateActionItem?: {
    __typename?: 'ActionItem';
    id: string;
    title: string;
    description?: string | null;
    teamId: string;
    resolvedAt?: number | null;
    dueDate?: number | null;
    authorId: string;
    boardId?: string | null;
    status: ActionItemStatus;
    archived: boolean;
    createdAt?: number | null;
    children: Array<{
      __typename?: 'ActionItemChild';
      id: string;
      text: string;
    }>;
    author?: { __typename?: 'Author'; name: string } | null;
    assignees?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
    } | null> | null;
    improvementGoals?: Array<{
      __typename?: 'ImprovementGoal';
      id: string;
      type: { __typename?: 'ImprovementGoalType'; name: string };
    } | null> | null;
    jiraIssueLink?: {
      __typename?: 'ActionItemJiraLink';
      jiraIssueId?: string | null;
      jiraIssueUrl?: string | null;
      jiraIssueKey?: string | null;
      jiraProjectKey?: string | null;
    } | null;
    comments: Array<{
      __typename?: 'ActionItemComment';
      id: string;
      text: string;
      actionItemId: string;
      createdAt: number;
      archived: boolean;
      author: { __typename?: 'User'; id: string; name?: string | null };
    }>;
  } | null;
};

export type ActionItemByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ActionItemByIdQuery = {
  __typename?: 'Query';
  actionItem?: {
    __typename?: 'ActionItem';
    id: string;
    title: string;
    description?: string | null;
    teamId: string;
    resolvedAt?: number | null;
    dueDate?: number | null;
    authorId: string;
    boardId?: string | null;
    status: ActionItemStatus;
    archived: boolean;
    createdAt?: number | null;
    board?: {
      __typename?: 'Board';
      id: string;
      name: string;
      type: string;
      createdAt?: number | null;
      format: {
        __typename?: 'RetrospectiveFormat';
        displayName?: string | null;
        iconImageUrl?: string | null;
        description?: string | null;
      };
    } | null;
    children: Array<{
      __typename?: 'ActionItemChild';
      id: string;
      text: string;
    }>;
    author?: { __typename?: 'Author'; name: string } | null;
    assignees?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
    } | null> | null;
    improvementGoals?: Array<{
      __typename?: 'ImprovementGoal';
      id: string;
      type: { __typename?: 'ImprovementGoalType'; name: string };
    } | null> | null;
    jiraIssueLink?: {
      __typename?: 'ActionItemJiraLink';
      jiraIssueId?: string | null;
      jiraIssueUrl?: string | null;
      jiraIssueKey?: string | null;
      jiraProjectKey?: string | null;
    } | null;
    comments: Array<{
      __typename?: 'ActionItemComment';
      id: string;
      text: string;
      actionItemId: string;
      createdAt: number;
      archived: boolean;
      author: { __typename?: 'User'; id: string; name?: string | null };
    }>;
  } | null;
};

export type ActionItemReviewAnalyticsQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;

export type ActionItemReviewAnalyticsQuery = {
  __typename?: 'Query';
  actionItemReviewAnalytics: {
    __typename?: 'ActionItemReviewAnalytics';
    id: string;
    averageTimeToResolveActionItemsInDays?: number | null;
    improvementGoalDistribution: Array<{
      __typename?: 'ImprovementGoalDistribution';
      percentage: number;
      goal?: {
        __typename?: 'ImprovementGoal';
        id: string;
        type: {
          __typename?: 'ImprovementGoalType';
          id: string;
          name: string;
          color: string;
        };
      } | null;
    }>;
  };
};

export type ActionItemsByFiltersQueryVariables = Exact<{
  teamId: Scalars['String'];
  boardId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ActionItemStatus>;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
  assigneeIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  dueDate?: InputMaybe<
    Array<InputMaybe<Scalars['Date']>> | InputMaybe<Scalars['Date']>
  >;
  improvementGoals?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type ActionItemsByFiltersQuery = {
  __typename?: 'Query';
  actionItemsByFilters: Array<{
    __typename?: 'ActionItem';
    id: string;
    title: string;
    description?: string | null;
    teamId: string;
    resolvedAt?: number | null;
    dueDate?: number | null;
    authorId: string;
    boardId?: string | null;
    status: ActionItemStatus;
    archived: boolean;
    createdAt?: number | null;
    children: Array<{
      __typename?: 'ActionItemChild';
      id: string;
      text: string;
    }>;
    author?: { __typename?: 'Author'; name: string } | null;
    assignees?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
    } | null> | null;
    improvementGoals?: Array<{
      __typename?: 'ImprovementGoal';
      id: string;
      type: { __typename?: 'ImprovementGoalType'; name: string };
    } | null> | null;
    jiraIssueLink?: {
      __typename?: 'ActionItemJiraLink';
      jiraIssueId?: string | null;
      jiraIssueUrl?: string | null;
      jiraIssueKey?: string | null;
      jiraProjectKey?: string | null;
    } | null;
    comments: Array<{
      __typename?: 'ActionItemComment';
      id: string;
      text: string;
      actionItemId: string;
      createdAt: number;
      archived: boolean;
      author: { __typename?: 'User'; id: string; name?: string | null };
    }>;
  }>;
};

export type ActionItemReviewAnalyticsUpdatesSubscriptionVariables = Exact<{
  teamId: Scalars['String'];
}>;

export type ActionItemReviewAnalyticsUpdatesSubscription = {
  __typename?: 'Subscription';
  actionItemReviewAnalytics: {
    __typename?: 'ActionItemReviewAnalytics';
    id: string;
    averageTimeToResolveActionItemsInDays?: number | null;
    improvementGoalDistribution: Array<{
      __typename?: 'ImprovementGoalDistribution';
      percentage: number;
      goal?: {
        __typename?: 'ImprovementGoal';
        id: string;
        type: {
          __typename?: 'ImprovementGoalType';
          id: string;
          name: string;
          color: string;
        };
      } | null;
    }>;
  };
};

export type ActionItemsByFiltersSubscriptionSubscriptionVariables = Exact<{
  teamId: Scalars['String'];
  boardId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<ActionItemStatus>;
}>;

export type ActionItemsByFiltersSubscriptionSubscription = {
  __typename?: 'Subscription';
  actionItemsByFilters?: {
    __typename?: 'ActionItem';
    id: string;
    title: string;
    description?: string | null;
    teamId: string;
    resolvedAt?: number | null;
    dueDate?: number | null;
    authorId: string;
    boardId?: string | null;
    status: ActionItemStatus;
    archived: boolean;
    createdAt?: number | null;
    children: Array<{
      __typename?: 'ActionItemChild';
      id: string;
      text: string;
    }>;
    author?: { __typename?: 'Author'; name: string } | null;
    assignees?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
    } | null> | null;
    improvementGoals?: Array<{
      __typename?: 'ImprovementGoal';
      id: string;
      type: { __typename?: 'ImprovementGoalType'; name: string };
    } | null> | null;
    jiraIssueLink?: {
      __typename?: 'ActionItemJiraLink';
      jiraIssueId?: string | null;
      jiraIssueUrl?: string | null;
      jiraIssueKey?: string | null;
      jiraProjectKey?: string | null;
    } | null;
    comments: Array<{
      __typename?: 'ActionItemComment';
      id: string;
      text: string;
      actionItemId: string;
      createdAt: number;
      archived: boolean;
      author: { __typename?: 'User'; id: string; name?: string | null };
    }>;
  } | null;
};

export type ActivityFeedItemFragmentFragment = {
  __typename?: 'Activity';
  id: string;
  hideReaction: boolean;
  teamId?: string | null;
  verb: ActivityVerb;
  headerTitle: string;
  bodyText: string;
  type: ActivityType;
  createdAt: number;
  updatedAt: number;
  reactions: Array<{
    __typename?: 'ActivityFeedReaction';
    id: string;
    userId: string;
    kind: ActivityReactionKind;
    createdAt: number;
    updatedAt: number;
    user: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      name?: string | null;
      email: string;
    };
  } | null>;
  comments: Array<{
    __typename?: 'ActivityFeedComment';
    id: string;
    userId: string;
    text: string;
    createdAt: number;
    updatedAt: number;
    user: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      name?: string | null;
      email: string;
    };
  } | null>;
  board?: {
    __typename?: 'Board';
    id: string;
    name: string;
    teamId: string;
    ownerId: string;
    boardAsyncScheduleId?: string | null;
    participationRate?: number | null;
    totalCards: number;
    public: boolean;
    createdAt?: number | null;
    updatedAt?: number | null;
    archived: boolean;
    type: string;
    formatSlug: string;
    format: {
      __typename?: 'RetrospectiveFormat';
      iconImageUrl?: string | null;
      displayName?: string | null;
    };
  } | null;
  actionItem?: {
    __typename?: 'ActionItem';
    id: string;
    title: string;
    description?: string | null;
    teamId: string;
    resolvedAt?: number | null;
    dueDate?: number | null;
    authorId: string;
    boardId?: string | null;
    status: ActionItemStatus;
    archived: boolean;
    createdAt?: number | null;
    children: Array<{
      __typename?: 'ActionItemChild';
      id: string;
      text: string;
    }>;
    author?: { __typename?: 'Author'; name: string } | null;
    assignees?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
    } | null> | null;
    improvementGoals?: Array<{
      __typename?: 'ImprovementGoal';
      id: string;
      type: { __typename?: 'ImprovementGoalType'; name: string };
    } | null> | null;
    jiraIssueLink?: {
      __typename?: 'ActionItemJiraLink';
      jiraIssueId?: string | null;
      jiraIssueUrl?: string | null;
      jiraIssueKey?: string | null;
      jiraProjectKey?: string | null;
    } | null;
    comments: Array<{
      __typename?: 'ActionItemComment';
      id: string;
      text: string;
      actionItemId: string;
      createdAt: number;
      archived: boolean;
      author: { __typename?: 'User'; id: string; name?: string | null };
    }>;
  } | null;
  parkingLot?: {
    __typename?: 'ParkingLotItem';
    id: string;
    text: string;
    votes: number;
    createdAt: number;
    archived: boolean;
    children: Array<{ __typename?: 'ParkingLotItem'; text: string }>;
    team: { __typename?: 'Team'; id: string };
    author: { __typename?: 'User'; id: string };
  } | null;
  goal?: {
    __typename?: 'ImprovementGoal';
    id: string;
    createdAt: number;
    timesUsed: number;
    updatedAt: number;
    archived: boolean;
    paused: boolean;
    target: number;
    ongoingActionsCount: number;
    actionsTakenCount: number;
    data: Array<{
      __typename?: 'ImprovementGoalData';
      id: string;
      improvementGoalId: string;
      value: number;
      date: number;
      createdAt: number;
      archived: boolean;
      origin?: ImprovementGoalDataOrigin | null;
      children: Array<{
        __typename?: 'ImprovementGoalData';
        id: string;
        improvementGoalId: string;
        value: number;
        date: number;
        createdAt: number;
        archived: boolean;
        origin?: ImprovementGoalDataOrigin | null;
      } | null>;
    }>;
    type: {
      __typename?: 'ImprovementGoalType';
      id: string;
      name: string;
      color: string;
      description?: string | null;
      learnMoreUrl?: string | null;
      unit: ImprovementGoalUnit;
      categories: Array<ImprovementGoalTypeCategory>;
      subCategory?: string | null;
      targetType: ImprovementGoalTypeTargetType;
      aggregationPeriod: ImprovementGoalAggregationPeriod;
      aggregationFunction: ImprovementGoalAggregationFunction;
      isPollable: boolean;
      minValue?: number | null;
      maxValue?: number | null;
    };
    team: { __typename?: 'Team'; type: TeamType; name: string };
    user: { __typename?: 'User'; name?: string | null };
    userReminders: Array<{
      __typename?: 'ImprovementGoalUserReminder';
      improvementGoalId: string;
      userId: string;
      notificationTime: number;
      frequency: ImprovementGoalUserReminderFrequency;
      createdAt: number;
      updatedAt: number;
    }>;
  } | null;
  insight?: {
    __typename?: 'TeamInsightFactCauseSolution';
    id: string;
    seen: boolean;
    rated: boolean;
    date: number;
    team: { __typename?: 'Team'; id: string; name: string; type: TeamType };
    fact:
      | {
          __typename?: 'TeamInsightFactCauseSolutionFactMetricBelowRecommended';
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionFactMetricDeterioration';
          id: string;
          type: {
            __typename?: 'TeamInsightMetricType';
            name: string;
            unit: ImprovementGoalUnit;
            providerCategory?: MetricsProviderCategory | null;
            ideal: Trend;
          };
          history: Array<{
            __typename?: 'TimeSeries';
            date: number;
            value?: number | null;
          }>;
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionFactMetricImprovement';
          id: string;
          type: {
            __typename?: 'TeamInsightMetricType';
            name: string;
            unit: ImprovementGoalUnit;
            providerCategory?: MetricsProviderCategory | null;
            ideal: Trend;
          };
          history: Array<{
            __typename?: 'TimeSeries';
            date: number;
            value?: number | null;
          }>;
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionFactMetricInadequacy';
          id: string;
          recommended: number;
          type: {
            __typename?: 'TeamInsightMetricType';
            name: string;
            unit: ImprovementGoalUnit;
            providerCategory?: MetricsProviderCategory | null;
            ideal: Trend;
          };
          history: Array<{
            __typename?: 'TimeSeries';
            date: number;
            value?: number | null;
          }>;
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionFactOutlierWorkItemTopic';
          id: string;
          topic: string;
        };
    causes?: Array<
      | {
          __typename?: 'TeamInsightFactCauseSolutionCauseMetricDeterioration';
          id: string;
          type: {
            __typename?: 'TeamInsightMetricType';
            name: string;
            unit: ImprovementGoalUnit;
            providerCategory?: MetricsProviderCategory | null;
            ideal: Trend;
          };
          history: Array<{
            __typename?: 'TimeSeries';
            date: number;
            value?: number | null;
          }>;
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequests';
          id: string;
          topic?: string | null;
          pullRequests: Array<{
            __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequest';
            title: string;
            externalUrl?: string | null;
            slug?: string | null;
            origin?: SourceControlOrigin | null;
            wipTimeMs?: number | null;
          }>;
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionCauseOutlierWorkItems';
          id: string;
          topic?: string | null;
          workItems: Array<{
            __typename?: 'TeamInsightFactCauseSolutionCauseWorkItem';
            slug?: string | null;
            title: string;
            wipTimeMs: number;
            origin?: WorkItemOrigin | null;
            url?: string | null;
          }>;
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionCauseTopic';
          id: string;
          topic?: string | null;
          cards: Array<{
            __typename?: 'TeamInsightFactCauseSolutionCauseTopicCard';
            text: string;
            badgeColor: string;
            badgeText: string;
            retroName: string;
            retroDate: number;
          }>;
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionCauseUnreviewedPullRequests';
          id: string;
          topic?: string | null;
          pullRequests: Array<{
            __typename?: 'TeamInsightFactCauseSolutionCausePullRequest';
            title: string;
            description?: string | null;
            externalUrl?: string | null;
            slug?: string | null;
            createdAt: string;
            origin?: SourceControlOrigin | null;
          }>;
        }
    > | null;
    solutions?: Array<
      Array<
        | {
            __typename?: 'TeamInsightFactCauseSolutionSolutionActionItem';
            id: string;
            text: string;
            originatedActionItem?: {
              __typename?: 'ActionItem';
              id: string;
              title: string;
              description?: string | null;
              teamId: string;
              resolvedAt?: number | null;
              dueDate?: number | null;
              authorId: string;
              boardId?: string | null;
              status: ActionItemStatus;
              archived: boolean;
              createdAt?: number | null;
              children: Array<{
                __typename?: 'ActionItemChild';
                id: string;
                text: string;
              }>;
              author?: { __typename?: 'Author'; name: string } | null;
              assignees?: Array<{
                __typename?: 'User';
                id: string;
                name?: string | null;
              } | null> | null;
              improvementGoals?: Array<{
                __typename?: 'ImprovementGoal';
                id: string;
                type: { __typename?: 'ImprovementGoalType'; name: string };
              } | null> | null;
              jiraIssueLink?: {
                __typename?: 'ActionItemJiraLink';
                jiraIssueId?: string | null;
                jiraIssueUrl?: string | null;
                jiraIssueKey?: string | null;
                jiraProjectKey?: string | null;
              } | null;
              comments: Array<{
                __typename?: 'ActionItemComment';
                id: string;
                text: string;
                actionItemId: string;
                createdAt: number;
                archived: boolean;
                author: {
                  __typename?: 'User';
                  id: string;
                  name?: string | null;
                };
              }>;
            } | null;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionSolutionGoal';
            id: string;
            type: {
              __typename?: 'ImprovementGoalType';
              id: string;
              name: string;
              color: string;
              description?: string | null;
              learnMoreUrl?: string | null;
              unit: ImprovementGoalUnit;
              categories: Array<ImprovementGoalTypeCategory>;
              subCategory?: string | null;
              targetType: ImprovementGoalTypeTargetType;
              aggregationPeriod: ImprovementGoalAggregationPeriod;
              aggregationFunction: ImprovementGoalAggregationFunction;
              isPollable: boolean;
              minValue?: number | null;
              maxValue?: number | null;
            };
          }
      >
    > | null;
  } | null;
  user?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
  } | null;
  team?: {
    __typename?: 'Team';
    id: string;
    name: string;
    type: TeamType;
    createdAt?: number | null;
  } | null;
};

export type CreateCommentForActivityFeedMutationVariables = Exact<{
  activityId: Scalars['String'];
  text: Scalars['String'];
}>;

export type CreateCommentForActivityFeedMutation = {
  __typename?: 'Mutation';
  createCommentForActivityFeed?: {
    __typename?: 'Activity';
    id: string;
    hideReaction: boolean;
    teamId?: string | null;
    verb: ActivityVerb;
    headerTitle: string;
    bodyText: string;
    type: ActivityType;
    createdAt: number;
    updatedAt: number;
    reactions: Array<{
      __typename?: 'ActivityFeedReaction';
      id: string;
      userId: string;
      kind: ActivityReactionKind;
      createdAt: number;
      updatedAt: number;
      user: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        name?: string | null;
        email: string;
      };
    } | null>;
    comments: Array<{
      __typename?: 'ActivityFeedComment';
      id: string;
      userId: string;
      text: string;
      createdAt: number;
      updatedAt: number;
      user: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        name?: string | null;
        email: string;
      };
    } | null>;
    board?: {
      __typename?: 'Board';
      id: string;
      name: string;
      teamId: string;
      ownerId: string;
      boardAsyncScheduleId?: string | null;
      participationRate?: number | null;
      totalCards: number;
      public: boolean;
      createdAt?: number | null;
      updatedAt?: number | null;
      archived: boolean;
      type: string;
      formatSlug: string;
      format: {
        __typename?: 'RetrospectiveFormat';
        iconImageUrl?: string | null;
        displayName?: string | null;
      };
    } | null;
    actionItem?: {
      __typename?: 'ActionItem';
      id: string;
      title: string;
      description?: string | null;
      teamId: string;
      resolvedAt?: number | null;
      dueDate?: number | null;
      authorId: string;
      boardId?: string | null;
      status: ActionItemStatus;
      archived: boolean;
      createdAt?: number | null;
      children: Array<{
        __typename?: 'ActionItemChild';
        id: string;
        text: string;
      }>;
      author?: { __typename?: 'Author'; name: string } | null;
      assignees?: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
      } | null> | null;
      improvementGoals?: Array<{
        __typename?: 'ImprovementGoal';
        id: string;
        type: { __typename?: 'ImprovementGoalType'; name: string };
      } | null> | null;
      jiraIssueLink?: {
        __typename?: 'ActionItemJiraLink';
        jiraIssueId?: string | null;
        jiraIssueUrl?: string | null;
        jiraIssueKey?: string | null;
        jiraProjectKey?: string | null;
      } | null;
      comments: Array<{
        __typename?: 'ActionItemComment';
        id: string;
        text: string;
        actionItemId: string;
        createdAt: number;
        archived: boolean;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
    } | null;
    parkingLot?: {
      __typename?: 'ParkingLotItem';
      id: string;
      text: string;
      votes: number;
      createdAt: number;
      archived: boolean;
      children: Array<{ __typename?: 'ParkingLotItem'; text: string }>;
      team: { __typename?: 'Team'; id: string };
      author: { __typename?: 'User'; id: string };
    } | null;
    goal?: {
      __typename?: 'ImprovementGoal';
      id: string;
      createdAt: number;
      timesUsed: number;
      updatedAt: number;
      archived: boolean;
      paused: boolean;
      target: number;
      ongoingActionsCount: number;
      actionsTakenCount: number;
      data: Array<{
        __typename?: 'ImprovementGoalData';
        id: string;
        improvementGoalId: string;
        value: number;
        date: number;
        createdAt: number;
        archived: boolean;
        origin?: ImprovementGoalDataOrigin | null;
        children: Array<{
          __typename?: 'ImprovementGoalData';
          id: string;
          improvementGoalId: string;
          value: number;
          date: number;
          createdAt: number;
          archived: boolean;
          origin?: ImprovementGoalDataOrigin | null;
        } | null>;
      }>;
      type: {
        __typename?: 'ImprovementGoalType';
        id: string;
        name: string;
        color: string;
        description?: string | null;
        learnMoreUrl?: string | null;
        unit: ImprovementGoalUnit;
        categories: Array<ImprovementGoalTypeCategory>;
        subCategory?: string | null;
        targetType: ImprovementGoalTypeTargetType;
        aggregationPeriod: ImprovementGoalAggregationPeriod;
        aggregationFunction: ImprovementGoalAggregationFunction;
        isPollable: boolean;
        minValue?: number | null;
        maxValue?: number | null;
      };
      team: { __typename?: 'Team'; type: TeamType; name: string };
      user: { __typename?: 'User'; name?: string | null };
      userReminders: Array<{
        __typename?: 'ImprovementGoalUserReminder';
        improvementGoalId: string;
        userId: string;
        notificationTime: number;
        frequency: ImprovementGoalUserReminderFrequency;
        createdAt: number;
        updatedAt: number;
      }>;
    } | null;
    insight?: {
      __typename?: 'TeamInsightFactCauseSolution';
      id: string;
      seen: boolean;
      rated: boolean;
      date: number;
      team: { __typename?: 'Team'; id: string; name: string; type: TeamType };
      fact:
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricBelowRecommended';
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricDeterioration';
            id: string;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricImprovement';
            id: string;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricInadequacy';
            id: string;
            recommended: number;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactOutlierWorkItemTopic';
            id: string;
            topic: string;
          };
      causes?: Array<
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseMetricDeterioration';
            id: string;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequests';
            id: string;
            topic?: string | null;
            pullRequests: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequest';
              title: string;
              externalUrl?: string | null;
              slug?: string | null;
              origin?: SourceControlOrigin | null;
              wipTimeMs?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseOutlierWorkItems';
            id: string;
            topic?: string | null;
            workItems: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCauseWorkItem';
              slug?: string | null;
              title: string;
              wipTimeMs: number;
              origin?: WorkItemOrigin | null;
              url?: string | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseTopic';
            id: string;
            topic?: string | null;
            cards: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCauseTopicCard';
              text: string;
              badgeColor: string;
              badgeText: string;
              retroName: string;
              retroDate: number;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseUnreviewedPullRequests';
            id: string;
            topic?: string | null;
            pullRequests: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCausePullRequest';
              title: string;
              description?: string | null;
              externalUrl?: string | null;
              slug?: string | null;
              createdAt: string;
              origin?: SourceControlOrigin | null;
            }>;
          }
      > | null;
      solutions?: Array<
        Array<
          | {
              __typename?: 'TeamInsightFactCauseSolutionSolutionActionItem';
              id: string;
              text: string;
              originatedActionItem?: {
                __typename?: 'ActionItem';
                id: string;
                title: string;
                description?: string | null;
                teamId: string;
                resolvedAt?: number | null;
                dueDate?: number | null;
                authorId: string;
                boardId?: string | null;
                status: ActionItemStatus;
                archived: boolean;
                createdAt?: number | null;
                children: Array<{
                  __typename?: 'ActionItemChild';
                  id: string;
                  text: string;
                }>;
                author?: { __typename?: 'Author'; name: string } | null;
                assignees?: Array<{
                  __typename?: 'User';
                  id: string;
                  name?: string | null;
                } | null> | null;
                improvementGoals?: Array<{
                  __typename?: 'ImprovementGoal';
                  id: string;
                  type: { __typename?: 'ImprovementGoalType'; name: string };
                } | null> | null;
                jiraIssueLink?: {
                  __typename?: 'ActionItemJiraLink';
                  jiraIssueId?: string | null;
                  jiraIssueUrl?: string | null;
                  jiraIssueKey?: string | null;
                  jiraProjectKey?: string | null;
                } | null;
                comments: Array<{
                  __typename?: 'ActionItemComment';
                  id: string;
                  text: string;
                  actionItemId: string;
                  createdAt: number;
                  archived: boolean;
                  author: {
                    __typename?: 'User';
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionSolutionGoal';
              id: string;
              type: {
                __typename?: 'ImprovementGoalType';
                id: string;
                name: string;
                color: string;
                description?: string | null;
                learnMoreUrl?: string | null;
                unit: ImprovementGoalUnit;
                categories: Array<ImprovementGoalTypeCategory>;
                subCategory?: string | null;
                targetType: ImprovementGoalTypeTargetType;
                aggregationPeriod: ImprovementGoalAggregationPeriod;
                aggregationFunction: ImprovementGoalAggregationFunction;
                isPollable: boolean;
                minValue?: number | null;
                maxValue?: number | null;
              };
            }
        >
      > | null;
    } | null;
    user?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    } | null;
    team?: {
      __typename?: 'Team';
      id: string;
      name: string;
      type: TeamType;
      createdAt?: number | null;
    } | null;
  } | null;
};

export type ArchiveCommentForActivityFeedMutationVariables = Exact<{
  activityId: Scalars['String'];
  activityFeedCommentId: Scalars['String'];
}>;

export type ArchiveCommentForActivityFeedMutation = {
  __typename?: 'Mutation';
  archiveCommentForActivityFeed?: {
    __typename?: 'Activity';
    id: string;
    hideReaction: boolean;
    teamId?: string | null;
    verb: ActivityVerb;
    headerTitle: string;
    bodyText: string;
    type: ActivityType;
    createdAt: number;
    updatedAt: number;
    reactions: Array<{
      __typename?: 'ActivityFeedReaction';
      id: string;
      userId: string;
      kind: ActivityReactionKind;
      createdAt: number;
      updatedAt: number;
      user: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        name?: string | null;
        email: string;
      };
    } | null>;
    comments: Array<{
      __typename?: 'ActivityFeedComment';
      id: string;
      userId: string;
      text: string;
      createdAt: number;
      updatedAt: number;
      user: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        name?: string | null;
        email: string;
      };
    } | null>;
    board?: {
      __typename?: 'Board';
      id: string;
      name: string;
      teamId: string;
      ownerId: string;
      boardAsyncScheduleId?: string | null;
      participationRate?: number | null;
      totalCards: number;
      public: boolean;
      createdAt?: number | null;
      updatedAt?: number | null;
      archived: boolean;
      type: string;
      formatSlug: string;
      format: {
        __typename?: 'RetrospectiveFormat';
        iconImageUrl?: string | null;
        displayName?: string | null;
      };
    } | null;
    actionItem?: {
      __typename?: 'ActionItem';
      id: string;
      title: string;
      description?: string | null;
      teamId: string;
      resolvedAt?: number | null;
      dueDate?: number | null;
      authorId: string;
      boardId?: string | null;
      status: ActionItemStatus;
      archived: boolean;
      createdAt?: number | null;
      children: Array<{
        __typename?: 'ActionItemChild';
        id: string;
        text: string;
      }>;
      author?: { __typename?: 'Author'; name: string } | null;
      assignees?: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
      } | null> | null;
      improvementGoals?: Array<{
        __typename?: 'ImprovementGoal';
        id: string;
        type: { __typename?: 'ImprovementGoalType'; name: string };
      } | null> | null;
      jiraIssueLink?: {
        __typename?: 'ActionItemJiraLink';
        jiraIssueId?: string | null;
        jiraIssueUrl?: string | null;
        jiraIssueKey?: string | null;
        jiraProjectKey?: string | null;
      } | null;
      comments: Array<{
        __typename?: 'ActionItemComment';
        id: string;
        text: string;
        actionItemId: string;
        createdAt: number;
        archived: boolean;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
    } | null;
    parkingLot?: {
      __typename?: 'ParkingLotItem';
      id: string;
      text: string;
      votes: number;
      createdAt: number;
      archived: boolean;
      children: Array<{ __typename?: 'ParkingLotItem'; text: string }>;
      team: { __typename?: 'Team'; id: string };
      author: { __typename?: 'User'; id: string };
    } | null;
    goal?: {
      __typename?: 'ImprovementGoal';
      id: string;
      createdAt: number;
      timesUsed: number;
      updatedAt: number;
      archived: boolean;
      paused: boolean;
      target: number;
      ongoingActionsCount: number;
      actionsTakenCount: number;
      data: Array<{
        __typename?: 'ImprovementGoalData';
        id: string;
        improvementGoalId: string;
        value: number;
        date: number;
        createdAt: number;
        archived: boolean;
        origin?: ImprovementGoalDataOrigin | null;
        children: Array<{
          __typename?: 'ImprovementGoalData';
          id: string;
          improvementGoalId: string;
          value: number;
          date: number;
          createdAt: number;
          archived: boolean;
          origin?: ImprovementGoalDataOrigin | null;
        } | null>;
      }>;
      type: {
        __typename?: 'ImprovementGoalType';
        id: string;
        name: string;
        color: string;
        description?: string | null;
        learnMoreUrl?: string | null;
        unit: ImprovementGoalUnit;
        categories: Array<ImprovementGoalTypeCategory>;
        subCategory?: string | null;
        targetType: ImprovementGoalTypeTargetType;
        aggregationPeriod: ImprovementGoalAggregationPeriod;
        aggregationFunction: ImprovementGoalAggregationFunction;
        isPollable: boolean;
        minValue?: number | null;
        maxValue?: number | null;
      };
      team: { __typename?: 'Team'; type: TeamType; name: string };
      user: { __typename?: 'User'; name?: string | null };
      userReminders: Array<{
        __typename?: 'ImprovementGoalUserReminder';
        improvementGoalId: string;
        userId: string;
        notificationTime: number;
        frequency: ImprovementGoalUserReminderFrequency;
        createdAt: number;
        updatedAt: number;
      }>;
    } | null;
    insight?: {
      __typename?: 'TeamInsightFactCauseSolution';
      id: string;
      seen: boolean;
      rated: boolean;
      date: number;
      team: { __typename?: 'Team'; id: string; name: string; type: TeamType };
      fact:
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricBelowRecommended';
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricDeterioration';
            id: string;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricImprovement';
            id: string;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricInadequacy';
            id: string;
            recommended: number;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactOutlierWorkItemTopic';
            id: string;
            topic: string;
          };
      causes?: Array<
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseMetricDeterioration';
            id: string;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequests';
            id: string;
            topic?: string | null;
            pullRequests: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequest';
              title: string;
              externalUrl?: string | null;
              slug?: string | null;
              origin?: SourceControlOrigin | null;
              wipTimeMs?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseOutlierWorkItems';
            id: string;
            topic?: string | null;
            workItems: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCauseWorkItem';
              slug?: string | null;
              title: string;
              wipTimeMs: number;
              origin?: WorkItemOrigin | null;
              url?: string | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseTopic';
            id: string;
            topic?: string | null;
            cards: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCauseTopicCard';
              text: string;
              badgeColor: string;
              badgeText: string;
              retroName: string;
              retroDate: number;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseUnreviewedPullRequests';
            id: string;
            topic?: string | null;
            pullRequests: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCausePullRequest';
              title: string;
              description?: string | null;
              externalUrl?: string | null;
              slug?: string | null;
              createdAt: string;
              origin?: SourceControlOrigin | null;
            }>;
          }
      > | null;
      solutions?: Array<
        Array<
          | {
              __typename?: 'TeamInsightFactCauseSolutionSolutionActionItem';
              id: string;
              text: string;
              originatedActionItem?: {
                __typename?: 'ActionItem';
                id: string;
                title: string;
                description?: string | null;
                teamId: string;
                resolvedAt?: number | null;
                dueDate?: number | null;
                authorId: string;
                boardId?: string | null;
                status: ActionItemStatus;
                archived: boolean;
                createdAt?: number | null;
                children: Array<{
                  __typename?: 'ActionItemChild';
                  id: string;
                  text: string;
                }>;
                author?: { __typename?: 'Author'; name: string } | null;
                assignees?: Array<{
                  __typename?: 'User';
                  id: string;
                  name?: string | null;
                } | null> | null;
                improvementGoals?: Array<{
                  __typename?: 'ImprovementGoal';
                  id: string;
                  type: { __typename?: 'ImprovementGoalType'; name: string };
                } | null> | null;
                jiraIssueLink?: {
                  __typename?: 'ActionItemJiraLink';
                  jiraIssueId?: string | null;
                  jiraIssueUrl?: string | null;
                  jiraIssueKey?: string | null;
                  jiraProjectKey?: string | null;
                } | null;
                comments: Array<{
                  __typename?: 'ActionItemComment';
                  id: string;
                  text: string;
                  actionItemId: string;
                  createdAt: number;
                  archived: boolean;
                  author: {
                    __typename?: 'User';
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionSolutionGoal';
              id: string;
              type: {
                __typename?: 'ImprovementGoalType';
                id: string;
                name: string;
                color: string;
                description?: string | null;
                learnMoreUrl?: string | null;
                unit: ImprovementGoalUnit;
                categories: Array<ImprovementGoalTypeCategory>;
                subCategory?: string | null;
                targetType: ImprovementGoalTypeTargetType;
                aggregationPeriod: ImprovementGoalAggregationPeriod;
                aggregationFunction: ImprovementGoalAggregationFunction;
                isPollable: boolean;
                minValue?: number | null;
                maxValue?: number | null;
              };
            }
        >
      > | null;
    } | null;
    user?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    } | null;
    team?: {
      __typename?: 'Team';
      id: string;
      name: string;
      type: TeamType;
      createdAt?: number | null;
    } | null;
  } | null;
};

export type ToggleReactionForActivityFeedMutationVariables = Exact<{
  activityId: Scalars['String'];
  kind: ActivityReactionKind;
}>;

export type ToggleReactionForActivityFeedMutation = {
  __typename?: 'Mutation';
  toggleReaction?: {
    __typename?: 'Activity';
    id: string;
    hideReaction: boolean;
    teamId?: string | null;
    verb: ActivityVerb;
    headerTitle: string;
    bodyText: string;
    type: ActivityType;
    createdAt: number;
    updatedAt: number;
    reactions: Array<{
      __typename?: 'ActivityFeedReaction';
      id: string;
      userId: string;
      kind: ActivityReactionKind;
      createdAt: number;
      updatedAt: number;
      user: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        name?: string | null;
        email: string;
      };
    } | null>;
    comments: Array<{
      __typename?: 'ActivityFeedComment';
      id: string;
      userId: string;
      text: string;
      createdAt: number;
      updatedAt: number;
      user: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        name?: string | null;
        email: string;
      };
    } | null>;
    board?: {
      __typename?: 'Board';
      id: string;
      name: string;
      teamId: string;
      ownerId: string;
      boardAsyncScheduleId?: string | null;
      participationRate?: number | null;
      totalCards: number;
      public: boolean;
      createdAt?: number | null;
      updatedAt?: number | null;
      archived: boolean;
      type: string;
      formatSlug: string;
      format: {
        __typename?: 'RetrospectiveFormat';
        iconImageUrl?: string | null;
        displayName?: string | null;
      };
    } | null;
    actionItem?: {
      __typename?: 'ActionItem';
      id: string;
      title: string;
      description?: string | null;
      teamId: string;
      resolvedAt?: number | null;
      dueDate?: number | null;
      authorId: string;
      boardId?: string | null;
      status: ActionItemStatus;
      archived: boolean;
      createdAt?: number | null;
      children: Array<{
        __typename?: 'ActionItemChild';
        id: string;
        text: string;
      }>;
      author?: { __typename?: 'Author'; name: string } | null;
      assignees?: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
      } | null> | null;
      improvementGoals?: Array<{
        __typename?: 'ImprovementGoal';
        id: string;
        type: { __typename?: 'ImprovementGoalType'; name: string };
      } | null> | null;
      jiraIssueLink?: {
        __typename?: 'ActionItemJiraLink';
        jiraIssueId?: string | null;
        jiraIssueUrl?: string | null;
        jiraIssueKey?: string | null;
        jiraProjectKey?: string | null;
      } | null;
      comments: Array<{
        __typename?: 'ActionItemComment';
        id: string;
        text: string;
        actionItemId: string;
        createdAt: number;
        archived: boolean;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
    } | null;
    parkingLot?: {
      __typename?: 'ParkingLotItem';
      id: string;
      text: string;
      votes: number;
      createdAt: number;
      archived: boolean;
      children: Array<{ __typename?: 'ParkingLotItem'; text: string }>;
      team: { __typename?: 'Team'; id: string };
      author: { __typename?: 'User'; id: string };
    } | null;
    goal?: {
      __typename?: 'ImprovementGoal';
      id: string;
      createdAt: number;
      timesUsed: number;
      updatedAt: number;
      archived: boolean;
      paused: boolean;
      target: number;
      ongoingActionsCount: number;
      actionsTakenCount: number;
      data: Array<{
        __typename?: 'ImprovementGoalData';
        id: string;
        improvementGoalId: string;
        value: number;
        date: number;
        createdAt: number;
        archived: boolean;
        origin?: ImprovementGoalDataOrigin | null;
        children: Array<{
          __typename?: 'ImprovementGoalData';
          id: string;
          improvementGoalId: string;
          value: number;
          date: number;
          createdAt: number;
          archived: boolean;
          origin?: ImprovementGoalDataOrigin | null;
        } | null>;
      }>;
      type: {
        __typename?: 'ImprovementGoalType';
        id: string;
        name: string;
        color: string;
        description?: string | null;
        learnMoreUrl?: string | null;
        unit: ImprovementGoalUnit;
        categories: Array<ImprovementGoalTypeCategory>;
        subCategory?: string | null;
        targetType: ImprovementGoalTypeTargetType;
        aggregationPeriod: ImprovementGoalAggregationPeriod;
        aggregationFunction: ImprovementGoalAggregationFunction;
        isPollable: boolean;
        minValue?: number | null;
        maxValue?: number | null;
      };
      team: { __typename?: 'Team'; type: TeamType; name: string };
      user: { __typename?: 'User'; name?: string | null };
      userReminders: Array<{
        __typename?: 'ImprovementGoalUserReminder';
        improvementGoalId: string;
        userId: string;
        notificationTime: number;
        frequency: ImprovementGoalUserReminderFrequency;
        createdAt: number;
        updatedAt: number;
      }>;
    } | null;
    insight?: {
      __typename?: 'TeamInsightFactCauseSolution';
      id: string;
      seen: boolean;
      rated: boolean;
      date: number;
      team: { __typename?: 'Team'; id: string; name: string; type: TeamType };
      fact:
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricBelowRecommended';
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricDeterioration';
            id: string;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricImprovement';
            id: string;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricInadequacy';
            id: string;
            recommended: number;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactOutlierWorkItemTopic';
            id: string;
            topic: string;
          };
      causes?: Array<
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseMetricDeterioration';
            id: string;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequests';
            id: string;
            topic?: string | null;
            pullRequests: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequest';
              title: string;
              externalUrl?: string | null;
              slug?: string | null;
              origin?: SourceControlOrigin | null;
              wipTimeMs?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseOutlierWorkItems';
            id: string;
            topic?: string | null;
            workItems: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCauseWorkItem';
              slug?: string | null;
              title: string;
              wipTimeMs: number;
              origin?: WorkItemOrigin | null;
              url?: string | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseTopic';
            id: string;
            topic?: string | null;
            cards: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCauseTopicCard';
              text: string;
              badgeColor: string;
              badgeText: string;
              retroName: string;
              retroDate: number;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseUnreviewedPullRequests';
            id: string;
            topic?: string | null;
            pullRequests: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCausePullRequest';
              title: string;
              description?: string | null;
              externalUrl?: string | null;
              slug?: string | null;
              createdAt: string;
              origin?: SourceControlOrigin | null;
            }>;
          }
      > | null;
      solutions?: Array<
        Array<
          | {
              __typename?: 'TeamInsightFactCauseSolutionSolutionActionItem';
              id: string;
              text: string;
              originatedActionItem?: {
                __typename?: 'ActionItem';
                id: string;
                title: string;
                description?: string | null;
                teamId: string;
                resolvedAt?: number | null;
                dueDate?: number | null;
                authorId: string;
                boardId?: string | null;
                status: ActionItemStatus;
                archived: boolean;
                createdAt?: number | null;
                children: Array<{
                  __typename?: 'ActionItemChild';
                  id: string;
                  text: string;
                }>;
                author?: { __typename?: 'Author'; name: string } | null;
                assignees?: Array<{
                  __typename?: 'User';
                  id: string;
                  name?: string | null;
                } | null> | null;
                improvementGoals?: Array<{
                  __typename?: 'ImprovementGoal';
                  id: string;
                  type: { __typename?: 'ImprovementGoalType'; name: string };
                } | null> | null;
                jiraIssueLink?: {
                  __typename?: 'ActionItemJiraLink';
                  jiraIssueId?: string | null;
                  jiraIssueUrl?: string | null;
                  jiraIssueKey?: string | null;
                  jiraProjectKey?: string | null;
                } | null;
                comments: Array<{
                  __typename?: 'ActionItemComment';
                  id: string;
                  text: string;
                  actionItemId: string;
                  createdAt: number;
                  archived: boolean;
                  author: {
                    __typename?: 'User';
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionSolutionGoal';
              id: string;
              type: {
                __typename?: 'ImprovementGoalType';
                id: string;
                name: string;
                color: string;
                description?: string | null;
                learnMoreUrl?: string | null;
                unit: ImprovementGoalUnit;
                categories: Array<ImprovementGoalTypeCategory>;
                subCategory?: string | null;
                targetType: ImprovementGoalTypeTargetType;
                aggregationPeriod: ImprovementGoalAggregationPeriod;
                aggregationFunction: ImprovementGoalAggregationFunction;
                isPollable: boolean;
                minValue?: number | null;
                maxValue?: number | null;
              };
            }
        >
      > | null;
    } | null;
    user?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    } | null;
    team?: {
      __typename?: 'Team';
      id: string;
      name: string;
      type: TeamType;
      createdAt?: number | null;
    } | null;
  } | null;
};

export type GetActivityFeedQueryVariables = Exact<{
  teamId: Scalars['String'];
  pageSize: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
  typeFilters?: InputMaybe<Array<ActivityType> | ActivityType>;
}>;

export type GetActivityFeedQuery = {
  __typename?: 'Query';
  feed?: {
    __typename?: 'ActivityFeed';
    nextCursor?: string | null;
    activities: Array<{
      __typename?: 'Activity';
      id: string;
      hideReaction: boolean;
      teamId?: string | null;
      verb: ActivityVerb;
      headerTitle: string;
      bodyText: string;
      type: ActivityType;
      createdAt: number;
      updatedAt: number;
      reactions: Array<{
        __typename?: 'ActivityFeedReaction';
        id: string;
        userId: string;
        kind: ActivityReactionKind;
        createdAt: number;
        updatedAt: number;
        user: {
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          name?: string | null;
          email: string;
        };
      } | null>;
      comments: Array<{
        __typename?: 'ActivityFeedComment';
        id: string;
        userId: string;
        text: string;
        createdAt: number;
        updatedAt: number;
        user: {
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          name?: string | null;
          email: string;
        };
      } | null>;
      board?: {
        __typename?: 'Board';
        id: string;
        name: string;
        teamId: string;
        ownerId: string;
        boardAsyncScheduleId?: string | null;
        participationRate?: number | null;
        totalCards: number;
        public: boolean;
        createdAt?: number | null;
        updatedAt?: number | null;
        archived: boolean;
        type: string;
        formatSlug: string;
        format: {
          __typename?: 'RetrospectiveFormat';
          iconImageUrl?: string | null;
          displayName?: string | null;
        };
      } | null;
      actionItem?: {
        __typename?: 'ActionItem';
        id: string;
        title: string;
        description?: string | null;
        teamId: string;
        resolvedAt?: number | null;
        dueDate?: number | null;
        authorId: string;
        boardId?: string | null;
        status: ActionItemStatus;
        archived: boolean;
        createdAt?: number | null;
        children: Array<{
          __typename?: 'ActionItemChild';
          id: string;
          text: string;
        }>;
        author?: { __typename?: 'Author'; name: string } | null;
        assignees?: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
        } | null> | null;
        improvementGoals?: Array<{
          __typename?: 'ImprovementGoal';
          id: string;
          type: { __typename?: 'ImprovementGoalType'; name: string };
        } | null> | null;
        jiraIssueLink?: {
          __typename?: 'ActionItemJiraLink';
          jiraIssueId?: string | null;
          jiraIssueUrl?: string | null;
          jiraIssueKey?: string | null;
          jiraProjectKey?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'ActionItemComment';
          id: string;
          text: string;
          actionItemId: string;
          createdAt: number;
          archived: boolean;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null;
      parkingLot?: {
        __typename?: 'ParkingLotItem';
        id: string;
        text: string;
        votes: number;
        createdAt: number;
        archived: boolean;
        children: Array<{ __typename?: 'ParkingLotItem'; text: string }>;
        team: { __typename?: 'Team'; id: string };
        author: { __typename?: 'User'; id: string };
      } | null;
      goal?: {
        __typename?: 'ImprovementGoal';
        id: string;
        createdAt: number;
        timesUsed: number;
        updatedAt: number;
        archived: boolean;
        paused: boolean;
        target: number;
        ongoingActionsCount: number;
        actionsTakenCount: number;
        data: Array<{
          __typename?: 'ImprovementGoalData';
          id: string;
          improvementGoalId: string;
          value: number;
          date: number;
          createdAt: number;
          archived: boolean;
          origin?: ImprovementGoalDataOrigin | null;
          children: Array<{
            __typename?: 'ImprovementGoalData';
            id: string;
            improvementGoalId: string;
            value: number;
            date: number;
            createdAt: number;
            archived: boolean;
            origin?: ImprovementGoalDataOrigin | null;
          } | null>;
        }>;
        type: {
          __typename?: 'ImprovementGoalType';
          id: string;
          name: string;
          color: string;
          description?: string | null;
          learnMoreUrl?: string | null;
          unit: ImprovementGoalUnit;
          categories: Array<ImprovementGoalTypeCategory>;
          subCategory?: string | null;
          targetType: ImprovementGoalTypeTargetType;
          aggregationPeriod: ImprovementGoalAggregationPeriod;
          aggregationFunction: ImprovementGoalAggregationFunction;
          isPollable: boolean;
          minValue?: number | null;
          maxValue?: number | null;
        };
        team: { __typename?: 'Team'; type: TeamType; name: string };
        user: { __typename?: 'User'; name?: string | null };
        userReminders: Array<{
          __typename?: 'ImprovementGoalUserReminder';
          improvementGoalId: string;
          userId: string;
          notificationTime: number;
          frequency: ImprovementGoalUserReminderFrequency;
          createdAt: number;
          updatedAt: number;
        }>;
      } | null;
      insight?: {
        __typename?: 'TeamInsightFactCauseSolution';
        id: string;
        seen: boolean;
        rated: boolean;
        date: number;
        team: { __typename?: 'Team'; id: string; name: string; type: TeamType };
        fact:
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricBelowRecommended';
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricDeterioration';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricImprovement';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricInadequacy';
              id: string;
              recommended: number;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactOutlierWorkItemTopic';
              id: string;
              topic: string;
            };
        causes?: Array<
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseMetricDeterioration';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequests';
              id: string;
              topic?: string | null;
              pullRequests: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequest';
                title: string;
                externalUrl?: string | null;
                slug?: string | null;
                origin?: SourceControlOrigin | null;
                wipTimeMs?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseOutlierWorkItems';
              id: string;
              topic?: string | null;
              workItems: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseWorkItem';
                slug?: string | null;
                title: string;
                wipTimeMs: number;
                origin?: WorkItemOrigin | null;
                url?: string | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseTopic';
              id: string;
              topic?: string | null;
              cards: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseTopicCard';
                text: string;
                badgeColor: string;
                badgeText: string;
                retroName: string;
                retroDate: number;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseUnreviewedPullRequests';
              id: string;
              topic?: string | null;
              pullRequests: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCausePullRequest';
                title: string;
                description?: string | null;
                externalUrl?: string | null;
                slug?: string | null;
                createdAt: string;
                origin?: SourceControlOrigin | null;
              }>;
            }
        > | null;
        solutions?: Array<
          Array<
            | {
                __typename?: 'TeamInsightFactCauseSolutionSolutionActionItem';
                id: string;
                text: string;
                originatedActionItem?: {
                  __typename?: 'ActionItem';
                  id: string;
                  title: string;
                  description?: string | null;
                  teamId: string;
                  resolvedAt?: number | null;
                  dueDate?: number | null;
                  authorId: string;
                  boardId?: string | null;
                  status: ActionItemStatus;
                  archived: boolean;
                  createdAt?: number | null;
                  children: Array<{
                    __typename?: 'ActionItemChild';
                    id: string;
                    text: string;
                  }>;
                  author?: { __typename?: 'Author'; name: string } | null;
                  assignees?: Array<{
                    __typename?: 'User';
                    id: string;
                    name?: string | null;
                  } | null> | null;
                  improvementGoals?: Array<{
                    __typename?: 'ImprovementGoal';
                    id: string;
                    type: { __typename?: 'ImprovementGoalType'; name: string };
                  } | null> | null;
                  jiraIssueLink?: {
                    __typename?: 'ActionItemJiraLink';
                    jiraIssueId?: string | null;
                    jiraIssueUrl?: string | null;
                    jiraIssueKey?: string | null;
                    jiraProjectKey?: string | null;
                  } | null;
                  comments: Array<{
                    __typename?: 'ActionItemComment';
                    id: string;
                    text: string;
                    actionItemId: string;
                    createdAt: number;
                    archived: boolean;
                    author: {
                      __typename?: 'User';
                      id: string;
                      name?: string | null;
                    };
                  }>;
                } | null;
              }
            | {
                __typename?: 'TeamInsightFactCauseSolutionSolutionGoal';
                id: string;
                type: {
                  __typename?: 'ImprovementGoalType';
                  id: string;
                  name: string;
                  color: string;
                  description?: string | null;
                  learnMoreUrl?: string | null;
                  unit: ImprovementGoalUnit;
                  categories: Array<ImprovementGoalTypeCategory>;
                  subCategory?: string | null;
                  targetType: ImprovementGoalTypeTargetType;
                  aggregationPeriod: ImprovementGoalAggregationPeriod;
                  aggregationFunction: ImprovementGoalAggregationFunction;
                  isPollable: boolean;
                  minValue?: number | null;
                  maxValue?: number | null;
                };
              }
          >
        > | null;
      } | null;
      user?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      team?: {
        __typename?: 'Team';
        id: string;
        name: string;
        type: TeamType;
        createdAt?: number | null;
      } | null;
    }>;
  } | null;
};

export type ActivityFeedNewActivityByTeamIdSubscriptionVariables = Exact<{
  teamId: Scalars['String'];
}>;

export type ActivityFeedNewActivityByTeamIdSubscription = {
  __typename?: 'Subscription';
  activityFeedNewActivityByTeamId?: {
    __typename?: 'Activity';
    id: string;
    hideReaction: boolean;
    teamId?: string | null;
    verb: ActivityVerb;
    headerTitle: string;
    bodyText: string;
    type: ActivityType;
    createdAt: number;
    updatedAt: number;
    reactions: Array<{
      __typename?: 'ActivityFeedReaction';
      id: string;
      userId: string;
      kind: ActivityReactionKind;
      createdAt: number;
      updatedAt: number;
      user: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        name?: string | null;
        email: string;
      };
    } | null>;
    comments: Array<{
      __typename?: 'ActivityFeedComment';
      id: string;
      userId: string;
      text: string;
      createdAt: number;
      updatedAt: number;
      user: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        name?: string | null;
        email: string;
      };
    } | null>;
    board?: {
      __typename?: 'Board';
      id: string;
      name: string;
      teamId: string;
      ownerId: string;
      boardAsyncScheduleId?: string | null;
      participationRate?: number | null;
      totalCards: number;
      public: boolean;
      createdAt?: number | null;
      updatedAt?: number | null;
      archived: boolean;
      type: string;
      formatSlug: string;
      format: {
        __typename?: 'RetrospectiveFormat';
        iconImageUrl?: string | null;
        displayName?: string | null;
      };
    } | null;
    actionItem?: {
      __typename?: 'ActionItem';
      id: string;
      title: string;
      description?: string | null;
      teamId: string;
      resolvedAt?: number | null;
      dueDate?: number | null;
      authorId: string;
      boardId?: string | null;
      status: ActionItemStatus;
      archived: boolean;
      createdAt?: number | null;
      children: Array<{
        __typename?: 'ActionItemChild';
        id: string;
        text: string;
      }>;
      author?: { __typename?: 'Author'; name: string } | null;
      assignees?: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
      } | null> | null;
      improvementGoals?: Array<{
        __typename?: 'ImprovementGoal';
        id: string;
        type: { __typename?: 'ImprovementGoalType'; name: string };
      } | null> | null;
      jiraIssueLink?: {
        __typename?: 'ActionItemJiraLink';
        jiraIssueId?: string | null;
        jiraIssueUrl?: string | null;
        jiraIssueKey?: string | null;
        jiraProjectKey?: string | null;
      } | null;
      comments: Array<{
        __typename?: 'ActionItemComment';
        id: string;
        text: string;
        actionItemId: string;
        createdAt: number;
        archived: boolean;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
    } | null;
    parkingLot?: {
      __typename?: 'ParkingLotItem';
      id: string;
      text: string;
      votes: number;
      createdAt: number;
      archived: boolean;
      children: Array<{ __typename?: 'ParkingLotItem'; text: string }>;
      team: { __typename?: 'Team'; id: string };
      author: { __typename?: 'User'; id: string };
    } | null;
    goal?: {
      __typename?: 'ImprovementGoal';
      id: string;
      createdAt: number;
      timesUsed: number;
      updatedAt: number;
      archived: boolean;
      paused: boolean;
      target: number;
      ongoingActionsCount: number;
      actionsTakenCount: number;
      data: Array<{
        __typename?: 'ImprovementGoalData';
        id: string;
        improvementGoalId: string;
        value: number;
        date: number;
        createdAt: number;
        archived: boolean;
        origin?: ImprovementGoalDataOrigin | null;
        children: Array<{
          __typename?: 'ImprovementGoalData';
          id: string;
          improvementGoalId: string;
          value: number;
          date: number;
          createdAt: number;
          archived: boolean;
          origin?: ImprovementGoalDataOrigin | null;
        } | null>;
      }>;
      type: {
        __typename?: 'ImprovementGoalType';
        id: string;
        name: string;
        color: string;
        description?: string | null;
        learnMoreUrl?: string | null;
        unit: ImprovementGoalUnit;
        categories: Array<ImprovementGoalTypeCategory>;
        subCategory?: string | null;
        targetType: ImprovementGoalTypeTargetType;
        aggregationPeriod: ImprovementGoalAggregationPeriod;
        aggregationFunction: ImprovementGoalAggregationFunction;
        isPollable: boolean;
        minValue?: number | null;
        maxValue?: number | null;
      };
      team: { __typename?: 'Team'; type: TeamType; name: string };
      user: { __typename?: 'User'; name?: string | null };
      userReminders: Array<{
        __typename?: 'ImprovementGoalUserReminder';
        improvementGoalId: string;
        userId: string;
        notificationTime: number;
        frequency: ImprovementGoalUserReminderFrequency;
        createdAt: number;
        updatedAt: number;
      }>;
    } | null;
    insight?: {
      __typename?: 'TeamInsightFactCauseSolution';
      id: string;
      seen: boolean;
      rated: boolean;
      date: number;
      team: { __typename?: 'Team'; id: string; name: string; type: TeamType };
      fact:
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricBelowRecommended';
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricDeterioration';
            id: string;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricImprovement';
            id: string;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricInadequacy';
            id: string;
            recommended: number;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactOutlierWorkItemTopic';
            id: string;
            topic: string;
          };
      causes?: Array<
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseMetricDeterioration';
            id: string;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequests';
            id: string;
            topic?: string | null;
            pullRequests: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequest';
              title: string;
              externalUrl?: string | null;
              slug?: string | null;
              origin?: SourceControlOrigin | null;
              wipTimeMs?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseOutlierWorkItems';
            id: string;
            topic?: string | null;
            workItems: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCauseWorkItem';
              slug?: string | null;
              title: string;
              wipTimeMs: number;
              origin?: WorkItemOrigin | null;
              url?: string | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseTopic';
            id: string;
            topic?: string | null;
            cards: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCauseTopicCard';
              text: string;
              badgeColor: string;
              badgeText: string;
              retroName: string;
              retroDate: number;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseUnreviewedPullRequests';
            id: string;
            topic?: string | null;
            pullRequests: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCausePullRequest';
              title: string;
              description?: string | null;
              externalUrl?: string | null;
              slug?: string | null;
              createdAt: string;
              origin?: SourceControlOrigin | null;
            }>;
          }
      > | null;
      solutions?: Array<
        Array<
          | {
              __typename?: 'TeamInsightFactCauseSolutionSolutionActionItem';
              id: string;
              text: string;
              originatedActionItem?: {
                __typename?: 'ActionItem';
                id: string;
                title: string;
                description?: string | null;
                teamId: string;
                resolvedAt?: number | null;
                dueDate?: number | null;
                authorId: string;
                boardId?: string | null;
                status: ActionItemStatus;
                archived: boolean;
                createdAt?: number | null;
                children: Array<{
                  __typename?: 'ActionItemChild';
                  id: string;
                  text: string;
                }>;
                author?: { __typename?: 'Author'; name: string } | null;
                assignees?: Array<{
                  __typename?: 'User';
                  id: string;
                  name?: string | null;
                } | null> | null;
                improvementGoals?: Array<{
                  __typename?: 'ImprovementGoal';
                  id: string;
                  type: { __typename?: 'ImprovementGoalType'; name: string };
                } | null> | null;
                jiraIssueLink?: {
                  __typename?: 'ActionItemJiraLink';
                  jiraIssueId?: string | null;
                  jiraIssueUrl?: string | null;
                  jiraIssueKey?: string | null;
                  jiraProjectKey?: string | null;
                } | null;
                comments: Array<{
                  __typename?: 'ActionItemComment';
                  id: string;
                  text: string;
                  actionItemId: string;
                  createdAt: number;
                  archived: boolean;
                  author: {
                    __typename?: 'User';
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionSolutionGoal';
              id: string;
              type: {
                __typename?: 'ImprovementGoalType';
                id: string;
                name: string;
                color: string;
                description?: string | null;
                learnMoreUrl?: string | null;
                unit: ImprovementGoalUnit;
                categories: Array<ImprovementGoalTypeCategory>;
                subCategory?: string | null;
                targetType: ImprovementGoalTypeTargetType;
                aggregationPeriod: ImprovementGoalAggregationPeriod;
                aggregationFunction: ImprovementGoalAggregationFunction;
                isPollable: boolean;
                minValue?: number | null;
                maxValue?: number | null;
              };
            }
        >
      > | null;
    } | null;
    user?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    } | null;
    team?: {
      __typename?: 'Team';
      id: string;
      name: string;
      type: TeamType;
      createdAt?: number | null;
    } | null;
  } | null;
};

export type ActivityFeedUpdateByTeamIdSubscriptionVariables = Exact<{
  teamId: Scalars['String'];
}>;

export type ActivityFeedUpdateByTeamIdSubscription = {
  __typename?: 'Subscription';
  activityFeedUpdateByTeamId?: {
    __typename?: 'Activity';
    id: string;
    hideReaction: boolean;
    teamId?: string | null;
    verb: ActivityVerb;
    headerTitle: string;
    bodyText: string;
    type: ActivityType;
    createdAt: number;
    updatedAt: number;
    reactions: Array<{
      __typename?: 'ActivityFeedReaction';
      id: string;
      userId: string;
      kind: ActivityReactionKind;
      createdAt: number;
      updatedAt: number;
      user: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        name?: string | null;
        email: string;
      };
    } | null>;
    comments: Array<{
      __typename?: 'ActivityFeedComment';
      id: string;
      userId: string;
      text: string;
      createdAt: number;
      updatedAt: number;
      user: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        name?: string | null;
        email: string;
      };
    } | null>;
    board?: {
      __typename?: 'Board';
      id: string;
      name: string;
      teamId: string;
      ownerId: string;
      boardAsyncScheduleId?: string | null;
      participationRate?: number | null;
      totalCards: number;
      public: boolean;
      createdAt?: number | null;
      updatedAt?: number | null;
      archived: boolean;
      type: string;
      formatSlug: string;
      format: {
        __typename?: 'RetrospectiveFormat';
        iconImageUrl?: string | null;
        displayName?: string | null;
      };
    } | null;
    actionItem?: {
      __typename?: 'ActionItem';
      id: string;
      title: string;
      description?: string | null;
      teamId: string;
      resolvedAt?: number | null;
      dueDate?: number | null;
      authorId: string;
      boardId?: string | null;
      status: ActionItemStatus;
      archived: boolean;
      createdAt?: number | null;
      children: Array<{
        __typename?: 'ActionItemChild';
        id: string;
        text: string;
      }>;
      author?: { __typename?: 'Author'; name: string } | null;
      assignees?: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
      } | null> | null;
      improvementGoals?: Array<{
        __typename?: 'ImprovementGoal';
        id: string;
        type: { __typename?: 'ImprovementGoalType'; name: string };
      } | null> | null;
      jiraIssueLink?: {
        __typename?: 'ActionItemJiraLink';
        jiraIssueId?: string | null;
        jiraIssueUrl?: string | null;
        jiraIssueKey?: string | null;
        jiraProjectKey?: string | null;
      } | null;
      comments: Array<{
        __typename?: 'ActionItemComment';
        id: string;
        text: string;
        actionItemId: string;
        createdAt: number;
        archived: boolean;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
    } | null;
    parkingLot?: {
      __typename?: 'ParkingLotItem';
      id: string;
      text: string;
      votes: number;
      createdAt: number;
      archived: boolean;
      children: Array<{ __typename?: 'ParkingLotItem'; text: string }>;
      team: { __typename?: 'Team'; id: string };
      author: { __typename?: 'User'; id: string };
    } | null;
    goal?: {
      __typename?: 'ImprovementGoal';
      id: string;
      createdAt: number;
      timesUsed: number;
      updatedAt: number;
      archived: boolean;
      paused: boolean;
      target: number;
      ongoingActionsCount: number;
      actionsTakenCount: number;
      data: Array<{
        __typename?: 'ImprovementGoalData';
        id: string;
        improvementGoalId: string;
        value: number;
        date: number;
        createdAt: number;
        archived: boolean;
        origin?: ImprovementGoalDataOrigin | null;
        children: Array<{
          __typename?: 'ImprovementGoalData';
          id: string;
          improvementGoalId: string;
          value: number;
          date: number;
          createdAt: number;
          archived: boolean;
          origin?: ImprovementGoalDataOrigin | null;
        } | null>;
      }>;
      type: {
        __typename?: 'ImprovementGoalType';
        id: string;
        name: string;
        color: string;
        description?: string | null;
        learnMoreUrl?: string | null;
        unit: ImprovementGoalUnit;
        categories: Array<ImprovementGoalTypeCategory>;
        subCategory?: string | null;
        targetType: ImprovementGoalTypeTargetType;
        aggregationPeriod: ImprovementGoalAggregationPeriod;
        aggregationFunction: ImprovementGoalAggregationFunction;
        isPollable: boolean;
        minValue?: number | null;
        maxValue?: number | null;
      };
      team: { __typename?: 'Team'; type: TeamType; name: string };
      user: { __typename?: 'User'; name?: string | null };
      userReminders: Array<{
        __typename?: 'ImprovementGoalUserReminder';
        improvementGoalId: string;
        userId: string;
        notificationTime: number;
        frequency: ImprovementGoalUserReminderFrequency;
        createdAt: number;
        updatedAt: number;
      }>;
    } | null;
    insight?: {
      __typename?: 'TeamInsightFactCauseSolution';
      id: string;
      seen: boolean;
      rated: boolean;
      date: number;
      team: { __typename?: 'Team'; id: string; name: string; type: TeamType };
      fact:
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricBelowRecommended';
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricDeterioration';
            id: string;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricImprovement';
            id: string;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricInadequacy';
            id: string;
            recommended: number;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactOutlierWorkItemTopic';
            id: string;
            topic: string;
          };
      causes?: Array<
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseMetricDeterioration';
            id: string;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequests';
            id: string;
            topic?: string | null;
            pullRequests: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequest';
              title: string;
              externalUrl?: string | null;
              slug?: string | null;
              origin?: SourceControlOrigin | null;
              wipTimeMs?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseOutlierWorkItems';
            id: string;
            topic?: string | null;
            workItems: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCauseWorkItem';
              slug?: string | null;
              title: string;
              wipTimeMs: number;
              origin?: WorkItemOrigin | null;
              url?: string | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseTopic';
            id: string;
            topic?: string | null;
            cards: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCauseTopicCard';
              text: string;
              badgeColor: string;
              badgeText: string;
              retroName: string;
              retroDate: number;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseUnreviewedPullRequests';
            id: string;
            topic?: string | null;
            pullRequests: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCausePullRequest';
              title: string;
              description?: string | null;
              externalUrl?: string | null;
              slug?: string | null;
              createdAt: string;
              origin?: SourceControlOrigin | null;
            }>;
          }
      > | null;
      solutions?: Array<
        Array<
          | {
              __typename?: 'TeamInsightFactCauseSolutionSolutionActionItem';
              id: string;
              text: string;
              originatedActionItem?: {
                __typename?: 'ActionItem';
                id: string;
                title: string;
                description?: string | null;
                teamId: string;
                resolvedAt?: number | null;
                dueDate?: number | null;
                authorId: string;
                boardId?: string | null;
                status: ActionItemStatus;
                archived: boolean;
                createdAt?: number | null;
                children: Array<{
                  __typename?: 'ActionItemChild';
                  id: string;
                  text: string;
                }>;
                author?: { __typename?: 'Author'; name: string } | null;
                assignees?: Array<{
                  __typename?: 'User';
                  id: string;
                  name?: string | null;
                } | null> | null;
                improvementGoals?: Array<{
                  __typename?: 'ImprovementGoal';
                  id: string;
                  type: { __typename?: 'ImprovementGoalType'; name: string };
                } | null> | null;
                jiraIssueLink?: {
                  __typename?: 'ActionItemJiraLink';
                  jiraIssueId?: string | null;
                  jiraIssueUrl?: string | null;
                  jiraIssueKey?: string | null;
                  jiraProjectKey?: string | null;
                } | null;
                comments: Array<{
                  __typename?: 'ActionItemComment';
                  id: string;
                  text: string;
                  actionItemId: string;
                  createdAt: number;
                  archived: boolean;
                  author: {
                    __typename?: 'User';
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionSolutionGoal';
              id: string;
              type: {
                __typename?: 'ImprovementGoalType';
                id: string;
                name: string;
                color: string;
                description?: string | null;
                learnMoreUrl?: string | null;
                unit: ImprovementGoalUnit;
                categories: Array<ImprovementGoalTypeCategory>;
                subCategory?: string | null;
                targetType: ImprovementGoalTypeTargetType;
                aggregationPeriod: ImprovementGoalAggregationPeriod;
                aggregationFunction: ImprovementGoalAggregationFunction;
                isPollable: boolean;
                minValue?: number | null;
                maxValue?: number | null;
              };
            }
        >
      > | null;
    } | null;
    user?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    } | null;
    team?: {
      __typename?: 'Team';
      id: string;
      name: string;
      type: TeamType;
      createdAt?: number | null;
    } | null;
  } | null;
};

export type ActionItemsAnalyticsQueryVariables = Exact<{
  teamId: Scalars['ID'];
  organizationTeamId: Scalars['ID'];
  startDate: Scalars['Date'];
  middleDate: Scalars['Date'];
  endDate: Scalars['Date'];
  recursive: Scalars['Boolean'];
}>;

export type ActionItemsAnalyticsQuery = {
  __typename?: 'Query';
  current: {
    __typename?: 'ActionItemAnalyticsResults';
    averageTimeToResolve: number;
    createdCount: number;
    resolvedCount: number;
  };
  past: {
    __typename?: 'ActionItemAnalyticsResults';
    averageTimeToResolve: number;
    createdCount: number;
    resolvedCount: number;
  };
  organization: {
    __typename?: 'ActionItemAnalyticsResults';
    averageTimeToResolve: number;
    averageCreatedCount: number;
    averageResolvedCount: number;
  };
};

export type BoardAnalyticsQueryVariables = Exact<{
  teamId: Scalars['ID'];
  organizationTeamId: Scalars['ID'];
  startDate: Scalars['Date'];
  middleDate: Scalars['Date'];
  endDate: Scalars['Date'];
  recursive: Scalars['Boolean'];
}>;

export type BoardAnalyticsQuery = {
  __typename?: 'Query';
  current: {
    __typename?: 'BoardAnalyticsResults';
    averageTimeBetweenRetros: number;
    sumBoardsCreated: number;
    averageParticipationRate: number;
  };
  past: {
    __typename?: 'BoardAnalyticsResults';
    averageTimeBetweenRetros: number;
    sumBoardsCreated: number;
    averageParticipationRate: number;
  };
  organization: {
    __typename?: 'BoardAnalyticsResults';
    averageBoardsCreated: number;
    averageTimeBetweenRetros: number;
    averageParticipationRate: number;
  };
};

export type DiscussionAnalyticsQueryVariables = Exact<{
  teamId: Scalars['ID'];
  organizationTeamId: Scalars['ID'];
  startDate: Scalars['Date'];
  middleDate: Scalars['Date'];
  endDate: Scalars['Date'];
  recursive: Scalars['Boolean'];
}>;

export type DiscussionAnalyticsQuery = {
  __typename?: 'Query';
  current?: {
    __typename?: 'DiscussionAnalyticsResults';
    averageTimeToResolveMs?: number | null;
    avgTimeBetweenRetrosMs?: number | null;
    participationRatePercent: number;
    positiveCardPercentage: number;
    neutralCardPercentage: number;
    negativeCardPercentage: number;
    positiveKeywordExamples: Array<string>;
    neutralKeywordExamples: Array<string>;
    negativeKeywordExamples: Array<string>;
    insight?: {
      __typename?: 'DiscussionAnalyticsInsightKeywordHighlight';
      sentiment: string;
      keyword: string;
    } | null;
  } | null;
  past?: {
    __typename?: 'DiscussionAnalyticsResults';
    averageTimeToResolveMs?: number | null;
    avgTimeBetweenRetrosMs?: number | null;
    participationRatePercent: number;
  } | null;
  organization?: {
    __typename?: 'DiscussionAnalyticsResults';
    averageTimeToResolveMs?: number | null;
    avgTimeBetweenRetrosMs?: number | null;
    participationRatePercent: number;
  } | null;
};

export type GoalFocusAnalyticsQueryVariables = Exact<{
  filter: GoalFocusAnalyticsFilter;
}>;

export type GoalFocusAnalyticsQuery = {
  __typename?: 'Query';
  goalFocusAnalytics: {
    __typename?: 'GoalFocusAnalyticsResults';
    orgGoalsFocusData?: Array<{
      __typename?: 'GoalFocusData';
      goalName: string;
      goalColor: string;
      onTargetPercentage: number;
      offTargetPercentage: number;
      onTargetCount: number;
      offTargetCount: number;
    }> | null;
    programGoalsFocusData: Array<{
      __typename?: 'GoalFocusData';
      goalName: string;
      goalColor: string;
      onTargetPercentage: number;
      offTargetPercentage: number;
      onTargetCount: number;
      offTargetCount: number;
    }>;
    teamGoalsFocusData: Array<{
      __typename?: 'GoalFocusData';
      goalName: string;
      goalColor: string;
      onTargetPercentage: number;
      offTargetPercentage: number;
      onTargetCount: number;
      offTargetCount: number;
    }>;
  };
};

export type RetroSummaryAnalyticsQueryVariables = Exact<{
  filters: AnalyticsFilters;
}>;

export type RetroSummaryAnalyticsQuery = {
  __typename?: 'Query';
  rollupAnalyticsByFilters: {
    __typename?: 'RollupAnalytics';
    averageCardsPerRetrospective?: number | null;
    averageTimePerRetrospectiveInDays?: number | null;
    averageTimeToResolveActionItemsInDays?: number | null;
    sentimentAnalysis: {
      __typename?: 'SentimentAnalysis';
      totalPositive: number;
      totalNegative: number;
      totalNeutral: number;
    };
  };
};

export type BasicBoardFragment = {
  __typename?: 'Board';
  id: string;
  name: string;
  teamId: string;
  ownerId: string;
  boardAsyncScheduleId?: string | null;
  participationRate?: number | null;
  totalCards: number;
  public: boolean;
  createdAt?: number | null;
  updatedAt?: number | null;
  archived: boolean;
  type: string;
  formatSlug: string;
  format: {
    __typename?: 'RetrospectiveFormat';
    iconImageUrl?: string | null;
    displayName?: string | null;
  };
};

export type BoardFragmentFragment = {
  __typename?: 'Board';
  id: string;
  name: string;
  teamId: string;
  boardAsyncScheduleId?: string | null;
  createdAt?: number | null;
  updatedAt?: number | null;
  participationRate?: number | null;
  facilitators?: Array<string | null> | null;
  archived: boolean;
  ownerId: string;
  public: boolean;
  type: string;
  onlineUserIds: Array<string | null>;
  discussReadyUserIds: Array<string | null>;
  tooltipHintsEnabled: boolean;
  formatSlug: string;
  takeaways?: Array<{
    __typename?: 'Takeaway';
    id: string;
    text: string;
    boardId: string;
    type: TakeawayType;
  }> | null;
  format: {
    __typename?: 'RetrospectiveFormat';
    displayName?: string | null;
    iconImageUrl?: string | null;
    description?: string | null;
  };
  state: {
    __typename?: 'BoardState';
    privateCards?: boolean | null;
    privateVotes?: boolean | null;
    timerStartTimeMiliseconds?: number | null;
    timerStopTimeMiliseconds?: number | null;
    stage?: BoardStage | null;
    isGuided?: boolean | null;
    showActionPlan?: boolean | null;
    voteCount?: number | null;
    voteCountWasSuggested?: boolean | null;
    discussCurrentCardId?: string | null;
    discussSortByTags?: boolean | null;
    discussionCardsSortingKey?: BoardDiscussionCardsSortingKey | null;
    password?: string | null;
    takeAwayStatus?: TakeawayStatus | null;
  };
  cardBeingDiscussed?: {
    __typename?: 'Card';
    id: string;
    listId: string;
    authorId: string;
    text: string;
    parentId?: string | null;
    index: number;
    votes: Array<string>;
    archived: boolean;
    updatedAt?: number | null;
    createdAt?: number | null;
    wasDiscussed?: boolean | null;
    originalListBadge?: {
      __typename?: 'OriginalListBadge';
      originalListId?: string | null;
      text?: string | null;
      color?: string | null;
    } | null;
    promotedToPrograms: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
    }>;
    comments: Array<{
      __typename?: 'CardComment';
      id: string;
      text: string;
      authorId: string;
      cardId: string;
      archived: boolean;
      createdAt: number;
      author: { __typename?: 'User'; id: string; name?: string | null };
    }>;
    children?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      authorId: string;
      children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
    } | null> | null;
  } | null;
  cardsOnDiscussion?: Array<{
    __typename?: 'Card';
    id: string;
    listId: string;
    authorId: string;
    text: string;
    parentId?: string | null;
    index: number;
    votes: Array<string>;
    archived: boolean;
    updatedAt?: number | null;
    createdAt?: number | null;
    wasDiscussed?: boolean | null;
    originalListBadge?: {
      __typename?: 'OriginalListBadge';
      originalListId?: string | null;
      text?: string | null;
      color?: string | null;
    } | null;
    promotedToPrograms: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
    }>;
    comments: Array<{
      __typename?: 'CardComment';
      id: string;
      text: string;
      authorId: string;
      cardId: string;
      archived: boolean;
      createdAt: number;
      author: { __typename?: 'User'; id: string; name?: string | null };
    }>;
    children?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      authorId: string;
      children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
    } | null> | null;
  } | null> | null;
  lists?: Array<{
    __typename?: 'List';
    id: string;
    boardId: string;
    name: string;
    index: number;
    archived?: boolean | null;
    cardColor?: string | null;
    cards?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type BoardParticipationFragmentFragment = {
  __typename?: 'BoardParticipation';
  status: BoardParticipationStatus;
  userId: string;
  isFacilitator: boolean;
  numberOfVotes: number;
  numberOfCards: number;
  participant: { __typename?: 'User'; id: string; name?: string | null };
};

export type CardFragmentFragment = {
  __typename?: 'Card';
  id: string;
  listId: string;
  authorId: string;
  text: string;
  parentId?: string | null;
  index: number;
  votes: Array<string>;
  archived: boolean;
  updatedAt?: number | null;
  createdAt?: number | null;
  wasDiscussed?: boolean | null;
  originalListBadge?: {
    __typename?: 'OriginalListBadge';
    originalListId?: string | null;
    text?: string | null;
    color?: string | null;
  } | null;
  promotedToPrograms: Array<{ __typename?: 'Team'; id: string; name: string }>;
  comments: Array<{
    __typename?: 'CardComment';
    id: string;
    text: string;
    authorId: string;
    cardId: string;
    archived: boolean;
    createdAt: number;
    author: { __typename?: 'User'; id: string; name?: string | null };
  }>;
  children?: Array<{
    __typename?: 'Card';
    id: string;
    listId: string;
    text: string;
    parentId?: string | null;
    index: number;
    votes: Array<string>;
    archived: boolean;
    updatedAt?: number | null;
    createdAt?: number | null;
    authorId: string;
    children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
    originalListBadge?: {
      __typename?: 'OriginalListBadge';
      originalListId?: string | null;
      text?: string | null;
      color?: string | null;
    } | null;
    comments: Array<{
      __typename?: 'CardComment';
      id: string;
      text: string;
      authorId: string;
      cardId: string;
      archived: boolean;
      createdAt: number;
      author: { __typename?: 'User'; id: string; name?: string | null };
    }>;
  } | null> | null;
};

export type CardCommentFragmentFragment = {
  __typename?: 'CardComment';
  id: string;
  text: string;
  authorId: string;
  cardId: string;
  archived: boolean;
  createdAt: number;
  author: { __typename?: 'User'; id: string; name?: string | null };
};

export type ListFragmentFragment = {
  __typename?: 'List';
  id: string;
  boardId: string;
  name: string;
  index: number;
  archived?: boolean | null;
  cardColor?: string | null;
  cards?: Array<{
    __typename?: 'Card';
    id: string;
    listId: string;
    authorId: string;
    text: string;
    parentId?: string | null;
    index: number;
    votes: Array<string>;
    archived: boolean;
    updatedAt?: number | null;
    createdAt?: number | null;
    wasDiscussed?: boolean | null;
    originalListBadge?: {
      __typename?: 'OriginalListBadge';
      originalListId?: string | null;
      text?: string | null;
      color?: string | null;
    } | null;
    promotedToPrograms: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
    }>;
    comments: Array<{
      __typename?: 'CardComment';
      id: string;
      text: string;
      authorId: string;
      cardId: string;
      archived: boolean;
      createdAt: number;
      author: { __typename?: 'User'; id: string; name?: string | null };
    }>;
    children?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      authorId: string;
      children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
    } | null> | null;
  } | null> | null;
};

export type WhiteboardFragmentFragment = {
  __typename?: 'Board';
  id: string;
  name: string;
  teamId: string;
  updatedAt?: number | null;
  participationRate?: number | null;
  facilitators?: Array<string | null> | null;
  archived: boolean;
  ownerId: string;
  type: string;
  onlineUserIds: Array<string | null>;
  discussReadyUserIds: Array<string | null>;
  tooltipHintsEnabled: boolean;
  formatSlug: string;
  format: {
    __typename?: 'RetrospectiveFormat';
    displayName?: string | null;
    iconImageUrl?: string | null;
    description?: string | null;
  };
  state: {
    __typename?: 'BoardState';
    privateCards?: boolean | null;
    privateVotes?: boolean | null;
    timerStartTimeMiliseconds?: number | null;
    timerStopTimeMiliseconds?: number | null;
    stage?: BoardStage | null;
    isGuided?: boolean | null;
    password?: string | null;
    showActionPlan?: boolean | null;
    voteCount?: number | null;
    voteCountWasSuggested?: boolean | null;
    discussCurrentCardId?: string | null;
    discussSortByTags?: boolean | null;
    discussionCardsSortingKey?: BoardDiscussionCardsSortingKey | null;
  };
  cardBeingDiscussed?: {
    __typename?: 'Card';
    id: string;
    listId: string;
    authorId: string;
    text: string;
    parentId?: string | null;
    index: number;
    votes: Array<string>;
    archived: boolean;
    updatedAt?: number | null;
    createdAt?: number | null;
    wasDiscussed?: boolean | null;
    originalListBadge?: {
      __typename?: 'OriginalListBadge';
      originalListId?: string | null;
      text?: string | null;
      color?: string | null;
    } | null;
    promotedToPrograms: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
    }>;
    comments: Array<{
      __typename?: 'CardComment';
      id: string;
      text: string;
      authorId: string;
      cardId: string;
      archived: boolean;
      createdAt: number;
      author: { __typename?: 'User'; id: string; name?: string | null };
    }>;
    children?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      authorId: string;
      children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
    } | null> | null;
  } | null;
  cardsOnDiscussion?: Array<{
    __typename?: 'Card';
    id: string;
    listId: string;
    authorId: string;
    text: string;
    parentId?: string | null;
    index: number;
    votes: Array<string>;
    archived: boolean;
    updatedAt?: number | null;
    createdAt?: number | null;
    wasDiscussed?: boolean | null;
    originalListBadge?: {
      __typename?: 'OriginalListBadge';
      originalListId?: string | null;
      text?: string | null;
      color?: string | null;
    } | null;
    promotedToPrograms: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
    }>;
    comments: Array<{
      __typename?: 'CardComment';
      id: string;
      text: string;
      authorId: string;
      cardId: string;
      archived: boolean;
      createdAt: number;
      author: { __typename?: 'User'; id: string; name?: string | null };
    }>;
    children?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      authorId: string;
      children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
    } | null> | null;
  } | null> | null;
  lists?: Array<{
    __typename?: 'List';
    id: string;
    boardId: string;
    name: string;
    index: number;
    archived?: boolean | null;
    cardColor?: string | null;
    cards?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type BroadcastBoardMessageMutationVariables = Exact<{
  boardMessageBroadcastInput: BoardMessageBroadcastInput;
}>;

export type BroadcastBoardMessageMutation = {
  __typename?: 'Mutation';
  boardMessageBroadcast?: boolean | null;
};

export type CloneBoardMutationVariables = Exact<{
  boardIdToClone: Scalars['String'];
  nameOfClone: Scalars['String'];
}>;

export type CloneBoardMutation = {
  __typename?: 'Mutation';
  cloneBoard?: {
    __typename?: 'Board';
    id: string;
    name: string;
    teamId: string;
    ownerId: string;
    boardAsyncScheduleId?: string | null;
    participationRate?: number | null;
    totalCards: number;
    public: boolean;
    createdAt?: number | null;
    updatedAt?: number | null;
    archived: boolean;
    type: string;
    formatSlug: string;
    format: {
      __typename?: 'RetrospectiveFormat';
      iconImageUrl?: string | null;
      displayName?: string | null;
    };
  } | null;
};

export type CreateBoardMutationVariables = Exact<{
  teamId: Scalars['String'];
  ownerId: Scalars['String'];
  name: Scalars['String'];
  listNames?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  public: Scalars['Boolean'];
  guided: Scalars['Boolean'];
  parkingLotItems?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  startOnStage?: InputMaybe<BoardStage>;
  format?: InputMaybe<Scalars['String']>;
}>;

export type CreateBoardMutation = {
  __typename?: 'Mutation';
  createBoard?: {
    __typename?: 'Board';
    id: string;
    name: string;
    participationRate?: number | null;
    totalCards: number;
    public: boolean;
    updatedAt?: number | null;
    teamId: string;
    archived: boolean;
    formatSlug: string;
  } | null;
};

export type CreateCardMutationVariables = Exact<{
  listId: Scalars['String'];
  text: Scalars['String'];
}>;

export type CreateCardMutation = {
  __typename?: 'Mutation';
  createCard?: {
    __typename?: 'Card';
    id: string;
    listId: string;
    authorId: string;
    text: string;
    parentId?: string | null;
    index: number;
    votes: Array<string>;
    archived: boolean;
    updatedAt?: number | null;
    createdAt?: number | null;
    wasDiscussed?: boolean | null;
    originalListBadge?: {
      __typename?: 'OriginalListBadge';
      originalListId?: string | null;
      text?: string | null;
      color?: string | null;
    } | null;
    promotedToPrograms: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
    }>;
    comments: Array<{
      __typename?: 'CardComment';
      id: string;
      text: string;
      authorId: string;
      cardId: string;
      archived: boolean;
      createdAt: number;
      author: { __typename?: 'User'; id: string; name?: string | null };
    }>;
    children?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      authorId: string;
      children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
    } | null> | null;
  } | null;
};

export type CreateCardCommentMutationVariables = Exact<{
  cardId: Scalars['ID'];
  text: Scalars['String'];
}>;

export type CreateCardCommentMutation = {
  __typename?: 'Mutation';
  createCardComment: {
    __typename?: 'CardComment';
    id: string;
    text: string;
    authorId: string;
    cardId: string;
    archived: boolean;
    createdAt: number;
    author: { __typename?: 'User'; id: string; name?: string | null };
  };
};

export type CreateGroupCardMutationVariables = Exact<{
  dragCardId?: InputMaybe<Scalars['String']>;
  dropCardId?: InputMaybe<Scalars['String']>;
}>;

export type CreateGroupCardMutation = {
  __typename?: 'Mutation';
  createGroupCard?: {
    __typename?: 'Board';
    id: string;
    name: string;
    teamId: string;
    boardAsyncScheduleId?: string | null;
    createdAt?: number | null;
    updatedAt?: number | null;
    participationRate?: number | null;
    facilitators?: Array<string | null> | null;
    archived: boolean;
    ownerId: string;
    public: boolean;
    type: string;
    onlineUserIds: Array<string | null>;
    discussReadyUserIds: Array<string | null>;
    tooltipHintsEnabled: boolean;
    formatSlug: string;
    takeaways?: Array<{
      __typename?: 'Takeaway';
      id: string;
      text: string;
      boardId: string;
      type: TakeawayType;
    }> | null;
    format: {
      __typename?: 'RetrospectiveFormat';
      displayName?: string | null;
      iconImageUrl?: string | null;
      description?: string | null;
    };
    state: {
      __typename?: 'BoardState';
      privateCards?: boolean | null;
      privateVotes?: boolean | null;
      timerStartTimeMiliseconds?: number | null;
      timerStopTimeMiliseconds?: number | null;
      stage?: BoardStage | null;
      isGuided?: boolean | null;
      showActionPlan?: boolean | null;
      voteCount?: number | null;
      voteCountWasSuggested?: boolean | null;
      discussCurrentCardId?: string | null;
      discussSortByTags?: boolean | null;
      discussionCardsSortingKey?: BoardDiscussionCardsSortingKey | null;
      password?: string | null;
      takeAwayStatus?: TakeawayStatus | null;
    };
    cardBeingDiscussed?: {
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null;
    cardsOnDiscussion?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null> | null;
    lists?: Array<{
      __typename?: 'List';
      id: string;
      boardId: string;
      name: string;
      index: number;
      archived?: boolean | null;
      cardColor?: string | null;
      cards?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        authorId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        wasDiscussed?: boolean | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        promotedToPrograms: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
        }>;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
        children?: Array<{
          __typename?: 'Card';
          id: string;
          listId: string;
          text: string;
          parentId?: string | null;
          index: number;
          votes: Array<string>;
          archived: boolean;
          updatedAt?: number | null;
          createdAt?: number | null;
          authorId: string;
          children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
          originalListBadge?: {
            __typename?: 'OriginalListBadge';
            originalListId?: string | null;
            text?: string | null;
            color?: string | null;
          } | null;
          comments: Array<{
            __typename?: 'CardComment';
            id: string;
            text: string;
            authorId: string;
            cardId: string;
            archived: boolean;
            createdAt: number;
            author: { __typename?: 'User'; id: string; name?: string | null };
          }>;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type CreateJiraIssueFromCardMutationVariables = Exact<{
  projectKey: Scalars['String'];
  cardId: Scalars['ID'];
}>;

export type CreateJiraIssueFromCardMutation = {
  __typename?: 'Mutation';
  createJiraIssueFromCard: { __typename?: 'Card'; id: string };
};

export type CreateListMutationVariables = Exact<{
  boardId: Scalars['String'];
  name: Scalars['String'];
}>;

export type CreateListMutation = {
  __typename?: 'Mutation';
  createList?: {
    __typename?: 'List';
    id: string;
    boardId: string;
    name: string;
    index: number;
    archived?: boolean | null;
    cardColor?: string | null;
    cards?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type CreateWhiteboardMutationVariables = Exact<{
  teamId: Scalars['String'];
  ownerId: Scalars['String'];
  name: Scalars['String'];
  public: Scalars['Boolean'];
  guided: Scalars['Boolean'];
  format: Scalars['String'];
}>;

export type CreateWhiteboardMutation = {
  __typename?: 'Mutation';
  createWhiteboard?: {
    __typename?: 'Board';
    id: string;
    name: string;
    teamId: string;
    updatedAt?: number | null;
    participationRate?: number | null;
    facilitators?: Array<string | null> | null;
    archived: boolean;
    ownerId: string;
    type: string;
    onlineUserIds: Array<string | null>;
    discussReadyUserIds: Array<string | null>;
    tooltipHintsEnabled: boolean;
    formatSlug: string;
    format: {
      __typename?: 'RetrospectiveFormat';
      displayName?: string | null;
      iconImageUrl?: string | null;
      description?: string | null;
    };
    state: {
      __typename?: 'BoardState';
      privateCards?: boolean | null;
      privateVotes?: boolean | null;
      timerStartTimeMiliseconds?: number | null;
      timerStopTimeMiliseconds?: number | null;
      stage?: BoardStage | null;
      isGuided?: boolean | null;
      password?: string | null;
      showActionPlan?: boolean | null;
      voteCount?: number | null;
      voteCountWasSuggested?: boolean | null;
      discussCurrentCardId?: string | null;
      discussSortByTags?: boolean | null;
      discussionCardsSortingKey?: BoardDiscussionCardsSortingKey | null;
    };
    cardBeingDiscussed?: {
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null;
    cardsOnDiscussion?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null> | null;
    lists?: Array<{
      __typename?: 'List';
      id: string;
      boardId: string;
      name: string;
      index: number;
      archived?: boolean | null;
      cardColor?: string | null;
      cards?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        authorId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        wasDiscussed?: boolean | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        promotedToPrograms: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
        }>;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
        children?: Array<{
          __typename?: 'Card';
          id: string;
          listId: string;
          text: string;
          parentId?: string | null;
          index: number;
          votes: Array<string>;
          archived: boolean;
          updatedAt?: number | null;
          createdAt?: number | null;
          authorId: string;
          children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
          originalListBadge?: {
            __typename?: 'OriginalListBadge';
            originalListId?: string | null;
            text?: string | null;
            color?: string | null;
          } | null;
          comments: Array<{
            __typename?: 'CardComment';
            id: string;
            text: string;
            authorId: string;
            cardId: string;
            archived: boolean;
            createdAt: number;
            author: { __typename?: 'User'; id: string; name?: string | null };
          }>;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type DuplicateCardMutationVariables = Exact<{
  card: Scalars['JSON'];
  ownerId?: InputMaybe<Scalars['String']>;
  oldCardIdsOrder:
    | Array<InputMaybe<Scalars['String']>>
    | InputMaybe<Scalars['String']>;
}>;

export type DuplicateCardMutation = {
  __typename?: 'Mutation';
  duplicateCard?: {
    __typename?: 'Board';
    id: string;
    name: string;
    teamId: string;
    boardAsyncScheduleId?: string | null;
    createdAt?: number | null;
    updatedAt?: number | null;
    participationRate?: number | null;
    facilitators?: Array<string | null> | null;
    archived: boolean;
    ownerId: string;
    public: boolean;
    type: string;
    onlineUserIds: Array<string | null>;
    discussReadyUserIds: Array<string | null>;
    tooltipHintsEnabled: boolean;
    formatSlug: string;
    takeaways?: Array<{
      __typename?: 'Takeaway';
      id: string;
      text: string;
      boardId: string;
      type: TakeawayType;
    }> | null;
    format: {
      __typename?: 'RetrospectiveFormat';
      displayName?: string | null;
      iconImageUrl?: string | null;
      description?: string | null;
    };
    state: {
      __typename?: 'BoardState';
      privateCards?: boolean | null;
      privateVotes?: boolean | null;
      timerStartTimeMiliseconds?: number | null;
      timerStopTimeMiliseconds?: number | null;
      stage?: BoardStage | null;
      isGuided?: boolean | null;
      showActionPlan?: boolean | null;
      voteCount?: number | null;
      voteCountWasSuggested?: boolean | null;
      discussCurrentCardId?: string | null;
      discussSortByTags?: boolean | null;
      discussionCardsSortingKey?: BoardDiscussionCardsSortingKey | null;
      password?: string | null;
      takeAwayStatus?: TakeawayStatus | null;
    };
    cardBeingDiscussed?: {
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null;
    cardsOnDiscussion?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null> | null;
    lists?: Array<{
      __typename?: 'List';
      id: string;
      boardId: string;
      name: string;
      index: number;
      archived?: boolean | null;
      cardColor?: string | null;
      cards?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        authorId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        wasDiscussed?: boolean | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        promotedToPrograms: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
        }>;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
        children?: Array<{
          __typename?: 'Card';
          id: string;
          listId: string;
          text: string;
          parentId?: string | null;
          index: number;
          votes: Array<string>;
          archived: boolean;
          updatedAt?: number | null;
          createdAt?: number | null;
          authorId: string;
          children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
          originalListBadge?: {
            __typename?: 'OriginalListBadge';
            originalListId?: string | null;
            text?: string | null;
            color?: string | null;
          } | null;
          comments: Array<{
            __typename?: 'CardComment';
            id: string;
            text: string;
            authorId: string;
            cardId: string;
            archived: boolean;
            createdAt: number;
            author: { __typename?: 'User'; id: string; name?: string | null };
          }>;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type FinishBoardMutationVariables = Exact<{
  boardId: Scalars['ID'];
  sendSummary: Scalars['Boolean'];
}>;

export type FinishBoardMutation = {
  __typename?: 'Mutation';
  finishBoard: {
    __typename?: 'Board';
    id: string;
    state: { __typename?: 'BoardState'; stage?: BoardStage | null };
  };
};

export type GenerateBoardTakeawaysMutationVariables = Exact<{
  boardId: Scalars['ID'];
}>;

export type GenerateBoardTakeawaysMutation = {
  __typename?: 'Mutation';
  generateBoardTakeaways: {
    __typename?: 'Board';
    id: string;
    name: string;
    teamId: string;
    boardAsyncScheduleId?: string | null;
    createdAt?: number | null;
    updatedAt?: number | null;
    participationRate?: number | null;
    facilitators?: Array<string | null> | null;
    archived: boolean;
    ownerId: string;
    public: boolean;
    type: string;
    onlineUserIds: Array<string | null>;
    discussReadyUserIds: Array<string | null>;
    tooltipHintsEnabled: boolean;
    formatSlug: string;
    takeaways?: Array<{
      __typename?: 'Takeaway';
      id: string;
      text: string;
      boardId: string;
      type: TakeawayType;
    }> | null;
    format: {
      __typename?: 'RetrospectiveFormat';
      displayName?: string | null;
      iconImageUrl?: string | null;
      description?: string | null;
    };
    state: {
      __typename?: 'BoardState';
      privateCards?: boolean | null;
      privateVotes?: boolean | null;
      timerStartTimeMiliseconds?: number | null;
      timerStopTimeMiliseconds?: number | null;
      stage?: BoardStage | null;
      isGuided?: boolean | null;
      showActionPlan?: boolean | null;
      voteCount?: number | null;
      voteCountWasSuggested?: boolean | null;
      discussCurrentCardId?: string | null;
      discussSortByTags?: boolean | null;
      discussionCardsSortingKey?: BoardDiscussionCardsSortingKey | null;
      password?: string | null;
      takeAwayStatus?: TakeawayStatus | null;
    };
    cardBeingDiscussed?: {
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null;
    cardsOnDiscussion?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null> | null;
    lists?: Array<{
      __typename?: 'List';
      id: string;
      boardId: string;
      name: string;
      index: number;
      archived?: boolean | null;
      cardColor?: string | null;
      cards?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        authorId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        wasDiscussed?: boolean | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        promotedToPrograms: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
        }>;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
        children?: Array<{
          __typename?: 'Card';
          id: string;
          listId: string;
          text: string;
          parentId?: string | null;
          index: number;
          votes: Array<string>;
          archived: boolean;
          updatedAt?: number | null;
          createdAt?: number | null;
          authorId: string;
          children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
          originalListBadge?: {
            __typename?: 'OriginalListBadge';
            originalListId?: string | null;
            text?: string | null;
            color?: string | null;
          } | null;
          comments: Array<{
            __typename?: 'CardComment';
            id: string;
            text: string;
            authorId: string;
            cardId: string;
            archived: boolean;
            createdAt: number;
            author: { __typename?: 'User'; id: string; name?: string | null };
          }>;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  };
};

export type ImportBoardsMutationVariables = Exact<{
  boardInputs?: InputMaybe<
    Array<InputMaybe<BoardsInput>> | InputMaybe<BoardsInput>
  >;
}>;

export type ImportBoardsMutation = {
  __typename?: 'Mutation';
  importBoards?: Array<{
    __typename?: 'Board';
    id: string;
    name: string;
    createdAt?: number | null;
    updatedAt?: number | null;
  } | null> | null;
};

export type InviteToBoardByEmailMutationVariables = Exact<{
  boardId: Scalars['ID'];
  email: Scalars['String'];
}>;

export type InviteToBoardByEmailMutation = {
  __typename?: 'Mutation';
  inviteToBoardByEmail?: { __typename?: 'Board'; id: string } | null;
};

export type MarkDiscussionReadinessMutationVariables = Exact<{
  boardId: Scalars['String'];
  isReady: Scalars['Boolean'];
}>;

export type MarkDiscussionReadinessMutation = {
  __typename?: 'Mutation';
  markCardDiscussionReadiness?: {
    __typename?: 'Board';
    id: string;
    onlineUserIds: Array<string | null>;
    discussReadyUserIds: Array<string | null>;
  } | null;
};

export type MoveCardIntoListMutationVariables = Exact<{
  listId: Scalars['String'];
  cardId?: InputMaybe<Scalars['String']>;
  newCardIdsOrder:
    | Array<InputMaybe<Scalars['String']>>
    | InputMaybe<Scalars['String']>;
}>;

export type MoveCardIntoListMutation = {
  __typename?: 'Mutation';
  moveCardIntoList?: {
    __typename?: 'Board';
    id: string;
    name: string;
    teamId: string;
    boardAsyncScheduleId?: string | null;
    createdAt?: number | null;
    updatedAt?: number | null;
    participationRate?: number | null;
    facilitators?: Array<string | null> | null;
    archived: boolean;
    ownerId: string;
    public: boolean;
    type: string;
    onlineUserIds: Array<string | null>;
    discussReadyUserIds: Array<string | null>;
    tooltipHintsEnabled: boolean;
    formatSlug: string;
    takeaways?: Array<{
      __typename?: 'Takeaway';
      id: string;
      text: string;
      boardId: string;
      type: TakeawayType;
    }> | null;
    format: {
      __typename?: 'RetrospectiveFormat';
      displayName?: string | null;
      iconImageUrl?: string | null;
      description?: string | null;
    };
    state: {
      __typename?: 'BoardState';
      privateCards?: boolean | null;
      privateVotes?: boolean | null;
      timerStartTimeMiliseconds?: number | null;
      timerStopTimeMiliseconds?: number | null;
      stage?: BoardStage | null;
      isGuided?: boolean | null;
      showActionPlan?: boolean | null;
      voteCount?: number | null;
      voteCountWasSuggested?: boolean | null;
      discussCurrentCardId?: string | null;
      discussSortByTags?: boolean | null;
      discussionCardsSortingKey?: BoardDiscussionCardsSortingKey | null;
      password?: string | null;
      takeAwayStatus?: TakeawayStatus | null;
    };
    cardBeingDiscussed?: {
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null;
    cardsOnDiscussion?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null> | null;
    lists?: Array<{
      __typename?: 'List';
      id: string;
      boardId: string;
      name: string;
      index: number;
      archived?: boolean | null;
      cardColor?: string | null;
      cards?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        authorId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        wasDiscussed?: boolean | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        promotedToPrograms: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
        }>;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
        children?: Array<{
          __typename?: 'Card';
          id: string;
          listId: string;
          text: string;
          parentId?: string | null;
          index: number;
          votes: Array<string>;
          archived: boolean;
          updatedAt?: number | null;
          createdAt?: number | null;
          authorId: string;
          children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
          originalListBadge?: {
            __typename?: 'OriginalListBadge';
            originalListId?: string | null;
            text?: string | null;
            color?: string | null;
          } | null;
          comments: Array<{
            __typename?: 'CardComment';
            id: string;
            text: string;
            authorId: string;
            cardId: string;
            archived: boolean;
            createdAt: number;
            author: { __typename?: 'User'; id: string; name?: string | null };
          }>;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type NotifyTeamMutationVariables = Exact<{
  boardId: Scalars['ID'];
}>;

export type NotifyTeamMutation = {
  __typename?: 'Mutation';
  notifyTeam: boolean;
};

export type PingBoardParticipationMutationVariables = Exact<{
  input?: InputMaybe<PingBoardParticipationAsAuthenticatedUserInput>;
}>;

export type PingBoardParticipationMutation = {
  __typename?: 'Mutation';
  pingBoardParticipationAsAuthenticatedUser?: {
    __typename?: 'PingBoardParticipationAsAuthenticatedUserOutput';
    success: boolean;
  } | null;
};

export type PingBoardParticipationFacilitatorMutationVariables = Exact<{
  input?: InputMaybe<PingBoardParticipationAsAuthenticatedUserInput>;
}>;

export type PingBoardParticipationFacilitatorMutation = {
  __typename?: 'Mutation';
  pingBoardParticipationAsAuthenticatedUser?: {
    __typename?: 'PingBoardParticipationAsAuthenticatedUserOutput';
    participations?: Array<{
      __typename?: 'BoardParticipation';
      status: BoardParticipationStatus;
      userId: string;
      isFacilitator: boolean;
      numberOfVotes: number;
      numberOfCards: number;
      participant: { __typename?: 'User'; id: string; name?: string | null };
    } | null> | null;
  } | null;
};

export type PromoteCardToProgramsMutationVariables = Exact<{
  cardId: Scalars['String'];
  programIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type PromoteCardToProgramsMutation = {
  __typename?: 'Mutation';
  promoteCardToPrograms: {
    __typename?: 'Card';
    id: string;
    listId: string;
    authorId: string;
    text: string;
    parentId?: string | null;
    index: number;
    votes: Array<string>;
    archived: boolean;
    updatedAt?: number | null;
    createdAt?: number | null;
    wasDiscussed?: boolean | null;
    originalListBadge?: {
      __typename?: 'OriginalListBadge';
      originalListId?: string | null;
      text?: string | null;
      color?: string | null;
    } | null;
    promotedToPrograms: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
    }>;
    comments: Array<{
      __typename?: 'CardComment';
      id: string;
      text: string;
      authorId: string;
      cardId: string;
      archived: boolean;
      createdAt: number;
      author: { __typename?: 'User'; id: string; name?: string | null };
    }>;
    children?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      authorId: string;
      children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
    } | null> | null;
  };
};

export type ResetBoardVotesMutationVariables = Exact<{
  boardId: Scalars['String'];
}>;

export type ResetBoardVotesMutation = {
  __typename?: 'Mutation';
  resetVotesBoard?: {
    __typename?: 'Board';
    id: string;
    name: string;
    teamId: string;
    boardAsyncScheduleId?: string | null;
    createdAt?: number | null;
    updatedAt?: number | null;
    participationRate?: number | null;
    facilitators?: Array<string | null> | null;
    archived: boolean;
    ownerId: string;
    public: boolean;
    type: string;
    onlineUserIds: Array<string | null>;
    discussReadyUserIds: Array<string | null>;
    tooltipHintsEnabled: boolean;
    formatSlug: string;
    takeaways?: Array<{
      __typename?: 'Takeaway';
      id: string;
      text: string;
      boardId: string;
      type: TakeawayType;
    }> | null;
    format: {
      __typename?: 'RetrospectiveFormat';
      displayName?: string | null;
      iconImageUrl?: string | null;
      description?: string | null;
    };
    state: {
      __typename?: 'BoardState';
      privateCards?: boolean | null;
      privateVotes?: boolean | null;
      timerStartTimeMiliseconds?: number | null;
      timerStopTimeMiliseconds?: number | null;
      stage?: BoardStage | null;
      isGuided?: boolean | null;
      showActionPlan?: boolean | null;
      voteCount?: number | null;
      voteCountWasSuggested?: boolean | null;
      discussCurrentCardId?: string | null;
      discussSortByTags?: boolean | null;
      discussionCardsSortingKey?: BoardDiscussionCardsSortingKey | null;
      password?: string | null;
      takeAwayStatus?: TakeawayStatus | null;
    };
    cardBeingDiscussed?: {
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null;
    cardsOnDiscussion?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null> | null;
    lists?: Array<{
      __typename?: 'List';
      id: string;
      boardId: string;
      name: string;
      index: number;
      archived?: boolean | null;
      cardColor?: string | null;
      cards?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        authorId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        wasDiscussed?: boolean | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        promotedToPrograms: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
        }>;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
        children?: Array<{
          __typename?: 'Card';
          id: string;
          listId: string;
          text: string;
          parentId?: string | null;
          index: number;
          votes: Array<string>;
          archived: boolean;
          updatedAt?: number | null;
          createdAt?: number | null;
          authorId: string;
          children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
          originalListBadge?: {
            __typename?: 'OriginalListBadge';
            originalListId?: string | null;
            text?: string | null;
            color?: string | null;
          } | null;
          comments: Array<{
            __typename?: 'CardComment';
            id: string;
            text: string;
            authorId: string;
            cardId: string;
            archived: boolean;
            createdAt: number;
            author: { __typename?: 'User'; id: string; name?: string | null };
          }>;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type SetBoardCardBeingDiscussedMutationVariables = Exact<{
  boardId: Scalars['ID'];
  cardId: Scalars['ID'];
  moveCurrentCard: CardDiscussionMovement;
}>;

export type SetBoardCardBeingDiscussedMutation = {
  __typename?: 'Mutation';
  setBoardCardBeingDiscussed: {
    __typename?: 'Board';
    id: string;
    name: string;
    teamId: string;
    boardAsyncScheduleId?: string | null;
    createdAt?: number | null;
    updatedAt?: number | null;
    participationRate?: number | null;
    facilitators?: Array<string | null> | null;
    archived: boolean;
    ownerId: string;
    public: boolean;
    type: string;
    onlineUserIds: Array<string | null>;
    discussReadyUserIds: Array<string | null>;
    tooltipHintsEnabled: boolean;
    formatSlug: string;
    takeaways?: Array<{
      __typename?: 'Takeaway';
      id: string;
      text: string;
      boardId: string;
      type: TakeawayType;
    }> | null;
    format: {
      __typename?: 'RetrospectiveFormat';
      displayName?: string | null;
      iconImageUrl?: string | null;
      description?: string | null;
    };
    state: {
      __typename?: 'BoardState';
      privateCards?: boolean | null;
      privateVotes?: boolean | null;
      timerStartTimeMiliseconds?: number | null;
      timerStopTimeMiliseconds?: number | null;
      stage?: BoardStage | null;
      isGuided?: boolean | null;
      showActionPlan?: boolean | null;
      voteCount?: number | null;
      voteCountWasSuggested?: boolean | null;
      discussCurrentCardId?: string | null;
      discussSortByTags?: boolean | null;
      discussionCardsSortingKey?: BoardDiscussionCardsSortingKey | null;
      password?: string | null;
      takeAwayStatus?: TakeawayStatus | null;
    };
    cardBeingDiscussed?: {
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null;
    cardsOnDiscussion?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null> | null;
    lists?: Array<{
      __typename?: 'List';
      id: string;
      boardId: string;
      name: string;
      index: number;
      archived?: boolean | null;
      cardColor?: string | null;
      cards?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        authorId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        wasDiscussed?: boolean | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        promotedToPrograms: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
        }>;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
        children?: Array<{
          __typename?: 'Card';
          id: string;
          listId: string;
          text: string;
          parentId?: string | null;
          index: number;
          votes: Array<string>;
          archived: boolean;
          updatedAt?: number | null;
          createdAt?: number | null;
          authorId: string;
          children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
          originalListBadge?: {
            __typename?: 'OriginalListBadge';
            originalListId?: string | null;
            text?: string | null;
            color?: string | null;
          } | null;
          comments: Array<{
            __typename?: 'CardComment';
            id: string;
            text: string;
            authorId: string;
            cardId: string;
            archived: boolean;
            createdAt: number;
            author: { __typename?: 'User'; id: string; name?: string | null };
          }>;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  };
};

export type SortBoardMutationVariables = Exact<{
  boardId: Scalars['String'];
  type: Scalars['String'];
}>;

export type SortBoardMutation = {
  __typename?: 'Mutation';
  sortBoard?: Array<{
    __typename?: 'List';
    id: string;
    boardId: string;
    name: string;
    index: number;
    archived?: boolean | null;
    cardColor?: string | null;
    cards?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type UpdateBoardMutationVariables = Exact<{
  boardId: Scalars['String'];
  fields: Scalars['JSON'];
}>;

export type UpdateBoardMutation = {
  __typename?: 'Mutation';
  updateBoard?: {
    __typename?: 'Board';
    id: string;
    name: string;
    teamId: string;
    boardAsyncScheduleId?: string | null;
    createdAt?: number | null;
    updatedAt?: number | null;
    participationRate?: number | null;
    facilitators?: Array<string | null> | null;
    archived: boolean;
    ownerId: string;
    public: boolean;
    type: string;
    onlineUserIds: Array<string | null>;
    discussReadyUserIds: Array<string | null>;
    tooltipHintsEnabled: boolean;
    formatSlug: string;
    takeaways?: Array<{
      __typename?: 'Takeaway';
      id: string;
      text: string;
      boardId: string;
      type: TakeawayType;
    }> | null;
    format: {
      __typename?: 'RetrospectiveFormat';
      displayName?: string | null;
      iconImageUrl?: string | null;
      description?: string | null;
    };
    state: {
      __typename?: 'BoardState';
      privateCards?: boolean | null;
      privateVotes?: boolean | null;
      timerStartTimeMiliseconds?: number | null;
      timerStopTimeMiliseconds?: number | null;
      stage?: BoardStage | null;
      isGuided?: boolean | null;
      showActionPlan?: boolean | null;
      voteCount?: number | null;
      voteCountWasSuggested?: boolean | null;
      discussCurrentCardId?: string | null;
      discussSortByTags?: boolean | null;
      discussionCardsSortingKey?: BoardDiscussionCardsSortingKey | null;
      password?: string | null;
      takeAwayStatus?: TakeawayStatus | null;
    };
    cardBeingDiscussed?: {
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null;
    cardsOnDiscussion?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null> | null;
    lists?: Array<{
      __typename?: 'List';
      id: string;
      boardId: string;
      name: string;
      index: number;
      archived?: boolean | null;
      cardColor?: string | null;
      cards?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        authorId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        wasDiscussed?: boolean | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        promotedToPrograms: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
        }>;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
        children?: Array<{
          __typename?: 'Card';
          id: string;
          listId: string;
          text: string;
          parentId?: string | null;
          index: number;
          votes: Array<string>;
          archived: boolean;
          updatedAt?: number | null;
          createdAt?: number | null;
          authorId: string;
          children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
          originalListBadge?: {
            __typename?: 'OriginalListBadge';
            originalListId?: string | null;
            text?: string | null;
            color?: string | null;
          } | null;
          comments: Array<{
            __typename?: 'CardComment';
            id: string;
            text: string;
            authorId: string;
            cardId: string;
            archived: boolean;
            createdAt: number;
            author: { __typename?: 'User'; id: string; name?: string | null };
          }>;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type UpdateCardMutationVariables = Exact<{
  cardId: Scalars['String'];
  fields: Scalars['JSON'];
}>;

export type UpdateCardMutation = {
  __typename?: 'Mutation';
  updateCard?: {
    __typename?: 'Card';
    id: string;
    listId: string;
    authorId: string;
    text: string;
    parentId?: string | null;
    index: number;
    votes: Array<string>;
    archived: boolean;
    updatedAt?: number | null;
    createdAt?: number | null;
    wasDiscussed?: boolean | null;
    originalListBadge?: {
      __typename?: 'OriginalListBadge';
      originalListId?: string | null;
      text?: string | null;
      color?: string | null;
    } | null;
    promotedToPrograms: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
    }>;
    comments: Array<{
      __typename?: 'CardComment';
      id: string;
      text: string;
      authorId: string;
      cardId: string;
      archived: boolean;
      createdAt: number;
      author: { __typename?: 'User'; id: string; name?: string | null };
    }>;
    children?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      authorId: string;
      children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
    } | null> | null;
  } | null;
};

export type ArchiveCardCommentMutationVariables = Exact<{
  commentId: Scalars['ID'];
}>;

export type ArchiveCardCommentMutation = {
  __typename?: 'Mutation';
  archiveCardComment: {
    __typename?: 'CardComment';
    id: string;
    text: string;
    authorId: string;
    cardId: string;
    archived: boolean;
    createdAt: number;
    author: { __typename?: 'User'; id: string; name?: string | null };
  };
};

export type AddCardToGroupMutationVariables = Exact<{
  cardToAddId: Scalars['String'];
  groupId: Scalars['String'];
  newChildrenIdsOrder: Array<Scalars['String']> | Scalars['String'];
}>;

export type AddCardToGroupMutation = {
  __typename?: 'Mutation';
  addCardToGroup?: {
    __typename?: 'Board';
    id: string;
    name: string;
    teamId: string;
    boardAsyncScheduleId?: string | null;
    createdAt?: number | null;
    updatedAt?: number | null;
    participationRate?: number | null;
    facilitators?: Array<string | null> | null;
    archived: boolean;
    ownerId: string;
    public: boolean;
    type: string;
    onlineUserIds: Array<string | null>;
    discussReadyUserIds: Array<string | null>;
    tooltipHintsEnabled: boolean;
    formatSlug: string;
    takeaways?: Array<{
      __typename?: 'Takeaway';
      id: string;
      text: string;
      boardId: string;
      type: TakeawayType;
    }> | null;
    format: {
      __typename?: 'RetrospectiveFormat';
      displayName?: string | null;
      iconImageUrl?: string | null;
      description?: string | null;
    };
    state: {
      __typename?: 'BoardState';
      privateCards?: boolean | null;
      privateVotes?: boolean | null;
      timerStartTimeMiliseconds?: number | null;
      timerStopTimeMiliseconds?: number | null;
      stage?: BoardStage | null;
      isGuided?: boolean | null;
      showActionPlan?: boolean | null;
      voteCount?: number | null;
      voteCountWasSuggested?: boolean | null;
      discussCurrentCardId?: string | null;
      discussSortByTags?: boolean | null;
      discussionCardsSortingKey?: BoardDiscussionCardsSortingKey | null;
      password?: string | null;
      takeAwayStatus?: TakeawayStatus | null;
    };
    cardBeingDiscussed?: {
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null;
    cardsOnDiscussion?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null> | null;
    lists?: Array<{
      __typename?: 'List';
      id: string;
      boardId: string;
      name: string;
      index: number;
      archived?: boolean | null;
      cardColor?: string | null;
      cards?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        authorId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        wasDiscussed?: boolean | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        promotedToPrograms: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
        }>;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
        children?: Array<{
          __typename?: 'Card';
          id: string;
          listId: string;
          text: string;
          parentId?: string | null;
          index: number;
          votes: Array<string>;
          archived: boolean;
          updatedAt?: number | null;
          createdAt?: number | null;
          authorId: string;
          children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
          originalListBadge?: {
            __typename?: 'OriginalListBadge';
            originalListId?: string | null;
            text?: string | null;
            color?: string | null;
          } | null;
          comments: Array<{
            __typename?: 'CardComment';
            id: string;
            text: string;
            authorId: string;
            cardId: string;
            archived: boolean;
            createdAt: number;
            author: { __typename?: 'User'; id: string; name?: string | null };
          }>;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type UpdateListMutationVariables = Exact<{
  listId: Scalars['String'];
  fields: Scalars['JSON'];
}>;

export type UpdateListMutation = {
  __typename?: 'Mutation';
  updateList?: {
    __typename?: 'List';
    id: string;
    boardId: string;
    name: string;
    index: number;
    archived?: boolean | null;
    cardColor?: string | null;
    cards?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type UpdateListOrderMutationVariables = Exact<{
  boardId: Scalars['String'];
  listIds: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;

export type UpdateListOrderMutation = {
  __typename?: 'Mutation';
  updateListOrder?: {
    __typename?: 'Board';
    id: string;
    name: string;
    teamId: string;
    boardAsyncScheduleId?: string | null;
    createdAt?: number | null;
    updatedAt?: number | null;
    participationRate?: number | null;
    facilitators?: Array<string | null> | null;
    archived: boolean;
    ownerId: string;
    public: boolean;
    type: string;
    onlineUserIds: Array<string | null>;
    discussReadyUserIds: Array<string | null>;
    tooltipHintsEnabled: boolean;
    formatSlug: string;
    takeaways?: Array<{
      __typename?: 'Takeaway';
      id: string;
      text: string;
      boardId: string;
      type: TakeawayType;
    }> | null;
    format: {
      __typename?: 'RetrospectiveFormat';
      displayName?: string | null;
      iconImageUrl?: string | null;
      description?: string | null;
    };
    state: {
      __typename?: 'BoardState';
      privateCards?: boolean | null;
      privateVotes?: boolean | null;
      timerStartTimeMiliseconds?: number | null;
      timerStopTimeMiliseconds?: number | null;
      stage?: BoardStage | null;
      isGuided?: boolean | null;
      showActionPlan?: boolean | null;
      voteCount?: number | null;
      voteCountWasSuggested?: boolean | null;
      discussCurrentCardId?: string | null;
      discussSortByTags?: boolean | null;
      discussionCardsSortingKey?: BoardDiscussionCardsSortingKey | null;
      password?: string | null;
      takeAwayStatus?: TakeawayStatus | null;
    };
    cardBeingDiscussed?: {
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null;
    cardsOnDiscussion?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null> | null;
    lists?: Array<{
      __typename?: 'List';
      id: string;
      boardId: string;
      name: string;
      index: number;
      archived?: boolean | null;
      cardColor?: string | null;
      cards?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        authorId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        wasDiscussed?: boolean | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        promotedToPrograms: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
        }>;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
        children?: Array<{
          __typename?: 'Card';
          id: string;
          listId: string;
          text: string;
          parentId?: string | null;
          index: number;
          votes: Array<string>;
          archived: boolean;
          updatedAt?: number | null;
          createdAt?: number | null;
          authorId: string;
          children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
          originalListBadge?: {
            __typename?: 'OriginalListBadge';
            originalListId?: string | null;
            text?: string | null;
            color?: string | null;
          } | null;
          comments: Array<{
            __typename?: 'CardComment';
            id: string;
            text: string;
            authorId: string;
            cardId: string;
            archived: boolean;
            createdAt: number;
            author: { __typename?: 'User'; id: string; name?: string | null };
          }>;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type VoteCardMutationVariables = Exact<{
  boardId?: InputMaybe<Scalars['String']>;
  cardId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
  voted: Scalars['Boolean'];
  anonymousUser: Scalars['Boolean'];
}>;

export type VoteCardMutation = {
  __typename?: 'Mutation';
  voteCard?: {
    __typename?: 'Card';
    id: string;
    listId: string;
    authorId: string;
    text: string;
    parentId?: string | null;
    index: number;
    votes: Array<string>;
    archived: boolean;
    updatedAt?: number | null;
    createdAt?: number | null;
    wasDiscussed?: boolean | null;
    originalListBadge?: {
      __typename?: 'OriginalListBadge';
      originalListId?: string | null;
      text?: string | null;
      color?: string | null;
    } | null;
    promotedToPrograms: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
    }>;
    comments: Array<{
      __typename?: 'CardComment';
      id: string;
      text: string;
      authorId: string;
      cardId: string;
      archived: boolean;
      createdAt: number;
      author: { __typename?: 'User'; id: string; name?: string | null };
    }>;
    children?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      authorId: string;
      children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
    } | null> | null;
  } | null;
};

export type AllRetroFormatsQueryVariables = Exact<{ [key: string]: never }>;

export type AllRetroFormatsQuery = {
  __typename?: 'Query';
  retrospectiveFormats?: Array<{
    __typename?: 'RetrospectiveFormat';
    slug: string;
    displayName?: string | null;
    columnNames?: Array<string | null> | null;
    iconImageUrl?: string | null;
    description?: string | null;
    canvasImageUrl?: string | null;
    canvasImageWidth?: number | null;
    canvasImageHeight?: number | null;
    detailsMarkup?: string | null;
  } | null> | null;
};

export type BoardByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type BoardByIdQuery = {
  __typename?: 'Query';
  board?: {
    __typename?: 'Board';
    id: string;
    name: string;
    teamId: string;
    boardAsyncScheduleId?: string | null;
    createdAt?: number | null;
    updatedAt?: number | null;
    participationRate?: number | null;
    facilitators?: Array<string | null> | null;
    archived: boolean;
    ownerId: string;
    public: boolean;
    type: string;
    onlineUserIds: Array<string | null>;
    discussReadyUserIds: Array<string | null>;
    tooltipHintsEnabled: boolean;
    formatSlug: string;
    takeaways?: Array<{
      __typename?: 'Takeaway';
      id: string;
      text: string;
      boardId: string;
      type: TakeawayType;
    }> | null;
    format: {
      __typename?: 'RetrospectiveFormat';
      displayName?: string | null;
      iconImageUrl?: string | null;
      description?: string | null;
    };
    state: {
      __typename?: 'BoardState';
      privateCards?: boolean | null;
      privateVotes?: boolean | null;
      timerStartTimeMiliseconds?: number | null;
      timerStopTimeMiliseconds?: number | null;
      stage?: BoardStage | null;
      isGuided?: boolean | null;
      showActionPlan?: boolean | null;
      voteCount?: number | null;
      voteCountWasSuggested?: boolean | null;
      discussCurrentCardId?: string | null;
      discussSortByTags?: boolean | null;
      discussionCardsSortingKey?: BoardDiscussionCardsSortingKey | null;
      password?: string | null;
      takeAwayStatus?: TakeawayStatus | null;
    };
    cardBeingDiscussed?: {
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null;
    cardsOnDiscussion?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null> | null;
    lists?: Array<{
      __typename?: 'List';
      id: string;
      boardId: string;
      name: string;
      index: number;
      archived?: boolean | null;
      cardColor?: string | null;
      cards?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        authorId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        wasDiscussed?: boolean | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        promotedToPrograms: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
        }>;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
        children?: Array<{
          __typename?: 'Card';
          id: string;
          listId: string;
          text: string;
          parentId?: string | null;
          index: number;
          votes: Array<string>;
          archived: boolean;
          updatedAt?: number | null;
          createdAt?: number | null;
          authorId: string;
          children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
          originalListBadge?: {
            __typename?: 'OriginalListBadge';
            originalListId?: string | null;
            text?: string | null;
            color?: string | null;
          } | null;
          comments: Array<{
            __typename?: 'CardComment';
            id: string;
            text: string;
            authorId: string;
            cardId: string;
            archived: boolean;
            createdAt: number;
            author: { __typename?: 'User'; id: string; name?: string | null };
          }>;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type BoardNameByTeamInviteAndBoardIdQueryVariables = Exact<{
  teamInviteToken: Scalars['String'];
  boardId: Scalars['String'];
}>;

export type BoardNameByTeamInviteAndBoardIdQuery = {
  __typename?: 'Query';
  boardNameByTeamInviteAndBoardId?: string | null;
};

export type IsUserAuthorizedForBoardQueryVariables = Exact<{
  boardId: Scalars['ID'];
}>;

export type IsUserAuthorizedForBoardQuery = {
  __typename?: 'Query';
  isUserAuthorizedForBoard?: boolean | null;
};

export type PublicBoardNameByBoardIdQueryVariables = Exact<{
  boardId: Scalars['String'];
}>;

export type PublicBoardNameByBoardIdQuery = {
  __typename?: 'Query';
  publicBoardNameByBoardId?: string | null;
};

export type BoardCreatedSubscriptionVariables = Exact<{
  teamId: Scalars['ID'];
}>;

export type BoardCreatedSubscription = {
  __typename?: 'Subscription';
  boardCreated: {
    __typename?: 'Board';
    id: string;
    name: string;
    teamId: string;
    ownerId: string;
    participationRate?: number | null;
    totalCards: number;
    public: boolean;
    createdAt?: number | null;
    updatedAt?: number | null;
    archived: boolean;
    type: string;
    formatSlug: string;
    format: {
      __typename?: 'RetrospectiveFormat';
      iconImageUrl?: string | null;
    };
  };
};

export type BoardDiscussStageUpdatesSubscriptionVariables = Exact<{
  boardId: Scalars['ID'];
}>;

export type BoardDiscussStageUpdatesSubscription = {
  __typename?: 'Subscription';
  boardDiscussStageUpdates?: {
    __typename?: 'Board';
    id: string;
    discussReadyUserIds: Array<string | null>;
    cardsOnDiscussion?: Array<{
      __typename?: 'Card';
      id: string;
      text: string;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        color?: string | null;
        text?: string | null;
      } | null;
    } | null> | null;
    cardBeingDiscussed?: {
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null;
  } | null;
};

export type BoardMessageBroadcastSubscriptionVariables = Exact<{
  boardId: Scalars['ID'];
}>;

export type BoardMessageBroadcastSubscription = {
  __typename?: 'Subscription';
  boardMessageBroadcastSubscription?: {
    __typename?: 'BoardMessageBroadcast';
    title?: string | null;
    message?: string | null;
    timeOut?: number | null;
    newFacilitatorUserId?: string | null;
  } | null;
};

export type BoardUpdatesSubscriptionVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type BoardUpdatesSubscription = {
  __typename?: 'Subscription';
  boardUpdate?: {
    __typename?: 'Board';
    id: string;
    name: string;
    teamId: string;
    boardAsyncScheduleId?: string | null;
    createdAt?: number | null;
    updatedAt?: number | null;
    participationRate?: number | null;
    facilitators?: Array<string | null> | null;
    archived: boolean;
    ownerId: string;
    public: boolean;
    type: string;
    onlineUserIds: Array<string | null>;
    discussReadyUserIds: Array<string | null>;
    tooltipHintsEnabled: boolean;
    formatSlug: string;
    takeaways?: Array<{
      __typename?: 'Takeaway';
      id: string;
      text: string;
      boardId: string;
      type: TakeawayType;
    }> | null;
    format: {
      __typename?: 'RetrospectiveFormat';
      displayName?: string | null;
      iconImageUrl?: string | null;
      description?: string | null;
    };
    state: {
      __typename?: 'BoardState';
      privateCards?: boolean | null;
      privateVotes?: boolean | null;
      timerStartTimeMiliseconds?: number | null;
      timerStopTimeMiliseconds?: number | null;
      stage?: BoardStage | null;
      isGuided?: boolean | null;
      showActionPlan?: boolean | null;
      voteCount?: number | null;
      voteCountWasSuggested?: boolean | null;
      discussCurrentCardId?: string | null;
      discussSortByTags?: boolean | null;
      discussionCardsSortingKey?: BoardDiscussionCardsSortingKey | null;
      password?: string | null;
      takeAwayStatus?: TakeawayStatus | null;
    };
    cardBeingDiscussed?: {
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null;
    cardsOnDiscussion?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null> | null;
    lists?: Array<{
      __typename?: 'List';
      id: string;
      boardId: string;
      name: string;
      index: number;
      archived?: boolean | null;
      cardColor?: string | null;
      cards?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        authorId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        wasDiscussed?: boolean | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        promotedToPrograms: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
        }>;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
        children?: Array<{
          __typename?: 'Card';
          id: string;
          listId: string;
          text: string;
          parentId?: string | null;
          index: number;
          votes: Array<string>;
          archived: boolean;
          updatedAt?: number | null;
          createdAt?: number | null;
          authorId: string;
          children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
          originalListBadge?: {
            __typename?: 'OriginalListBadge';
            originalListId?: string | null;
            text?: string | null;
            color?: string | null;
          } | null;
          comments: Array<{
            __typename?: 'CardComment';
            id: string;
            text: string;
            authorId: string;
            cardId: string;
            archived: boolean;
            createdAt: number;
            author: { __typename?: 'User'; id: string; name?: string | null };
          }>;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type CardVoteUpdatesByBoardIdSubscriptionVariables = Exact<{
  boardId: Scalars['ID'];
}>;

export type CardVoteUpdatesByBoardIdSubscription = {
  __typename?: 'Subscription';
  cardVoteUpdatesByBoardId?: {
    __typename?: 'Card';
    id: string;
    votes: Array<string>;
  } | null;
};

export type NewCardsByBoardIdSubscriptionVariables = Exact<{
  boardId: Scalars['String'];
}>;

export type NewCardsByBoardIdSubscription = {
  __typename?: 'Subscription';
  cardUpdate?: {
    __typename?: 'Card';
    id: string;
    listId: string;
    authorId: string;
    text: string;
    parentId?: string | null;
    index: number;
    votes: Array<string>;
    archived: boolean;
    updatedAt?: number | null;
    createdAt?: number | null;
    wasDiscussed?: boolean | null;
    originalListBadge?: {
      __typename?: 'OriginalListBadge';
      originalListId?: string | null;
      text?: string | null;
      color?: string | null;
    } | null;
    promotedToPrograms: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
    }>;
    comments: Array<{
      __typename?: 'CardComment';
      id: string;
      text: string;
      authorId: string;
      cardId: string;
      archived: boolean;
      createdAt: number;
      author: { __typename?: 'User'; id: string; name?: string | null };
    }>;
    children?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      authorId: string;
      children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
    } | null> | null;
  } | null;
};

export type ParticipationChangesSubscriptionVariables = Exact<{
  input?: InputMaybe<ParticipationChangedInput>;
}>;

export type ParticipationChangesSubscription = {
  __typename?: 'Subscription';
  participationChanged?: {
    __typename?: 'BoardParticipationChangeEvent';
    participationChanges?: Array<{
      __typename?: 'BoardParticipation';
      status: BoardParticipationStatus;
      userId: string;
      isFacilitator: boolean;
      numberOfVotes: number;
      numberOfCards: number;
      participant: { __typename?: 'User'; id: string; name?: string | null };
    } | null> | null;
  } | null;
};

export type BoardAsyncScheduleFragmentFragment = {
  __typename?: 'BoardAsyncSchedule';
  id: string;
  teamId: string;
  name: string;
  scheduleType: BoardAsyncScheduleType;
  rrule?: string | null;
  oneTimeSchedule?: number | null;
  collectionTime: number;
  feedbackCollectionType: BoardAsyncCollectionType;
  isActive: boolean;
  areQuestionsOptional: boolean;
  questionType: BoardAsyncQuestionType;
  sendReminders: boolean;
  retrospectiveFormatId?: string | null;
  feedbackType: BoardAsyncFeedbackType;
  customQuestions?: Array<{
    __typename?: 'BoardCustomQuestion';
    id: string;
    question: string;
    isOptional: boolean;
    index: number;
  }> | null;
};

export type ArchiveBoardAsyncScheduleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ArchiveBoardAsyncScheduleMutation = {
  __typename?: 'Mutation';
  archiveBoardAsyncSchedule: {
    __typename?: 'BoardAsyncSchedule';
    id: string;
    teamId: string;
    name: string;
    scheduleType: BoardAsyncScheduleType;
    rrule?: string | null;
    oneTimeSchedule?: number | null;
    collectionTime: number;
    feedbackCollectionType: BoardAsyncCollectionType;
    isActive: boolean;
    areQuestionsOptional: boolean;
    questionType: BoardAsyncQuestionType;
    sendReminders: boolean;
    retrospectiveFormatId?: string | null;
    feedbackType: BoardAsyncFeedbackType;
    customQuestions?: Array<{
      __typename?: 'BoardCustomQuestion';
      id: string;
      question: string;
      isOptional: boolean;
      index: number;
    }> | null;
  };
};

export type CreateBoardAsyncScheduleMutationVariables = Exact<{
  data: BoardAsyncScheduleInput;
  customQuestions?: InputMaybe<
    | Array<BoardAsyncScheduleCustomQuestionInput>
    | BoardAsyncScheduleCustomQuestionInput
  >;
}>;

export type CreateBoardAsyncScheduleMutation = {
  __typename?: 'Mutation';
  createBoardAsyncSchedule: {
    __typename?: 'BoardAsyncSchedule';
    id: string;
    teamId: string;
    name: string;
    scheduleType: BoardAsyncScheduleType;
    rrule?: string | null;
    oneTimeSchedule?: number | null;
    collectionTime: number;
    feedbackCollectionType: BoardAsyncCollectionType;
    isActive: boolean;
    areQuestionsOptional: boolean;
    questionType: BoardAsyncQuestionType;
    sendReminders: boolean;
    retrospectiveFormatId?: string | null;
    feedbackType: BoardAsyncFeedbackType;
    customQuestions?: Array<{
      __typename?: 'BoardCustomQuestion';
      id: string;
      question: string;
      isOptional: boolean;
      index: number;
    }> | null;
  };
};

export type TriggerBoardAsyncScheduleByIdMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TriggerBoardAsyncScheduleByIdMutation = {
  __typename?: 'Mutation';
  triggerBoardAsyncScheduleById: boolean;
};

export type UpdateBoardAsyncScheduleMutationVariables = Exact<{
  id: Scalars['ID'];
  data: BoardAsyncScheduleInput;
  customQuestions?: InputMaybe<
    | Array<BoardAsyncScheduleCustomQuestionInput>
    | BoardAsyncScheduleCustomQuestionInput
  >;
}>;

export type UpdateBoardAsyncScheduleMutation = {
  __typename?: 'Mutation';
  updateBoardAsyncSchedule: {
    __typename?: 'BoardAsyncSchedule';
    id: string;
    teamId: string;
    name: string;
    scheduleType: BoardAsyncScheduleType;
    rrule?: string | null;
    oneTimeSchedule?: number | null;
    collectionTime: number;
    feedbackCollectionType: BoardAsyncCollectionType;
    isActive: boolean;
    areQuestionsOptional: boolean;
    questionType: BoardAsyncQuestionType;
    sendReminders: boolean;
    retrospectiveFormatId?: string | null;
    feedbackType: BoardAsyncFeedbackType;
    customQuestions?: Array<{
      __typename?: 'BoardCustomQuestion';
      id: string;
      question: string;
      isOptional: boolean;
      index: number;
    }> | null;
  };
};

export type BoardAsyncSchedulesByTeamIdQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;

export type BoardAsyncSchedulesByTeamIdQuery = {
  __typename?: 'Query';
  boardAsyncSchedulesByTeamId: Array<{
    __typename?: 'BoardAsyncSchedule';
    id: string;
    teamId: string;
    name: string;
    scheduleType: BoardAsyncScheduleType;
    rrule?: string | null;
    oneTimeSchedule?: number | null;
    collectionTime: number;
    feedbackCollectionType: BoardAsyncCollectionType;
    isActive: boolean;
    areQuestionsOptional: boolean;
    questionType: BoardAsyncQuestionType;
    sendReminders: boolean;
    retrospectiveFormatId?: string | null;
    feedbackType: BoardAsyncFeedbackType;
    customQuestions?: Array<{
      __typename?: 'BoardCustomQuestion';
      id: string;
      question: string;
      isOptional: boolean;
      index: number;
    }> | null;
  }>;
};

export type ImpactReportQueryVariables = Exact<{
  filter: ImpactReportFilter;
}>;

export type ImpactReportQuery = {
  __typename?: 'Query';
  impactReport: {
    __typename?: 'ImpactReport';
    insight: string;
    badges: Array<{
      __typename?: 'ImpactReportBadge';
      title: string;
      icon: string;
      value: string;
      detail?: string | null;
    }>;
    cards: Array<{
      __typename?: 'ImpactReportSummaryCard';
      title: string;
      description: string;
      summary: string;
      monetaryImpact: number;
      badges: Array<{
        __typename?: 'ImpactReportBadge';
        title: string;
        icon: string;
        value: string;
        detail?: string | null;
      }>;
      items: Array<{
        __typename?: 'ImpactReportSummaryCardItem';
        id: string;
        type: string;
        title: string;
        severity: string;
        teamCount: number;
        monetaryImpact: number;
        occurrenceCount: number;
        hoursAssociated: number;
        summary: string;
        topics: Array<string>;
      }>;
    }>;
    engagementScoreOverTime: {
      __typename?: 'ImpactReportEngagementScoreOverTime';
      legend: string;
      data: Array<{
        __typename?: 'ImpactReportEngagementScoreOverTimeDataPoint';
        date: number;
        value: number;
      }>;
    };
    sentimentOverTime: {
      __typename?: 'ImpactReportSentimentOverTime';
      legend: string;
      data: Array<{
        __typename?: 'ImpactReportSentimentOverTimeDataPoint';
        date: number;
        value: number;
        positiveCount: number;
        negativeCount: number;
        neutralCount: number;
      }>;
    };
  };
};

export type ImpactReportBoardSummaryQueryVariables = Exact<{
  boardId: Scalars['ID'];
}>;

export type ImpactReportBoardSummaryQuery = {
  __typename?: 'Query';
  impactReportBoardSummary: {
    __typename?: 'ImpactReportBoardSummary';
    positiveCount: number;
    neutralCount: number;
    negativeCount: number;
    summary: string;
    engagementScore: number;
    sentiment: number;
    issuesInFeedback: Array<{
      __typename?: 'ImpactReportSummaryCardItem';
      id: string;
      type: string;
      title: string;
      severity: string;
      teamCount: number;
      hoursAssociated: number;
      occurrenceCount: number;
      monetaryImpact: number;
      summary: string;
      topics: Array<string>;
    }>;
  };
};

export type ImpactReportIssueDrilldownQueryVariables = Exact<{
  teamId: Scalars['ID'];
  issueId: Scalars['ID'];
}>;

export type ImpactReportIssueDrilldownQuery = {
  __typename?: 'Query';
  impactReportIssueDrilldown: {
    __typename?: 'ImpactReportIssueDrilldown';
    id: string;
    color: string;
    type: string;
    title: string;
    summary: string;
    keywords: Array<string>;
    estimatedCost: number;
    mentionCount: number;
    negativeMentionCount: number;
    actionItemsCountRelated: number;
    actionItemsCountResolved: number;
    recurrenceSummary: string;
    impactedTeamsSeverity: string;
    nature: string;
    impactedTeams: Array<{
      __typename?: 'ImpactReportIssueDrilldownImpactedTeam';
      id: string;
      name: string;
      occurrenceCount: number;
    }>;
    recurrenceData: Array<{
      __typename?: 'ImpactReportIssueOccurrence';
      date: number;
      cardCount: number;
      teamCount: number;
    }>;
    possibleCauses: Array<
      | {
          __typename: 'ImpactReportIssueCauseInsight';
          origin: string;
          summary: string;
          teamCount: number;
        }
      | {
          __typename: 'ImpactReportIssueCauseTopic';
          summary: string;
          cards: Array<{
            __typename?: 'ImpactReportIssueCauseTopicCard';
            text: string;
            badgeColor: string;
            badgeText: string;
            retroName: string;
            retroDate: number;
          }>;
        }
    >;
  };
};

export type ImprovementGoalDataFragmentFragment = {
  __typename?: 'ImprovementGoalData';
  id: string;
  improvementGoalId: string;
  value: number;
  date: number;
  createdAt: number;
  archived: boolean;
  origin?: ImprovementGoalDataOrigin | null;
  children: Array<{
    __typename?: 'ImprovementGoalData';
    id: string;
    improvementGoalId: string;
    value: number;
    date: number;
    createdAt: number;
    archived: boolean;
    origin?: ImprovementGoalDataOrigin | null;
  } | null>;
};

export type ImprovementGoalFragmentFragment = {
  __typename?: 'ImprovementGoal';
  id: string;
  createdAt: number;
  timesUsed: number;
  updatedAt: number;
  archived: boolean;
  paused: boolean;
  target: number;
  ongoingActionsCount: number;
  actionsTakenCount: number;
  type: {
    __typename?: 'ImprovementGoalType';
    id: string;
    name: string;
    color: string;
    description?: string | null;
    learnMoreUrl?: string | null;
    unit: ImprovementGoalUnit;
    categories: Array<ImprovementGoalTypeCategory>;
    subCategory?: string | null;
    targetType: ImprovementGoalTypeTargetType;
    aggregationPeriod: ImprovementGoalAggregationPeriod;
    aggregationFunction: ImprovementGoalAggregationFunction;
    isPollable: boolean;
    minValue?: number | null;
    maxValue?: number | null;
  };
  team: { __typename?: 'Team'; type: TeamType; name: string };
  user: { __typename?: 'User'; name?: string | null };
  userReminders: Array<{
    __typename?: 'ImprovementGoalUserReminder';
    improvementGoalId: string;
    userId: string;
    notificationTime: number;
    frequency: ImprovementGoalUserReminderFrequency;
    createdAt: number;
    updatedAt: number;
  }>;
};

export type ImprovementGoalReminderFragmentFragment = {
  __typename?: 'ImprovementGoalUserReminder';
  improvementGoalId: string;
  userId: string;
  notificationTime: number;
  frequency: ImprovementGoalUserReminderFrequency;
  createdAt: number;
  updatedAt: number;
};

export type ImprovementGoalTypeFragmentFragment = {
  __typename?: 'ImprovementGoalType';
  id: string;
  name: string;
  color: string;
  description?: string | null;
  learnMoreUrl?: string | null;
  unit: ImprovementGoalUnit;
  categories: Array<ImprovementGoalTypeCategory>;
  subCategory?: string | null;
  targetType: ImprovementGoalTypeTargetType;
  aggregationPeriod: ImprovementGoalAggregationPeriod;
  aggregationFunction: ImprovementGoalAggregationFunction;
  isPollable: boolean;
  minValue?: number | null;
  maxValue?: number | null;
};

export type AddGoalDataMutationVariables = Exact<{
  goalId: Scalars['ID'];
  reportingTeamId: Scalars['ID'];
  value: Scalars['Float'];
  date: Scalars['Date'];
}>;

export type AddGoalDataMutation = {
  __typename?: 'Mutation';
  addImprovementGoalData: {
    __typename?: 'ImprovementGoalData';
    id: string;
    improvementGoalId: string;
    value: number;
    date: number;
    createdAt: number;
    archived: boolean;
    origin?: ImprovementGoalDataOrigin | null;
    children: Array<{
      __typename?: 'ImprovementGoalData';
      id: string;
      improvementGoalId: string;
      value: number;
      date: number;
      createdAt: number;
      archived: boolean;
      origin?: ImprovementGoalDataOrigin | null;
    } | null>;
  };
};

export type ArchiveGoalDataMutationVariables = Exact<{
  goalDataId: Scalars['ID'];
}>;

export type ArchiveGoalDataMutation = {
  __typename?: 'Mutation';
  archiveImprovementGoalData?: {
    __typename?: 'ImprovementGoalData';
    id: string;
    improvementGoalId: string;
    value: number;
    date: number;
    createdAt: number;
    archived: boolean;
    origin?: ImprovementGoalDataOrigin | null;
    children: Array<{
      __typename?: 'ImprovementGoalData';
      id: string;
      improvementGoalId: string;
      value: number;
      date: number;
      createdAt: number;
      archived: boolean;
      origin?: ImprovementGoalDataOrigin | null;
    } | null>;
  } | null;
};

export type CreateImprovementGoalMutationVariables = Exact<{
  input: ImprovementGoalInput;
}>;

export type CreateImprovementGoalMutation = {
  __typename?: 'Mutation';
  createImprovementGoal: {
    __typename?: 'ImprovementGoal';
    id: string;
    createdAt: number;
    timesUsed: number;
    updatedAt: number;
    archived: boolean;
    paused: boolean;
    target: number;
    ongoingActionsCount: number;
    actionsTakenCount: number;
    type: {
      __typename?: 'ImprovementGoalType';
      id: string;
      name: string;
      color: string;
      description?: string | null;
      learnMoreUrl?: string | null;
      unit: ImprovementGoalUnit;
      categories: Array<ImprovementGoalTypeCategory>;
      subCategory?: string | null;
      targetType: ImprovementGoalTypeTargetType;
      aggregationPeriod: ImprovementGoalAggregationPeriod;
      aggregationFunction: ImprovementGoalAggregationFunction;
      isPollable: boolean;
      minValue?: number | null;
      maxValue?: number | null;
    };
    team: { __typename?: 'Team'; type: TeamType; name: string };
    user: { __typename?: 'User'; name?: string | null };
    userReminders: Array<{
      __typename?: 'ImprovementGoalUserReminder';
      improvementGoalId: string;
      userId: string;
      notificationTime: number;
      frequency: ImprovementGoalUserReminderFrequency;
      createdAt: number;
      updatedAt: number;
    }>;
  };
};

export type DeleteImprovementGoalMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteImprovementGoalMutation = {
  __typename?: 'Mutation';
  removeImprovementGoal?: {
    __typename?: 'ImprovementGoal';
    id: string;
    createdAt: number;
    timesUsed: number;
    updatedAt: number;
    archived: boolean;
    paused: boolean;
    target: number;
    ongoingActionsCount: number;
    actionsTakenCount: number;
    type: {
      __typename?: 'ImprovementGoalType';
      id: string;
      name: string;
      color: string;
      description?: string | null;
      learnMoreUrl?: string | null;
      unit: ImprovementGoalUnit;
      categories: Array<ImprovementGoalTypeCategory>;
      subCategory?: string | null;
      targetType: ImprovementGoalTypeTargetType;
      aggregationPeriod: ImprovementGoalAggregationPeriod;
      aggregationFunction: ImprovementGoalAggregationFunction;
      isPollable: boolean;
      minValue?: number | null;
      maxValue?: number | null;
    };
    team: { __typename?: 'Team'; type: TeamType; name: string };
    user: { __typename?: 'User'; name?: string | null };
    userReminders: Array<{
      __typename?: 'ImprovementGoalUserReminder';
      improvementGoalId: string;
      userId: string;
      notificationTime: number;
      frequency: ImprovementGoalUserReminderFrequency;
      createdAt: number;
      updatedAt: number;
    }>;
  } | null;
};

export type ImportImprovementGoalDataMutationVariables = Exact<{
  improvementGoalId: Scalars['ID'];
  data: Array<ImprovementGoalDataImportInput> | ImprovementGoalDataImportInput;
}>;

export type ImportImprovementGoalDataMutation = {
  __typename?: 'Mutation';
  importImprovementGoalData: { __typename?: 'ImprovementGoal'; id: string };
};

export type ReplaceGoalDataMutationVariables = Exact<{
  goalDataId: Scalars['ID'];
  newValue: Scalars['Float'];
}>;

export type ReplaceGoalDataMutation = {
  __typename?: 'Mutation';
  replaceImprovementGoalData: Array<{
    __typename?: 'ImprovementGoalData';
    id: string;
    improvementGoalId: string;
    value: number;
    date: number;
    createdAt: number;
    archived: boolean;
    origin?: ImprovementGoalDataOrigin | null;
    children: Array<{
      __typename?: 'ImprovementGoalData';
      id: string;
      improvementGoalId: string;
      value: number;
      date: number;
      createdAt: number;
      archived: boolean;
      origin?: ImprovementGoalDataOrigin | null;
    } | null>;
  } | null>;
};

export type SetGoalRemindersMutationVariables = Exact<{
  improvementGoalId: Scalars['ID'];
  userIds: Array<Scalars['ID']> | Scalars['ID'];
  notificationTime: Scalars['Date'];
  frequency: ImprovementGoalUserReminderFrequency;
}>;

export type SetGoalRemindersMutation = {
  __typename?: 'Mutation';
  setImprovementGoalUserReminders: {
    __typename?: 'ImprovementGoal';
    id: string;
    createdAt: number;
    timesUsed: number;
    updatedAt: number;
    archived: boolean;
    paused: boolean;
    target: number;
    ongoingActionsCount: number;
    actionsTakenCount: number;
    type: {
      __typename?: 'ImprovementGoalType';
      id: string;
      name: string;
      color: string;
      description?: string | null;
      learnMoreUrl?: string | null;
      unit: ImprovementGoalUnit;
      categories: Array<ImprovementGoalTypeCategory>;
      subCategory?: string | null;
      targetType: ImprovementGoalTypeTargetType;
      aggregationPeriod: ImprovementGoalAggregationPeriod;
      aggregationFunction: ImprovementGoalAggregationFunction;
      isPollable: boolean;
      minValue?: number | null;
      maxValue?: number | null;
    };
    team: { __typename?: 'Team'; type: TeamType; name: string };
    user: { __typename?: 'User'; name?: string | null };
    userReminders: Array<{
      __typename?: 'ImprovementGoalUserReminder';
      improvementGoalId: string;
      userId: string;
      notificationTime: number;
      frequency: ImprovementGoalUserReminderFrequency;
      createdAt: number;
      updatedAt: number;
    }>;
  };
};

export type UpdateImprovementGoalMutationVariables = Exact<{
  id: Scalars['ID'];
  input: ImprovementGoalInput;
}>;

export type UpdateImprovementGoalMutation = {
  __typename?: 'Mutation';
  updateImprovementGoal?: {
    __typename?: 'ImprovementGoal';
    id: string;
    createdAt: number;
    timesUsed: number;
    updatedAt: number;
    archived: boolean;
    paused: boolean;
    target: number;
    ongoingActionsCount: number;
    actionsTakenCount: number;
    type: {
      __typename?: 'ImprovementGoalType';
      id: string;
      name: string;
      color: string;
      description?: string | null;
      learnMoreUrl?: string | null;
      unit: ImprovementGoalUnit;
      categories: Array<ImprovementGoalTypeCategory>;
      subCategory?: string | null;
      targetType: ImprovementGoalTypeTargetType;
      aggregationPeriod: ImprovementGoalAggregationPeriod;
      aggregationFunction: ImprovementGoalAggregationFunction;
      isPollable: boolean;
      minValue?: number | null;
      maxValue?: number | null;
    };
    team: { __typename?: 'Team'; type: TeamType; name: string };
    user: { __typename?: 'User'; name?: string | null };
    userReminders: Array<{
      __typename?: 'ImprovementGoalUserReminder';
      improvementGoalId: string;
      userId: string;
      notificationTime: number;
      frequency: ImprovementGoalUserReminderFrequency;
      createdAt: number;
      updatedAt: number;
    }>;
  } | null;
};

export type ImprovementGoalByIdQueryVariables = Exact<{
  id: Scalars['ID'];
  takeLastNPeriodsWithData?: Scalars['Int'];
  utcOffsetMs?: InputMaybe<Scalars['Int']>;
  reportingDataTeamIds: Array<ImprovementGoalTeam> | ImprovementGoalTeam;
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
}>;

export type ImprovementGoalByIdQuery = {
  __typename?: 'Query';
  findImprovementGoalById: {
    __typename?: 'ImprovementGoal';
    id: string;
    createdAt: number;
    timesUsed: number;
    updatedAt: number;
    archived: boolean;
    paused: boolean;
    target: number;
    ongoingActionsCount: number;
    actionsTakenCount: number;
    team: {
      __typename?: 'Team';
      type: TeamType;
      name: string;
      id: string;
      createdAt?: number | null;
    };
    data: Array<{
      __typename?: 'ImprovementGoalData';
      id: string;
      improvementGoalId: string;
      value: number;
      date: number;
      createdAt: number;
      archived: boolean;
      origin?: ImprovementGoalDataOrigin | null;
      children: Array<{
        __typename?: 'ImprovementGoalData';
        id: string;
        improvementGoalId: string;
        value: number;
        date: number;
        createdAt: number;
        archived: boolean;
        origin?: ImprovementGoalDataOrigin | null;
      } | null>;
    }>;
    type: {
      __typename?: 'ImprovementGoalType';
      id: string;
      name: string;
      color: string;
      description?: string | null;
      learnMoreUrl?: string | null;
      unit: ImprovementGoalUnit;
      categories: Array<ImprovementGoalTypeCategory>;
      subCategory?: string | null;
      targetType: ImprovementGoalTypeTargetType;
      aggregationPeriod: ImprovementGoalAggregationPeriod;
      aggregationFunction: ImprovementGoalAggregationFunction;
      isPollable: boolean;
      minValue?: number | null;
      maxValue?: number | null;
    };
    user: { __typename?: 'User'; name?: string | null };
    userReminders: Array<{
      __typename?: 'ImprovementGoalUserReminder';
      improvementGoalId: string;
      userId: string;
      notificationTime: number;
      frequency: ImprovementGoalUserReminderFrequency;
      createdAt: number;
      updatedAt: number;
    }>;
  };
};

export type ImprovementGoalSummaryQueryVariables = Exact<{
  teamId: Scalars['ID'];
  utcOffsetMs?: InputMaybe<Scalars['Int']>;
}>;

export type ImprovementGoalSummaryQuery = {
  __typename?: 'Query';
  teamById?: {
    __typename?: 'Team';
    id: string;
    improvementGoals: {
      __typename?: 'TeamImprovementGoalResults';
      totalSize: number;
      insight?: {
        __typename?: 'ImprovementGoalInsightFocus';
        percentage: number;
        goalName: string;
      } | null;
      improvementGoals: Array<{
        __typename?: 'ImprovementGoal';
        id: string;
        createdAt: number;
        timesUsed: number;
        updatedAt: number;
        archived: boolean;
        paused: boolean;
        target: number;
        ongoingActionsCount: number;
        actionsTakenCount: number;
        data: Array<{
          __typename?: 'ImprovementGoalData';
          id: string;
          improvementGoalId: string;
          value: number;
          date: number;
          createdAt: number;
          archived: boolean;
          origin?: ImprovementGoalDataOrigin | null;
          children: Array<{
            __typename?: 'ImprovementGoalData';
            id: string;
            improvementGoalId: string;
            value: number;
            date: number;
            createdAt: number;
            archived: boolean;
            origin?: ImprovementGoalDataOrigin | null;
          } | null>;
        }>;
        type: {
          __typename?: 'ImprovementGoalType';
          id: string;
          name: string;
          color: string;
          description?: string | null;
          learnMoreUrl?: string | null;
          unit: ImprovementGoalUnit;
          categories: Array<ImprovementGoalTypeCategory>;
          subCategory?: string | null;
          targetType: ImprovementGoalTypeTargetType;
          aggregationPeriod: ImprovementGoalAggregationPeriod;
          aggregationFunction: ImprovementGoalAggregationFunction;
          isPollable: boolean;
          minValue?: number | null;
          maxValue?: number | null;
        };
        team: { __typename?: 'Team'; type: TeamType; name: string };
        user: { __typename?: 'User'; name?: string | null };
        userReminders: Array<{
          __typename?: 'ImprovementGoalUserReminder';
          improvementGoalId: string;
          userId: string;
          notificationTime: number;
          frequency: ImprovementGoalUserReminderFrequency;
          createdAt: number;
          updatedAt: number;
        }>;
      }>;
    };
  } | null;
};

export type ImprovementGoalTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ImprovementGoalTypesQuery = {
  __typename?: 'Query';
  improvementGoalTypes: Array<{
    __typename?: 'ImprovementGoalType';
    id: string;
    name: string;
    color: string;
    description?: string | null;
    learnMoreUrl?: string | null;
    unit: ImprovementGoalUnit;
    categories: Array<ImprovementGoalTypeCategory>;
    subCategory?: string | null;
    targetType: ImprovementGoalTypeTargetType;
    aggregationPeriod: ImprovementGoalAggregationPeriod;
    aggregationFunction: ImprovementGoalAggregationFunction;
    isPollable: boolean;
    minValue?: number | null;
    maxValue?: number | null;
  } | null>;
};

export type ImprovementGoalsByTeamIdQueryVariables = Exact<{
  teamId: Scalars['ID'];
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  takeLastNPeriodsWithData?: Scalars['Int'];
  utcOffsetMs?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['JSON']>;
}>;

export type ImprovementGoalsByTeamIdQuery = {
  __typename?: 'Query';
  teamById?: {
    __typename?: 'Team';
    id: string;
    improvementGoals: {
      __typename?: 'TeamImprovementGoalResults';
      totalSize: number;
      improvementGoals: Array<{
        __typename?: 'ImprovementGoal';
        id: string;
        createdAt: number;
        timesUsed: number;
        updatedAt: number;
        archived: boolean;
        paused: boolean;
        target: number;
        ongoingActionsCount: number;
        actionsTakenCount: number;
        data: Array<{
          __typename?: 'ImprovementGoalData';
          id: string;
          improvementGoalId: string;
          value: number;
          date: number;
          createdAt: number;
          archived: boolean;
          origin?: ImprovementGoalDataOrigin | null;
          children: Array<{
            __typename?: 'ImprovementGoalData';
            id: string;
            improvementGoalId: string;
            value: number;
            date: number;
            createdAt: number;
            archived: boolean;
            origin?: ImprovementGoalDataOrigin | null;
          } | null>;
        }>;
        type: {
          __typename?: 'ImprovementGoalType';
          id: string;
          name: string;
          color: string;
          description?: string | null;
          learnMoreUrl?: string | null;
          unit: ImprovementGoalUnit;
          categories: Array<ImprovementGoalTypeCategory>;
          subCategory?: string | null;
          targetType: ImprovementGoalTypeTargetType;
          aggregationPeriod: ImprovementGoalAggregationPeriod;
          aggregationFunction: ImprovementGoalAggregationFunction;
          isPollable: boolean;
          minValue?: number | null;
          maxValue?: number | null;
        };
        team: { __typename?: 'Team'; type: TeamType; name: string };
        user: { __typename?: 'User'; name?: string | null };
        userReminders: Array<{
          __typename?: 'ImprovementGoalUserReminder';
          improvementGoalId: string;
          userId: string;
          notificationTime: number;
          frequency: ImprovementGoalUserReminderFrequency;
          createdAt: number;
          updatedAt: number;
        }>;
      }>;
    };
  } | null;
};

export type ImprovementGoalsByTeamIdsQueryVariables = Exact<{
  reportingDataTeamIds: Array<ImprovementGoalTeam> | ImprovementGoalTeam;
  improvementGoalTeamIds: Array<Scalars['ID']> | Scalars['ID'];
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  takeLastNPeriodsWithData?: Scalars['Int'];
  utcOffsetMs?: InputMaybe<Scalars['Int']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
  sort?: InputMaybe<Scalars['JSON']>;
}>;

export type ImprovementGoalsByTeamIdsQuery = {
  __typename?: 'Query';
  improvementGoalsByTeamIds: {
    __typename?: 'TeamImprovementGoalResults';
    totalSize: number;
    improvementGoals: Array<{
      __typename?: 'ImprovementGoal';
      id: string;
      createdAt: number;
      timesUsed: number;
      updatedAt: number;
      archived: boolean;
      paused: boolean;
      target: number;
      ongoingActionsCount: number;
      actionsTakenCount: number;
      data: Array<{
        __typename?: 'ImprovementGoalData';
        id: string;
        improvementGoalId: string;
        value: number;
        date: number;
        createdAt: number;
        archived: boolean;
        origin?: ImprovementGoalDataOrigin | null;
        children: Array<{
          __typename?: 'ImprovementGoalData';
          id: string;
          improvementGoalId: string;
          value: number;
          date: number;
          createdAt: number;
          archived: boolean;
          origin?: ImprovementGoalDataOrigin | null;
        } | null>;
      }>;
      goalIssues: Array<{
        __typename?: 'Issue';
        id: string;
        shortSummary: string;
        summary: string;
      }>;
      type: {
        __typename?: 'ImprovementGoalType';
        id: string;
        name: string;
        color: string;
        description?: string | null;
        learnMoreUrl?: string | null;
        unit: ImprovementGoalUnit;
        categories: Array<ImprovementGoalTypeCategory>;
        subCategory?: string | null;
        targetType: ImprovementGoalTypeTargetType;
        aggregationPeriod: ImprovementGoalAggregationPeriod;
        aggregationFunction: ImprovementGoalAggregationFunction;
        isPollable: boolean;
        minValue?: number | null;
        maxValue?: number | null;
      };
      team: { __typename?: 'Team'; type: TeamType; name: string };
      user: { __typename?: 'User'; name?: string | null };
      userReminders: Array<{
        __typename?: 'ImprovementGoalUserReminder';
        improvementGoalId: string;
        userId: string;
        notificationTime: number;
        frequency: ImprovementGoalUserReminderFrequency;
        createdAt: number;
        updatedAt: number;
      }>;
    }>;
  };
};

export type OnOffTargetTeamsByGoalIdQueryVariables = Exact<{
  id: Scalars['ID'];
  reportingDataTeamIds: Array<ImprovementGoalTeam> | ImprovementGoalTeam;
  fromDate?: InputMaybe<Scalars['Date']>;
  toDate?: InputMaybe<Scalars['Date']>;
}>;

export type OnOffTargetTeamsByGoalIdQuery = {
  __typename?: 'Query';
  onOffTargetTeamsByGoalId: Array<{
    __typename?: 'ImprovementGoalOnOffTeam';
    lastValue: number;
    team: {
      __typename?: 'Team';
      id: string;
      name: string;
      type: TeamType;
      createdAt?: number | null;
    };
  }>;
};

export type ImprovementGoalDataUpdatesByTeamIdSubscriptionVariables = Exact<{
  teamId: Scalars['ID'];
  takeLastNPeriodsWithData: Scalars['Int'];
  utcOffsetMs: Scalars['Int'];
}>;

export type ImprovementGoalDataUpdatesByTeamIdSubscription = {
  __typename?: 'Subscription';
  improvementGoalDataUpdatesByTeamId: Array<{
    __typename?: 'ImprovementGoal';
    id: string;
    createdAt: number;
    timesUsed: number;
    updatedAt: number;
    archived: boolean;
    paused: boolean;
    target: number;
    ongoingActionsCount: number;
    actionsTakenCount: number;
    data: Array<{
      __typename?: 'ImprovementGoalData';
      id: string;
      improvementGoalId: string;
      value: number;
      date: number;
      createdAt: number;
      archived: boolean;
      origin?: ImprovementGoalDataOrigin | null;
      children: Array<{
        __typename?: 'ImprovementGoalData';
        id: string;
        improvementGoalId: string;
        value: number;
        date: number;
        createdAt: number;
        archived: boolean;
        origin?: ImprovementGoalDataOrigin | null;
      } | null>;
    }>;
    type: {
      __typename?: 'ImprovementGoalType';
      id: string;
      name: string;
      color: string;
      description?: string | null;
      learnMoreUrl?: string | null;
      unit: ImprovementGoalUnit;
      categories: Array<ImprovementGoalTypeCategory>;
      subCategory?: string | null;
      targetType: ImprovementGoalTypeTargetType;
      aggregationPeriod: ImprovementGoalAggregationPeriod;
      aggregationFunction: ImprovementGoalAggregationFunction;
      isPollable: boolean;
      minValue?: number | null;
      maxValue?: number | null;
    };
    team: { __typename?: 'Team'; type: TeamType; name: string };
    user: { __typename?: 'User'; name?: string | null };
    userReminders: Array<{
      __typename?: 'ImprovementGoalUserReminder';
      improvementGoalId: string;
      userId: string;
      notificationTime: number;
      frequency: ImprovementGoalUserReminderFrequency;
      createdAt: number;
      updatedAt: number;
    }>;
  }>;
};

export type ImprovementGoalDataUpdatesByTeamIdsSubscriptionVariables = Exact<{
  improvementGoalTeamIds: Array<Scalars['ID']> | Scalars['ID'];
  reportingDataTeamIds: Array<ImprovementGoalTeam> | ImprovementGoalTeam;
  takeLastNPeriodsWithData: Scalars['Int'];
  utcOffsetMs: Scalars['Int'];
}>;

export type ImprovementGoalDataUpdatesByTeamIdsSubscription = {
  __typename?: 'Subscription';
  improvementGoalDataUpdatesByTeamIds: Array<{
    __typename?: 'ImprovementGoal';
    id: string;
    createdAt: number;
    timesUsed: number;
    updatedAt: number;
    archived: boolean;
    paused: boolean;
    target: number;
    ongoingActionsCount: number;
    actionsTakenCount: number;
    data: Array<{
      __typename?: 'ImprovementGoalData';
      id: string;
      improvementGoalId: string;
      value: number;
      date: number;
      createdAt: number;
      archived: boolean;
      origin?: ImprovementGoalDataOrigin | null;
      children: Array<{
        __typename?: 'ImprovementGoalData';
        id: string;
        improvementGoalId: string;
        value: number;
        date: number;
        createdAt: number;
        archived: boolean;
        origin?: ImprovementGoalDataOrigin | null;
      } | null>;
    }>;
    goalIssues: Array<{
      __typename?: 'Issue';
      id: string;
      shortSummary: string;
      summary: string;
    }>;
    type: {
      __typename?: 'ImprovementGoalType';
      id: string;
      name: string;
      color: string;
      description?: string | null;
      learnMoreUrl?: string | null;
      unit: ImprovementGoalUnit;
      categories: Array<ImprovementGoalTypeCategory>;
      subCategory?: string | null;
      targetType: ImprovementGoalTypeTargetType;
      aggregationPeriod: ImprovementGoalAggregationPeriod;
      aggregationFunction: ImprovementGoalAggregationFunction;
      isPollable: boolean;
      minValue?: number | null;
      maxValue?: number | null;
    };
    team: { __typename?: 'Team'; type: TeamType; name: string };
    user: { __typename?: 'User'; name?: string | null };
    userReminders: Array<{
      __typename?: 'ImprovementGoalUserReminder';
      improvementGoalId: string;
      userId: string;
      notificationTime: number;
      frequency: ImprovementGoalUserReminderFrequency;
      createdAt: number;
      updatedAt: number;
    }>;
  }>;
};

export type TeamInsightsFragmentFragment = {
  __typename?: 'Team';
  insights: Array<{
    __typename?: 'TeamInsightFactCauseSolution';
    id: string;
    seen: boolean;
    rated: boolean;
    date: number;
    team: { __typename?: 'Team'; id: string; name: string; type: TeamType };
    fact:
      | {
          __typename?: 'TeamInsightFactCauseSolutionFactMetricBelowRecommended';
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionFactMetricDeterioration';
          id: string;
          type: {
            __typename?: 'TeamInsightMetricType';
            name: string;
            unit: ImprovementGoalUnit;
            providerCategory?: MetricsProviderCategory | null;
            ideal: Trend;
          };
          history: Array<{
            __typename?: 'TimeSeries';
            date: number;
            value?: number | null;
          }>;
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionFactMetricImprovement';
          id: string;
          type: {
            __typename?: 'TeamInsightMetricType';
            name: string;
            unit: ImprovementGoalUnit;
            providerCategory?: MetricsProviderCategory | null;
            ideal: Trend;
          };
          history: Array<{
            __typename?: 'TimeSeries';
            date: number;
            value?: number | null;
          }>;
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionFactMetricInadequacy';
          id: string;
          recommended: number;
          type: {
            __typename?: 'TeamInsightMetricType';
            name: string;
            unit: ImprovementGoalUnit;
            providerCategory?: MetricsProviderCategory | null;
            ideal: Trend;
          };
          history: Array<{
            __typename?: 'TimeSeries';
            date: number;
            value?: number | null;
          }>;
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionFactOutlierWorkItemTopic';
          id: string;
          topic: string;
        };
    causes?: Array<
      | {
          __typename?: 'TeamInsightFactCauseSolutionCauseMetricDeterioration';
          id: string;
          type: {
            __typename?: 'TeamInsightMetricType';
            name: string;
            unit: ImprovementGoalUnit;
            providerCategory?: MetricsProviderCategory | null;
            ideal: Trend;
          };
          history: Array<{
            __typename?: 'TimeSeries';
            date: number;
            value?: number | null;
          }>;
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequests';
          id: string;
          topic?: string | null;
          pullRequests: Array<{
            __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequest';
            title: string;
            externalUrl?: string | null;
            slug?: string | null;
            origin?: SourceControlOrigin | null;
            wipTimeMs?: number | null;
          }>;
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionCauseOutlierWorkItems';
          id: string;
          topic?: string | null;
          workItems: Array<{
            __typename?: 'TeamInsightFactCauseSolutionCauseWorkItem';
            slug?: string | null;
            title: string;
            wipTimeMs: number;
            origin?: WorkItemOrigin | null;
            url?: string | null;
          }>;
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionCauseTopic';
          id: string;
          topic?: string | null;
          cards: Array<{
            __typename?: 'TeamInsightFactCauseSolutionCauseTopicCard';
            text: string;
            badgeColor: string;
            badgeText: string;
            retroName: string;
            retroDate: number;
          }>;
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionCauseUnreviewedPullRequests';
          id: string;
          topic?: string | null;
          pullRequests: Array<{
            __typename?: 'TeamInsightFactCauseSolutionCausePullRequest';
            title: string;
            description?: string | null;
            externalUrl?: string | null;
            slug?: string | null;
            createdAt: string;
            origin?: SourceControlOrigin | null;
          }>;
        }
    > | null;
    solutions?: Array<
      Array<
        | {
            __typename?: 'TeamInsightFactCauseSolutionSolutionActionItem';
            id: string;
            text: string;
            originatedActionItem?: {
              __typename?: 'ActionItem';
              id: string;
              title: string;
              description?: string | null;
              teamId: string;
              resolvedAt?: number | null;
              dueDate?: number | null;
              authorId: string;
              boardId?: string | null;
              status: ActionItemStatus;
              archived: boolean;
              createdAt?: number | null;
              children: Array<{
                __typename?: 'ActionItemChild';
                id: string;
                text: string;
              }>;
              author?: { __typename?: 'Author'; name: string } | null;
              assignees?: Array<{
                __typename?: 'User';
                id: string;
                name?: string | null;
              } | null> | null;
              improvementGoals?: Array<{
                __typename?: 'ImprovementGoal';
                id: string;
                type: { __typename?: 'ImprovementGoalType'; name: string };
              } | null> | null;
              jiraIssueLink?: {
                __typename?: 'ActionItemJiraLink';
                jiraIssueId?: string | null;
                jiraIssueUrl?: string | null;
                jiraIssueKey?: string | null;
                jiraProjectKey?: string | null;
              } | null;
              comments: Array<{
                __typename?: 'ActionItemComment';
                id: string;
                text: string;
                actionItemId: string;
                createdAt: number;
                archived: boolean;
                author: {
                  __typename?: 'User';
                  id: string;
                  name?: string | null;
                };
              }>;
            } | null;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionSolutionGoal';
            id: string;
            type: {
              __typename?: 'ImprovementGoalType';
              id: string;
              name: string;
              color: string;
              description?: string | null;
              learnMoreUrl?: string | null;
              unit: ImprovementGoalUnit;
              categories: Array<ImprovementGoalTypeCategory>;
              subCategory?: string | null;
              targetType: ImprovementGoalTypeTargetType;
              aggregationPeriod: ImprovementGoalAggregationPeriod;
              aggregationFunction: ImprovementGoalAggregationFunction;
              isPollable: boolean;
              minValue?: number | null;
              maxValue?: number | null;
            };
          }
      >
    > | null;
  }>;
};

export type TimeSeriesFragmentFragment = {
  __typename?: 'TimeSeries';
  date: number;
  value?: number | null;
};

export type TeamInsightMetricTypeFragmentFragment = {
  __typename?: 'TeamInsightMetricType';
  name: string;
  unit: ImprovementGoalUnit;
  providerCategory?: MetricsProviderCategory | null;
  ideal: Trend;
};

export type TeamInsightFactCauseSolutionFragmentFragment = {
  __typename?: 'TeamInsightFactCauseSolution';
  id: string;
  seen: boolean;
  rated: boolean;
  date: number;
  team: { __typename?: 'Team'; id: string; name: string; type: TeamType };
  fact:
    | { __typename?: 'TeamInsightFactCauseSolutionFactMetricBelowRecommended' }
    | {
        __typename?: 'TeamInsightFactCauseSolutionFactMetricDeterioration';
        id: string;
        type: {
          __typename?: 'TeamInsightMetricType';
          name: string;
          unit: ImprovementGoalUnit;
          providerCategory?: MetricsProviderCategory | null;
          ideal: Trend;
        };
        history: Array<{
          __typename?: 'TimeSeries';
          date: number;
          value?: number | null;
        }>;
      }
    | {
        __typename?: 'TeamInsightFactCauseSolutionFactMetricImprovement';
        id: string;
        type: {
          __typename?: 'TeamInsightMetricType';
          name: string;
          unit: ImprovementGoalUnit;
          providerCategory?: MetricsProviderCategory | null;
          ideal: Trend;
        };
        history: Array<{
          __typename?: 'TimeSeries';
          date: number;
          value?: number | null;
        }>;
      }
    | {
        __typename?: 'TeamInsightFactCauseSolutionFactMetricInadequacy';
        id: string;
        recommended: number;
        type: {
          __typename?: 'TeamInsightMetricType';
          name: string;
          unit: ImprovementGoalUnit;
          providerCategory?: MetricsProviderCategory | null;
          ideal: Trend;
        };
        history: Array<{
          __typename?: 'TimeSeries';
          date: number;
          value?: number | null;
        }>;
      }
    | {
        __typename?: 'TeamInsightFactCauseSolutionFactOutlierWorkItemTopic';
        id: string;
        topic: string;
      };
  causes?: Array<
    | {
        __typename?: 'TeamInsightFactCauseSolutionCauseMetricDeterioration';
        id: string;
        type: {
          __typename?: 'TeamInsightMetricType';
          name: string;
          unit: ImprovementGoalUnit;
          providerCategory?: MetricsProviderCategory | null;
          ideal: Trend;
        };
        history: Array<{
          __typename?: 'TimeSeries';
          date: number;
          value?: number | null;
        }>;
      }
    | {
        __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequests';
        id: string;
        topic?: string | null;
        pullRequests: Array<{
          __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequest';
          title: string;
          externalUrl?: string | null;
          slug?: string | null;
          origin?: SourceControlOrigin | null;
          wipTimeMs?: number | null;
        }>;
      }
    | {
        __typename?: 'TeamInsightFactCauseSolutionCauseOutlierWorkItems';
        id: string;
        topic?: string | null;
        workItems: Array<{
          __typename?: 'TeamInsightFactCauseSolutionCauseWorkItem';
          slug?: string | null;
          title: string;
          wipTimeMs: number;
          origin?: WorkItemOrigin | null;
          url?: string | null;
        }>;
      }
    | {
        __typename?: 'TeamInsightFactCauseSolutionCauseTopic';
        id: string;
        topic?: string | null;
        cards: Array<{
          __typename?: 'TeamInsightFactCauseSolutionCauseTopicCard';
          text: string;
          badgeColor: string;
          badgeText: string;
          retroName: string;
          retroDate: number;
        }>;
      }
    | {
        __typename?: 'TeamInsightFactCauseSolutionCauseUnreviewedPullRequests';
        id: string;
        topic?: string | null;
        pullRequests: Array<{
          __typename?: 'TeamInsightFactCauseSolutionCausePullRequest';
          title: string;
          description?: string | null;
          externalUrl?: string | null;
          slug?: string | null;
          createdAt: string;
          origin?: SourceControlOrigin | null;
        }>;
      }
  > | null;
  solutions?: Array<
    Array<
      | {
          __typename?: 'TeamInsightFactCauseSolutionSolutionActionItem';
          id: string;
          text: string;
          originatedActionItem?: {
            __typename?: 'ActionItem';
            id: string;
            title: string;
            description?: string | null;
            teamId: string;
            resolvedAt?: number | null;
            dueDate?: number | null;
            authorId: string;
            boardId?: string | null;
            status: ActionItemStatus;
            archived: boolean;
            createdAt?: number | null;
            children: Array<{
              __typename?: 'ActionItemChild';
              id: string;
              text: string;
            }>;
            author?: { __typename?: 'Author'; name: string } | null;
            assignees?: Array<{
              __typename?: 'User';
              id: string;
              name?: string | null;
            } | null> | null;
            improvementGoals?: Array<{
              __typename?: 'ImprovementGoal';
              id: string;
              type: { __typename?: 'ImprovementGoalType'; name: string };
            } | null> | null;
            jiraIssueLink?: {
              __typename?: 'ActionItemJiraLink';
              jiraIssueId?: string | null;
              jiraIssueUrl?: string | null;
              jiraIssueKey?: string | null;
              jiraProjectKey?: string | null;
            } | null;
            comments: Array<{
              __typename?: 'ActionItemComment';
              id: string;
              text: string;
              actionItemId: string;
              createdAt: number;
              archived: boolean;
              author: { __typename?: 'User'; id: string; name?: string | null };
            }>;
          } | null;
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionSolutionGoal';
          id: string;
          type: {
            __typename?: 'ImprovementGoalType';
            id: string;
            name: string;
            color: string;
            description?: string | null;
            learnMoreUrl?: string | null;
            unit: ImprovementGoalUnit;
            categories: Array<ImprovementGoalTypeCategory>;
            subCategory?: string | null;
            targetType: ImprovementGoalTypeTargetType;
            aggregationPeriod: ImprovementGoalAggregationPeriod;
            aggregationFunction: ImprovementGoalAggregationFunction;
            isPollable: boolean;
            minValue?: number | null;
            maxValue?: number | null;
          };
        }
    >
  > | null;
};

export type TeamInsightFactCauseSolutionFactFragmentFragment = {
  __typename?: 'TeamInsightFactCauseSolution';
  fact:
    | { __typename?: 'TeamInsightFactCauseSolutionFactMetricBelowRecommended' }
    | {
        __typename?: 'TeamInsightFactCauseSolutionFactMetricDeterioration';
        id: string;
        type: {
          __typename?: 'TeamInsightMetricType';
          name: string;
          unit: ImprovementGoalUnit;
          providerCategory?: MetricsProviderCategory | null;
          ideal: Trend;
        };
        history: Array<{
          __typename?: 'TimeSeries';
          date: number;
          value?: number | null;
        }>;
      }
    | {
        __typename?: 'TeamInsightFactCauseSolutionFactMetricImprovement';
        id: string;
        type: {
          __typename?: 'TeamInsightMetricType';
          name: string;
          unit: ImprovementGoalUnit;
          providerCategory?: MetricsProviderCategory | null;
          ideal: Trend;
        };
        history: Array<{
          __typename?: 'TimeSeries';
          date: number;
          value?: number | null;
        }>;
      }
    | {
        __typename?: 'TeamInsightFactCauseSolutionFactMetricInadequacy';
        id: string;
        recommended: number;
        type: {
          __typename?: 'TeamInsightMetricType';
          name: string;
          unit: ImprovementGoalUnit;
          providerCategory?: MetricsProviderCategory | null;
          ideal: Trend;
        };
        history: Array<{
          __typename?: 'TimeSeries';
          date: number;
          value?: number | null;
        }>;
      }
    | {
        __typename?: 'TeamInsightFactCauseSolutionFactOutlierWorkItemTopic';
        id: string;
        topic: string;
      };
};

export type TeamInsightFactCauseSolutionCausesFragmentFragment = {
  __typename?: 'TeamInsightFactCauseSolution';
  causes?: Array<
    | {
        __typename?: 'TeamInsightFactCauseSolutionCauseMetricDeterioration';
        id: string;
        type: {
          __typename?: 'TeamInsightMetricType';
          name: string;
          unit: ImprovementGoalUnit;
          providerCategory?: MetricsProviderCategory | null;
          ideal: Trend;
        };
        history: Array<{
          __typename?: 'TimeSeries';
          date: number;
          value?: number | null;
        }>;
      }
    | {
        __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequests';
        id: string;
        topic?: string | null;
        pullRequests: Array<{
          __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequest';
          title: string;
          externalUrl?: string | null;
          slug?: string | null;
          origin?: SourceControlOrigin | null;
          wipTimeMs?: number | null;
        }>;
      }
    | {
        __typename?: 'TeamInsightFactCauseSolutionCauseOutlierWorkItems';
        id: string;
        topic?: string | null;
        workItems: Array<{
          __typename?: 'TeamInsightFactCauseSolutionCauseWorkItem';
          slug?: string | null;
          title: string;
          wipTimeMs: number;
          origin?: WorkItemOrigin | null;
          url?: string | null;
        }>;
      }
    | {
        __typename?: 'TeamInsightFactCauseSolutionCauseTopic';
        id: string;
        topic?: string | null;
        cards: Array<{
          __typename?: 'TeamInsightFactCauseSolutionCauseTopicCard';
          text: string;
          badgeColor: string;
          badgeText: string;
          retroName: string;
          retroDate: number;
        }>;
      }
    | {
        __typename?: 'TeamInsightFactCauseSolutionCauseUnreviewedPullRequests';
        id: string;
        topic?: string | null;
        pullRequests: Array<{
          __typename?: 'TeamInsightFactCauseSolutionCausePullRequest';
          title: string;
          description?: string | null;
          externalUrl?: string | null;
          slug?: string | null;
          createdAt: string;
          origin?: SourceControlOrigin | null;
        }>;
      }
  > | null;
};

export type TeamInsightFactCauseSolutionSolutionsFragmentFragment = {
  __typename?: 'TeamInsightFactCauseSolution';
  solutions?: Array<
    Array<
      | {
          __typename?: 'TeamInsightFactCauseSolutionSolutionActionItem';
          id: string;
          text: string;
          originatedActionItem?: {
            __typename?: 'ActionItem';
            id: string;
            title: string;
            description?: string | null;
            teamId: string;
            resolvedAt?: number | null;
            dueDate?: number | null;
            authorId: string;
            boardId?: string | null;
            status: ActionItemStatus;
            archived: boolean;
            createdAt?: number | null;
            children: Array<{
              __typename?: 'ActionItemChild';
              id: string;
              text: string;
            }>;
            author?: { __typename?: 'Author'; name: string } | null;
            assignees?: Array<{
              __typename?: 'User';
              id: string;
              name?: string | null;
            } | null> | null;
            improvementGoals?: Array<{
              __typename?: 'ImprovementGoal';
              id: string;
              type: { __typename?: 'ImprovementGoalType'; name: string };
            } | null> | null;
            jiraIssueLink?: {
              __typename?: 'ActionItemJiraLink';
              jiraIssueId?: string | null;
              jiraIssueUrl?: string | null;
              jiraIssueKey?: string | null;
              jiraProjectKey?: string | null;
            } | null;
            comments: Array<{
              __typename?: 'ActionItemComment';
              id: string;
              text: string;
              actionItemId: string;
              createdAt: number;
              archived: boolean;
              author: { __typename?: 'User'; id: string; name?: string | null };
            }>;
          } | null;
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionSolutionGoal';
          id: string;
          type: {
            __typename?: 'ImprovementGoalType';
            id: string;
            name: string;
            color: string;
            description?: string | null;
            learnMoreUrl?: string | null;
            unit: ImprovementGoalUnit;
            categories: Array<ImprovementGoalTypeCategory>;
            subCategory?: string | null;
            targetType: ImprovementGoalTypeTargetType;
            aggregationPeriod: ImprovementGoalAggregationPeriod;
            aggregationFunction: ImprovementGoalAggregationFunction;
            isPollable: boolean;
            minValue?: number | null;
            maxValue?: number | null;
          };
        }
    >
  > | null;
};

export type TeamInsightFactCauseSolutionSolutionActionItemFragmentFragment = {
  __typename?: 'TeamInsightFactCauseSolutionSolutionActionItem';
  id: string;
  text: string;
  originatedActionItem?: {
    __typename?: 'ActionItem';
    id: string;
    title: string;
    description?: string | null;
    teamId: string;
    resolvedAt?: number | null;
    dueDate?: number | null;
    authorId: string;
    boardId?: string | null;
    status: ActionItemStatus;
    archived: boolean;
    createdAt?: number | null;
    children: Array<{
      __typename?: 'ActionItemChild';
      id: string;
      text: string;
    }>;
    author?: { __typename?: 'Author'; name: string } | null;
    assignees?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
    } | null> | null;
    improvementGoals?: Array<{
      __typename?: 'ImprovementGoal';
      id: string;
      type: { __typename?: 'ImprovementGoalType'; name: string };
    } | null> | null;
    jiraIssueLink?: {
      __typename?: 'ActionItemJiraLink';
      jiraIssueId?: string | null;
      jiraIssueUrl?: string | null;
      jiraIssueKey?: string | null;
      jiraProjectKey?: string | null;
    } | null;
    comments: Array<{
      __typename?: 'ActionItemComment';
      id: string;
      text: string;
      actionItemId: string;
      createdAt: number;
      archived: boolean;
      author: { __typename?: 'User'; id: string; name?: string | null };
    }>;
  } | null;
};

export type TeamInsightFactCauseSolutionSolutionGoalFragmentFragment = {
  __typename?: 'TeamInsightFactCauseSolutionSolutionGoal';
  id: string;
  type: {
    __typename?: 'ImprovementGoalType';
    id: string;
    name: string;
    color: string;
    description?: string | null;
    learnMoreUrl?: string | null;
    unit: ImprovementGoalUnit;
    categories: Array<ImprovementGoalTypeCategory>;
    subCategory?: string | null;
    targetType: ImprovementGoalTypeTargetType;
    aggregationPeriod: ImprovementGoalAggregationPeriod;
    aggregationFunction: ImprovementGoalAggregationFunction;
    isPollable: boolean;
    minValue?: number | null;
    maxValue?: number | null;
  };
};

export type ExportTeamInsightToConfluenceMutationVariables = Exact<{
  teamInsightId: Scalars['ID'];
  confluenceSpaceKey: Scalars['String'];
}>;

export type ExportTeamInsightToConfluenceMutation = {
  __typename?: 'Mutation';
  exportTeamInsightToConfluence: {
    __typename?: 'ConfluencePageURI';
    hostname: string;
    spaceKey: string;
    contentId: string;
  };
};

export type LinkTeamInsightActionItemOriginMutationVariables = Exact<{
  teamInsightSolutionId: Scalars['ID'];
  actionItemId: Scalars['ID'];
}>;

export type LinkTeamInsightActionItemOriginMutation = {
  __typename?: 'Mutation';
  linkTeamInsightActionItemOrigin: {
    __typename?: 'TeamInsightFactCauseSolution';
    id: string;
    solutions?: Array<
      Array<
        | {
            __typename?: 'TeamInsightFactCauseSolutionSolutionActionItem';
            originatedActionItem?: {
              __typename?: 'ActionItem';
              id: string;
              title: string;
              createdAt?: number | null;
            } | null;
          }
        | { __typename?: 'TeamInsightFactCauseSolutionSolutionGoal' }
      >
    > | null;
  };
};

export type MarkTeamInsightAsSeenMutationVariables = Exact<{
  teamInsightId: Scalars['ID'];
}>;

export type MarkTeamInsightAsSeenMutation = {
  __typename?: 'Mutation';
  markTeamInsightAsSeen: {
    __typename?: 'TeamInsightFactCauseSolution';
    id: string;
  };
};

export type RateTeamInsightMutationVariables = Exact<{
  teamInsightId: Scalars['ID'];
  rating: QualityName;
}>;

export type RateTeamInsightMutation = {
  __typename?: 'Mutation';
  rateTeamInsight: {
    __typename?: 'TeamInsightFactCauseSolution';
    id: string;
    rated: boolean;
  };
};

export type TeamInsightByIdQueryVariables = Exact<{
  teamInsightId: Scalars['ID'];
}>;

export type TeamInsightByIdQuery = {
  __typename?: 'Query';
  teamInsightById: {
    __typename?: 'TeamInsightFactCauseSolution';
    id: string;
    seen: boolean;
    rated: boolean;
    date: number;
    team: { __typename?: 'Team'; id: string; name: string; type: TeamType };
    fact:
      | {
          __typename?: 'TeamInsightFactCauseSolutionFactMetricBelowRecommended';
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionFactMetricDeterioration';
          id: string;
          type: {
            __typename?: 'TeamInsightMetricType';
            name: string;
            unit: ImprovementGoalUnit;
            providerCategory?: MetricsProviderCategory | null;
            ideal: Trend;
          };
          history: Array<{
            __typename?: 'TimeSeries';
            date: number;
            value?: number | null;
          }>;
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionFactMetricImprovement';
          id: string;
          type: {
            __typename?: 'TeamInsightMetricType';
            name: string;
            unit: ImprovementGoalUnit;
            providerCategory?: MetricsProviderCategory | null;
            ideal: Trend;
          };
          history: Array<{
            __typename?: 'TimeSeries';
            date: number;
            value?: number | null;
          }>;
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionFactMetricInadequacy';
          id: string;
          recommended: number;
          type: {
            __typename?: 'TeamInsightMetricType';
            name: string;
            unit: ImprovementGoalUnit;
            providerCategory?: MetricsProviderCategory | null;
            ideal: Trend;
          };
          history: Array<{
            __typename?: 'TimeSeries';
            date: number;
            value?: number | null;
          }>;
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionFactOutlierWorkItemTopic';
          id: string;
          topic: string;
        };
    causes?: Array<
      | {
          __typename?: 'TeamInsightFactCauseSolutionCauseMetricDeterioration';
          id: string;
          type: {
            __typename?: 'TeamInsightMetricType';
            name: string;
            unit: ImprovementGoalUnit;
            providerCategory?: MetricsProviderCategory | null;
            ideal: Trend;
          };
          history: Array<{
            __typename?: 'TimeSeries';
            date: number;
            value?: number | null;
          }>;
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequests';
          id: string;
          topic?: string | null;
          pullRequests: Array<{
            __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequest';
            title: string;
            externalUrl?: string | null;
            slug?: string | null;
            origin?: SourceControlOrigin | null;
            wipTimeMs?: number | null;
          }>;
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionCauseOutlierWorkItems';
          id: string;
          topic?: string | null;
          workItems: Array<{
            __typename?: 'TeamInsightFactCauseSolutionCauseWorkItem';
            slug?: string | null;
            title: string;
            wipTimeMs: number;
            origin?: WorkItemOrigin | null;
            url?: string | null;
          }>;
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionCauseTopic';
          id: string;
          topic?: string | null;
          cards: Array<{
            __typename?: 'TeamInsightFactCauseSolutionCauseTopicCard';
            text: string;
            badgeColor: string;
            badgeText: string;
            retroName: string;
            retroDate: number;
          }>;
        }
      | {
          __typename?: 'TeamInsightFactCauseSolutionCauseUnreviewedPullRequests';
          id: string;
          topic?: string | null;
          pullRequests: Array<{
            __typename?: 'TeamInsightFactCauseSolutionCausePullRequest';
            title: string;
            description?: string | null;
            externalUrl?: string | null;
            slug?: string | null;
            createdAt: string;
            origin?: SourceControlOrigin | null;
          }>;
        }
    > | null;
    solutions?: Array<
      Array<
        | {
            __typename?: 'TeamInsightFactCauseSolutionSolutionActionItem';
            id: string;
            text: string;
            originatedActionItem?: {
              __typename?: 'ActionItem';
              id: string;
              title: string;
              description?: string | null;
              teamId: string;
              resolvedAt?: number | null;
              dueDate?: number | null;
              authorId: string;
              boardId?: string | null;
              status: ActionItemStatus;
              archived: boolean;
              createdAt?: number | null;
              children: Array<{
                __typename?: 'ActionItemChild';
                id: string;
                text: string;
              }>;
              author?: { __typename?: 'Author'; name: string } | null;
              assignees?: Array<{
                __typename?: 'User';
                id: string;
                name?: string | null;
              } | null> | null;
              improvementGoals?: Array<{
                __typename?: 'ImprovementGoal';
                id: string;
                type: { __typename?: 'ImprovementGoalType'; name: string };
              } | null> | null;
              jiraIssueLink?: {
                __typename?: 'ActionItemJiraLink';
                jiraIssueId?: string | null;
                jiraIssueUrl?: string | null;
                jiraIssueKey?: string | null;
                jiraProjectKey?: string | null;
              } | null;
              comments: Array<{
                __typename?: 'ActionItemComment';
                id: string;
                text: string;
                actionItemId: string;
                createdAt: number;
                archived: boolean;
                author: {
                  __typename?: 'User';
                  id: string;
                  name?: string | null;
                };
              }>;
            } | null;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionSolutionGoal';
            id: string;
            type: {
              __typename?: 'ImprovementGoalType';
              id: string;
              name: string;
              color: string;
              description?: string | null;
              learnMoreUrl?: string | null;
              unit: ImprovementGoalUnit;
              categories: Array<ImprovementGoalTypeCategory>;
              subCategory?: string | null;
              targetType: ImprovementGoalTypeTargetType;
              aggregationPeriod: ImprovementGoalAggregationPeriod;
              aggregationFunction: ImprovementGoalAggregationFunction;
              isPollable: boolean;
              minValue?: number | null;
              maxValue?: number | null;
            };
          }
      >
    > | null;
  };
};

export type TeamInsightUpdateSubscriptionVariables = Exact<{
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
}>;

export type TeamInsightUpdateSubscription = {
  __typename?: 'Subscription';
  teamInsightUpdate: {
    __typename?: 'Team';
    id: string;
    insights: Array<{
      __typename?: 'TeamInsightFactCauseSolution';
      id: string;
      seen: boolean;
      rated: boolean;
      date: number;
      team: { __typename?: 'Team'; id: string; name: string; type: TeamType };
      fact:
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricBelowRecommended';
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricDeterioration';
            id: string;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricImprovement';
            id: string;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricInadequacy';
            id: string;
            recommended: number;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactOutlierWorkItemTopic';
            id: string;
            topic: string;
          };
      causes?: Array<
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseMetricDeterioration';
            id: string;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequests';
            id: string;
            topic?: string | null;
            pullRequests: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequest';
              title: string;
              externalUrl?: string | null;
              slug?: string | null;
              origin?: SourceControlOrigin | null;
              wipTimeMs?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseOutlierWorkItems';
            id: string;
            topic?: string | null;
            workItems: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCauseWorkItem';
              slug?: string | null;
              title: string;
              wipTimeMs: number;
              origin?: WorkItemOrigin | null;
              url?: string | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseTopic';
            id: string;
            topic?: string | null;
            cards: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCauseTopicCard';
              text: string;
              badgeColor: string;
              badgeText: string;
              retroName: string;
              retroDate: number;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseUnreviewedPullRequests';
            id: string;
            topic?: string | null;
            pullRequests: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCausePullRequest';
              title: string;
              description?: string | null;
              externalUrl?: string | null;
              slug?: string | null;
              createdAt: string;
              origin?: SourceControlOrigin | null;
            }>;
          }
      > | null;
      solutions?: Array<
        Array<
          | {
              __typename?: 'TeamInsightFactCauseSolutionSolutionActionItem';
              id: string;
              text: string;
              originatedActionItem?: {
                __typename?: 'ActionItem';
                id: string;
                title: string;
                description?: string | null;
                teamId: string;
                resolvedAt?: number | null;
                dueDate?: number | null;
                authorId: string;
                boardId?: string | null;
                status: ActionItemStatus;
                archived: boolean;
                createdAt?: number | null;
                children: Array<{
                  __typename?: 'ActionItemChild';
                  id: string;
                  text: string;
                }>;
                author?: { __typename?: 'Author'; name: string } | null;
                assignees?: Array<{
                  __typename?: 'User';
                  id: string;
                  name?: string | null;
                } | null> | null;
                improvementGoals?: Array<{
                  __typename?: 'ImprovementGoal';
                  id: string;
                  type: { __typename?: 'ImprovementGoalType'; name: string };
                } | null> | null;
                jiraIssueLink?: {
                  __typename?: 'ActionItemJiraLink';
                  jiraIssueId?: string | null;
                  jiraIssueUrl?: string | null;
                  jiraIssueKey?: string | null;
                  jiraProjectKey?: string | null;
                } | null;
                comments: Array<{
                  __typename?: 'ActionItemComment';
                  id: string;
                  text: string;
                  actionItemId: string;
                  createdAt: number;
                  archived: boolean;
                  author: {
                    __typename?: 'User';
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionSolutionGoal';
              id: string;
              type: {
                __typename?: 'ImprovementGoalType';
                id: string;
                name: string;
                color: string;
                description?: string | null;
                learnMoreUrl?: string | null;
                unit: ImprovementGoalUnit;
                categories: Array<ImprovementGoalTypeCategory>;
                subCategory?: string | null;
                targetType: ImprovementGoalTypeTargetType;
                aggregationPeriod: ImprovementGoalAggregationPeriod;
                aggregationFunction: ImprovementGoalAggregationFunction;
                isPollable: boolean;
                minValue?: number | null;
                maxValue?: number | null;
              };
            }
        >
      > | null;
    }>;
  };
};

export type InstalledOrganizationIntegrationsFragment = {
  __typename: 'InstalledOrganizationIntegrations';
  slack: boolean;
  jira: boolean;
  github: boolean;
  bitbucket: boolean;
  slackHmacPublicDigest: string;
};

export type InstallBitbucketMetricsIntegrationMutationVariables = Exact<{
  state: Scalars['String'];
}>;

export type InstallBitbucketMetricsIntegrationMutation = {
  __typename?: 'Mutation';
  installBitbucketMetricsIntegration: {
    __typename?: 'Organization';
    id?: string | null;
  };
};

export type InstallJiraMetricsIntegrationMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type InstallJiraMetricsIntegrationMutation = {
  __typename?: 'Mutation';
  installJiraMetricsIntegration: {
    __typename?: 'Organization';
    id?: string | null;
  };
};

export type SetupGithubIntegrationMutationVariables = Exact<{
  state: Scalars['String'];
}>;

export type SetupGithubIntegrationMutation = {
  __typename?: 'Mutation';
  installGithubIntegration: { __typename?: 'Organization'; id?: string | null };
};

export type UninstallBitbucketMetricsIntegrationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type UninstallBitbucketMetricsIntegrationMutation = {
  __typename?: 'Mutation';
  uninstallBitbucketMetricsIntegration: {
    __typename?: 'Organization';
    id?: string | null;
  };
};

export type UninstallGithubIntegrationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type UninstallGithubIntegrationMutation = {
  __typename?: 'Mutation';
  uninstallGithubIntegration: {
    __typename?: 'Organization';
    id?: string | null;
  };
};

export type UninstallJiraMetricsIntegrationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type UninstallJiraMetricsIntegrationMutation = {
  __typename?: 'Mutation';
  uninstallJiraMetricsIntegration: {
    __typename?: 'Organization';
    id?: string | null;
  };
};

export type UninstallSlackIntegrationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type UninstallSlackIntegrationMutation = {
  __typename?: 'Mutation';
  uninstallSlackIntegration: boolean;
};

export type UpdateLinkedBitbucketRepositoriesMutationVariables = Exact<{
  teamId: Scalars['ID'];
  bitbucketRepositoryIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type UpdateLinkedBitbucketRepositoriesMutation = {
  __typename?: 'Mutation';
  updateLinkedBitbucketRepositories: {
    __typename?: 'Team';
    id: string;
    linkedBitbucketRepositories: Array<{
      __typename?: 'BitbucketRepository';
      id: string;
      name: string;
    }>;
  };
};

export type UpdateLinkedGithubRepositoriesMutationVariables = Exact<{
  teamId: Scalars['ID'];
  githubRepositoryIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type UpdateLinkedGithubRepositoriesMutation = {
  __typename?: 'Mutation';
  updateLinkedGithubRepositories: {
    __typename?: 'Team';
    id: string;
    linkedGithubRepositories: Array<{
      __typename?: 'GithubRepository';
      id: string;
      name: string;
    }>;
  };
};

export type UpdateLinkedJiraBoardsMutationVariables = Exact<{
  teamId: Scalars['ID'];
  jiraBoardIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type UpdateLinkedJiraBoardsMutation = {
  __typename?: 'Mutation';
  updateLinkedJiraBoards: {
    __typename?: 'Team';
    id: string;
    linkedJiraBoards: Array<{
      __typename?: 'LinkedJiraBoard';
      id: string;
      name: string;
    }>;
  };
};

export type InstalledOrgIntegrationsQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;

export type InstalledOrgIntegrationsQuery = {
  __typename?: 'Query';
  installedOrganizationIntegrations: {
    __typename: 'InstalledOrganizationIntegrations';
    slack: boolean;
    jira: boolean;
    github: boolean;
    bitbucket: boolean;
    slackHmacPublicDigest: string;
  };
};

export type InstalledUserIntegrationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type InstalledUserIntegrationsQuery = {
  __typename?: 'Query';
  installedUserIntegrations: {
    __typename?: 'InstalledUserIntegrations';
    confluence: boolean;
    jira: boolean;
    miro: boolean;
    trello: boolean;
  };
};

export type LinkableBitbucketRepositoriesQueryVariables = Exact<{
  teamId: Scalars['ID'];
  search: Scalars['String'];
}>;

export type LinkableBitbucketRepositoriesQuery = {
  __typename?: 'Query';
  team?: {
    __typename?: 'Team';
    id: string;
    searchLinkableBitbucketRepositories: Array<{
      __typename?: 'BitbucketRepository';
      id: string;
      name: string;
    }>;
  } | null;
};

export type LinkableGithubRepositoriesQueryVariables = Exact<{
  teamId: Scalars['ID'];
  search: Scalars['String'];
}>;

export type LinkableGithubRepositoriesQuery = {
  __typename?: 'Query';
  team?: {
    __typename?: 'Team';
    id: string;
    searchLinkableGithubRepositories: Array<{
      __typename?: 'GithubRepository';
      id: string;
      name: string;
    }>;
  } | null;
};

export type LinkableJiraBoardsQueryVariables = Exact<{
  teamId: Scalars['ID'];
  search: Scalars['String'];
}>;

export type LinkableJiraBoardsQuery = {
  __typename?: 'Query';
  team?: {
    __typename?: 'Team';
    id: string;
    linkableJiraBoards: Array<{
      __typename?: 'LinkableJiraBoard';
      id: string;
      name: string;
      isSoftwareDevProject: boolean;
    }>;
  } | null;
};

export type LinkedBitbucketRepositoriesQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;

export type LinkedBitbucketRepositoriesQuery = {
  __typename?: 'Query';
  team?: {
    __typename?: 'Team';
    id: string;
    linkedBitbucketRepositories: Array<{
      __typename?: 'BitbucketRepository';
      id: string;
      name: string;
    }>;
  } | null;
};

export type LinkedGithubRepositoriesQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;

export type LinkedGithubRepositoriesQuery = {
  __typename?: 'Query';
  team?: {
    __typename?: 'Team';
    id: string;
    linkedGithubRepositories: Array<{
      __typename?: 'GithubRepository';
      id: string;
      name: string;
    }>;
  } | null;
};

export type LinkedJiraBoardsQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;

export type LinkedJiraBoardsQuery = {
  __typename?: 'Query';
  team?: {
    __typename?: 'Team';
    id: string;
    linkedJiraBoards: Array<{
      __typename?: 'LinkedJiraBoard';
      id: string;
      name: string;
    }>;
  } | null;
};

export type OrgIntegrationsChangedSubscriptionVariables = Exact<{
  organizationId: Scalars['ID'];
}>;

export type OrgIntegrationsChangedSubscription = {
  __typename?: 'Subscription';
  installedOrganizationIntegrationsUpdate: {
    __typename: 'InstalledOrganizationIntegrations';
    slack: boolean;
    jira: boolean;
    github: boolean;
    bitbucket: boolean;
    slackHmacPublicDigest: string;
  };
};

export type LivePollFragmentFragment = {
  __typename?: 'LivePoll';
  id: string;
  title: string;
  boardId: string;
  answeredUserIds: Array<string>;
  skippedUserIds: Array<string>;
  status: LivePollUserStatus;
  result?: number | null;
  createdAt: number;
  board?: {
    __typename?: 'Board';
    id: string;
    onlineUserIds: Array<string | null>;
  } | null;
  improvementGoal?: {
    __typename?: 'ImprovementGoal';
    id: string;
    target: number;
    type: {
      __typename?: 'ImprovementGoalType';
      id: string;
      name: string;
      color: string;
      targetType: ImprovementGoalTypeTargetType;
    };
  } | null;
};

export type DiscardLivePollMutationVariables = Exact<{
  livePollId: Scalars['ID'];
}>;

export type DiscardLivePollMutation = {
  __typename?: 'Mutation';
  discardLivePollResult: {
    __typename?: 'LivePoll';
    id: string;
    status: LivePollUserStatus;
    result?: number | null;
  };
};

export type FinishLivePollsByBoardIdMutationVariables = Exact<{
  boardId: Scalars['ID'];
}>;

export type FinishLivePollsByBoardIdMutation = {
  __typename?: 'Mutation';
  finishLivePollsByBoardId: Array<{
    __typename?: 'LivePoll';
    id: string;
    status: LivePollUserStatus;
    result?: number | null;
  }>;
};

export type StartGoalsLivePollMutationVariables = Exact<{
  improvementGoalIds: Array<Scalars['ID']> | Scalars['ID'];
  boardId: Scalars['ID'];
}>;

export type StartGoalsLivePollMutation = {
  __typename?: 'Mutation';
  startGoalsLivePoll: Array<{
    __typename?: 'LivePoll';
    id: string;
    title: string;
    boardId: string;
    answeredUserIds: Array<string>;
    skippedUserIds: Array<string>;
    status: LivePollUserStatus;
    result?: number | null;
    createdAt: number;
    board?: {
      __typename?: 'Board';
      id: string;
      onlineUserIds: Array<string | null>;
    } | null;
    improvementGoal?: {
      __typename?: 'ImprovementGoal';
      id: string;
      target: number;
      type: {
        __typename?: 'ImprovementGoalType';
        id: string;
        name: string;
        color: string;
        targetType: ImprovementGoalTypeTargetType;
      };
    } | null;
  }>;
};

export type SubmitLivePollAnswerMutationVariables = Exact<{
  livePollId: Scalars['ID'];
  answer: Scalars['Int'];
}>;

export type SubmitLivePollAnswerMutation = {
  __typename?: 'Mutation';
  submitLivePollAnswer: {
    __typename?: 'LivePoll';
    id: string;
    status: LivePollUserStatus;
    answeredUserIds: Array<string>;
  };
};

export type SubmitLivePollSkipAnswerMutationVariables = Exact<{
  livePollId: Scalars['ID'];
  reason: LivePollSkipReason;
}>;

export type SubmitLivePollSkipAnswerMutation = {
  __typename?: 'Mutation';
  submitLivePollSkipAnswer: {
    __typename?: 'LivePoll';
    id: string;
    status: LivePollUserStatus;
    skippedUserIds: Array<string>;
  };
};

export type UndoLivePollAnswerMutationVariables = Exact<{
  livePollId: Scalars['ID'];
}>;

export type UndoLivePollAnswerMutation = {
  __typename?: 'Mutation';
  removeLivePollAnswer: {
    __typename?: 'LivePoll';
    id: string;
    status: LivePollUserStatus;
    skippedUserIds: Array<string>;
    answeredUserIds: Array<string>;
  };
};

export type UpdateLivePollStatusMutationVariables = Exact<{
  livePollId: Scalars['ID'];
  pollStatus: LivePollStatus;
}>;

export type UpdateLivePollStatusMutation = {
  __typename?: 'Mutation';
  updateLivePollStatus: {
    __typename?: 'LivePoll';
    id: string;
    status: LivePollUserStatus;
  };
};

export type LivePollsByBoardIdQueryVariables = Exact<{
  boardId: Scalars['ID'];
}>;

export type LivePollsByBoardIdQuery = {
  __typename?: 'Query';
  livePollsByBoardId: Array<{
    __typename?: 'LivePoll';
    id: string;
    title: string;
    boardId: string;
    answeredUserIds: Array<string>;
    skippedUserIds: Array<string>;
    status: LivePollUserStatus;
    result?: number | null;
    createdAt: number;
    board?: {
      __typename?: 'Board';
      id: string;
      onlineUserIds: Array<string | null>;
    } | null;
    improvementGoal?: {
      __typename?: 'ImprovementGoal';
      id: string;
      target: number;
      type: {
        __typename?: 'ImprovementGoalType';
        id: string;
        name: string;
        color: string;
        targetType: ImprovementGoalTypeTargetType;
      };
    } | null;
  }>;
};

export type LivePollUpdatesByBoardIdSubscriptionVariables = Exact<{
  boardId: Scalars['ID'];
  userId: Scalars['ID'];
}>;

export type LivePollUpdatesByBoardIdSubscription = {
  __typename?: 'Subscription';
  livePollUpdatesByBoardId: Array<{
    __typename?: 'LivePoll';
    id: string;
    title: string;
    boardId: string;
    answeredUserIds: Array<string>;
    skippedUserIds: Array<string>;
    status: LivePollUserStatus;
    result?: number | null;
    createdAt: number;
    board?: {
      __typename?: 'Board';
      id: string;
      onlineUserIds: Array<string | null>;
    } | null;
    improvementGoal?: {
      __typename?: 'ImprovementGoal';
      id: string;
      target: number;
      type: {
        __typename?: 'ImprovementGoalType';
        id: string;
        name: string;
        color: string;
        targetType: ImprovementGoalTypeTargetType;
      };
    } | null;
  }>;
};

export type InFlightWorkItemFragmentFragment = {
  __typename?: 'InFlightWorkItem';
  title: string;
  slug: string;
  origin: WorkItemOrigin;
  externalUrl: string;
  externalStatusName: string;
  createdAt: number;
  wipTimeMs: number;
  statusAgeQuality: QualityName;
  cycleTimeQuality: QualityName;
  staleForMs: number;
  timeLeftToExternalStatusCycleTimeMs: number;
  timeLeftToOverallCycleTimeMs: number;
};

export type FlowMetricsQueryVariables = Exact<{
  teamId: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  utcOffsetMs: Scalars['Int'];
}>;

export type FlowMetricsQuery = {
  __typename?: 'Query';
  flowMetrics: {
    __typename?: 'FlowMetrics';
    isProcessing: boolean;
    workItemsPerDay: number;
    cycleTimeMs: number;
    leadTimeMs: number;
    predictabilityQuality: QualityName;
    lastIngestionAt?: number | null;
    statuses: Array<{
      __typename?: 'WorkItemTimeSpentInExternalStatus';
      name: string;
      timeSpentMs: number;
    }>;
    cycleTimeHistogram: Array<{
      __typename?: 'HistogramData';
      value: number;
      from: number;
      to: number;
    }>;
    insight?: {
      __typename: 'FlowInsightForecast';
      cycleTimeMs: number;
      itemsOverCycleTime: Array<{
        __typename?: 'WorkItemWithLifecycle';
        slug: string;
        wipTimeMs: number;
        externalUrl: string;
      }>;
      itemsNearCycleTime: Array<{
        __typename?: 'WorkItemWithLifecycle';
        slug: string;
        wipTimeMs: number;
        externalUrl: string;
      }>;
    } | null;
  };
};

export type InFlightWorkItemsQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;

export type InFlightWorkItemsQuery = {
  __typename?: 'Query';
  inFlightWorkItems: {
    __typename?: 'InFlightWorkItemsResult';
    statusNames: Array<string>;
    workItems: Array<{
      __typename?: 'InFlightWorkItem';
      title: string;
      slug: string;
      origin: WorkItemOrigin;
      externalUrl: string;
      externalStatusName: string;
      createdAt: number;
      wipTimeMs: number;
      statusAgeQuality: QualityName;
      cycleTimeQuality: QualityName;
      staleForMs: number;
      timeLeftToExternalStatusCycleTimeMs: number;
      timeLeftToOverallCycleTimeMs: number;
    }>;
    insight?: {
      __typename: 'FlowInsightForecast';
      cycleTimeMs: number;
      itemsOverCycleTime: Array<{
        __typename?: 'WorkItemWithLifecycle';
        slug: string;
        wipTimeMs: number;
        externalUrl: string;
      }>;
      itemsNearCycleTime: Array<{
        __typename?: 'WorkItemWithLifecycle';
        slug: string;
        wipTimeMs: number;
        externalUrl: string;
      }>;
    } | null;
  };
};

export type SourceControlMetricsQueryVariables = Exact<{
  teamId: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  utcOffsetMs: Scalars['Int'];
}>;

export type SourceControlMetricsQuery = {
  __typename?: 'Query';
  sourceControlMetrics: {
    __typename?: 'SourceControlMetrics';
    isProcessing: boolean;
    prsPerDay: number;
    cycleTimeMs: number;
    pickupTimeMs: number;
    approvalTimeMs: number;
    mergeTimeMs: number;
    consistencyQuality: QualityName;
    reviewLeaderboard: Array<{
      __typename?: 'SourceControlReviewerLeaderboardItem';
      userName: string;
      count: number;
    }>;
    workItemLeaderboard: Array<{
      __typename?: 'SourceControlReviewerLeaderboardItem';
      userName: string;
      count: number;
    }>;
    insight?: {
      __typename: 'SourceControlInsightReviewPercent';
      picUrl?: string | null;
      userName: string;
      value: number;
    } | null;
  };
};

export type TeamMetricsOverTimeQueryVariables = Exact<{
  teamId: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  utcOffsetMs: Scalars['Int'];
  timeWindow: TimeWindow;
}>;

export type TeamMetricsOverTimeQuery = {
  __typename?: 'Query';
  teamMetricsOverTime: {
    __typename?: 'TeamMetricsOverTime';
    pullRequestsOpen: Array<{
      __typename?: 'TimeSeries';
      date: number;
      value?: number | null;
    }>;
    pullRequestsMergedWithoutReview: Array<{
      __typename?: 'TimeSeries';
      date: number;
      value?: number | null;
    }>;
  };
};

export type OrganizationFragmentFragment = {
  __typename?: 'Organization';
  id?: string | null;
  adminOnlyTeamCreation: boolean;
  ownerId?: string | null;
  name?: string | null;
  userCount?: number | null;
  prepaidSeatCount: number;
  numberOfTeams?: number | null;
  numberOfPrograms?: number | null;
  isExceedingSeats?: boolean | null;
  numberOfRetrospectivesHeld?: number | null;
  averageTimeBetweenRetrospectives?: number | null;
  inviteToken?: string | null;
  domains: Array<string>;
  hasSSO?: boolean | null;
  status?: PaymentStatus | null;
  trialEndsAt?: number | null;
  owner?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
  } | null;
  team?: {
    __typename?: 'Team';
    id: string;
    createdAt?: number | null;
    name: string;
    inviteToken?: string | null;
    archived?: boolean | null;
    type: TeamType;
    organizationId?: string | null;
    userCount?: number | null;
    teamCount: number;
    maxActiveImprovementGoals: number;
    watchers: Array<{ __typename?: 'User'; id: string }>;
    settings?: {
      __typename?: 'TeamSettings';
      id: string;
      defaultVoteCount: number;
      defaultPublicRetrospectives: boolean;
      defaultGuidedRetrospectives: boolean;
      tooltipHintsEnabled: boolean;
      businessType: TeamBusinessType;
      jiraMetricsEnabled: DisableFeatureState;
      githubMetricsEnabled: DisableFeatureState;
      bitbucketMetricsEnabled: DisableFeatureState;
      cardCategories?: Array<{
        __typename?: 'CardCategory';
        name: string;
        color: string;
      } | null> | null;
    } | null;
    members: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    }>;
    children: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
      type: TeamType;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
    }>;
    admin?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    } | null;
    parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
  } | null;
  plan?: {
    __typename?: 'Plan';
    slug?: string | null;
    displayName?: string | null;
    blockExceedingSeats?: boolean | null;
    trialUserTimeSeconds?: number | null;
    programs?: boolean | null;
    integrationMicrosoftTeams?: boolean | null;
    integrationJira?: boolean | null;
    supportLevel?: SupportLevel | null;
    sso?: boolean | null;
  } | null;
};

export type PlanFragmentFragment = {
  __typename?: 'Plan';
  slug?: string | null;
  displayName?: string | null;
  blockExceedingSeats?: boolean | null;
  trialUserTimeSeconds?: number | null;
  programs?: boolean | null;
  integrationMicrosoftTeams?: boolean | null;
  integrationJira?: boolean | null;
  supportLevel?: SupportLevel | null;
  sso?: boolean | null;
};

export type CancelSubscriptionMutationVariables = Exact<{
  organizationId: Scalars['String'];
}>;

export type CancelSubscriptionMutation = {
  __typename?: 'Mutation';
  cancelSubscription?: {
    __typename?: 'Organization';
    id?: string | null;
    status?: PaymentStatus | null;
    userCount?: number | null;
    prepaidSeatCount: number;
    isExceedingSeats?: boolean | null;
    trialEndsAt?: number | null;
    plan?: {
      __typename?: 'Plan';
      slug?: string | null;
      displayName?: string | null;
      blockExceedingSeats?: boolean | null;
      trialUserTimeSeconds?: number | null;
      programs?: boolean | null;
      integrationMicrosoftTeams?: boolean | null;
      integrationJira?: boolean | null;
      supportLevel?: SupportLevel | null;
      sso?: boolean | null;
    } | null;
  } | null;
};

export type CreateOrganizationMutationVariables = Exact<{
  orgName: Scalars['String'];
}>;

export type CreateOrganizationMutation = {
  __typename?: 'Mutation';
  createOrganization?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    role?: MembershipRole | null;
    betaFeatures: Array<BetaFeature>;
    announcementsModalSeen?: boolean | null;
    lastActiveAt?: number | null;
    createdAt?: number | null;
    verified?: boolean | null;
    facilitatorLabelsShown?: boolean | null;
    ssoControlled?: boolean | null;
    provider?: string | null;
    team?: {
      __typename?: 'Team';
      betaFeatures: Array<BetaFeature>;
      id: string;
      createdAt?: number | null;
      name: string;
      inviteToken?: string | null;
      archived?: boolean | null;
      type: TeamType;
      organizationId?: string | null;
      userCount?: number | null;
      teamCount: number;
      maxActiveImprovementGoals: number;
      watchers: Array<{ __typename?: 'User'; id: string }>;
      settings?: {
        __typename?: 'TeamSettings';
        id: string;
        defaultVoteCount: number;
        defaultPublicRetrospectives: boolean;
        defaultGuidedRetrospectives: boolean;
        tooltipHintsEnabled: boolean;
        businessType: TeamBusinessType;
        jiraMetricsEnabled: DisableFeatureState;
        githubMetricsEnabled: DisableFeatureState;
        bitbucketMetricsEnabled: DisableFeatureState;
        cardCategories?: Array<{
          __typename?: 'CardCategory';
          name: string;
          color: string;
        } | null> | null;
      } | null;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
      children: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
        type: TeamType;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
      }>;
      admin?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
      insights: Array<{
        __typename?: 'TeamInsightFactCauseSolution';
        id: string;
        seen: boolean;
        rated: boolean;
        date: number;
        team: { __typename?: 'Team'; id: string; name: string; type: TeamType };
        fact:
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricBelowRecommended';
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricDeterioration';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricImprovement';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricInadequacy';
              id: string;
              recommended: number;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactOutlierWorkItemTopic';
              id: string;
              topic: string;
            };
        causes?: Array<
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseMetricDeterioration';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequests';
              id: string;
              topic?: string | null;
              pullRequests: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequest';
                title: string;
                externalUrl?: string | null;
                slug?: string | null;
                origin?: SourceControlOrigin | null;
                wipTimeMs?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseOutlierWorkItems';
              id: string;
              topic?: string | null;
              workItems: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseWorkItem';
                slug?: string | null;
                title: string;
                wipTimeMs: number;
                origin?: WorkItemOrigin | null;
                url?: string | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseTopic';
              id: string;
              topic?: string | null;
              cards: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseTopicCard';
                text: string;
                badgeColor: string;
                badgeText: string;
                retroName: string;
                retroDate: number;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseUnreviewedPullRequests';
              id: string;
              topic?: string | null;
              pullRequests: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCausePullRequest';
                title: string;
                description?: string | null;
                externalUrl?: string | null;
                slug?: string | null;
                createdAt: string;
                origin?: SourceControlOrigin | null;
              }>;
            }
        > | null;
        solutions?: Array<
          Array<
            | {
                __typename?: 'TeamInsightFactCauseSolutionSolutionActionItem';
                id: string;
                text: string;
                originatedActionItem?: {
                  __typename?: 'ActionItem';
                  id: string;
                  title: string;
                  description?: string | null;
                  teamId: string;
                  resolvedAt?: number | null;
                  dueDate?: number | null;
                  authorId: string;
                  boardId?: string | null;
                  status: ActionItemStatus;
                  archived: boolean;
                  createdAt?: number | null;
                  children: Array<{
                    __typename?: 'ActionItemChild';
                    id: string;
                    text: string;
                  }>;
                  author?: { __typename?: 'Author'; name: string } | null;
                  assignees?: Array<{
                    __typename?: 'User';
                    id: string;
                    name?: string | null;
                  } | null> | null;
                  improvementGoals?: Array<{
                    __typename?: 'ImprovementGoal';
                    id: string;
                    type: { __typename?: 'ImprovementGoalType'; name: string };
                  } | null> | null;
                  jiraIssueLink?: {
                    __typename?: 'ActionItemJiraLink';
                    jiraIssueId?: string | null;
                    jiraIssueUrl?: string | null;
                    jiraIssueKey?: string | null;
                    jiraProjectKey?: string | null;
                  } | null;
                  comments: Array<{
                    __typename?: 'ActionItemComment';
                    id: string;
                    text: string;
                    actionItemId: string;
                    createdAt: number;
                    archived: boolean;
                    author: {
                      __typename?: 'User';
                      id: string;
                      name?: string | null;
                    };
                  }>;
                } | null;
              }
            | {
                __typename?: 'TeamInsightFactCauseSolutionSolutionGoal';
                id: string;
                type: {
                  __typename?: 'ImprovementGoalType';
                  id: string;
                  name: string;
                  color: string;
                  description?: string | null;
                  learnMoreUrl?: string | null;
                  unit: ImprovementGoalUnit;
                  categories: Array<ImprovementGoalTypeCategory>;
                  subCategory?: string | null;
                  targetType: ImprovementGoalTypeTargetType;
                  aggregationPeriod: ImprovementGoalAggregationPeriod;
                  aggregationFunction: ImprovementGoalAggregationFunction;
                  isPollable: boolean;
                  minValue?: number | null;
                  maxValue?: number | null;
                };
              }
          >
        > | null;
      }>;
    } | null;
    watchedTeams?: Array<{
      __typename?: 'Team';
      id: string;
      createdAt?: number | null;
      name: string;
      inviteToken?: string | null;
      archived?: boolean | null;
      type: TeamType;
      organizationId?: string | null;
      userCount?: number | null;
      teamCount: number;
      maxActiveImprovementGoals: number;
      watchers: Array<{ __typename?: 'User'; id: string }>;
      settings?: {
        __typename?: 'TeamSettings';
        id: string;
        defaultVoteCount: number;
        defaultPublicRetrospectives: boolean;
        defaultGuidedRetrospectives: boolean;
        tooltipHintsEnabled: boolean;
        businessType: TeamBusinessType;
        jiraMetricsEnabled: DisableFeatureState;
        githubMetricsEnabled: DisableFeatureState;
        bitbucketMetricsEnabled: DisableFeatureState;
        cardCategories?: Array<{
          __typename?: 'CardCategory';
          name: string;
          color: string;
        } | null> | null;
      } | null;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
      children: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
        type: TeamType;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
      }>;
      admin?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      betaFeatures: Array<BetaFeature>;
      id?: string | null;
      adminOnlyTeamCreation: boolean;
      ownerId?: string | null;
      name?: string | null;
      userCount?: number | null;
      prepaidSeatCount: number;
      numberOfTeams?: number | null;
      numberOfPrograms?: number | null;
      isExceedingSeats?: boolean | null;
      numberOfRetrospectivesHeld?: number | null;
      averageTimeBetweenRetrospectives?: number | null;
      inviteToken?: string | null;
      domains: Array<string>;
      hasSSO?: boolean | null;
      status?: PaymentStatus | null;
      trialEndsAt?: number | null;
      owner?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      team?: {
        __typename?: 'Team';
        id: string;
        createdAt?: number | null;
        name: string;
        inviteToken?: string | null;
        archived?: boolean | null;
        type: TeamType;
        organizationId?: string | null;
        userCount?: number | null;
        teamCount: number;
        maxActiveImprovementGoals: number;
        watchers: Array<{ __typename?: 'User'; id: string }>;
        settings?: {
          __typename?: 'TeamSettings';
          id: string;
          defaultVoteCount: number;
          defaultPublicRetrospectives: boolean;
          defaultGuidedRetrospectives: boolean;
          tooltipHintsEnabled: boolean;
          businessType: TeamBusinessType;
          jiraMetricsEnabled: DisableFeatureState;
          githubMetricsEnabled: DisableFeatureState;
          bitbucketMetricsEnabled: DisableFeatureState;
          cardCategories?: Array<{
            __typename?: 'CardCategory';
            name: string;
            color: string;
          } | null> | null;
        } | null;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
        children: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
          type: TeamType;
          members: Array<{
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          }>;
        }>;
        admin?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        } | null;
        parents?: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
        }> | null;
      } | null;
      plan?: {
        __typename?: 'Plan';
        slug?: string | null;
        displayName?: string | null;
        blockExceedingSeats?: boolean | null;
        trialUserTimeSeconds?: number | null;
        programs?: boolean | null;
        integrationMicrosoftTeams?: boolean | null;
        integrationJira?: boolean | null;
        supportLevel?: SupportLevel | null;
        sso?: boolean | null;
      } | null;
    } | null;
    memberships?: Array<{
      __typename?: 'OrganizationUserMembership';
      role: MembershipRole;
      trialEndsAt?: number | null;
      archived: boolean;
      joinedAt?: number | null;
      organization: {
        __typename?: 'Organization';
        hasSSO?: boolean | null;
        id?: string | null;
        adminOnlyTeamCreation: boolean;
        ownerId?: string | null;
        name?: string | null;
        userCount?: number | null;
        prepaidSeatCount: number;
        numberOfTeams?: number | null;
        numberOfPrograms?: number | null;
        isExceedingSeats?: boolean | null;
        numberOfRetrospectivesHeld?: number | null;
        averageTimeBetweenRetrospectives?: number | null;
        inviteToken?: string | null;
        domains: Array<string>;
        status?: PaymentStatus | null;
        trialEndsAt?: number | null;
        owner?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        } | null;
        team?: {
          __typename?: 'Team';
          id: string;
          createdAt?: number | null;
          name: string;
          inviteToken?: string | null;
          archived?: boolean | null;
          type: TeamType;
          organizationId?: string | null;
          userCount?: number | null;
          teamCount: number;
          maxActiveImprovementGoals: number;
          watchers: Array<{ __typename?: 'User'; id: string }>;
          settings?: {
            __typename?: 'TeamSettings';
            id: string;
            defaultVoteCount: number;
            defaultPublicRetrospectives: boolean;
            defaultGuidedRetrospectives: boolean;
            tooltipHintsEnabled: boolean;
            businessType: TeamBusinessType;
            jiraMetricsEnabled: DisableFeatureState;
            githubMetricsEnabled: DisableFeatureState;
            bitbucketMetricsEnabled: DisableFeatureState;
            cardCategories?: Array<{
              __typename?: 'CardCategory';
              name: string;
              color: string;
            } | null> | null;
          } | null;
          members: Array<{
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          }>;
          children: Array<{
            __typename?: 'Team';
            id: string;
            name: string;
            type: TeamType;
            members: Array<{
              __typename?: 'User';
              id: string;
              name?: string | null;
              email: string;
            }>;
          }>;
          admin?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          } | null;
          parents?: Array<{
            __typename?: 'Team';
            id: string;
            name: string;
          }> | null;
        } | null;
        plan?: {
          __typename?: 'Plan';
          slug?: string | null;
          displayName?: string | null;
          blockExceedingSeats?: boolean | null;
          trialUserTimeSeconds?: number | null;
          programs?: boolean | null;
          integrationMicrosoftTeams?: boolean | null;
          integrationJira?: boolean | null;
          supportLevel?: SupportLevel | null;
          sso?: boolean | null;
        } | null;
      };
    }> | null;
  } | null;
};

export type RemoveOrgMembershipMutationVariables = Exact<{
  userId: Scalars['ID'];
  organizationId: Scalars['ID'];
}>;

export type RemoveOrgMembershipMutation = {
  __typename?: 'Mutation';
  removeMembership: {
    __typename?: 'OrganizationUserMembership';
    role: MembershipRole;
    trialEndsAt?: number | null;
    archived: boolean;
    joinedAt?: number | null;
  };
};

export type SubmitOnboardingSurveyMutationVariables = Exact<{
  questions:
    | Array<InputMaybe<QuestionAnswerInput>>
    | InputMaybe<QuestionAnswerInput>;
}>;

export type SubmitOnboardingSurveyMutation = {
  __typename?: 'Mutation';
  organizationMoreInfo?: Array<{
    __typename?: 'QuestionAnswer';
    id?: string | null;
  } | null> | null;
};

export type BillingDetailsQueryVariables = Exact<{
  organizationId: Scalars['String'];
}>;

export type BillingDetailsQuery = {
  __typename?: 'Query';
  billingDetails?: {
    __typename?: 'BillingDetails';
    currentPeriodEndAt?: number | null;
    canceled?: boolean | null;
    nextBillingDate?: number | null;
    subscriptionStartsAt?: number | null;
  } | null;
};

export type ParkingLotItemFragmentFragment = {
  __typename?: 'ParkingLotItem';
  id: string;
  text: string;
  votes: number;
  createdAt: number;
  archived: boolean;
  children: Array<{ __typename?: 'ParkingLotItem'; text: string }>;
  team: { __typename?: 'Team'; id: string };
  author: { __typename?: 'User'; id: string };
};

export type AddParkingLotListMutationVariables = Exact<{
  boardId: Scalars['ID'];
  parkingLotItemIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type AddParkingLotListMutation = {
  __typename?: 'Mutation';
  addParkingLotList: {
    __typename?: 'Board';
    id: string;
    name: string;
    teamId: string;
    boardAsyncScheduleId?: string | null;
    createdAt?: number | null;
    updatedAt?: number | null;
    participationRate?: number | null;
    facilitators?: Array<string | null> | null;
    archived: boolean;
    ownerId: string;
    public: boolean;
    type: string;
    onlineUserIds: Array<string | null>;
    discussReadyUserIds: Array<string | null>;
    tooltipHintsEnabled: boolean;
    formatSlug: string;
    takeaways?: Array<{
      __typename?: 'Takeaway';
      id: string;
      text: string;
      boardId: string;
      type: TakeawayType;
    }> | null;
    format: {
      __typename?: 'RetrospectiveFormat';
      displayName?: string | null;
      iconImageUrl?: string | null;
      description?: string | null;
    };
    state: {
      __typename?: 'BoardState';
      privateCards?: boolean | null;
      privateVotes?: boolean | null;
      timerStartTimeMiliseconds?: number | null;
      timerStopTimeMiliseconds?: number | null;
      stage?: BoardStage | null;
      isGuided?: boolean | null;
      showActionPlan?: boolean | null;
      voteCount?: number | null;
      voteCountWasSuggested?: boolean | null;
      discussCurrentCardId?: string | null;
      discussSortByTags?: boolean | null;
      discussionCardsSortingKey?: BoardDiscussionCardsSortingKey | null;
      password?: string | null;
      takeAwayStatus?: TakeawayStatus | null;
    };
    cardBeingDiscussed?: {
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null;
    cardsOnDiscussion?: Array<{
      __typename?: 'Card';
      id: string;
      listId: string;
      authorId: string;
      text: string;
      parentId?: string | null;
      index: number;
      votes: Array<string>;
      archived: boolean;
      updatedAt?: number | null;
      createdAt?: number | null;
      wasDiscussed?: boolean | null;
      originalListBadge?: {
        __typename?: 'OriginalListBadge';
        originalListId?: string | null;
        text?: string | null;
        color?: string | null;
      } | null;
      promotedToPrograms: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
      }>;
      comments: Array<{
        __typename?: 'CardComment';
        id: string;
        text: string;
        authorId: string;
        cardId: string;
        archived: boolean;
        createdAt: number;
        author: { __typename?: 'User'; id: string; name?: string | null };
      }>;
      children?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        authorId: string;
        children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
      } | null> | null;
    } | null> | null;
    lists?: Array<{
      __typename?: 'List';
      id: string;
      boardId: string;
      name: string;
      index: number;
      archived?: boolean | null;
      cardColor?: string | null;
      cards?: Array<{
        __typename?: 'Card';
        id: string;
        listId: string;
        authorId: string;
        text: string;
        parentId?: string | null;
        index: number;
        votes: Array<string>;
        archived: boolean;
        updatedAt?: number | null;
        createdAt?: number | null;
        wasDiscussed?: boolean | null;
        originalListBadge?: {
          __typename?: 'OriginalListBadge';
          originalListId?: string | null;
          text?: string | null;
          color?: string | null;
        } | null;
        promotedToPrograms: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
        }>;
        comments: Array<{
          __typename?: 'CardComment';
          id: string;
          text: string;
          authorId: string;
          cardId: string;
          archived: boolean;
          createdAt: number;
          author: { __typename?: 'User'; id: string; name?: string | null };
        }>;
        children?: Array<{
          __typename?: 'Card';
          id: string;
          listId: string;
          text: string;
          parentId?: string | null;
          index: number;
          votes: Array<string>;
          archived: boolean;
          updatedAt?: number | null;
          createdAt?: number | null;
          authorId: string;
          children?: Array<{ __typename?: 'Card'; id: string } | null> | null;
          originalListBadge?: {
            __typename?: 'OriginalListBadge';
            originalListId?: string | null;
            text?: string | null;
            color?: string | null;
          } | null;
          comments: Array<{
            __typename?: 'CardComment';
            id: string;
            text: string;
            authorId: string;
            cardId: string;
            archived: boolean;
            createdAt: number;
            author: { __typename?: 'User'; id: string; name?: string | null };
          }>;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  };
};

export type ArchiveParkingLotItemMutationVariables = Exact<{
  itemId: Scalars['ID'];
}>;

export type ArchiveParkingLotItemMutation = {
  __typename?: 'Mutation';
  archiveParkingLotItem: {
    __typename?: 'ParkingLotItem';
    id: string;
    text: string;
    votes: number;
    createdAt: number;
    archived: boolean;
    children: Array<{ __typename?: 'ParkingLotItem'; text: string }>;
    team: { __typename?: 'Team'; id: string };
    author: { __typename?: 'User'; id: string };
  };
};

export type CreateParkingLotItemMutationVariables = Exact<{
  teamId: Scalars['ID'];
  text: Scalars['String'];
}>;

export type CreateParkingLotItemMutation = {
  __typename?: 'Mutation';
  createParkingLotCard: {
    __typename?: 'ParkingLotItem';
    id: string;
    text: string;
    votes: number;
    createdAt: number;
    archived: boolean;
    children: Array<{ __typename?: 'ParkingLotItem'; text: string }>;
    team: { __typename?: 'Team'; id: string };
    author: { __typename?: 'User'; id: string };
  };
};

export type CreateParkingLotItemFromCardIdMutationVariables = Exact<{
  boardId: Scalars['ID'];
  teamId: Scalars['ID'];
  ownerId?: InputMaybe<Scalars['ID']>;
  cardId: Scalars['ID'];
}>;

export type CreateParkingLotItemFromCardIdMutation = {
  __typename?: 'Mutation';
  createParkingLotItemFromCardId: {
    __typename?: 'ParkingLotItem';
    id: string;
    text: string;
    votes: number;
    createdAt: number;
    archived: boolean;
    children: Array<{ __typename?: 'ParkingLotItem'; text: string }>;
    team: { __typename?: 'Team'; id: string };
    author: { __typename?: 'User'; id: string };
  };
};

export type PromoteParkingLotItemMutationVariables = Exact<{
  parkingLotItemId: Scalars['ID'];
  programId: Scalars['ID'];
}>;

export type PromoteParkingLotItemMutation = {
  __typename?: 'Mutation';
  copyParkingLotItemToTeam: {
    __typename?: 'ParkingLotItem';
    id: string;
    text: string;
    votes: number;
    createdAt: number;
    archived: boolean;
    children: Array<{ __typename?: 'ParkingLotItem'; text: string }>;
    team: { __typename?: 'Team'; id: string };
    author: { __typename?: 'User'; id: string };
  };
};

export type UpdateParkingLotItemTextMutationVariables = Exact<{
  itemId: Scalars['ID'];
  text: Scalars['String'];
}>;

export type UpdateParkingLotItemTextMutation = {
  __typename?: 'Mutation';
  changeParkingLotItemText: {
    __typename?: 'ParkingLotItem';
    id: string;
    text: string;
    votes: number;
    createdAt: number;
    archived: boolean;
    children: Array<{ __typename?: 'ParkingLotItem'; text: string }>;
    team: { __typename?: 'Team'; id: string };
    author: { __typename?: 'User'; id: string };
  };
};

export type ParkingLotByTeamIdAndTypeQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;

export type ParkingLotByTeamIdAndTypeQuery = {
  __typename?: 'Query';
  parkingLotByTeamId: Array<{
    __typename?: 'ParkingLotItem';
    id: string;
    text: string;
    votes: number;
    createdAt: number;
    archived: boolean;
    children: Array<{ __typename?: 'ParkingLotItem'; text: string }>;
    team: { __typename?: 'Team'; id: string };
    author: { __typename?: 'User'; id: string };
  }>;
};

export type ParkingLotCardCreatedSubscriptionVariables = Exact<{
  teamId: Scalars['ID'];
}>;

export type ParkingLotCardCreatedSubscription = {
  __typename?: 'Subscription';
  parkingLotCardCreated: {
    __typename?: 'ParkingLotItem';
    id: string;
    text: string;
    votes: number;
    createdAt: number;
    archived: boolean;
    children: Array<{ __typename?: 'ParkingLotItem'; text: string }>;
    team: { __typename?: 'Team'; id: string };
    author: { __typename?: 'User'; id: string };
  };
};

export type ParkingLotCardUpdatedSubscriptionVariables = Exact<{
  teamId: Scalars['ID'];
}>;

export type ParkingLotCardUpdatedSubscription = {
  __typename?: 'Subscription';
  parkingLotCardUpdated: {
    __typename?: 'ParkingLotItem';
    id: string;
    text: string;
    votes: number;
    createdAt: number;
    archived: boolean;
    children: Array<{ __typename?: 'ParkingLotItem'; text: string }>;
    team: { __typename?: 'Team'; id: string };
    author: { __typename?: 'User'; id: string };
  };
};

export type ArchiveReportMutationVariables = Exact<{
  reportId: Scalars['ID'];
}>;

export type ArchiveReportMutation = {
  __typename?: 'Mutation';
  archiveReport: { __typename?: 'Report'; id: string; archived: boolean };
};

export type GenerateReportMutationVariables = Exact<{
  input: GenerateReportInput;
}>;

export type GenerateReportMutation = {
  __typename?: 'Mutation';
  generateReport: {
    __typename?: 'Report';
    id: string;
    type: ReportType;
    status: ReportStatus;
    createdAt: number;
    updatedAt: number;
    teamId?: string | null;
  };
};

export type ReportDetailsQueryVariables = Exact<{
  reportId: Scalars['ID'];
}>;

export type ReportDetailsQuery = {
  __typename?: 'Query';
  reportDetails: {
    __typename?: 'Report';
    id: string;
    type: ReportType;
    status: ReportStatus;
    createdAt: number;
    updatedAt: number;
    completedAt?: number | null;
    inputData: any;
    reportData?: any | null;
  };
};

export type ReportsByTeamIdQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;

export type ReportsByTeamIdQuery = {
  __typename?: 'Query';
  reportsByTeamId: Array<{
    __typename?: 'Report';
    id: string;
    type: ReportType;
    status: ReportStatus;
    createdAt: number;
    updatedAt: number;
    completedAt?: number | null;
    inputData: any;
    archived: boolean;
  }>;
};

export type MinimalTeamFragmentFragment = {
  __typename?: 'Team';
  id: string;
  name: string;
  type: TeamType;
  createdAt?: number | null;
};

export type TeamFragmentFragment = {
  __typename?: 'Team';
  id: string;
  createdAt?: number | null;
  name: string;
  inviteToken?: string | null;
  archived?: boolean | null;
  type: TeamType;
  organizationId?: string | null;
  userCount?: number | null;
  teamCount: number;
  maxActiveImprovementGoals: number;
  watchers: Array<{ __typename?: 'User'; id: string }>;
  settings?: {
    __typename?: 'TeamSettings';
    id: string;
    defaultVoteCount: number;
    defaultPublicRetrospectives: boolean;
    defaultGuidedRetrospectives: boolean;
    tooltipHintsEnabled: boolean;
    businessType: TeamBusinessType;
    jiraMetricsEnabled: DisableFeatureState;
    githubMetricsEnabled: DisableFeatureState;
    bitbucketMetricsEnabled: DisableFeatureState;
    cardCategories?: Array<{
      __typename?: 'CardCategory';
      name: string;
      color: string;
    } | null> | null;
  } | null;
  members: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
  }>;
  children: Array<{
    __typename?: 'Team';
    id: string;
    name: string;
    type: TeamType;
    members: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    }>;
  }>;
  admin?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
  } | null;
  parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
};

export type TeamTreeFragmentFragment = {
  __typename?: 'TeamTree';
  isWatcher?: boolean | null;
  team: {
    __typename?: 'Team';
    id: string;
    name: string;
    type: TeamType;
    createdAt?: number | null;
  };
  children: Array<{
    __typename?: 'TeamTree';
    isWatcher?: boolean | null;
    team: {
      __typename?: 'Team';
      id: string;
      name: string;
      type: TeamType;
      createdAt?: number | null;
    };
    children: Array<{
      __typename?: 'TeamTree';
      isWatcher?: boolean | null;
      team: {
        __typename?: 'Team';
        id: string;
        name: string;
        type: TeamType;
        createdAt?: number | null;
      };
      children: Array<{
        __typename?: 'TeamTree';
        team: {
          __typename?: 'Team';
          id: string;
          name: string;
          type: TeamType;
          createdAt?: number | null;
        };
      }>;
    }>;
  }>;
};

export type TeamWithOrgFragment = {
  __typename?: 'Team';
  id: string;
  createdAt?: number | null;
  name: string;
  inviteToken?: string | null;
  archived?: boolean | null;
  type: TeamType;
  organizationId?: string | null;
  userCount?: number | null;
  teamCount: number;
  maxActiveImprovementGoals: number;
  organization?: {
    __typename?: 'Organization';
    id?: string | null;
    name?: string | null;
    userCount?: number | null;
    status?: PaymentStatus | null;
    prepaidSeatCount: number;
  } | null;
  watchers: Array<{ __typename?: 'User'; id: string }>;
  settings?: {
    __typename?: 'TeamSettings';
    id: string;
    defaultVoteCount: number;
    defaultPublicRetrospectives: boolean;
    defaultGuidedRetrospectives: boolean;
    tooltipHintsEnabled: boolean;
    businessType: TeamBusinessType;
    jiraMetricsEnabled: DisableFeatureState;
    githubMetricsEnabled: DisableFeatureState;
    bitbucketMetricsEnabled: DisableFeatureState;
    cardCategories?: Array<{
      __typename?: 'CardCategory';
      name: string;
      color: string;
    } | null> | null;
  } | null;
  members: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
  }>;
  children: Array<{
    __typename?: 'Team';
    id: string;
    name: string;
    type: TeamType;
    members: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    }>;
  }>;
  admin?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
  } | null;
  parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
};

export type AddTeamToProgramMutationVariables = Exact<{
  teamId: Scalars['ID'];
  programId: Scalars['ID'];
}>;

export type AddTeamToProgramMutation = {
  __typename?: 'Mutation';
  addTeamToProgram?: {
    __typename?: 'Team';
    id: string;
    name: string;
    children: Array<{ __typename?: 'Team'; id: string; name: string }>;
  } | null;
};

export type ArchiveTeamMutationVariables = Exact<{
  teamId: Scalars['ID'];
}>;

export type ArchiveTeamMutation = {
  __typename?: 'Mutation';
  archiveTeam?: {
    __typename?: 'Team';
    id: string;
    createdAt?: number | null;
    name: string;
    inviteToken?: string | null;
    archived?: boolean | null;
    type: TeamType;
    organizationId?: string | null;
    userCount?: number | null;
    teamCount: number;
    maxActiveImprovementGoals: number;
    watchers: Array<{ __typename?: 'User'; id: string }>;
    settings?: {
      __typename?: 'TeamSettings';
      id: string;
      defaultVoteCount: number;
      defaultPublicRetrospectives: boolean;
      defaultGuidedRetrospectives: boolean;
      tooltipHintsEnabled: boolean;
      businessType: TeamBusinessType;
      jiraMetricsEnabled: DisableFeatureState;
      githubMetricsEnabled: DisableFeatureState;
      bitbucketMetricsEnabled: DisableFeatureState;
      cardCategories?: Array<{
        __typename?: 'CardCategory';
        name: string;
        color: string;
      } | null> | null;
    } | null;
    members: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    }>;
    children: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
      type: TeamType;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
    }>;
    admin?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    } | null;
    parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
  } | null;
};

export type CreateTeamMutationVariables = Exact<{
  teamInput: TeamInput;
}>;

export type CreateTeamMutation = {
  __typename?: 'Mutation';
  createTeam?: {
    __typename?: 'Team';
    id: string;
    createdAt?: number | null;
    name: string;
    inviteToken?: string | null;
    archived?: boolean | null;
    type: TeamType;
    organizationId?: string | null;
    userCount?: number | null;
    teamCount: number;
    maxActiveImprovementGoals: number;
    watchers: Array<{ __typename?: 'User'; id: string }>;
    settings?: {
      __typename?: 'TeamSettings';
      id: string;
      defaultVoteCount: number;
      defaultPublicRetrospectives: boolean;
      defaultGuidedRetrospectives: boolean;
      tooltipHintsEnabled: boolean;
      businessType: TeamBusinessType;
      jiraMetricsEnabled: DisableFeatureState;
      githubMetricsEnabled: DisableFeatureState;
      bitbucketMetricsEnabled: DisableFeatureState;
      cardCategories?: Array<{
        __typename?: 'CardCategory';
        name: string;
        color: string;
      } | null> | null;
    } | null;
    members: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    }>;
    children: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
      type: TeamType;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
    }>;
    admin?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    } | null;
    parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
  } | null;
};

export type InviteToTeamByEmailMutationVariables = Exact<{
  teamId: Scalars['ID'];
  email: Scalars['String'];
}>;

export type InviteToTeamByEmailMutation = {
  __typename?: 'Mutation';
  inviteToTeamByEmail: {
    __typename?: 'Team';
    id: string;
    createdAt?: number | null;
    name: string;
    inviteToken?: string | null;
    archived?: boolean | null;
    type: TeamType;
    organizationId?: string | null;
    userCount?: number | null;
    teamCount: number;
    maxActiveImprovementGoals: number;
    watchers: Array<{ __typename?: 'User'; id: string }>;
    settings?: {
      __typename?: 'TeamSettings';
      id: string;
      defaultVoteCount: number;
      defaultPublicRetrospectives: boolean;
      defaultGuidedRetrospectives: boolean;
      tooltipHintsEnabled: boolean;
      businessType: TeamBusinessType;
      jiraMetricsEnabled: DisableFeatureState;
      githubMetricsEnabled: DisableFeatureState;
      bitbucketMetricsEnabled: DisableFeatureState;
      cardCategories?: Array<{
        __typename?: 'CardCategory';
        name: string;
        color: string;
      } | null> | null;
    } | null;
    members: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    }>;
    children: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
      type: TeamType;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
    }>;
    admin?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    } | null;
    parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
  };
};

export type RemoveTeamFromProgramMutationVariables = Exact<{
  teamId: Scalars['ID'];
  programId: Scalars['ID'];
}>;

export type RemoveTeamFromProgramMutation = {
  __typename?: 'Mutation';
  leaveProgram?: {
    __typename?: 'Team';
    id: string;
    name: string;
    children: Array<{ __typename?: 'Team'; id: string; name: string }>;
  } | null;
};

export type UpdateTeamMutationVariables = Exact<{
  teamId: Scalars['ID'];
  userId: Scalars['String'];
  fields: Scalars['JSON'];
}>;

export type UpdateTeamMutation = {
  __typename?: 'Mutation';
  updateTeam?: {
    __typename?: 'Team';
    id: string;
    createdAt?: number | null;
    name: string;
    inviteToken?: string | null;
    archived?: boolean | null;
    type: TeamType;
    organizationId?: string | null;
    userCount?: number | null;
    teamCount: number;
    maxActiveImprovementGoals: number;
    watchers: Array<{ __typename?: 'User'; id: string }>;
    settings?: {
      __typename?: 'TeamSettings';
      id: string;
      defaultVoteCount: number;
      defaultPublicRetrospectives: boolean;
      defaultGuidedRetrospectives: boolean;
      tooltipHintsEnabled: boolean;
      businessType: TeamBusinessType;
      jiraMetricsEnabled: DisableFeatureState;
      githubMetricsEnabled: DisableFeatureState;
      bitbucketMetricsEnabled: DisableFeatureState;
      cardCategories?: Array<{
        __typename?: 'CardCategory';
        name: string;
        color: string;
      } | null> | null;
    } | null;
    members: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    }>;
    children: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
      type: TeamType;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
    }>;
    admin?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    } | null;
    parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
  } | null;
};

export type PaginatedTeamBoardsQueryVariables = Exact<{
  teamId: Scalars['String'];
  archived: Scalars['Boolean'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;

export type PaginatedTeamBoardsQuery = {
  __typename?: 'Query';
  teamBoardsPagination?: {
    __typename?: 'BoardQueryResults';
    totalSize: number;
    boards: Array<{
      __typename?: 'Board';
      id: string;
      name: string;
      teamId: string;
      ownerId: string;
      boardAsyncScheduleId?: string | null;
      participationRate?: number | null;
      totalCards: number;
      public: boolean;
      createdAt?: number | null;
      updatedAt?: number | null;
      archived: boolean;
      type: string;
      formatSlug: string;
      format: {
        __typename?: 'RetrospectiveFormat';
        iconImageUrl?: string | null;
        displayName?: string | null;
      };
    } | null>;
  } | null;
};

export type SearchTeamMembersQueryVariables = Exact<{
  teamId: Scalars['String'];
  term?: InputMaybe<Scalars['String']>;
}>;

export type SearchTeamMembersQuery = {
  __typename?: 'Query';
  searchTeamMembers: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
  }>;
};

export type TeamByIdQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;

export type TeamByIdQuery = {
  __typename?: 'Query';
  teamById?: {
    __typename?: 'Team';
    id: string;
    createdAt?: number | null;
    name: string;
    inviteToken?: string | null;
    archived?: boolean | null;
    type: TeamType;
    organizationId?: string | null;
    userCount?: number | null;
    teamCount: number;
    maxActiveImprovementGoals: number;
    watchers: Array<{ __typename?: 'User'; id: string }>;
    settings?: {
      __typename?: 'TeamSettings';
      id: string;
      defaultVoteCount: number;
      defaultPublicRetrospectives: boolean;
      defaultGuidedRetrospectives: boolean;
      tooltipHintsEnabled: boolean;
      businessType: TeamBusinessType;
      jiraMetricsEnabled: DisableFeatureState;
      githubMetricsEnabled: DisableFeatureState;
      bitbucketMetricsEnabled: DisableFeatureState;
      cardCategories?: Array<{
        __typename?: 'CardCategory';
        name: string;
        color: string;
      } | null> | null;
    } | null;
    members: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    }>;
    children: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
      type: TeamType;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
    }>;
    admin?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    } | null;
    parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
  } | null;
};

export type TeamByInviteTokenQueryVariables = Exact<{
  inviteToken: Scalars['String'];
}>;

export type TeamByInviteTokenQuery = {
  __typename?: 'Query';
  teamByInviteToken?: {
    __typename?: 'Team';
    id: string;
    createdAt?: number | null;
    name: string;
    inviteToken?: string | null;
    archived?: boolean | null;
    type: TeamType;
    organizationId?: string | null;
    userCount?: number | null;
    teamCount: number;
    maxActiveImprovementGoals: number;
    organization?: {
      __typename?: 'Organization';
      id?: string | null;
      name?: string | null;
      userCount?: number | null;
      status?: PaymentStatus | null;
      prepaidSeatCount: number;
    } | null;
    watchers: Array<{ __typename?: 'User'; id: string }>;
    settings?: {
      __typename?: 'TeamSettings';
      id: string;
      defaultVoteCount: number;
      defaultPublicRetrospectives: boolean;
      defaultGuidedRetrospectives: boolean;
      tooltipHintsEnabled: boolean;
      businessType: TeamBusinessType;
      jiraMetricsEnabled: DisableFeatureState;
      githubMetricsEnabled: DisableFeatureState;
      bitbucketMetricsEnabled: DisableFeatureState;
      cardCategories?: Array<{
        __typename?: 'CardCategory';
        name: string;
        color: string;
      } | null> | null;
    } | null;
    members: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    }>;
    children: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
      type: TeamType;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
    }>;
    admin?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    } | null;
    parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
  } | null;
};

export type TeamByPublicBoardIdQueryVariables = Exact<{
  boardId: Scalars['String'];
}>;

export type TeamByPublicBoardIdQuery = {
  __typename?: 'Query';
  teamByPublicBoardId?: {
    __typename?: 'Team';
    id: string;
    createdAt?: number | null;
    name: string;
    inviteToken?: string | null;
    archived?: boolean | null;
    type: TeamType;
    organizationId?: string | null;
    userCount?: number | null;
    teamCount: number;
    maxActiveImprovementGoals: number;
    organization?: {
      __typename?: 'Organization';
      id?: string | null;
      name?: string | null;
      userCount?: number | null;
      status?: PaymentStatus | null;
      prepaidSeatCount: number;
    } | null;
    watchers: Array<{ __typename?: 'User'; id: string }>;
    settings?: {
      __typename?: 'TeamSettings';
      id: string;
      defaultVoteCount: number;
      defaultPublicRetrospectives: boolean;
      defaultGuidedRetrospectives: boolean;
      tooltipHintsEnabled: boolean;
      businessType: TeamBusinessType;
      jiraMetricsEnabled: DisableFeatureState;
      githubMetricsEnabled: DisableFeatureState;
      bitbucketMetricsEnabled: DisableFeatureState;
      cardCategories?: Array<{
        __typename?: 'CardCategory';
        name: string;
        color: string;
      } | null> | null;
    } | null;
    members: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    }>;
    children: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
      type: TeamType;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
    }>;
    admin?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    } | null;
    parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
  } | null;
};

export type TeamParentsByTeamIdQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;

export type TeamParentsByTeamIdQuery = {
  __typename?: 'Query';
  teamById?: {
    __typename: 'Team';
    id: string;
    parents?: Array<{
      __typename: 'Team';
      id: string;
      name: string;
      type: TeamType;
    }> | null;
  } | null;
};

export type TeamTreeQueryVariables = Exact<{ [key: string]: never }>;

export type TeamTreeQuery = {
  __typename?: 'Query';
  topLevelTeams: Array<{
    __typename?: 'TeamTree';
    isWatcher?: boolean | null;
    team: {
      __typename?: 'Team';
      id: string;
      name: string;
      type: TeamType;
      createdAt?: number | null;
    };
    children: Array<{
      __typename?: 'TeamTree';
      isWatcher?: boolean | null;
      team: {
        __typename?: 'Team';
        id: string;
        name: string;
        type: TeamType;
        createdAt?: number | null;
      };
      children: Array<{
        __typename?: 'TeamTree';
        isWatcher?: boolean | null;
        team: {
          __typename?: 'Team';
          id: string;
          name: string;
          type: TeamType;
          createdAt?: number | null;
        };
        children: Array<{
          __typename?: 'TeamTree';
          team: {
            __typename?: 'Team';
            id: string;
            name: string;
            type: TeamType;
            createdAt?: number | null;
          };
        }>;
      }>;
    }>;
  }>;
  topLevelWatchedTeams: Array<{
    __typename?: 'TeamTree';
    isWatcher?: boolean | null;
    team: {
      __typename?: 'Team';
      id: string;
      name: string;
      type: TeamType;
      createdAt?: number | null;
    };
    children: Array<{
      __typename?: 'TeamTree';
      isWatcher?: boolean | null;
      team: {
        __typename?: 'Team';
        id: string;
        name: string;
        type: TeamType;
        createdAt?: number | null;
      };
      children: Array<{
        __typename?: 'TeamTree';
        isWatcher?: boolean | null;
        team: {
          __typename?: 'Team';
          id: string;
          name: string;
          type: TeamType;
          createdAt?: number | null;
        };
        children: Array<{
          __typename?: 'TeamTree';
          team: {
            __typename?: 'Team';
            id: string;
            name: string;
            type: TeamType;
            createdAt?: number | null;
          };
        }>;
      }>;
    }>;
  }>;
};

export type TeamsByOrganizationIdAndTypeQueryVariables = Exact<{
  organizationId: Scalars['String'];
  teamType: TeamType;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type TeamsByOrganizationIdAndTypeQuery = {
  __typename?: 'Query';
  teamsByOrganizationIdAndType: {
    __typename?: 'TeamQueryResults';
    totalSize: number;
    teams: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
      type: TeamType;
      createdAt?: number | null;
      organizationId?: string | null;
    } | null>;
  };
};

export type MinimalUserFragment = {
  __typename?: 'User';
  id: string;
  name?: string | null;
  email: string;
};

export type OrgMembershipFragmentFragment = {
  __typename?: 'OrganizationUserMembership';
  role: MembershipRole;
  trialEndsAt?: number | null;
  archived: boolean;
  joinedAt?: number | null;
};

export type OrgMembershipWithUserFragment = {
  __typename?: 'OrganizationUserMembership';
  seatType: SeatType;
  role: MembershipRole;
  trialEndsAt?: number | null;
  archived: boolean;
  joinedAt?: number | null;
  user: {
    __typename?: 'User';
    id: string;
    email: string;
    name?: string | null;
    lastActiveAt?: number | null;
    createdAt?: number | null;
  };
};

export type UserFragmentFragment = {
  __typename?: 'User';
  id: string;
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  role?: MembershipRole | null;
  betaFeatures: Array<BetaFeature>;
  announcementsModalSeen?: boolean | null;
  lastActiveAt?: number | null;
  createdAt?: number | null;
  verified?: boolean | null;
  facilitatorLabelsShown?: boolean | null;
  ssoControlled?: boolean | null;
  provider?: string | null;
  team?: {
    __typename?: 'Team';
    betaFeatures: Array<BetaFeature>;
    id: string;
    createdAt?: number | null;
    name: string;
    inviteToken?: string | null;
    archived?: boolean | null;
    type: TeamType;
    organizationId?: string | null;
    userCount?: number | null;
    teamCount: number;
    maxActiveImprovementGoals: number;
    watchers: Array<{ __typename?: 'User'; id: string }>;
    settings?: {
      __typename?: 'TeamSettings';
      id: string;
      defaultVoteCount: number;
      defaultPublicRetrospectives: boolean;
      defaultGuidedRetrospectives: boolean;
      tooltipHintsEnabled: boolean;
      businessType: TeamBusinessType;
      jiraMetricsEnabled: DisableFeatureState;
      githubMetricsEnabled: DisableFeatureState;
      bitbucketMetricsEnabled: DisableFeatureState;
      cardCategories?: Array<{
        __typename?: 'CardCategory';
        name: string;
        color: string;
      } | null> | null;
    } | null;
    members: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    }>;
    children: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
      type: TeamType;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
    }>;
    admin?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    } | null;
    parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
    insights: Array<{
      __typename?: 'TeamInsightFactCauseSolution';
      id: string;
      seen: boolean;
      rated: boolean;
      date: number;
      team: { __typename?: 'Team'; id: string; name: string; type: TeamType };
      fact:
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricBelowRecommended';
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricDeterioration';
            id: string;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricImprovement';
            id: string;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactMetricInadequacy';
            id: string;
            recommended: number;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionFactOutlierWorkItemTopic';
            id: string;
            topic: string;
          };
      causes?: Array<
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseMetricDeterioration';
            id: string;
            type: {
              __typename?: 'TeamInsightMetricType';
              name: string;
              unit: ImprovementGoalUnit;
              providerCategory?: MetricsProviderCategory | null;
              ideal: Trend;
            };
            history: Array<{
              __typename?: 'TimeSeries';
              date: number;
              value?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequests';
            id: string;
            topic?: string | null;
            pullRequests: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequest';
              title: string;
              externalUrl?: string | null;
              slug?: string | null;
              origin?: SourceControlOrigin | null;
              wipTimeMs?: number | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseOutlierWorkItems';
            id: string;
            topic?: string | null;
            workItems: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCauseWorkItem';
              slug?: string | null;
              title: string;
              wipTimeMs: number;
              origin?: WorkItemOrigin | null;
              url?: string | null;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseTopic';
            id: string;
            topic?: string | null;
            cards: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCauseTopicCard';
              text: string;
              badgeColor: string;
              badgeText: string;
              retroName: string;
              retroDate: number;
            }>;
          }
        | {
            __typename?: 'TeamInsightFactCauseSolutionCauseUnreviewedPullRequests';
            id: string;
            topic?: string | null;
            pullRequests: Array<{
              __typename?: 'TeamInsightFactCauseSolutionCausePullRequest';
              title: string;
              description?: string | null;
              externalUrl?: string | null;
              slug?: string | null;
              createdAt: string;
              origin?: SourceControlOrigin | null;
            }>;
          }
      > | null;
      solutions?: Array<
        Array<
          | {
              __typename?: 'TeamInsightFactCauseSolutionSolutionActionItem';
              id: string;
              text: string;
              originatedActionItem?: {
                __typename?: 'ActionItem';
                id: string;
                title: string;
                description?: string | null;
                teamId: string;
                resolvedAt?: number | null;
                dueDate?: number | null;
                authorId: string;
                boardId?: string | null;
                status: ActionItemStatus;
                archived: boolean;
                createdAt?: number | null;
                children: Array<{
                  __typename?: 'ActionItemChild';
                  id: string;
                  text: string;
                }>;
                author?: { __typename?: 'Author'; name: string } | null;
                assignees?: Array<{
                  __typename?: 'User';
                  id: string;
                  name?: string | null;
                } | null> | null;
                improvementGoals?: Array<{
                  __typename?: 'ImprovementGoal';
                  id: string;
                  type: { __typename?: 'ImprovementGoalType'; name: string };
                } | null> | null;
                jiraIssueLink?: {
                  __typename?: 'ActionItemJiraLink';
                  jiraIssueId?: string | null;
                  jiraIssueUrl?: string | null;
                  jiraIssueKey?: string | null;
                  jiraProjectKey?: string | null;
                } | null;
                comments: Array<{
                  __typename?: 'ActionItemComment';
                  id: string;
                  text: string;
                  actionItemId: string;
                  createdAt: number;
                  archived: boolean;
                  author: {
                    __typename?: 'User';
                    id: string;
                    name?: string | null;
                  };
                }>;
              } | null;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionSolutionGoal';
              id: string;
              type: {
                __typename?: 'ImprovementGoalType';
                id: string;
                name: string;
                color: string;
                description?: string | null;
                learnMoreUrl?: string | null;
                unit: ImprovementGoalUnit;
                categories: Array<ImprovementGoalTypeCategory>;
                subCategory?: string | null;
                targetType: ImprovementGoalTypeTargetType;
                aggregationPeriod: ImprovementGoalAggregationPeriod;
                aggregationFunction: ImprovementGoalAggregationFunction;
                isPollable: boolean;
                minValue?: number | null;
                maxValue?: number | null;
              };
            }
        >
      > | null;
    }>;
  } | null;
  watchedTeams?: Array<{
    __typename?: 'Team';
    id: string;
    createdAt?: number | null;
    name: string;
    inviteToken?: string | null;
    archived?: boolean | null;
    type: TeamType;
    organizationId?: string | null;
    userCount?: number | null;
    teamCount: number;
    maxActiveImprovementGoals: number;
    watchers: Array<{ __typename?: 'User'; id: string }>;
    settings?: {
      __typename?: 'TeamSettings';
      id: string;
      defaultVoteCount: number;
      defaultPublicRetrospectives: boolean;
      defaultGuidedRetrospectives: boolean;
      tooltipHintsEnabled: boolean;
      businessType: TeamBusinessType;
      jiraMetricsEnabled: DisableFeatureState;
      githubMetricsEnabled: DisableFeatureState;
      bitbucketMetricsEnabled: DisableFeatureState;
      cardCategories?: Array<{
        __typename?: 'CardCategory';
        name: string;
        color: string;
      } | null> | null;
    } | null;
    members: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    }>;
    children: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
      type: TeamType;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
    }>;
    admin?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    } | null;
    parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
  } | null> | null;
  organization?: {
    __typename?: 'Organization';
    betaFeatures: Array<BetaFeature>;
    id?: string | null;
    adminOnlyTeamCreation: boolean;
    ownerId?: string | null;
    name?: string | null;
    userCount?: number | null;
    prepaidSeatCount: number;
    numberOfTeams?: number | null;
    numberOfPrograms?: number | null;
    isExceedingSeats?: boolean | null;
    numberOfRetrospectivesHeld?: number | null;
    averageTimeBetweenRetrospectives?: number | null;
    inviteToken?: string | null;
    domains: Array<string>;
    hasSSO?: boolean | null;
    status?: PaymentStatus | null;
    trialEndsAt?: number | null;
    owner?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    } | null;
    team?: {
      __typename?: 'Team';
      id: string;
      createdAt?: number | null;
      name: string;
      inviteToken?: string | null;
      archived?: boolean | null;
      type: TeamType;
      organizationId?: string | null;
      userCount?: number | null;
      teamCount: number;
      maxActiveImprovementGoals: number;
      watchers: Array<{ __typename?: 'User'; id: string }>;
      settings?: {
        __typename?: 'TeamSettings';
        id: string;
        defaultVoteCount: number;
        defaultPublicRetrospectives: boolean;
        defaultGuidedRetrospectives: boolean;
        tooltipHintsEnabled: boolean;
        businessType: TeamBusinessType;
        jiraMetricsEnabled: DisableFeatureState;
        githubMetricsEnabled: DisableFeatureState;
        bitbucketMetricsEnabled: DisableFeatureState;
        cardCategories?: Array<{
          __typename?: 'CardCategory';
          name: string;
          color: string;
        } | null> | null;
      } | null;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
      children: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
        type: TeamType;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
      }>;
      admin?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
    } | null;
    plan?: {
      __typename?: 'Plan';
      slug?: string | null;
      displayName?: string | null;
      blockExceedingSeats?: boolean | null;
      trialUserTimeSeconds?: number | null;
      programs?: boolean | null;
      integrationMicrosoftTeams?: boolean | null;
      integrationJira?: boolean | null;
      supportLevel?: SupportLevel | null;
      sso?: boolean | null;
    } | null;
  } | null;
  memberships?: Array<{
    __typename?: 'OrganizationUserMembership';
    role: MembershipRole;
    trialEndsAt?: number | null;
    archived: boolean;
    joinedAt?: number | null;
    organization: {
      __typename?: 'Organization';
      hasSSO?: boolean | null;
      id?: string | null;
      adminOnlyTeamCreation: boolean;
      ownerId?: string | null;
      name?: string | null;
      userCount?: number | null;
      prepaidSeatCount: number;
      numberOfTeams?: number | null;
      numberOfPrograms?: number | null;
      isExceedingSeats?: boolean | null;
      numberOfRetrospectivesHeld?: number | null;
      averageTimeBetweenRetrospectives?: number | null;
      inviteToken?: string | null;
      domains: Array<string>;
      status?: PaymentStatus | null;
      trialEndsAt?: number | null;
      owner?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      team?: {
        __typename?: 'Team';
        id: string;
        createdAt?: number | null;
        name: string;
        inviteToken?: string | null;
        archived?: boolean | null;
        type: TeamType;
        organizationId?: string | null;
        userCount?: number | null;
        teamCount: number;
        maxActiveImprovementGoals: number;
        watchers: Array<{ __typename?: 'User'; id: string }>;
        settings?: {
          __typename?: 'TeamSettings';
          id: string;
          defaultVoteCount: number;
          defaultPublicRetrospectives: boolean;
          defaultGuidedRetrospectives: boolean;
          tooltipHintsEnabled: boolean;
          businessType: TeamBusinessType;
          jiraMetricsEnabled: DisableFeatureState;
          githubMetricsEnabled: DisableFeatureState;
          bitbucketMetricsEnabled: DisableFeatureState;
          cardCategories?: Array<{
            __typename?: 'CardCategory';
            name: string;
            color: string;
          } | null> | null;
        } | null;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
        children: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
          type: TeamType;
          members: Array<{
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          }>;
        }>;
        admin?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        } | null;
        parents?: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
        }> | null;
      } | null;
      plan?: {
        __typename?: 'Plan';
        slug?: string | null;
        displayName?: string | null;
        blockExceedingSeats?: boolean | null;
        trialUserTimeSeconds?: number | null;
        programs?: boolean | null;
        integrationMicrosoftTeams?: boolean | null;
        integrationJira?: boolean | null;
        supportLevel?: SupportLevel | null;
        sso?: boolean | null;
      } | null;
    };
  }> | null;
};

export type ChangeUserCurrentTeamAndAddIfNotMemberMutationVariables = Exact<{
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
}>;

export type ChangeUserCurrentTeamAndAddIfNotMemberMutation = {
  __typename?: 'Mutation';
  changeUserCurrentTeamAndAddIfNotMember?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    role?: MembershipRole | null;
    betaFeatures: Array<BetaFeature>;
    announcementsModalSeen?: boolean | null;
    lastActiveAt?: number | null;
    createdAt?: number | null;
    verified?: boolean | null;
    facilitatorLabelsShown?: boolean | null;
    ssoControlled?: boolean | null;
    provider?: string | null;
    team?: {
      __typename?: 'Team';
      betaFeatures: Array<BetaFeature>;
      id: string;
      createdAt?: number | null;
      name: string;
      inviteToken?: string | null;
      archived?: boolean | null;
      type: TeamType;
      organizationId?: string | null;
      userCount?: number | null;
      teamCount: number;
      maxActiveImprovementGoals: number;
      watchers: Array<{ __typename?: 'User'; id: string }>;
      settings?: {
        __typename?: 'TeamSettings';
        id: string;
        defaultVoteCount: number;
        defaultPublicRetrospectives: boolean;
        defaultGuidedRetrospectives: boolean;
        tooltipHintsEnabled: boolean;
        businessType: TeamBusinessType;
        jiraMetricsEnabled: DisableFeatureState;
        githubMetricsEnabled: DisableFeatureState;
        bitbucketMetricsEnabled: DisableFeatureState;
        cardCategories?: Array<{
          __typename?: 'CardCategory';
          name: string;
          color: string;
        } | null> | null;
      } | null;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
      children: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
        type: TeamType;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
      }>;
      admin?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
      insights: Array<{
        __typename?: 'TeamInsightFactCauseSolution';
        id: string;
        seen: boolean;
        rated: boolean;
        date: number;
        team: { __typename?: 'Team'; id: string; name: string; type: TeamType };
        fact:
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricBelowRecommended';
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricDeterioration';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricImprovement';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricInadequacy';
              id: string;
              recommended: number;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactOutlierWorkItemTopic';
              id: string;
              topic: string;
            };
        causes?: Array<
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseMetricDeterioration';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequests';
              id: string;
              topic?: string | null;
              pullRequests: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequest';
                title: string;
                externalUrl?: string | null;
                slug?: string | null;
                origin?: SourceControlOrigin | null;
                wipTimeMs?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseOutlierWorkItems';
              id: string;
              topic?: string | null;
              workItems: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseWorkItem';
                slug?: string | null;
                title: string;
                wipTimeMs: number;
                origin?: WorkItemOrigin | null;
                url?: string | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseTopic';
              id: string;
              topic?: string | null;
              cards: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseTopicCard';
                text: string;
                badgeColor: string;
                badgeText: string;
                retroName: string;
                retroDate: number;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseUnreviewedPullRequests';
              id: string;
              topic?: string | null;
              pullRequests: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCausePullRequest';
                title: string;
                description?: string | null;
                externalUrl?: string | null;
                slug?: string | null;
                createdAt: string;
                origin?: SourceControlOrigin | null;
              }>;
            }
        > | null;
        solutions?: Array<
          Array<
            | {
                __typename?: 'TeamInsightFactCauseSolutionSolutionActionItem';
                id: string;
                text: string;
                originatedActionItem?: {
                  __typename?: 'ActionItem';
                  id: string;
                  title: string;
                  description?: string | null;
                  teamId: string;
                  resolvedAt?: number | null;
                  dueDate?: number | null;
                  authorId: string;
                  boardId?: string | null;
                  status: ActionItemStatus;
                  archived: boolean;
                  createdAt?: number | null;
                  children: Array<{
                    __typename?: 'ActionItemChild';
                    id: string;
                    text: string;
                  }>;
                  author?: { __typename?: 'Author'; name: string } | null;
                  assignees?: Array<{
                    __typename?: 'User';
                    id: string;
                    name?: string | null;
                  } | null> | null;
                  improvementGoals?: Array<{
                    __typename?: 'ImprovementGoal';
                    id: string;
                    type: { __typename?: 'ImprovementGoalType'; name: string };
                  } | null> | null;
                  jiraIssueLink?: {
                    __typename?: 'ActionItemJiraLink';
                    jiraIssueId?: string | null;
                    jiraIssueUrl?: string | null;
                    jiraIssueKey?: string | null;
                    jiraProjectKey?: string | null;
                  } | null;
                  comments: Array<{
                    __typename?: 'ActionItemComment';
                    id: string;
                    text: string;
                    actionItemId: string;
                    createdAt: number;
                    archived: boolean;
                    author: {
                      __typename?: 'User';
                      id: string;
                      name?: string | null;
                    };
                  }>;
                } | null;
              }
            | {
                __typename?: 'TeamInsightFactCauseSolutionSolutionGoal';
                id: string;
                type: {
                  __typename?: 'ImprovementGoalType';
                  id: string;
                  name: string;
                  color: string;
                  description?: string | null;
                  learnMoreUrl?: string | null;
                  unit: ImprovementGoalUnit;
                  categories: Array<ImprovementGoalTypeCategory>;
                  subCategory?: string | null;
                  targetType: ImprovementGoalTypeTargetType;
                  aggregationPeriod: ImprovementGoalAggregationPeriod;
                  aggregationFunction: ImprovementGoalAggregationFunction;
                  isPollable: boolean;
                  minValue?: number | null;
                  maxValue?: number | null;
                };
              }
          >
        > | null;
      }>;
    } | null;
    watchedTeams?: Array<{
      __typename?: 'Team';
      id: string;
      createdAt?: number | null;
      name: string;
      inviteToken?: string | null;
      archived?: boolean | null;
      type: TeamType;
      organizationId?: string | null;
      userCount?: number | null;
      teamCount: number;
      maxActiveImprovementGoals: number;
      watchers: Array<{ __typename?: 'User'; id: string }>;
      settings?: {
        __typename?: 'TeamSettings';
        id: string;
        defaultVoteCount: number;
        defaultPublicRetrospectives: boolean;
        defaultGuidedRetrospectives: boolean;
        tooltipHintsEnabled: boolean;
        businessType: TeamBusinessType;
        jiraMetricsEnabled: DisableFeatureState;
        githubMetricsEnabled: DisableFeatureState;
        bitbucketMetricsEnabled: DisableFeatureState;
        cardCategories?: Array<{
          __typename?: 'CardCategory';
          name: string;
          color: string;
        } | null> | null;
      } | null;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
      children: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
        type: TeamType;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
      }>;
      admin?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      betaFeatures: Array<BetaFeature>;
      id?: string | null;
      adminOnlyTeamCreation: boolean;
      ownerId?: string | null;
      name?: string | null;
      userCount?: number | null;
      prepaidSeatCount: number;
      numberOfTeams?: number | null;
      numberOfPrograms?: number | null;
      isExceedingSeats?: boolean | null;
      numberOfRetrospectivesHeld?: number | null;
      averageTimeBetweenRetrospectives?: number | null;
      inviteToken?: string | null;
      domains: Array<string>;
      hasSSO?: boolean | null;
      status?: PaymentStatus | null;
      trialEndsAt?: number | null;
      owner?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      team?: {
        __typename?: 'Team';
        id: string;
        createdAt?: number | null;
        name: string;
        inviteToken?: string | null;
        archived?: boolean | null;
        type: TeamType;
        organizationId?: string | null;
        userCount?: number | null;
        teamCount: number;
        maxActiveImprovementGoals: number;
        watchers: Array<{ __typename?: 'User'; id: string }>;
        settings?: {
          __typename?: 'TeamSettings';
          id: string;
          defaultVoteCount: number;
          defaultPublicRetrospectives: boolean;
          defaultGuidedRetrospectives: boolean;
          tooltipHintsEnabled: boolean;
          businessType: TeamBusinessType;
          jiraMetricsEnabled: DisableFeatureState;
          githubMetricsEnabled: DisableFeatureState;
          bitbucketMetricsEnabled: DisableFeatureState;
          cardCategories?: Array<{
            __typename?: 'CardCategory';
            name: string;
            color: string;
          } | null> | null;
        } | null;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
        children: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
          type: TeamType;
          members: Array<{
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          }>;
        }>;
        admin?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        } | null;
        parents?: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
        }> | null;
      } | null;
      plan?: {
        __typename?: 'Plan';
        slug?: string | null;
        displayName?: string | null;
        blockExceedingSeats?: boolean | null;
        trialUserTimeSeconds?: number | null;
        programs?: boolean | null;
        integrationMicrosoftTeams?: boolean | null;
        integrationJira?: boolean | null;
        supportLevel?: SupportLevel | null;
        sso?: boolean | null;
      } | null;
    } | null;
    memberships?: Array<{
      __typename?: 'OrganizationUserMembership';
      role: MembershipRole;
      trialEndsAt?: number | null;
      archived: boolean;
      joinedAt?: number | null;
      organization: {
        __typename?: 'Organization';
        hasSSO?: boolean | null;
        id?: string | null;
        adminOnlyTeamCreation: boolean;
        ownerId?: string | null;
        name?: string | null;
        userCount?: number | null;
        prepaidSeatCount: number;
        numberOfTeams?: number | null;
        numberOfPrograms?: number | null;
        isExceedingSeats?: boolean | null;
        numberOfRetrospectivesHeld?: number | null;
        averageTimeBetweenRetrospectives?: number | null;
        inviteToken?: string | null;
        domains: Array<string>;
        status?: PaymentStatus | null;
        trialEndsAt?: number | null;
        owner?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        } | null;
        team?: {
          __typename?: 'Team';
          id: string;
          createdAt?: number | null;
          name: string;
          inviteToken?: string | null;
          archived?: boolean | null;
          type: TeamType;
          organizationId?: string | null;
          userCount?: number | null;
          teamCount: number;
          maxActiveImprovementGoals: number;
          watchers: Array<{ __typename?: 'User'; id: string }>;
          settings?: {
            __typename?: 'TeamSettings';
            id: string;
            defaultVoteCount: number;
            defaultPublicRetrospectives: boolean;
            defaultGuidedRetrospectives: boolean;
            tooltipHintsEnabled: boolean;
            businessType: TeamBusinessType;
            jiraMetricsEnabled: DisableFeatureState;
            githubMetricsEnabled: DisableFeatureState;
            bitbucketMetricsEnabled: DisableFeatureState;
            cardCategories?: Array<{
              __typename?: 'CardCategory';
              name: string;
              color: string;
            } | null> | null;
          } | null;
          members: Array<{
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          }>;
          children: Array<{
            __typename?: 'Team';
            id: string;
            name: string;
            type: TeamType;
            members: Array<{
              __typename?: 'User';
              id: string;
              name?: string | null;
              email: string;
            }>;
          }>;
          admin?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          } | null;
          parents?: Array<{
            __typename?: 'Team';
            id: string;
            name: string;
          }> | null;
        } | null;
        plan?: {
          __typename?: 'Plan';
          slug?: string | null;
          displayName?: string | null;
          blockExceedingSeats?: boolean | null;
          trialUserTimeSeconds?: number | null;
          programs?: boolean | null;
          integrationMicrosoftTeams?: boolean | null;
          integrationJira?: boolean | null;
          supportLevel?: SupportLevel | null;
          sso?: boolean | null;
        } | null;
      };
    }> | null;
  } | null;
};

export type CreateUserMutationVariables = Exact<{
  userInput?: InputMaybe<UserCreateInput>;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    role?: MembershipRole | null;
    betaFeatures: Array<BetaFeature>;
    announcementsModalSeen?: boolean | null;
    lastActiveAt?: number | null;
    createdAt?: number | null;
    verified?: boolean | null;
    facilitatorLabelsShown?: boolean | null;
    ssoControlled?: boolean | null;
    provider?: string | null;
    team?: {
      __typename?: 'Team';
      betaFeatures: Array<BetaFeature>;
      id: string;
      createdAt?: number | null;
      name: string;
      inviteToken?: string | null;
      archived?: boolean | null;
      type: TeamType;
      organizationId?: string | null;
      userCount?: number | null;
      teamCount: number;
      maxActiveImprovementGoals: number;
      watchers: Array<{ __typename?: 'User'; id: string }>;
      settings?: {
        __typename?: 'TeamSettings';
        id: string;
        defaultVoteCount: number;
        defaultPublicRetrospectives: boolean;
        defaultGuidedRetrospectives: boolean;
        tooltipHintsEnabled: boolean;
        businessType: TeamBusinessType;
        jiraMetricsEnabled: DisableFeatureState;
        githubMetricsEnabled: DisableFeatureState;
        bitbucketMetricsEnabled: DisableFeatureState;
        cardCategories?: Array<{
          __typename?: 'CardCategory';
          name: string;
          color: string;
        } | null> | null;
      } | null;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
      children: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
        type: TeamType;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
      }>;
      admin?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
      insights: Array<{
        __typename?: 'TeamInsightFactCauseSolution';
        id: string;
        seen: boolean;
        rated: boolean;
        date: number;
        team: { __typename?: 'Team'; id: string; name: string; type: TeamType };
        fact:
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricBelowRecommended';
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricDeterioration';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricImprovement';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricInadequacy';
              id: string;
              recommended: number;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactOutlierWorkItemTopic';
              id: string;
              topic: string;
            };
        causes?: Array<
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseMetricDeterioration';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequests';
              id: string;
              topic?: string | null;
              pullRequests: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequest';
                title: string;
                externalUrl?: string | null;
                slug?: string | null;
                origin?: SourceControlOrigin | null;
                wipTimeMs?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseOutlierWorkItems';
              id: string;
              topic?: string | null;
              workItems: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseWorkItem';
                slug?: string | null;
                title: string;
                wipTimeMs: number;
                origin?: WorkItemOrigin | null;
                url?: string | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseTopic';
              id: string;
              topic?: string | null;
              cards: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseTopicCard';
                text: string;
                badgeColor: string;
                badgeText: string;
                retroName: string;
                retroDate: number;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseUnreviewedPullRequests';
              id: string;
              topic?: string | null;
              pullRequests: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCausePullRequest';
                title: string;
                description?: string | null;
                externalUrl?: string | null;
                slug?: string | null;
                createdAt: string;
                origin?: SourceControlOrigin | null;
              }>;
            }
        > | null;
        solutions?: Array<
          Array<
            | {
                __typename?: 'TeamInsightFactCauseSolutionSolutionActionItem';
                id: string;
                text: string;
                originatedActionItem?: {
                  __typename?: 'ActionItem';
                  id: string;
                  title: string;
                  description?: string | null;
                  teamId: string;
                  resolvedAt?: number | null;
                  dueDate?: number | null;
                  authorId: string;
                  boardId?: string | null;
                  status: ActionItemStatus;
                  archived: boolean;
                  createdAt?: number | null;
                  children: Array<{
                    __typename?: 'ActionItemChild';
                    id: string;
                    text: string;
                  }>;
                  author?: { __typename?: 'Author'; name: string } | null;
                  assignees?: Array<{
                    __typename?: 'User';
                    id: string;
                    name?: string | null;
                  } | null> | null;
                  improvementGoals?: Array<{
                    __typename?: 'ImprovementGoal';
                    id: string;
                    type: { __typename?: 'ImprovementGoalType'; name: string };
                  } | null> | null;
                  jiraIssueLink?: {
                    __typename?: 'ActionItemJiraLink';
                    jiraIssueId?: string | null;
                    jiraIssueUrl?: string | null;
                    jiraIssueKey?: string | null;
                    jiraProjectKey?: string | null;
                  } | null;
                  comments: Array<{
                    __typename?: 'ActionItemComment';
                    id: string;
                    text: string;
                    actionItemId: string;
                    createdAt: number;
                    archived: boolean;
                    author: {
                      __typename?: 'User';
                      id: string;
                      name?: string | null;
                    };
                  }>;
                } | null;
              }
            | {
                __typename?: 'TeamInsightFactCauseSolutionSolutionGoal';
                id: string;
                type: {
                  __typename?: 'ImprovementGoalType';
                  id: string;
                  name: string;
                  color: string;
                  description?: string | null;
                  learnMoreUrl?: string | null;
                  unit: ImprovementGoalUnit;
                  categories: Array<ImprovementGoalTypeCategory>;
                  subCategory?: string | null;
                  targetType: ImprovementGoalTypeTargetType;
                  aggregationPeriod: ImprovementGoalAggregationPeriod;
                  aggregationFunction: ImprovementGoalAggregationFunction;
                  isPollable: boolean;
                  minValue?: number | null;
                  maxValue?: number | null;
                };
              }
          >
        > | null;
      }>;
    } | null;
    watchedTeams?: Array<{
      __typename?: 'Team';
      id: string;
      createdAt?: number | null;
      name: string;
      inviteToken?: string | null;
      archived?: boolean | null;
      type: TeamType;
      organizationId?: string | null;
      userCount?: number | null;
      teamCount: number;
      maxActiveImprovementGoals: number;
      watchers: Array<{ __typename?: 'User'; id: string }>;
      settings?: {
        __typename?: 'TeamSettings';
        id: string;
        defaultVoteCount: number;
        defaultPublicRetrospectives: boolean;
        defaultGuidedRetrospectives: boolean;
        tooltipHintsEnabled: boolean;
        businessType: TeamBusinessType;
        jiraMetricsEnabled: DisableFeatureState;
        githubMetricsEnabled: DisableFeatureState;
        bitbucketMetricsEnabled: DisableFeatureState;
        cardCategories?: Array<{
          __typename?: 'CardCategory';
          name: string;
          color: string;
        } | null> | null;
      } | null;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
      children: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
        type: TeamType;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
      }>;
      admin?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      betaFeatures: Array<BetaFeature>;
      id?: string | null;
      adminOnlyTeamCreation: boolean;
      ownerId?: string | null;
      name?: string | null;
      userCount?: number | null;
      prepaidSeatCount: number;
      numberOfTeams?: number | null;
      numberOfPrograms?: number | null;
      isExceedingSeats?: boolean | null;
      numberOfRetrospectivesHeld?: number | null;
      averageTimeBetweenRetrospectives?: number | null;
      inviteToken?: string | null;
      domains: Array<string>;
      hasSSO?: boolean | null;
      status?: PaymentStatus | null;
      trialEndsAt?: number | null;
      owner?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      team?: {
        __typename?: 'Team';
        id: string;
        createdAt?: number | null;
        name: string;
        inviteToken?: string | null;
        archived?: boolean | null;
        type: TeamType;
        organizationId?: string | null;
        userCount?: number | null;
        teamCount: number;
        maxActiveImprovementGoals: number;
        watchers: Array<{ __typename?: 'User'; id: string }>;
        settings?: {
          __typename?: 'TeamSettings';
          id: string;
          defaultVoteCount: number;
          defaultPublicRetrospectives: boolean;
          defaultGuidedRetrospectives: boolean;
          tooltipHintsEnabled: boolean;
          businessType: TeamBusinessType;
          jiraMetricsEnabled: DisableFeatureState;
          githubMetricsEnabled: DisableFeatureState;
          bitbucketMetricsEnabled: DisableFeatureState;
          cardCategories?: Array<{
            __typename?: 'CardCategory';
            name: string;
            color: string;
          } | null> | null;
        } | null;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
        children: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
          type: TeamType;
          members: Array<{
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          }>;
        }>;
        admin?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        } | null;
        parents?: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
        }> | null;
      } | null;
      plan?: {
        __typename?: 'Plan';
        slug?: string | null;
        displayName?: string | null;
        blockExceedingSeats?: boolean | null;
        trialUserTimeSeconds?: number | null;
        programs?: boolean | null;
        integrationMicrosoftTeams?: boolean | null;
        integrationJira?: boolean | null;
        supportLevel?: SupportLevel | null;
        sso?: boolean | null;
      } | null;
    } | null;
    memberships?: Array<{
      __typename?: 'OrganizationUserMembership';
      role: MembershipRole;
      trialEndsAt?: number | null;
      archived: boolean;
      joinedAt?: number | null;
      organization: {
        __typename?: 'Organization';
        hasSSO?: boolean | null;
        id?: string | null;
        adminOnlyTeamCreation: boolean;
        ownerId?: string | null;
        name?: string | null;
        userCount?: number | null;
        prepaidSeatCount: number;
        numberOfTeams?: number | null;
        numberOfPrograms?: number | null;
        isExceedingSeats?: boolean | null;
        numberOfRetrospectivesHeld?: number | null;
        averageTimeBetweenRetrospectives?: number | null;
        inviteToken?: string | null;
        domains: Array<string>;
        status?: PaymentStatus | null;
        trialEndsAt?: number | null;
        owner?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        } | null;
        team?: {
          __typename?: 'Team';
          id: string;
          createdAt?: number | null;
          name: string;
          inviteToken?: string | null;
          archived?: boolean | null;
          type: TeamType;
          organizationId?: string | null;
          userCount?: number | null;
          teamCount: number;
          maxActiveImprovementGoals: number;
          watchers: Array<{ __typename?: 'User'; id: string }>;
          settings?: {
            __typename?: 'TeamSettings';
            id: string;
            defaultVoteCount: number;
            defaultPublicRetrospectives: boolean;
            defaultGuidedRetrospectives: boolean;
            tooltipHintsEnabled: boolean;
            businessType: TeamBusinessType;
            jiraMetricsEnabled: DisableFeatureState;
            githubMetricsEnabled: DisableFeatureState;
            bitbucketMetricsEnabled: DisableFeatureState;
            cardCategories?: Array<{
              __typename?: 'CardCategory';
              name: string;
              color: string;
            } | null> | null;
          } | null;
          members: Array<{
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          }>;
          children: Array<{
            __typename?: 'Team';
            id: string;
            name: string;
            type: TeamType;
            members: Array<{
              __typename?: 'User';
              id: string;
              name?: string | null;
              email: string;
            }>;
          }>;
          admin?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          } | null;
          parents?: Array<{
            __typename?: 'Team';
            id: string;
            name: string;
          }> | null;
        } | null;
        plan?: {
          __typename?: 'Plan';
          slug?: string | null;
          displayName?: string | null;
          blockExceedingSeats?: boolean | null;
          trialUserTimeSeconds?: number | null;
          programs?: boolean | null;
          integrationMicrosoftTeams?: boolean | null;
          integrationJira?: boolean | null;
          supportLevel?: SupportLevel | null;
          sso?: boolean | null;
        } | null;
      };
    }> | null;
  } | null;
};

export type DeleteOwnAccountMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteOwnAccountMutation = {
  __typename?: 'Mutation';
  deleteOwnAccount: boolean;
};

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  isChangingProviders: Scalars['Boolean'];
}>;

export type ForgotPasswordMutation = {
  __typename?: 'Mutation';
  forgotPassword?: boolean | null;
};

export type LeaveTeamMutationVariables = Exact<{
  teamId: Scalars['ID'];
  userId: Scalars['String'];
}>;

export type LeaveTeamMutation = {
  __typename?: 'Mutation';
  leaveTeam?: {
    __typename?: 'Team';
    id: string;
    createdAt?: number | null;
    name: string;
    inviteToken?: string | null;
    archived?: boolean | null;
    type: TeamType;
    organizationId?: string | null;
    userCount?: number | null;
    teamCount: number;
    maxActiveImprovementGoals: number;
    watchers: Array<{ __typename?: 'User'; id: string }>;
    settings?: {
      __typename?: 'TeamSettings';
      id: string;
      defaultVoteCount: number;
      defaultPublicRetrospectives: boolean;
      defaultGuidedRetrospectives: boolean;
      tooltipHintsEnabled: boolean;
      businessType: TeamBusinessType;
      jiraMetricsEnabled: DisableFeatureState;
      githubMetricsEnabled: DisableFeatureState;
      bitbucketMetricsEnabled: DisableFeatureState;
      cardCategories?: Array<{
        __typename?: 'CardCategory';
        name: string;
        color: string;
      } | null> | null;
    } | null;
    members: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    }>;
    children: Array<{
      __typename?: 'Team';
      id: string;
      name: string;
      type: TeamType;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
    }>;
    admin?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
    } | null;
    parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
  } | null;
};

export type LoginMutationVariables = Exact<{
  loginInput?: InputMaybe<LoginInput>;
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    role?: MembershipRole | null;
    betaFeatures: Array<BetaFeature>;
    announcementsModalSeen?: boolean | null;
    lastActiveAt?: number | null;
    createdAt?: number | null;
    verified?: boolean | null;
    facilitatorLabelsShown?: boolean | null;
    ssoControlled?: boolean | null;
    provider?: string | null;
    team?: {
      __typename?: 'Team';
      betaFeatures: Array<BetaFeature>;
      id: string;
      createdAt?: number | null;
      name: string;
      inviteToken?: string | null;
      archived?: boolean | null;
      type: TeamType;
      organizationId?: string | null;
      userCount?: number | null;
      teamCount: number;
      maxActiveImprovementGoals: number;
      watchers: Array<{ __typename?: 'User'; id: string }>;
      settings?: {
        __typename?: 'TeamSettings';
        id: string;
        defaultVoteCount: number;
        defaultPublicRetrospectives: boolean;
        defaultGuidedRetrospectives: boolean;
        tooltipHintsEnabled: boolean;
        businessType: TeamBusinessType;
        jiraMetricsEnabled: DisableFeatureState;
        githubMetricsEnabled: DisableFeatureState;
        bitbucketMetricsEnabled: DisableFeatureState;
        cardCategories?: Array<{
          __typename?: 'CardCategory';
          name: string;
          color: string;
        } | null> | null;
      } | null;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
      children: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
        type: TeamType;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
      }>;
      admin?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
      insights: Array<{
        __typename?: 'TeamInsightFactCauseSolution';
        id: string;
        seen: boolean;
        rated: boolean;
        date: number;
        team: { __typename?: 'Team'; id: string; name: string; type: TeamType };
        fact:
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricBelowRecommended';
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricDeterioration';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricImprovement';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricInadequacy';
              id: string;
              recommended: number;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactOutlierWorkItemTopic';
              id: string;
              topic: string;
            };
        causes?: Array<
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseMetricDeterioration';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequests';
              id: string;
              topic?: string | null;
              pullRequests: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequest';
                title: string;
                externalUrl?: string | null;
                slug?: string | null;
                origin?: SourceControlOrigin | null;
                wipTimeMs?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseOutlierWorkItems';
              id: string;
              topic?: string | null;
              workItems: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseWorkItem';
                slug?: string | null;
                title: string;
                wipTimeMs: number;
                origin?: WorkItemOrigin | null;
                url?: string | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseTopic';
              id: string;
              topic?: string | null;
              cards: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseTopicCard';
                text: string;
                badgeColor: string;
                badgeText: string;
                retroName: string;
                retroDate: number;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseUnreviewedPullRequests';
              id: string;
              topic?: string | null;
              pullRequests: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCausePullRequest';
                title: string;
                description?: string | null;
                externalUrl?: string | null;
                slug?: string | null;
                createdAt: string;
                origin?: SourceControlOrigin | null;
              }>;
            }
        > | null;
        solutions?: Array<
          Array<
            | {
                __typename?: 'TeamInsightFactCauseSolutionSolutionActionItem';
                id: string;
                text: string;
                originatedActionItem?: {
                  __typename?: 'ActionItem';
                  id: string;
                  title: string;
                  description?: string | null;
                  teamId: string;
                  resolvedAt?: number | null;
                  dueDate?: number | null;
                  authorId: string;
                  boardId?: string | null;
                  status: ActionItemStatus;
                  archived: boolean;
                  createdAt?: number | null;
                  children: Array<{
                    __typename?: 'ActionItemChild';
                    id: string;
                    text: string;
                  }>;
                  author?: { __typename?: 'Author'; name: string } | null;
                  assignees?: Array<{
                    __typename?: 'User';
                    id: string;
                    name?: string | null;
                  } | null> | null;
                  improvementGoals?: Array<{
                    __typename?: 'ImprovementGoal';
                    id: string;
                    type: { __typename?: 'ImprovementGoalType'; name: string };
                  } | null> | null;
                  jiraIssueLink?: {
                    __typename?: 'ActionItemJiraLink';
                    jiraIssueId?: string | null;
                    jiraIssueUrl?: string | null;
                    jiraIssueKey?: string | null;
                    jiraProjectKey?: string | null;
                  } | null;
                  comments: Array<{
                    __typename?: 'ActionItemComment';
                    id: string;
                    text: string;
                    actionItemId: string;
                    createdAt: number;
                    archived: boolean;
                    author: {
                      __typename?: 'User';
                      id: string;
                      name?: string | null;
                    };
                  }>;
                } | null;
              }
            | {
                __typename?: 'TeamInsightFactCauseSolutionSolutionGoal';
                id: string;
                type: {
                  __typename?: 'ImprovementGoalType';
                  id: string;
                  name: string;
                  color: string;
                  description?: string | null;
                  learnMoreUrl?: string | null;
                  unit: ImprovementGoalUnit;
                  categories: Array<ImprovementGoalTypeCategory>;
                  subCategory?: string | null;
                  targetType: ImprovementGoalTypeTargetType;
                  aggregationPeriod: ImprovementGoalAggregationPeriod;
                  aggregationFunction: ImprovementGoalAggregationFunction;
                  isPollable: boolean;
                  minValue?: number | null;
                  maxValue?: number | null;
                };
              }
          >
        > | null;
      }>;
    } | null;
    watchedTeams?: Array<{
      __typename?: 'Team';
      id: string;
      createdAt?: number | null;
      name: string;
      inviteToken?: string | null;
      archived?: boolean | null;
      type: TeamType;
      organizationId?: string | null;
      userCount?: number | null;
      teamCount: number;
      maxActiveImprovementGoals: number;
      watchers: Array<{ __typename?: 'User'; id: string }>;
      settings?: {
        __typename?: 'TeamSettings';
        id: string;
        defaultVoteCount: number;
        defaultPublicRetrospectives: boolean;
        defaultGuidedRetrospectives: boolean;
        tooltipHintsEnabled: boolean;
        businessType: TeamBusinessType;
        jiraMetricsEnabled: DisableFeatureState;
        githubMetricsEnabled: DisableFeatureState;
        bitbucketMetricsEnabled: DisableFeatureState;
        cardCategories?: Array<{
          __typename?: 'CardCategory';
          name: string;
          color: string;
        } | null> | null;
      } | null;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
      children: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
        type: TeamType;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
      }>;
      admin?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      betaFeatures: Array<BetaFeature>;
      id?: string | null;
      adminOnlyTeamCreation: boolean;
      ownerId?: string | null;
      name?: string | null;
      userCount?: number | null;
      prepaidSeatCount: number;
      numberOfTeams?: number | null;
      numberOfPrograms?: number | null;
      isExceedingSeats?: boolean | null;
      numberOfRetrospectivesHeld?: number | null;
      averageTimeBetweenRetrospectives?: number | null;
      inviteToken?: string | null;
      domains: Array<string>;
      hasSSO?: boolean | null;
      status?: PaymentStatus | null;
      trialEndsAt?: number | null;
      owner?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      team?: {
        __typename?: 'Team';
        id: string;
        createdAt?: number | null;
        name: string;
        inviteToken?: string | null;
        archived?: boolean | null;
        type: TeamType;
        organizationId?: string | null;
        userCount?: number | null;
        teamCount: number;
        maxActiveImprovementGoals: number;
        watchers: Array<{ __typename?: 'User'; id: string }>;
        settings?: {
          __typename?: 'TeamSettings';
          id: string;
          defaultVoteCount: number;
          defaultPublicRetrospectives: boolean;
          defaultGuidedRetrospectives: boolean;
          tooltipHintsEnabled: boolean;
          businessType: TeamBusinessType;
          jiraMetricsEnabled: DisableFeatureState;
          githubMetricsEnabled: DisableFeatureState;
          bitbucketMetricsEnabled: DisableFeatureState;
          cardCategories?: Array<{
            __typename?: 'CardCategory';
            name: string;
            color: string;
          } | null> | null;
        } | null;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
        children: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
          type: TeamType;
          members: Array<{
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          }>;
        }>;
        admin?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        } | null;
        parents?: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
        }> | null;
      } | null;
      plan?: {
        __typename?: 'Plan';
        slug?: string | null;
        displayName?: string | null;
        blockExceedingSeats?: boolean | null;
        trialUserTimeSeconds?: number | null;
        programs?: boolean | null;
        integrationMicrosoftTeams?: boolean | null;
        integrationJira?: boolean | null;
        supportLevel?: SupportLevel | null;
        sso?: boolean | null;
      } | null;
    } | null;
    memberships?: Array<{
      __typename?: 'OrganizationUserMembership';
      role: MembershipRole;
      trialEndsAt?: number | null;
      archived: boolean;
      joinedAt?: number | null;
      organization: {
        __typename?: 'Organization';
        hasSSO?: boolean | null;
        id?: string | null;
        adminOnlyTeamCreation: boolean;
        ownerId?: string | null;
        name?: string | null;
        userCount?: number | null;
        prepaidSeatCount: number;
        numberOfTeams?: number | null;
        numberOfPrograms?: number | null;
        isExceedingSeats?: boolean | null;
        numberOfRetrospectivesHeld?: number | null;
        averageTimeBetweenRetrospectives?: number | null;
        inviteToken?: string | null;
        domains: Array<string>;
        status?: PaymentStatus | null;
        trialEndsAt?: number | null;
        owner?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        } | null;
        team?: {
          __typename?: 'Team';
          id: string;
          createdAt?: number | null;
          name: string;
          inviteToken?: string | null;
          archived?: boolean | null;
          type: TeamType;
          organizationId?: string | null;
          userCount?: number | null;
          teamCount: number;
          maxActiveImprovementGoals: number;
          watchers: Array<{ __typename?: 'User'; id: string }>;
          settings?: {
            __typename?: 'TeamSettings';
            id: string;
            defaultVoteCount: number;
            defaultPublicRetrospectives: boolean;
            defaultGuidedRetrospectives: boolean;
            tooltipHintsEnabled: boolean;
            businessType: TeamBusinessType;
            jiraMetricsEnabled: DisableFeatureState;
            githubMetricsEnabled: DisableFeatureState;
            bitbucketMetricsEnabled: DisableFeatureState;
            cardCategories?: Array<{
              __typename?: 'CardCategory';
              name: string;
              color: string;
            } | null> | null;
          } | null;
          members: Array<{
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          }>;
          children: Array<{
            __typename?: 'Team';
            id: string;
            name: string;
            type: TeamType;
            members: Array<{
              __typename?: 'User';
              id: string;
              name?: string | null;
              email: string;
            }>;
          }>;
          admin?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          } | null;
          parents?: Array<{
            __typename?: 'Team';
            id: string;
            name: string;
          }> | null;
        } | null;
        plan?: {
          __typename?: 'Plan';
          slug?: string | null;
          displayName?: string | null;
          blockExceedingSeats?: boolean | null;
          trialUserTimeSeconds?: number | null;
          programs?: boolean | null;
          integrationMicrosoftTeams?: boolean | null;
          integrationJira?: boolean | null;
          supportLevel?: SupportLevel | null;
          sso?: boolean | null;
        } | null;
      };
    }> | null;
  } | null;
};

export type OneTimePasswordLoginMutationVariables = Exact<{
  oneTimePasswordLoginInput?: InputMaybe<OneTimePasswordLoginInput>;
}>;

export type OneTimePasswordLoginMutation = {
  __typename?: 'Mutation';
  oneTimePasswordLogin?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    role?: MembershipRole | null;
    betaFeatures: Array<BetaFeature>;
    announcementsModalSeen?: boolean | null;
    lastActiveAt?: number | null;
    createdAt?: number | null;
    verified?: boolean | null;
    facilitatorLabelsShown?: boolean | null;
    ssoControlled?: boolean | null;
    provider?: string | null;
    team?: {
      __typename?: 'Team';
      betaFeatures: Array<BetaFeature>;
      id: string;
      createdAt?: number | null;
      name: string;
      inviteToken?: string | null;
      archived?: boolean | null;
      type: TeamType;
      organizationId?: string | null;
      userCount?: number | null;
      teamCount: number;
      maxActiveImprovementGoals: number;
      watchers: Array<{ __typename?: 'User'; id: string }>;
      settings?: {
        __typename?: 'TeamSettings';
        id: string;
        defaultVoteCount: number;
        defaultPublicRetrospectives: boolean;
        defaultGuidedRetrospectives: boolean;
        tooltipHintsEnabled: boolean;
        businessType: TeamBusinessType;
        jiraMetricsEnabled: DisableFeatureState;
        githubMetricsEnabled: DisableFeatureState;
        bitbucketMetricsEnabled: DisableFeatureState;
        cardCategories?: Array<{
          __typename?: 'CardCategory';
          name: string;
          color: string;
        } | null> | null;
      } | null;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
      children: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
        type: TeamType;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
      }>;
      admin?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
      insights: Array<{
        __typename?: 'TeamInsightFactCauseSolution';
        id: string;
        seen: boolean;
        rated: boolean;
        date: number;
        team: { __typename?: 'Team'; id: string; name: string; type: TeamType };
        fact:
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricBelowRecommended';
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricDeterioration';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricImprovement';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricInadequacy';
              id: string;
              recommended: number;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactOutlierWorkItemTopic';
              id: string;
              topic: string;
            };
        causes?: Array<
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseMetricDeterioration';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequests';
              id: string;
              topic?: string | null;
              pullRequests: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequest';
                title: string;
                externalUrl?: string | null;
                slug?: string | null;
                origin?: SourceControlOrigin | null;
                wipTimeMs?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseOutlierWorkItems';
              id: string;
              topic?: string | null;
              workItems: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseWorkItem';
                slug?: string | null;
                title: string;
                wipTimeMs: number;
                origin?: WorkItemOrigin | null;
                url?: string | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseTopic';
              id: string;
              topic?: string | null;
              cards: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseTopicCard';
                text: string;
                badgeColor: string;
                badgeText: string;
                retroName: string;
                retroDate: number;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseUnreviewedPullRequests';
              id: string;
              topic?: string | null;
              pullRequests: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCausePullRequest';
                title: string;
                description?: string | null;
                externalUrl?: string | null;
                slug?: string | null;
                createdAt: string;
                origin?: SourceControlOrigin | null;
              }>;
            }
        > | null;
        solutions?: Array<
          Array<
            | {
                __typename?: 'TeamInsightFactCauseSolutionSolutionActionItem';
                id: string;
                text: string;
                originatedActionItem?: {
                  __typename?: 'ActionItem';
                  id: string;
                  title: string;
                  description?: string | null;
                  teamId: string;
                  resolvedAt?: number | null;
                  dueDate?: number | null;
                  authorId: string;
                  boardId?: string | null;
                  status: ActionItemStatus;
                  archived: boolean;
                  createdAt?: number | null;
                  children: Array<{
                    __typename?: 'ActionItemChild';
                    id: string;
                    text: string;
                  }>;
                  author?: { __typename?: 'Author'; name: string } | null;
                  assignees?: Array<{
                    __typename?: 'User';
                    id: string;
                    name?: string | null;
                  } | null> | null;
                  improvementGoals?: Array<{
                    __typename?: 'ImprovementGoal';
                    id: string;
                    type: { __typename?: 'ImprovementGoalType'; name: string };
                  } | null> | null;
                  jiraIssueLink?: {
                    __typename?: 'ActionItemJiraLink';
                    jiraIssueId?: string | null;
                    jiraIssueUrl?: string | null;
                    jiraIssueKey?: string | null;
                    jiraProjectKey?: string | null;
                  } | null;
                  comments: Array<{
                    __typename?: 'ActionItemComment';
                    id: string;
                    text: string;
                    actionItemId: string;
                    createdAt: number;
                    archived: boolean;
                    author: {
                      __typename?: 'User';
                      id: string;
                      name?: string | null;
                    };
                  }>;
                } | null;
              }
            | {
                __typename?: 'TeamInsightFactCauseSolutionSolutionGoal';
                id: string;
                type: {
                  __typename?: 'ImprovementGoalType';
                  id: string;
                  name: string;
                  color: string;
                  description?: string | null;
                  learnMoreUrl?: string | null;
                  unit: ImprovementGoalUnit;
                  categories: Array<ImprovementGoalTypeCategory>;
                  subCategory?: string | null;
                  targetType: ImprovementGoalTypeTargetType;
                  aggregationPeriod: ImprovementGoalAggregationPeriod;
                  aggregationFunction: ImprovementGoalAggregationFunction;
                  isPollable: boolean;
                  minValue?: number | null;
                  maxValue?: number | null;
                };
              }
          >
        > | null;
      }>;
    } | null;
    watchedTeams?: Array<{
      __typename?: 'Team';
      id: string;
      createdAt?: number | null;
      name: string;
      inviteToken?: string | null;
      archived?: boolean | null;
      type: TeamType;
      organizationId?: string | null;
      userCount?: number | null;
      teamCount: number;
      maxActiveImprovementGoals: number;
      watchers: Array<{ __typename?: 'User'; id: string }>;
      settings?: {
        __typename?: 'TeamSettings';
        id: string;
        defaultVoteCount: number;
        defaultPublicRetrospectives: boolean;
        defaultGuidedRetrospectives: boolean;
        tooltipHintsEnabled: boolean;
        businessType: TeamBusinessType;
        jiraMetricsEnabled: DisableFeatureState;
        githubMetricsEnabled: DisableFeatureState;
        bitbucketMetricsEnabled: DisableFeatureState;
        cardCategories?: Array<{
          __typename?: 'CardCategory';
          name: string;
          color: string;
        } | null> | null;
      } | null;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
      children: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
        type: TeamType;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
      }>;
      admin?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      betaFeatures: Array<BetaFeature>;
      id?: string | null;
      adminOnlyTeamCreation: boolean;
      ownerId?: string | null;
      name?: string | null;
      userCount?: number | null;
      prepaidSeatCount: number;
      numberOfTeams?: number | null;
      numberOfPrograms?: number | null;
      isExceedingSeats?: boolean | null;
      numberOfRetrospectivesHeld?: number | null;
      averageTimeBetweenRetrospectives?: number | null;
      inviteToken?: string | null;
      domains: Array<string>;
      hasSSO?: boolean | null;
      status?: PaymentStatus | null;
      trialEndsAt?: number | null;
      owner?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      team?: {
        __typename?: 'Team';
        id: string;
        createdAt?: number | null;
        name: string;
        inviteToken?: string | null;
        archived?: boolean | null;
        type: TeamType;
        organizationId?: string | null;
        userCount?: number | null;
        teamCount: number;
        maxActiveImprovementGoals: number;
        watchers: Array<{ __typename?: 'User'; id: string }>;
        settings?: {
          __typename?: 'TeamSettings';
          id: string;
          defaultVoteCount: number;
          defaultPublicRetrospectives: boolean;
          defaultGuidedRetrospectives: boolean;
          tooltipHintsEnabled: boolean;
          businessType: TeamBusinessType;
          jiraMetricsEnabled: DisableFeatureState;
          githubMetricsEnabled: DisableFeatureState;
          bitbucketMetricsEnabled: DisableFeatureState;
          cardCategories?: Array<{
            __typename?: 'CardCategory';
            name: string;
            color: string;
          } | null> | null;
        } | null;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
        children: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
          type: TeamType;
          members: Array<{
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          }>;
        }>;
        admin?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        } | null;
        parents?: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
        }> | null;
      } | null;
      plan?: {
        __typename?: 'Plan';
        slug?: string | null;
        displayName?: string | null;
        blockExceedingSeats?: boolean | null;
        trialUserTimeSeconds?: number | null;
        programs?: boolean | null;
        integrationMicrosoftTeams?: boolean | null;
        integrationJira?: boolean | null;
        supportLevel?: SupportLevel | null;
        sso?: boolean | null;
      } | null;
    } | null;
    memberships?: Array<{
      __typename?: 'OrganizationUserMembership';
      role: MembershipRole;
      trialEndsAt?: number | null;
      archived: boolean;
      joinedAt?: number | null;
      organization: {
        __typename?: 'Organization';
        hasSSO?: boolean | null;
        id?: string | null;
        adminOnlyTeamCreation: boolean;
        ownerId?: string | null;
        name?: string | null;
        userCount?: number | null;
        prepaidSeatCount: number;
        numberOfTeams?: number | null;
        numberOfPrograms?: number | null;
        isExceedingSeats?: boolean | null;
        numberOfRetrospectivesHeld?: number | null;
        averageTimeBetweenRetrospectives?: number | null;
        inviteToken?: string | null;
        domains: Array<string>;
        status?: PaymentStatus | null;
        trialEndsAt?: number | null;
        owner?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        } | null;
        team?: {
          __typename?: 'Team';
          id: string;
          createdAt?: number | null;
          name: string;
          inviteToken?: string | null;
          archived?: boolean | null;
          type: TeamType;
          organizationId?: string | null;
          userCount?: number | null;
          teamCount: number;
          maxActiveImprovementGoals: number;
          watchers: Array<{ __typename?: 'User'; id: string }>;
          settings?: {
            __typename?: 'TeamSettings';
            id: string;
            defaultVoteCount: number;
            defaultPublicRetrospectives: boolean;
            defaultGuidedRetrospectives: boolean;
            tooltipHintsEnabled: boolean;
            businessType: TeamBusinessType;
            jiraMetricsEnabled: DisableFeatureState;
            githubMetricsEnabled: DisableFeatureState;
            bitbucketMetricsEnabled: DisableFeatureState;
            cardCategories?: Array<{
              __typename?: 'CardCategory';
              name: string;
              color: string;
            } | null> | null;
          } | null;
          members: Array<{
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          }>;
          children: Array<{
            __typename?: 'Team';
            id: string;
            name: string;
            type: TeamType;
            members: Array<{
              __typename?: 'User';
              id: string;
              name?: string | null;
              email: string;
            }>;
          }>;
          admin?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          } | null;
          parents?: Array<{
            __typename?: 'Team';
            id: string;
            name: string;
          }> | null;
        } | null;
        plan?: {
          __typename?: 'Plan';
          slug?: string | null;
          displayName?: string | null;
          blockExceedingSeats?: boolean | null;
          trialUserTimeSeconds?: number | null;
          programs?: boolean | null;
          integrationMicrosoftTeams?: boolean | null;
          integrationJira?: boolean | null;
          supportLevel?: SupportLevel | null;
          sso?: boolean | null;
        } | null;
      };
    }> | null;
  } | null;
};

export type ResendVerificationCodeMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type ResendVerificationCodeMutation = {
  __typename?: 'Mutation';
  createVerificationToken?: boolean | null;
};

export type ResetPasswordMutationVariables = Exact<{
  resetToken: Scalars['String'];
  newPassword: Scalars['String'];
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword?: {
    __typename?: 'User';
    id: string;
    email: string;
    name?: string | null;
    provider?: string | null;
  } | null;
};

export type TrialRegisterQuestionsMutationVariables = Exact<{
  email: Scalars['String'];
  questions?: InputMaybe<
    | Array<InputMaybe<TrialQuestionAnswerInput>>
    | InputMaybe<TrialQuestionAnswerInput>
  >;
  score: Scalars['Int'];
}>;

export type TrialRegisterQuestionsMutation = {
  __typename?: 'Mutation';
  trialRegisterQuestions?: string | null;
};

export type TrialRegisterMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type TrialRegisterMutation = {
  __typename?: 'Mutation';
  trialRegister?: string | null;
};

export type UpdateUserMutationVariables = Exact<{
  userInput: UserUpdateInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    role?: MembershipRole | null;
    betaFeatures: Array<BetaFeature>;
    announcementsModalSeen?: boolean | null;
    lastActiveAt?: number | null;
    createdAt?: number | null;
    verified?: boolean | null;
    facilitatorLabelsShown?: boolean | null;
    ssoControlled?: boolean | null;
    provider?: string | null;
    team?: {
      __typename?: 'Team';
      betaFeatures: Array<BetaFeature>;
      id: string;
      createdAt?: number | null;
      name: string;
      inviteToken?: string | null;
      archived?: boolean | null;
      type: TeamType;
      organizationId?: string | null;
      userCount?: number | null;
      teamCount: number;
      maxActiveImprovementGoals: number;
      watchers: Array<{ __typename?: 'User'; id: string }>;
      settings?: {
        __typename?: 'TeamSettings';
        id: string;
        defaultVoteCount: number;
        defaultPublicRetrospectives: boolean;
        defaultGuidedRetrospectives: boolean;
        tooltipHintsEnabled: boolean;
        businessType: TeamBusinessType;
        jiraMetricsEnabled: DisableFeatureState;
        githubMetricsEnabled: DisableFeatureState;
        bitbucketMetricsEnabled: DisableFeatureState;
        cardCategories?: Array<{
          __typename?: 'CardCategory';
          name: string;
          color: string;
        } | null> | null;
      } | null;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
      children: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
        type: TeamType;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
      }>;
      admin?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
      insights: Array<{
        __typename?: 'TeamInsightFactCauseSolution';
        id: string;
        seen: boolean;
        rated: boolean;
        date: number;
        team: { __typename?: 'Team'; id: string; name: string; type: TeamType };
        fact:
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricBelowRecommended';
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricDeterioration';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricImprovement';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricInadequacy';
              id: string;
              recommended: number;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactOutlierWorkItemTopic';
              id: string;
              topic: string;
            };
        causes?: Array<
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseMetricDeterioration';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequests';
              id: string;
              topic?: string | null;
              pullRequests: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequest';
                title: string;
                externalUrl?: string | null;
                slug?: string | null;
                origin?: SourceControlOrigin | null;
                wipTimeMs?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseOutlierWorkItems';
              id: string;
              topic?: string | null;
              workItems: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseWorkItem';
                slug?: string | null;
                title: string;
                wipTimeMs: number;
                origin?: WorkItemOrigin | null;
                url?: string | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseTopic';
              id: string;
              topic?: string | null;
              cards: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseTopicCard';
                text: string;
                badgeColor: string;
                badgeText: string;
                retroName: string;
                retroDate: number;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseUnreviewedPullRequests';
              id: string;
              topic?: string | null;
              pullRequests: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCausePullRequest';
                title: string;
                description?: string | null;
                externalUrl?: string | null;
                slug?: string | null;
                createdAt: string;
                origin?: SourceControlOrigin | null;
              }>;
            }
        > | null;
        solutions?: Array<
          Array<
            | {
                __typename?: 'TeamInsightFactCauseSolutionSolutionActionItem';
                id: string;
                text: string;
                originatedActionItem?: {
                  __typename?: 'ActionItem';
                  id: string;
                  title: string;
                  description?: string | null;
                  teamId: string;
                  resolvedAt?: number | null;
                  dueDate?: number | null;
                  authorId: string;
                  boardId?: string | null;
                  status: ActionItemStatus;
                  archived: boolean;
                  createdAt?: number | null;
                  children: Array<{
                    __typename?: 'ActionItemChild';
                    id: string;
                    text: string;
                  }>;
                  author?: { __typename?: 'Author'; name: string } | null;
                  assignees?: Array<{
                    __typename?: 'User';
                    id: string;
                    name?: string | null;
                  } | null> | null;
                  improvementGoals?: Array<{
                    __typename?: 'ImprovementGoal';
                    id: string;
                    type: { __typename?: 'ImprovementGoalType'; name: string };
                  } | null> | null;
                  jiraIssueLink?: {
                    __typename?: 'ActionItemJiraLink';
                    jiraIssueId?: string | null;
                    jiraIssueUrl?: string | null;
                    jiraIssueKey?: string | null;
                    jiraProjectKey?: string | null;
                  } | null;
                  comments: Array<{
                    __typename?: 'ActionItemComment';
                    id: string;
                    text: string;
                    actionItemId: string;
                    createdAt: number;
                    archived: boolean;
                    author: {
                      __typename?: 'User';
                      id: string;
                      name?: string | null;
                    };
                  }>;
                } | null;
              }
            | {
                __typename?: 'TeamInsightFactCauseSolutionSolutionGoal';
                id: string;
                type: {
                  __typename?: 'ImprovementGoalType';
                  id: string;
                  name: string;
                  color: string;
                  description?: string | null;
                  learnMoreUrl?: string | null;
                  unit: ImprovementGoalUnit;
                  categories: Array<ImprovementGoalTypeCategory>;
                  subCategory?: string | null;
                  targetType: ImprovementGoalTypeTargetType;
                  aggregationPeriod: ImprovementGoalAggregationPeriod;
                  aggregationFunction: ImprovementGoalAggregationFunction;
                  isPollable: boolean;
                  minValue?: number | null;
                  maxValue?: number | null;
                };
              }
          >
        > | null;
      }>;
    } | null;
    watchedTeams?: Array<{
      __typename?: 'Team';
      id: string;
      createdAt?: number | null;
      name: string;
      inviteToken?: string | null;
      archived?: boolean | null;
      type: TeamType;
      organizationId?: string | null;
      userCount?: number | null;
      teamCount: number;
      maxActiveImprovementGoals: number;
      watchers: Array<{ __typename?: 'User'; id: string }>;
      settings?: {
        __typename?: 'TeamSettings';
        id: string;
        defaultVoteCount: number;
        defaultPublicRetrospectives: boolean;
        defaultGuidedRetrospectives: boolean;
        tooltipHintsEnabled: boolean;
        businessType: TeamBusinessType;
        jiraMetricsEnabled: DisableFeatureState;
        githubMetricsEnabled: DisableFeatureState;
        bitbucketMetricsEnabled: DisableFeatureState;
        cardCategories?: Array<{
          __typename?: 'CardCategory';
          name: string;
          color: string;
        } | null> | null;
      } | null;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
      children: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
        type: TeamType;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
      }>;
      admin?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      betaFeatures: Array<BetaFeature>;
      id?: string | null;
      adminOnlyTeamCreation: boolean;
      ownerId?: string | null;
      name?: string | null;
      userCount?: number | null;
      prepaidSeatCount: number;
      numberOfTeams?: number | null;
      numberOfPrograms?: number | null;
      isExceedingSeats?: boolean | null;
      numberOfRetrospectivesHeld?: number | null;
      averageTimeBetweenRetrospectives?: number | null;
      inviteToken?: string | null;
      domains: Array<string>;
      hasSSO?: boolean | null;
      status?: PaymentStatus | null;
      trialEndsAt?: number | null;
      owner?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      team?: {
        __typename?: 'Team';
        id: string;
        createdAt?: number | null;
        name: string;
        inviteToken?: string | null;
        archived?: boolean | null;
        type: TeamType;
        organizationId?: string | null;
        userCount?: number | null;
        teamCount: number;
        maxActiveImprovementGoals: number;
        watchers: Array<{ __typename?: 'User'; id: string }>;
        settings?: {
          __typename?: 'TeamSettings';
          id: string;
          defaultVoteCount: number;
          defaultPublicRetrospectives: boolean;
          defaultGuidedRetrospectives: boolean;
          tooltipHintsEnabled: boolean;
          businessType: TeamBusinessType;
          jiraMetricsEnabled: DisableFeatureState;
          githubMetricsEnabled: DisableFeatureState;
          bitbucketMetricsEnabled: DisableFeatureState;
          cardCategories?: Array<{
            __typename?: 'CardCategory';
            name: string;
            color: string;
          } | null> | null;
        } | null;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
        children: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
          type: TeamType;
          members: Array<{
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          }>;
        }>;
        admin?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        } | null;
        parents?: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
        }> | null;
      } | null;
      plan?: {
        __typename?: 'Plan';
        slug?: string | null;
        displayName?: string | null;
        blockExceedingSeats?: boolean | null;
        trialUserTimeSeconds?: number | null;
        programs?: boolean | null;
        integrationMicrosoftTeams?: boolean | null;
        integrationJira?: boolean | null;
        supportLevel?: SupportLevel | null;
        sso?: boolean | null;
      } | null;
    } | null;
    memberships?: Array<{
      __typename?: 'OrganizationUserMembership';
      role: MembershipRole;
      trialEndsAt?: number | null;
      archived: boolean;
      joinedAt?: number | null;
      organization: {
        __typename?: 'Organization';
        hasSSO?: boolean | null;
        id?: string | null;
        adminOnlyTeamCreation: boolean;
        ownerId?: string | null;
        name?: string | null;
        userCount?: number | null;
        prepaidSeatCount: number;
        numberOfTeams?: number | null;
        numberOfPrograms?: number | null;
        isExceedingSeats?: boolean | null;
        numberOfRetrospectivesHeld?: number | null;
        averageTimeBetweenRetrospectives?: number | null;
        inviteToken?: string | null;
        domains: Array<string>;
        status?: PaymentStatus | null;
        trialEndsAt?: number | null;
        owner?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        } | null;
        team?: {
          __typename?: 'Team';
          id: string;
          createdAt?: number | null;
          name: string;
          inviteToken?: string | null;
          archived?: boolean | null;
          type: TeamType;
          organizationId?: string | null;
          userCount?: number | null;
          teamCount: number;
          maxActiveImprovementGoals: number;
          watchers: Array<{ __typename?: 'User'; id: string }>;
          settings?: {
            __typename?: 'TeamSettings';
            id: string;
            defaultVoteCount: number;
            defaultPublicRetrospectives: boolean;
            defaultGuidedRetrospectives: boolean;
            tooltipHintsEnabled: boolean;
            businessType: TeamBusinessType;
            jiraMetricsEnabled: DisableFeatureState;
            githubMetricsEnabled: DisableFeatureState;
            bitbucketMetricsEnabled: DisableFeatureState;
            cardCategories?: Array<{
              __typename?: 'CardCategory';
              name: string;
              color: string;
            } | null> | null;
          } | null;
          members: Array<{
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          }>;
          children: Array<{
            __typename?: 'Team';
            id: string;
            name: string;
            type: TeamType;
            members: Array<{
              __typename?: 'User';
              id: string;
              name?: string | null;
              email: string;
            }>;
          }>;
          admin?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          } | null;
          parents?: Array<{
            __typename?: 'Team';
            id: string;
            name: string;
          }> | null;
        } | null;
        plan?: {
          __typename?: 'Plan';
          slug?: string | null;
          displayName?: string | null;
          blockExceedingSeats?: boolean | null;
          trialUserTimeSeconds?: number | null;
          programs?: boolean | null;
          integrationMicrosoftTeams?: boolean | null;
          integrationJira?: boolean | null;
          supportLevel?: SupportLevel | null;
          sso?: boolean | null;
        } | null;
      };
    }> | null;
  };
};

export type VerifyEmailMutationVariables = Exact<{
  email: Scalars['String'];
  verificationToken: Scalars['String'];
}>;

export type VerifyEmailMutation = {
  __typename?: 'Mutation';
  verifyEmail?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    role?: MembershipRole | null;
    betaFeatures: Array<BetaFeature>;
    announcementsModalSeen?: boolean | null;
    lastActiveAt?: number | null;
    createdAt?: number | null;
    verified?: boolean | null;
    facilitatorLabelsShown?: boolean | null;
    ssoControlled?: boolean | null;
    provider?: string | null;
    team?: {
      __typename?: 'Team';
      betaFeatures: Array<BetaFeature>;
      id: string;
      createdAt?: number | null;
      name: string;
      inviteToken?: string | null;
      archived?: boolean | null;
      type: TeamType;
      organizationId?: string | null;
      userCount?: number | null;
      teamCount: number;
      maxActiveImprovementGoals: number;
      watchers: Array<{ __typename?: 'User'; id: string }>;
      settings?: {
        __typename?: 'TeamSettings';
        id: string;
        defaultVoteCount: number;
        defaultPublicRetrospectives: boolean;
        defaultGuidedRetrospectives: boolean;
        tooltipHintsEnabled: boolean;
        businessType: TeamBusinessType;
        jiraMetricsEnabled: DisableFeatureState;
        githubMetricsEnabled: DisableFeatureState;
        bitbucketMetricsEnabled: DisableFeatureState;
        cardCategories?: Array<{
          __typename?: 'CardCategory';
          name: string;
          color: string;
        } | null> | null;
      } | null;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
      children: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
        type: TeamType;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
      }>;
      admin?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
      insights: Array<{
        __typename?: 'TeamInsightFactCauseSolution';
        id: string;
        seen: boolean;
        rated: boolean;
        date: number;
        team: { __typename?: 'Team'; id: string; name: string; type: TeamType };
        fact:
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricBelowRecommended';
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricDeterioration';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricImprovement';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricInadequacy';
              id: string;
              recommended: number;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactOutlierWorkItemTopic';
              id: string;
              topic: string;
            };
        causes?: Array<
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseMetricDeterioration';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequests';
              id: string;
              topic?: string | null;
              pullRequests: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequest';
                title: string;
                externalUrl?: string | null;
                slug?: string | null;
                origin?: SourceControlOrigin | null;
                wipTimeMs?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseOutlierWorkItems';
              id: string;
              topic?: string | null;
              workItems: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseWorkItem';
                slug?: string | null;
                title: string;
                wipTimeMs: number;
                origin?: WorkItemOrigin | null;
                url?: string | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseTopic';
              id: string;
              topic?: string | null;
              cards: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseTopicCard';
                text: string;
                badgeColor: string;
                badgeText: string;
                retroName: string;
                retroDate: number;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseUnreviewedPullRequests';
              id: string;
              topic?: string | null;
              pullRequests: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCausePullRequest';
                title: string;
                description?: string | null;
                externalUrl?: string | null;
                slug?: string | null;
                createdAt: string;
                origin?: SourceControlOrigin | null;
              }>;
            }
        > | null;
        solutions?: Array<
          Array<
            | {
                __typename?: 'TeamInsightFactCauseSolutionSolutionActionItem';
                id: string;
                text: string;
                originatedActionItem?: {
                  __typename?: 'ActionItem';
                  id: string;
                  title: string;
                  description?: string | null;
                  teamId: string;
                  resolvedAt?: number | null;
                  dueDate?: number | null;
                  authorId: string;
                  boardId?: string | null;
                  status: ActionItemStatus;
                  archived: boolean;
                  createdAt?: number | null;
                  children: Array<{
                    __typename?: 'ActionItemChild';
                    id: string;
                    text: string;
                  }>;
                  author?: { __typename?: 'Author'; name: string } | null;
                  assignees?: Array<{
                    __typename?: 'User';
                    id: string;
                    name?: string | null;
                  } | null> | null;
                  improvementGoals?: Array<{
                    __typename?: 'ImprovementGoal';
                    id: string;
                    type: { __typename?: 'ImprovementGoalType'; name: string };
                  } | null> | null;
                  jiraIssueLink?: {
                    __typename?: 'ActionItemJiraLink';
                    jiraIssueId?: string | null;
                    jiraIssueUrl?: string | null;
                    jiraIssueKey?: string | null;
                    jiraProjectKey?: string | null;
                  } | null;
                  comments: Array<{
                    __typename?: 'ActionItemComment';
                    id: string;
                    text: string;
                    actionItemId: string;
                    createdAt: number;
                    archived: boolean;
                    author: {
                      __typename?: 'User';
                      id: string;
                      name?: string | null;
                    };
                  }>;
                } | null;
              }
            | {
                __typename?: 'TeamInsightFactCauseSolutionSolutionGoal';
                id: string;
                type: {
                  __typename?: 'ImprovementGoalType';
                  id: string;
                  name: string;
                  color: string;
                  description?: string | null;
                  learnMoreUrl?: string | null;
                  unit: ImprovementGoalUnit;
                  categories: Array<ImprovementGoalTypeCategory>;
                  subCategory?: string | null;
                  targetType: ImprovementGoalTypeTargetType;
                  aggregationPeriod: ImprovementGoalAggregationPeriod;
                  aggregationFunction: ImprovementGoalAggregationFunction;
                  isPollable: boolean;
                  minValue?: number | null;
                  maxValue?: number | null;
                };
              }
          >
        > | null;
      }>;
    } | null;
    watchedTeams?: Array<{
      __typename?: 'Team';
      id: string;
      createdAt?: number | null;
      name: string;
      inviteToken?: string | null;
      archived?: boolean | null;
      type: TeamType;
      organizationId?: string | null;
      userCount?: number | null;
      teamCount: number;
      maxActiveImprovementGoals: number;
      watchers: Array<{ __typename?: 'User'; id: string }>;
      settings?: {
        __typename?: 'TeamSettings';
        id: string;
        defaultVoteCount: number;
        defaultPublicRetrospectives: boolean;
        defaultGuidedRetrospectives: boolean;
        tooltipHintsEnabled: boolean;
        businessType: TeamBusinessType;
        jiraMetricsEnabled: DisableFeatureState;
        githubMetricsEnabled: DisableFeatureState;
        bitbucketMetricsEnabled: DisableFeatureState;
        cardCategories?: Array<{
          __typename?: 'CardCategory';
          name: string;
          color: string;
        } | null> | null;
      } | null;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
      children: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
        type: TeamType;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
      }>;
      admin?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      betaFeatures: Array<BetaFeature>;
      id?: string | null;
      adminOnlyTeamCreation: boolean;
      ownerId?: string | null;
      name?: string | null;
      userCount?: number | null;
      prepaidSeatCount: number;
      numberOfTeams?: number | null;
      numberOfPrograms?: number | null;
      isExceedingSeats?: boolean | null;
      numberOfRetrospectivesHeld?: number | null;
      averageTimeBetweenRetrospectives?: number | null;
      inviteToken?: string | null;
      domains: Array<string>;
      hasSSO?: boolean | null;
      status?: PaymentStatus | null;
      trialEndsAt?: number | null;
      owner?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      team?: {
        __typename?: 'Team';
        id: string;
        createdAt?: number | null;
        name: string;
        inviteToken?: string | null;
        archived?: boolean | null;
        type: TeamType;
        organizationId?: string | null;
        userCount?: number | null;
        teamCount: number;
        maxActiveImprovementGoals: number;
        watchers: Array<{ __typename?: 'User'; id: string }>;
        settings?: {
          __typename?: 'TeamSettings';
          id: string;
          defaultVoteCount: number;
          defaultPublicRetrospectives: boolean;
          defaultGuidedRetrospectives: boolean;
          tooltipHintsEnabled: boolean;
          businessType: TeamBusinessType;
          jiraMetricsEnabled: DisableFeatureState;
          githubMetricsEnabled: DisableFeatureState;
          bitbucketMetricsEnabled: DisableFeatureState;
          cardCategories?: Array<{
            __typename?: 'CardCategory';
            name: string;
            color: string;
          } | null> | null;
        } | null;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
        children: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
          type: TeamType;
          members: Array<{
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          }>;
        }>;
        admin?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        } | null;
        parents?: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
        }> | null;
      } | null;
      plan?: {
        __typename?: 'Plan';
        slug?: string | null;
        displayName?: string | null;
        blockExceedingSeats?: boolean | null;
        trialUserTimeSeconds?: number | null;
        programs?: boolean | null;
        integrationMicrosoftTeams?: boolean | null;
        integrationJira?: boolean | null;
        supportLevel?: SupportLevel | null;
        sso?: boolean | null;
      } | null;
    } | null;
    memberships?: Array<{
      __typename?: 'OrganizationUserMembership';
      role: MembershipRole;
      trialEndsAt?: number | null;
      archived: boolean;
      joinedAt?: number | null;
      organization: {
        __typename?: 'Organization';
        hasSSO?: boolean | null;
        id?: string | null;
        adminOnlyTeamCreation: boolean;
        ownerId?: string | null;
        name?: string | null;
        userCount?: number | null;
        prepaidSeatCount: number;
        numberOfTeams?: number | null;
        numberOfPrograms?: number | null;
        isExceedingSeats?: boolean | null;
        numberOfRetrospectivesHeld?: number | null;
        averageTimeBetweenRetrospectives?: number | null;
        inviteToken?: string | null;
        domains: Array<string>;
        status?: PaymentStatus | null;
        trialEndsAt?: number | null;
        owner?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        } | null;
        team?: {
          __typename?: 'Team';
          id: string;
          createdAt?: number | null;
          name: string;
          inviteToken?: string | null;
          archived?: boolean | null;
          type: TeamType;
          organizationId?: string | null;
          userCount?: number | null;
          teamCount: number;
          maxActiveImprovementGoals: number;
          watchers: Array<{ __typename?: 'User'; id: string }>;
          settings?: {
            __typename?: 'TeamSettings';
            id: string;
            defaultVoteCount: number;
            defaultPublicRetrospectives: boolean;
            defaultGuidedRetrospectives: boolean;
            tooltipHintsEnabled: boolean;
            businessType: TeamBusinessType;
            jiraMetricsEnabled: DisableFeatureState;
            githubMetricsEnabled: DisableFeatureState;
            bitbucketMetricsEnabled: DisableFeatureState;
            cardCategories?: Array<{
              __typename?: 'CardCategory';
              name: string;
              color: string;
            } | null> | null;
          } | null;
          members: Array<{
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          }>;
          children: Array<{
            __typename?: 'Team';
            id: string;
            name: string;
            type: TeamType;
            members: Array<{
              __typename?: 'User';
              id: string;
              name?: string | null;
              email: string;
            }>;
          }>;
          admin?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          } | null;
          parents?: Array<{
            __typename?: 'Team';
            id: string;
            name: string;
          }> | null;
        } | null;
        plan?: {
          __typename?: 'Plan';
          slug?: string | null;
          displayName?: string | null;
          blockExceedingSeats?: boolean | null;
          trialUserTimeSeconds?: number | null;
          programs?: boolean | null;
          integrationMicrosoftTeams?: boolean | null;
          integrationJira?: boolean | null;
          supportLevel?: SupportLevel | null;
          sso?: boolean | null;
        } | null;
      };
    }> | null;
  } | null;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    role?: MembershipRole | null;
    betaFeatures: Array<BetaFeature>;
    announcementsModalSeen?: boolean | null;
    lastActiveAt?: number | null;
    createdAt?: number | null;
    verified?: boolean | null;
    facilitatorLabelsShown?: boolean | null;
    ssoControlled?: boolean | null;
    provider?: string | null;
    team?: {
      __typename?: 'Team';
      betaFeatures: Array<BetaFeature>;
      id: string;
      createdAt?: number | null;
      name: string;
      inviteToken?: string | null;
      archived?: boolean | null;
      type: TeamType;
      organizationId?: string | null;
      userCount?: number | null;
      teamCount: number;
      maxActiveImprovementGoals: number;
      watchers: Array<{ __typename?: 'User'; id: string }>;
      settings?: {
        __typename?: 'TeamSettings';
        id: string;
        defaultVoteCount: number;
        defaultPublicRetrospectives: boolean;
        defaultGuidedRetrospectives: boolean;
        tooltipHintsEnabled: boolean;
        businessType: TeamBusinessType;
        jiraMetricsEnabled: DisableFeatureState;
        githubMetricsEnabled: DisableFeatureState;
        bitbucketMetricsEnabled: DisableFeatureState;
        cardCategories?: Array<{
          __typename?: 'CardCategory';
          name: string;
          color: string;
        } | null> | null;
      } | null;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
      children: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
        type: TeamType;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
      }>;
      admin?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
      insights: Array<{
        __typename?: 'TeamInsightFactCauseSolution';
        id: string;
        seen: boolean;
        rated: boolean;
        date: number;
        team: { __typename?: 'Team'; id: string; name: string; type: TeamType };
        fact:
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricBelowRecommended';
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricDeterioration';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricImprovement';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactMetricInadequacy';
              id: string;
              recommended: number;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionFactOutlierWorkItemTopic';
              id: string;
              topic: string;
            };
        causes?: Array<
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseMetricDeterioration';
              id: string;
              type: {
                __typename?: 'TeamInsightMetricType';
                name: string;
                unit: ImprovementGoalUnit;
                providerCategory?: MetricsProviderCategory | null;
                ideal: Trend;
              };
              history: Array<{
                __typename?: 'TimeSeries';
                date: number;
                value?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequests';
              id: string;
              topic?: string | null;
              pullRequests: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequest';
                title: string;
                externalUrl?: string | null;
                slug?: string | null;
                origin?: SourceControlOrigin | null;
                wipTimeMs?: number | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseOutlierWorkItems';
              id: string;
              topic?: string | null;
              workItems: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseWorkItem';
                slug?: string | null;
                title: string;
                wipTimeMs: number;
                origin?: WorkItemOrigin | null;
                url?: string | null;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseTopic';
              id: string;
              topic?: string | null;
              cards: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCauseTopicCard';
                text: string;
                badgeColor: string;
                badgeText: string;
                retroName: string;
                retroDate: number;
              }>;
            }
          | {
              __typename?: 'TeamInsightFactCauseSolutionCauseUnreviewedPullRequests';
              id: string;
              topic?: string | null;
              pullRequests: Array<{
                __typename?: 'TeamInsightFactCauseSolutionCausePullRequest';
                title: string;
                description?: string | null;
                externalUrl?: string | null;
                slug?: string | null;
                createdAt: string;
                origin?: SourceControlOrigin | null;
              }>;
            }
        > | null;
        solutions?: Array<
          Array<
            | {
                __typename?: 'TeamInsightFactCauseSolutionSolutionActionItem';
                id: string;
                text: string;
                originatedActionItem?: {
                  __typename?: 'ActionItem';
                  id: string;
                  title: string;
                  description?: string | null;
                  teamId: string;
                  resolvedAt?: number | null;
                  dueDate?: number | null;
                  authorId: string;
                  boardId?: string | null;
                  status: ActionItemStatus;
                  archived: boolean;
                  createdAt?: number | null;
                  children: Array<{
                    __typename?: 'ActionItemChild';
                    id: string;
                    text: string;
                  }>;
                  author?: { __typename?: 'Author'; name: string } | null;
                  assignees?: Array<{
                    __typename?: 'User';
                    id: string;
                    name?: string | null;
                  } | null> | null;
                  improvementGoals?: Array<{
                    __typename?: 'ImprovementGoal';
                    id: string;
                    type: { __typename?: 'ImprovementGoalType'; name: string };
                  } | null> | null;
                  jiraIssueLink?: {
                    __typename?: 'ActionItemJiraLink';
                    jiraIssueId?: string | null;
                    jiraIssueUrl?: string | null;
                    jiraIssueKey?: string | null;
                    jiraProjectKey?: string | null;
                  } | null;
                  comments: Array<{
                    __typename?: 'ActionItemComment';
                    id: string;
                    text: string;
                    actionItemId: string;
                    createdAt: number;
                    archived: boolean;
                    author: {
                      __typename?: 'User';
                      id: string;
                      name?: string | null;
                    };
                  }>;
                } | null;
              }
            | {
                __typename?: 'TeamInsightFactCauseSolutionSolutionGoal';
                id: string;
                type: {
                  __typename?: 'ImprovementGoalType';
                  id: string;
                  name: string;
                  color: string;
                  description?: string | null;
                  learnMoreUrl?: string | null;
                  unit: ImprovementGoalUnit;
                  categories: Array<ImprovementGoalTypeCategory>;
                  subCategory?: string | null;
                  targetType: ImprovementGoalTypeTargetType;
                  aggregationPeriod: ImprovementGoalAggregationPeriod;
                  aggregationFunction: ImprovementGoalAggregationFunction;
                  isPollable: boolean;
                  minValue?: number | null;
                  maxValue?: number | null;
                };
              }
          >
        > | null;
      }>;
    } | null;
    watchedTeams?: Array<{
      __typename?: 'Team';
      id: string;
      createdAt?: number | null;
      name: string;
      inviteToken?: string | null;
      archived?: boolean | null;
      type: TeamType;
      organizationId?: string | null;
      userCount?: number | null;
      teamCount: number;
      maxActiveImprovementGoals: number;
      watchers: Array<{ __typename?: 'User'; id: string }>;
      settings?: {
        __typename?: 'TeamSettings';
        id: string;
        defaultVoteCount: number;
        defaultPublicRetrospectives: boolean;
        defaultGuidedRetrospectives: boolean;
        tooltipHintsEnabled: boolean;
        businessType: TeamBusinessType;
        jiraMetricsEnabled: DisableFeatureState;
        githubMetricsEnabled: DisableFeatureState;
        bitbucketMetricsEnabled: DisableFeatureState;
        cardCategories?: Array<{
          __typename?: 'CardCategory';
          name: string;
          color: string;
        } | null> | null;
      } | null;
      members: Array<{
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      }>;
      children: Array<{
        __typename?: 'Team';
        id: string;
        name: string;
        type: TeamType;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
      }>;
      admin?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      parents?: Array<{ __typename?: 'Team'; id: string; name: string }> | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      betaFeatures: Array<BetaFeature>;
      id?: string | null;
      adminOnlyTeamCreation: boolean;
      ownerId?: string | null;
      name?: string | null;
      userCount?: number | null;
      prepaidSeatCount: number;
      numberOfTeams?: number | null;
      numberOfPrograms?: number | null;
      isExceedingSeats?: boolean | null;
      numberOfRetrospectivesHeld?: number | null;
      averageTimeBetweenRetrospectives?: number | null;
      inviteToken?: string | null;
      domains: Array<string>;
      hasSSO?: boolean | null;
      status?: PaymentStatus | null;
      trialEndsAt?: number | null;
      owner?: {
        __typename?: 'User';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      team?: {
        __typename?: 'Team';
        id: string;
        createdAt?: number | null;
        name: string;
        inviteToken?: string | null;
        archived?: boolean | null;
        type: TeamType;
        organizationId?: string | null;
        userCount?: number | null;
        teamCount: number;
        maxActiveImprovementGoals: number;
        watchers: Array<{ __typename?: 'User'; id: string }>;
        settings?: {
          __typename?: 'TeamSettings';
          id: string;
          defaultVoteCount: number;
          defaultPublicRetrospectives: boolean;
          defaultGuidedRetrospectives: boolean;
          tooltipHintsEnabled: boolean;
          businessType: TeamBusinessType;
          jiraMetricsEnabled: DisableFeatureState;
          githubMetricsEnabled: DisableFeatureState;
          bitbucketMetricsEnabled: DisableFeatureState;
          cardCategories?: Array<{
            __typename?: 'CardCategory';
            name: string;
            color: string;
          } | null> | null;
        } | null;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
        children: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
          type: TeamType;
          members: Array<{
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          }>;
        }>;
        admin?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        } | null;
        parents?: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
        }> | null;
      } | null;
      plan?: {
        __typename?: 'Plan';
        slug?: string | null;
        displayName?: string | null;
        blockExceedingSeats?: boolean | null;
        trialUserTimeSeconds?: number | null;
        programs?: boolean | null;
        integrationMicrosoftTeams?: boolean | null;
        integrationJira?: boolean | null;
        supportLevel?: SupportLevel | null;
        sso?: boolean | null;
      } | null;
    } | null;
    memberships?: Array<{
      __typename?: 'OrganizationUserMembership';
      role: MembershipRole;
      trialEndsAt?: number | null;
      archived: boolean;
      joinedAt?: number | null;
      organization: {
        __typename?: 'Organization';
        hasSSO?: boolean | null;
        id?: string | null;
        adminOnlyTeamCreation: boolean;
        ownerId?: string | null;
        name?: string | null;
        userCount?: number | null;
        prepaidSeatCount: number;
        numberOfTeams?: number | null;
        numberOfPrograms?: number | null;
        isExceedingSeats?: boolean | null;
        numberOfRetrospectivesHeld?: number | null;
        averageTimeBetweenRetrospectives?: number | null;
        inviteToken?: string | null;
        domains: Array<string>;
        status?: PaymentStatus | null;
        trialEndsAt?: number | null;
        owner?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        } | null;
        team?: {
          __typename?: 'Team';
          id: string;
          createdAt?: number | null;
          name: string;
          inviteToken?: string | null;
          archived?: boolean | null;
          type: TeamType;
          organizationId?: string | null;
          userCount?: number | null;
          teamCount: number;
          maxActiveImprovementGoals: number;
          watchers: Array<{ __typename?: 'User'; id: string }>;
          settings?: {
            __typename?: 'TeamSettings';
            id: string;
            defaultVoteCount: number;
            defaultPublicRetrospectives: boolean;
            defaultGuidedRetrospectives: boolean;
            tooltipHintsEnabled: boolean;
            businessType: TeamBusinessType;
            jiraMetricsEnabled: DisableFeatureState;
            githubMetricsEnabled: DisableFeatureState;
            bitbucketMetricsEnabled: DisableFeatureState;
            cardCategories?: Array<{
              __typename?: 'CardCategory';
              name: string;
              color: string;
            } | null> | null;
          } | null;
          members: Array<{
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          }>;
          children: Array<{
            __typename?: 'Team';
            id: string;
            name: string;
            type: TeamType;
            members: Array<{
              __typename?: 'User';
              id: string;
              name?: string | null;
              email: string;
            }>;
          }>;
          admin?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          } | null;
          parents?: Array<{
            __typename?: 'Team';
            id: string;
            name: string;
          }> | null;
        } | null;
        plan?: {
          __typename?: 'Plan';
          slug?: string | null;
          displayName?: string | null;
          blockExceedingSeats?: boolean | null;
          trialUserTimeSeconds?: number | null;
          programs?: boolean | null;
          integrationMicrosoftTeams?: boolean | null;
          integrationJira?: boolean | null;
          supportLevel?: SupportLevel | null;
          sso?: boolean | null;
        } | null;
      };
    }> | null;
  } | null;
};

export type IsLoggedInQueryVariables = Exact<{ [key: string]: never }>;

export type IsLoggedInQuery = {
  __typename?: 'Query';
  isLoggedIn?: boolean | null;
};

export type MembershipsByOrgIdAndEmailQueryVariables = Exact<{
  organizationId: Scalars['ID'];
  roleFilter?: InputMaybe<Array<MembershipRole> | MembershipRole>;
  email?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['JSON']>;
}>;

export type MembershipsByOrgIdAndEmailQuery = {
  __typename?: 'Query';
  organizationById?: {
    __typename?: 'Organization';
    memberships: {
      __typename?: 'MembershipQueryResults';
      totalSize: number;
      memberships: Array<{
        __typename?: 'OrganizationUserMembership';
        seatType: SeatType;
        role: MembershipRole;
        trialEndsAt?: number | null;
        archived: boolean;
        joinedAt?: number | null;
        user: {
          __typename?: 'User';
          id: string;
          email: string;
          name?: string | null;
          lastActiveAt?: number | null;
          createdAt?: number | null;
        };
      }>;
    };
  } | null;
};

export type OtpQueryVariables = Exact<{ [key: string]: never }>;

export type OtpQuery = { __typename?: 'Query'; otp: string };

export type SearchMembershipsByOrganizationIdAndEmailQueryVariables = Exact<{
  organizationId: Scalars['ID'];
  roleFilter?: InputMaybe<Array<MembershipRole> | MembershipRole>;
  email?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['JSON']>;
}>;

export type SearchMembershipsByOrganizationIdAndEmailQuery = {
  __typename?: 'Query';
  organizationById?: {
    __typename?: 'Organization';
    memberships: {
      __typename?: 'MembershipQueryResults';
      totalSize: number;
      memberships: Array<{
        __typename?: 'OrganizationUserMembership';
        seatType: SeatType;
        role: MembershipRole;
        trialEndsAt?: number | null;
        archived: boolean;
        joinedAt?: number | null;
        user: {
          __typename?: 'User';
          id: string;
          email: string;
          name?: string | null;
          lastActiveAt?: number | null;
          createdAt?: number | null;
        };
      }>;
    };
  } | null;
};

export type UserEmailByResetPasswordTokenQueryVariables = Exact<{
  resetPasswordToken: Scalars['String'];
}>;

export type UserEmailByResetPasswordTokenQuery = {
  __typename?: 'Query';
  userEmailByResetPasswordToken?: string | null;
};

export type UsersByIdsQueryVariables = Exact<{
  userIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type UsersByIdsQuery = {
  __typename?: 'Query';
  usersByIds: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
  }>;
};

export type UsersByOrgIdQueryVariables = Exact<{
  organizationId: Scalars['String'];
  roleFilter: MembershipRole;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  sort?: InputMaybe<Scalars['JSON']>;
}>;

export type UsersByOrgIdQuery = {
  __typename?: 'Query';
  usersByOrganizationId?: {
    __typename?: 'UserQueryResults';
    totalSize: number;
    users: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
      role?: MembershipRole | null;
      betaFeatures: Array<BetaFeature>;
      announcementsModalSeen?: boolean | null;
      lastActiveAt?: number | null;
      createdAt?: number | null;
      verified?: boolean | null;
      facilitatorLabelsShown?: boolean | null;
      ssoControlled?: boolean | null;
      provider?: string | null;
      team?: {
        __typename?: 'Team';
        betaFeatures: Array<BetaFeature>;
        id: string;
        createdAt?: number | null;
        name: string;
        inviteToken?: string | null;
        archived?: boolean | null;
        type: TeamType;
        organizationId?: string | null;
        userCount?: number | null;
        teamCount: number;
        maxActiveImprovementGoals: number;
        watchers: Array<{ __typename?: 'User'; id: string }>;
        settings?: {
          __typename?: 'TeamSettings';
          id: string;
          defaultVoteCount: number;
          defaultPublicRetrospectives: boolean;
          defaultGuidedRetrospectives: boolean;
          tooltipHintsEnabled: boolean;
          businessType: TeamBusinessType;
          jiraMetricsEnabled: DisableFeatureState;
          githubMetricsEnabled: DisableFeatureState;
          bitbucketMetricsEnabled: DisableFeatureState;
          cardCategories?: Array<{
            __typename?: 'CardCategory';
            name: string;
            color: string;
          } | null> | null;
        } | null;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
        children: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
          type: TeamType;
          members: Array<{
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          }>;
        }>;
        admin?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        } | null;
        parents?: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
        }> | null;
        insights: Array<{
          __typename?: 'TeamInsightFactCauseSolution';
          id: string;
          seen: boolean;
          rated: boolean;
          date: number;
          team: {
            __typename?: 'Team';
            id: string;
            name: string;
            type: TeamType;
          };
          fact:
            | {
                __typename?: 'TeamInsightFactCauseSolutionFactMetricBelowRecommended';
              }
            | {
                __typename?: 'TeamInsightFactCauseSolutionFactMetricDeterioration';
                id: string;
                type: {
                  __typename?: 'TeamInsightMetricType';
                  name: string;
                  unit: ImprovementGoalUnit;
                  providerCategory?: MetricsProviderCategory | null;
                  ideal: Trend;
                };
                history: Array<{
                  __typename?: 'TimeSeries';
                  date: number;
                  value?: number | null;
                }>;
              }
            | {
                __typename?: 'TeamInsightFactCauseSolutionFactMetricImprovement';
                id: string;
                type: {
                  __typename?: 'TeamInsightMetricType';
                  name: string;
                  unit: ImprovementGoalUnit;
                  providerCategory?: MetricsProviderCategory | null;
                  ideal: Trend;
                };
                history: Array<{
                  __typename?: 'TimeSeries';
                  date: number;
                  value?: number | null;
                }>;
              }
            | {
                __typename?: 'TeamInsightFactCauseSolutionFactMetricInadequacy';
                id: string;
                recommended: number;
                type: {
                  __typename?: 'TeamInsightMetricType';
                  name: string;
                  unit: ImprovementGoalUnit;
                  providerCategory?: MetricsProviderCategory | null;
                  ideal: Trend;
                };
                history: Array<{
                  __typename?: 'TimeSeries';
                  date: number;
                  value?: number | null;
                }>;
              }
            | {
                __typename?: 'TeamInsightFactCauseSolutionFactOutlierWorkItemTopic';
                id: string;
                topic: string;
              };
          causes?: Array<
            | {
                __typename?: 'TeamInsightFactCauseSolutionCauseMetricDeterioration';
                id: string;
                type: {
                  __typename?: 'TeamInsightMetricType';
                  name: string;
                  unit: ImprovementGoalUnit;
                  providerCategory?: MetricsProviderCategory | null;
                  ideal: Trend;
                };
                history: Array<{
                  __typename?: 'TimeSeries';
                  date: number;
                  value?: number | null;
                }>;
              }
            | {
                __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequests';
                id: string;
                topic?: string | null;
                pullRequests: Array<{
                  __typename?: 'TeamInsightFactCauseSolutionCauseOutlierPullRequest';
                  title: string;
                  externalUrl?: string | null;
                  slug?: string | null;
                  origin?: SourceControlOrigin | null;
                  wipTimeMs?: number | null;
                }>;
              }
            | {
                __typename?: 'TeamInsightFactCauseSolutionCauseOutlierWorkItems';
                id: string;
                topic?: string | null;
                workItems: Array<{
                  __typename?: 'TeamInsightFactCauseSolutionCauseWorkItem';
                  slug?: string | null;
                  title: string;
                  wipTimeMs: number;
                  origin?: WorkItemOrigin | null;
                  url?: string | null;
                }>;
              }
            | {
                __typename?: 'TeamInsightFactCauseSolutionCauseTopic';
                id: string;
                topic?: string | null;
                cards: Array<{
                  __typename?: 'TeamInsightFactCauseSolutionCauseTopicCard';
                  text: string;
                  badgeColor: string;
                  badgeText: string;
                  retroName: string;
                  retroDate: number;
                }>;
              }
            | {
                __typename?: 'TeamInsightFactCauseSolutionCauseUnreviewedPullRequests';
                id: string;
                topic?: string | null;
                pullRequests: Array<{
                  __typename?: 'TeamInsightFactCauseSolutionCausePullRequest';
                  title: string;
                  description?: string | null;
                  externalUrl?: string | null;
                  slug?: string | null;
                  createdAt: string;
                  origin?: SourceControlOrigin | null;
                }>;
              }
          > | null;
          solutions?: Array<
            Array<
              | {
                  __typename?: 'TeamInsightFactCauseSolutionSolutionActionItem';
                  id: string;
                  text: string;
                  originatedActionItem?: {
                    __typename?: 'ActionItem';
                    id: string;
                    title: string;
                    description?: string | null;
                    teamId: string;
                    resolvedAt?: number | null;
                    dueDate?: number | null;
                    authorId: string;
                    boardId?: string | null;
                    status: ActionItemStatus;
                    archived: boolean;
                    createdAt?: number | null;
                    children: Array<{
                      __typename?: 'ActionItemChild';
                      id: string;
                      text: string;
                    }>;
                    author?: { __typename?: 'Author'; name: string } | null;
                    assignees?: Array<{
                      __typename?: 'User';
                      id: string;
                      name?: string | null;
                    } | null> | null;
                    improvementGoals?: Array<{
                      __typename?: 'ImprovementGoal';
                      id: string;
                      type: {
                        __typename?: 'ImprovementGoalType';
                        name: string;
                      };
                    } | null> | null;
                    jiraIssueLink?: {
                      __typename?: 'ActionItemJiraLink';
                      jiraIssueId?: string | null;
                      jiraIssueUrl?: string | null;
                      jiraIssueKey?: string | null;
                      jiraProjectKey?: string | null;
                    } | null;
                    comments: Array<{
                      __typename?: 'ActionItemComment';
                      id: string;
                      text: string;
                      actionItemId: string;
                      createdAt: number;
                      archived: boolean;
                      author: {
                        __typename?: 'User';
                        id: string;
                        name?: string | null;
                      };
                    }>;
                  } | null;
                }
              | {
                  __typename?: 'TeamInsightFactCauseSolutionSolutionGoal';
                  id: string;
                  type: {
                    __typename?: 'ImprovementGoalType';
                    id: string;
                    name: string;
                    color: string;
                    description?: string | null;
                    learnMoreUrl?: string | null;
                    unit: ImprovementGoalUnit;
                    categories: Array<ImprovementGoalTypeCategory>;
                    subCategory?: string | null;
                    targetType: ImprovementGoalTypeTargetType;
                    aggregationPeriod: ImprovementGoalAggregationPeriod;
                    aggregationFunction: ImprovementGoalAggregationFunction;
                    isPollable: boolean;
                    minValue?: number | null;
                    maxValue?: number | null;
                  };
                }
            >
          > | null;
        }>;
      } | null;
      watchedTeams?: Array<{
        __typename?: 'Team';
        id: string;
        createdAt?: number | null;
        name: string;
        inviteToken?: string | null;
        archived?: boolean | null;
        type: TeamType;
        organizationId?: string | null;
        userCount?: number | null;
        teamCount: number;
        maxActiveImprovementGoals: number;
        watchers: Array<{ __typename?: 'User'; id: string }>;
        settings?: {
          __typename?: 'TeamSettings';
          id: string;
          defaultVoteCount: number;
          defaultPublicRetrospectives: boolean;
          defaultGuidedRetrospectives: boolean;
          tooltipHintsEnabled: boolean;
          businessType: TeamBusinessType;
          jiraMetricsEnabled: DisableFeatureState;
          githubMetricsEnabled: DisableFeatureState;
          bitbucketMetricsEnabled: DisableFeatureState;
          cardCategories?: Array<{
            __typename?: 'CardCategory';
            name: string;
            color: string;
          } | null> | null;
        } | null;
        members: Array<{
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        }>;
        children: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
          type: TeamType;
          members: Array<{
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          }>;
        }>;
        admin?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        } | null;
        parents?: Array<{
          __typename?: 'Team';
          id: string;
          name: string;
        }> | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        betaFeatures: Array<BetaFeature>;
        id?: string | null;
        adminOnlyTeamCreation: boolean;
        ownerId?: string | null;
        name?: string | null;
        userCount?: number | null;
        prepaidSeatCount: number;
        numberOfTeams?: number | null;
        numberOfPrograms?: number | null;
        isExceedingSeats?: boolean | null;
        numberOfRetrospectivesHeld?: number | null;
        averageTimeBetweenRetrospectives?: number | null;
        inviteToken?: string | null;
        domains: Array<string>;
        hasSSO?: boolean | null;
        status?: PaymentStatus | null;
        trialEndsAt?: number | null;
        owner?: {
          __typename?: 'User';
          id: string;
          name?: string | null;
          email: string;
        } | null;
        team?: {
          __typename?: 'Team';
          id: string;
          createdAt?: number | null;
          name: string;
          inviteToken?: string | null;
          archived?: boolean | null;
          type: TeamType;
          organizationId?: string | null;
          userCount?: number | null;
          teamCount: number;
          maxActiveImprovementGoals: number;
          watchers: Array<{ __typename?: 'User'; id: string }>;
          settings?: {
            __typename?: 'TeamSettings';
            id: string;
            defaultVoteCount: number;
            defaultPublicRetrospectives: boolean;
            defaultGuidedRetrospectives: boolean;
            tooltipHintsEnabled: boolean;
            businessType: TeamBusinessType;
            jiraMetricsEnabled: DisableFeatureState;
            githubMetricsEnabled: DisableFeatureState;
            bitbucketMetricsEnabled: DisableFeatureState;
            cardCategories?: Array<{
              __typename?: 'CardCategory';
              name: string;
              color: string;
            } | null> | null;
          } | null;
          members: Array<{
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          }>;
          children: Array<{
            __typename?: 'Team';
            id: string;
            name: string;
            type: TeamType;
            members: Array<{
              __typename?: 'User';
              id: string;
              name?: string | null;
              email: string;
            }>;
          }>;
          admin?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          } | null;
          parents?: Array<{
            __typename?: 'Team';
            id: string;
            name: string;
          }> | null;
        } | null;
        plan?: {
          __typename?: 'Plan';
          slug?: string | null;
          displayName?: string | null;
          blockExceedingSeats?: boolean | null;
          trialUserTimeSeconds?: number | null;
          programs?: boolean | null;
          integrationMicrosoftTeams?: boolean | null;
          integrationJira?: boolean | null;
          supportLevel?: SupportLevel | null;
          sso?: boolean | null;
        } | null;
      } | null;
      memberships?: Array<{
        __typename?: 'OrganizationUserMembership';
        role: MembershipRole;
        trialEndsAt?: number | null;
        archived: boolean;
        joinedAt?: number | null;
        organization: {
          __typename?: 'Organization';
          hasSSO?: boolean | null;
          id?: string | null;
          adminOnlyTeamCreation: boolean;
          ownerId?: string | null;
          name?: string | null;
          userCount?: number | null;
          prepaidSeatCount: number;
          numberOfTeams?: number | null;
          numberOfPrograms?: number | null;
          isExceedingSeats?: boolean | null;
          numberOfRetrospectivesHeld?: number | null;
          averageTimeBetweenRetrospectives?: number | null;
          inviteToken?: string | null;
          domains: Array<string>;
          status?: PaymentStatus | null;
          trialEndsAt?: number | null;
          owner?: {
            __typename?: 'User';
            id: string;
            name?: string | null;
            email: string;
          } | null;
          team?: {
            __typename?: 'Team';
            id: string;
            createdAt?: number | null;
            name: string;
            inviteToken?: string | null;
            archived?: boolean | null;
            type: TeamType;
            organizationId?: string | null;
            userCount?: number | null;
            teamCount: number;
            maxActiveImprovementGoals: number;
            watchers: Array<{ __typename?: 'User'; id: string }>;
            settings?: {
              __typename?: 'TeamSettings';
              id: string;
              defaultVoteCount: number;
              defaultPublicRetrospectives: boolean;
              defaultGuidedRetrospectives: boolean;
              tooltipHintsEnabled: boolean;
              businessType: TeamBusinessType;
              jiraMetricsEnabled: DisableFeatureState;
              githubMetricsEnabled: DisableFeatureState;
              bitbucketMetricsEnabled: DisableFeatureState;
              cardCategories?: Array<{
                __typename?: 'CardCategory';
                name: string;
                color: string;
              } | null> | null;
            } | null;
            members: Array<{
              __typename?: 'User';
              id: string;
              name?: string | null;
              email: string;
            }>;
            children: Array<{
              __typename?: 'Team';
              id: string;
              name: string;
              type: TeamType;
              members: Array<{
                __typename?: 'User';
                id: string;
                name?: string | null;
                email: string;
              }>;
            }>;
            admin?: {
              __typename?: 'User';
              id: string;
              name?: string | null;
              email: string;
            } | null;
            parents?: Array<{
              __typename?: 'Team';
              id: string;
              name: string;
            }> | null;
          } | null;
          plan?: {
            __typename?: 'Plan';
            slug?: string | null;
            displayName?: string | null;
            blockExceedingSeats?: boolean | null;
            trialUserTimeSeconds?: number | null;
            programs?: boolean | null;
            integrationMicrosoftTeams?: boolean | null;
            integrationJira?: boolean | null;
            supportLevel?: SupportLevel | null;
            sso?: boolean | null;
          } | null;
        };
      }> | null;
    } | null>;
  } | null;
};

export type InstalledUserIntegrationsUpdateSubscriptionVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type InstalledUserIntegrationsUpdateSubscription = {
  __typename?: 'Subscription';
  installedUserIntegrationsUpdate: {
    __typename?: 'InstalledUserIntegrations';
    confluence: boolean;
    jira: boolean;
    miro: boolean;
    trello: boolean;
  };
};

export const BasicBoardFragmentDoc = gql`
  fragment BasicBoard on Board {
    id
    name
    teamId
    ownerId
    boardAsyncScheduleId
    participationRate
    totalCards
    public
    createdAt
    updatedAt
    archived
    type
    formatSlug
    format {
      iconImageUrl
      displayName
    }
  }
`;
export const ActionItemCommentFragmentFragmentDoc = gql`
  fragment ActionItemCommentFragment on ActionItemComment {
    id
    text
    actionItemId
    createdAt
    archived
    author {
      id
      name
    }
  }
`;
export const ActionItemFragmentFragmentDoc = gql`
  fragment ActionItemFragment on ActionItem {
    id
    title
    description
    teamId
    resolvedAt
    dueDate
    authorId
    boardId
    status
    archived
    createdAt
    children {
      id
      text
    }
    author {
      name
    }
    assignees {
      id
      name
    }
    improvementGoals {
      id
      type {
        name
      }
    }
    jiraIssueLink {
      jiraIssueId
      jiraIssueUrl
      jiraIssueKey
      jiraProjectKey
    }
    comments {
      ...ActionItemCommentFragment
    }
  }
  ${ActionItemCommentFragmentFragmentDoc}
`;
export const ParkingLotItemFragmentFragmentDoc = gql`
  fragment ParkingLotItemFragment on ParkingLotItem {
    id
    text
    votes
    createdAt
    archived
    children {
      text
    }
    team {
      id
    }
    author {
      id
    }
  }
`;
export const ImprovementGoalTypeFragmentFragmentDoc = gql`
  fragment ImprovementGoalTypeFragment on ImprovementGoalType {
    id
    name
    color
    description
    learnMoreUrl
    unit
    categories
    subCategory
    targetType
    aggregationPeriod
    aggregationFunction
    isPollable
    minValue
    maxValue
  }
`;
export const ImprovementGoalReminderFragmentFragmentDoc = gql`
  fragment ImprovementGoalReminderFragment on ImprovementGoalUserReminder {
    improvementGoalId
    userId
    notificationTime
    frequency
    createdAt
    updatedAt
  }
`;
export const ImprovementGoalFragmentFragmentDoc = gql`
  fragment ImprovementGoalFragment on ImprovementGoal {
    id
    createdAt
    timesUsed
    createdAt
    updatedAt
    archived
    paused
    target
    ongoingActionsCount
    actionsTakenCount
    type {
      ...ImprovementGoalTypeFragment
    }
    team {
      type
      name
    }
    user {
      name
    }
    userReminders {
      ...ImprovementGoalReminderFragment
    }
  }
  ${ImprovementGoalTypeFragmentFragmentDoc}
  ${ImprovementGoalReminderFragmentFragmentDoc}
`;
export const ImprovementGoalDataFragmentFragmentDoc = gql`
  fragment ImprovementGoalDataFragment on ImprovementGoalData {
    id
    improvementGoalId
    value
    date
    createdAt
    archived
    origin
    children {
      id
      improvementGoalId
      value
      date
      createdAt
      archived
      origin
    }
  }
`;
export const TeamInsightMetricTypeFragmentFragmentDoc = gql`
  fragment TeamInsightMetricTypeFragment on TeamInsightMetricType {
    name
    unit
    providerCategory
    ideal
  }
`;
export const TimeSeriesFragmentFragmentDoc = gql`
  fragment TimeSeriesFragment on TimeSeries {
    date
    value
  }
`;
export const TeamInsightFactCauseSolutionFactFragmentFragmentDoc = gql`
  fragment TeamInsightFactCauseSolutionFactFragment on TeamInsightFactCauseSolution {
    fact {
      ... on TeamInsightFactCauseSolutionFactMetricDeterioration {
        id
        type {
          ...TeamInsightMetricTypeFragment
        }
        history {
          ...TimeSeriesFragment
        }
      }
      ... on TeamInsightFactCauseSolutionFactMetricImprovement {
        id
        type {
          ...TeamInsightMetricTypeFragment
        }
        history {
          ...TimeSeriesFragment
        }
      }
      ... on TeamInsightFactCauseSolutionFactMetricInadequacy {
        id
        recommended
        type {
          ...TeamInsightMetricTypeFragment
        }
        history {
          ...TimeSeriesFragment
        }
      }
      ... on TeamInsightFactCauseSolutionFactOutlierWorkItemTopic {
        id
        topic
      }
    }
  }
  ${TeamInsightMetricTypeFragmentFragmentDoc}
  ${TimeSeriesFragmentFragmentDoc}
`;
export const TeamInsightFactCauseSolutionCausesFragmentFragmentDoc = gql`
  fragment TeamInsightFactCauseSolutionCausesFragment on TeamInsightFactCauseSolution {
    causes {
      ... on TeamInsightFactCauseSolutionCauseTopic {
        id
        topic
        cards {
          text
          badgeColor
          badgeText
          retroName
          retroDate
        }
      }
      ... on TeamInsightFactCauseSolutionCauseMetricDeterioration {
        id
        type {
          ...TeamInsightMetricTypeFragment
        }
        history {
          ...TimeSeriesFragment
        }
      }
      ... on TeamInsightFactCauseSolutionCauseOutlierWorkItems {
        id
        topic
        workItems {
          slug
          title
          wipTimeMs
          origin
          url
        }
      }
      ... on TeamInsightFactCauseSolutionCauseUnreviewedPullRequests {
        id
        topic
        pullRequests {
          title
          description
          externalUrl
          slug
          createdAt
          origin
        }
      }
      ... on TeamInsightFactCauseSolutionCauseOutlierPullRequests {
        id
        topic
        pullRequests {
          title
          externalUrl
          slug
          origin
          wipTimeMs
        }
      }
    }
  }
  ${TeamInsightMetricTypeFragmentFragmentDoc}
  ${TimeSeriesFragmentFragmentDoc}
`;
export const TeamInsightFactCauseSolutionSolutionActionItemFragmentFragmentDoc = gql`
  fragment TeamInsightFactCauseSolutionSolutionActionItemFragment on TeamInsightFactCauseSolutionSolutionActionItem {
    id
    text
    originatedActionItem {
      ...ActionItemFragment
    }
  }
  ${ActionItemFragmentFragmentDoc}
`;
export const TeamInsightFactCauseSolutionSolutionGoalFragmentFragmentDoc = gql`
  fragment TeamInsightFactCauseSolutionSolutionGoalFragment on TeamInsightFactCauseSolutionSolutionGoal {
    id
    type {
      ...ImprovementGoalTypeFragment
    }
  }
  ${ImprovementGoalTypeFragmentFragmentDoc}
`;
export const TeamInsightFactCauseSolutionSolutionsFragmentFragmentDoc = gql`
  fragment TeamInsightFactCauseSolutionSolutionsFragment on TeamInsightFactCauseSolution {
    solutions {
      ... on TeamInsightFactCauseSolutionSolutionActionItem {
        ...TeamInsightFactCauseSolutionSolutionActionItemFragment
      }
      ... on TeamInsightFactCauseSolutionSolutionGoal {
        ...TeamInsightFactCauseSolutionSolutionGoalFragment
      }
    }
  }
  ${TeamInsightFactCauseSolutionSolutionActionItemFragmentFragmentDoc}
  ${TeamInsightFactCauseSolutionSolutionGoalFragmentFragmentDoc}
`;
export const TeamInsightFactCauseSolutionFragmentFragmentDoc = gql`
  fragment TeamInsightFactCauseSolutionFragment on TeamInsightFactCauseSolution {
    id
    seen
    rated
    team {
      id
      name
      type
    }
    date
    ...TeamInsightFactCauseSolutionFactFragment
    ...TeamInsightFactCauseSolutionCausesFragment
    ...TeamInsightFactCauseSolutionSolutionsFragment
  }
  ${TeamInsightFactCauseSolutionFactFragmentFragmentDoc}
  ${TeamInsightFactCauseSolutionCausesFragmentFragmentDoc}
  ${TeamInsightFactCauseSolutionSolutionsFragmentFragmentDoc}
`;
export const MinimalUserFragmentDoc = gql`
  fragment MinimalUser on User {
    id
    name
    email
  }
`;
export const MinimalTeamFragmentFragmentDoc = gql`
  fragment MinimalTeamFragment on Team {
    id
    name
    type
    createdAt
  }
`;
export const ActivityFeedItemFragmentFragmentDoc = gql`
  fragment ActivityFeedItemFragment on Activity {
    id
    hideReaction
    teamId
    verb
    headerTitle
    bodyText
    type
    reactions {
      id
      userId
      user {
        id
        firstName
        lastName
        name
        email
      }
      kind
      createdAt
      updatedAt
    }
    comments {
      id
      userId
      user {
        id
        firstName
        lastName
        name
        email
      }
      text
      createdAt
      updatedAt
    }
    board {
      ...BasicBoard
    }
    actionItem {
      ...ActionItemFragment
    }
    parkingLot {
      ...ParkingLotItemFragment
    }
    goal {
      ...ImprovementGoalFragment
      data {
        ...ImprovementGoalDataFragment
      }
    }
    insight {
      ...TeamInsightFactCauseSolutionFragment
    }
    user {
      ...MinimalUser
    }
    team {
      ...MinimalTeamFragment
    }
    createdAt
    updatedAt
  }
  ${BasicBoardFragmentDoc}
  ${ActionItemFragmentFragmentDoc}
  ${ParkingLotItemFragmentFragmentDoc}
  ${ImprovementGoalFragmentFragmentDoc}
  ${ImprovementGoalDataFragmentFragmentDoc}
  ${TeamInsightFactCauseSolutionFragmentFragmentDoc}
  ${MinimalUserFragmentDoc}
  ${MinimalTeamFragmentFragmentDoc}
`;
export const CardCommentFragmentFragmentDoc = gql`
  fragment CardCommentFragment on CardComment {
    id
    text
    authorId
    cardId
    archived
    createdAt
    author {
      id
      name
    }
  }
`;
export const CardFragmentFragmentDoc = gql`
  fragment CardFragment on Card {
    id
    listId
    authorId
    text
    parentId
    index
    votes
    archived
    updatedAt
    createdAt
    wasDiscussed
    originalListBadge {
      originalListId
      text
      color
    }
    promotedToPrograms {
      id
      name
    }
    comments {
      ...CardCommentFragment
    }
    children {
      id
      listId
      text
      parentId
      index
      votes
      archived
      updatedAt
      createdAt
      authorId
      children {
        id
      }
      originalListBadge {
        originalListId
        text
        color
      }
      comments {
        ...CardCommentFragment
      }
    }
  }
  ${CardCommentFragmentFragmentDoc}
`;
export const ListFragmentFragmentDoc = gql`
  fragment ListFragment on List {
    id
    boardId
    name
    index
    archived
    cardColor
    cards {
      ...CardFragment
    }
  }
  ${CardFragmentFragmentDoc}
`;
export const BoardFragmentFragmentDoc = gql`
  fragment BoardFragment on Board {
    id
    name
    teamId
    boardAsyncScheduleId
    createdAt
    updatedAt
    participationRate
    facilitators
    archived
    ownerId
    public
    type
    onlineUserIds
    discussReadyUserIds
    tooltipHintsEnabled
    takeaways {
      id
      text
      boardId
      type
    }
    formatSlug
    format {
      displayName
      iconImageUrl
      description
    }
    state {
      privateCards
      privateVotes
      timerStartTimeMiliseconds
      timerStopTimeMiliseconds
      stage
      isGuided
      showActionPlan
      voteCount
      voteCountWasSuggested
      discussCurrentCardId
      discussSortByTags
      discussionCardsSortingKey
      voteCount
      password
      takeAwayStatus
    }
    cardBeingDiscussed {
      ...CardFragment
    }
    cardsOnDiscussion {
      ...CardFragment
    }
    lists {
      ...ListFragment
    }
  }
  ${CardFragmentFragmentDoc}
  ${ListFragmentFragmentDoc}
`;
export const BoardParticipationFragmentFragmentDoc = gql`
  fragment BoardParticipationFragment on BoardParticipation {
    status
    userId
    isFacilitator
    numberOfVotes
    numberOfCards
    participant {
      id
      name
    }
  }
`;
export const WhiteboardFragmentFragmentDoc = gql`
  fragment WhiteboardFragment on Board {
    id
    name
    teamId
    updatedAt
    participationRate
    facilitators
    archived
    ownerId
    type
    onlineUserIds
    discussReadyUserIds
    tooltipHintsEnabled
    formatSlug
    format {
      displayName
      iconImageUrl
      description
    }
    state {
      privateCards
      privateVotes
      timerStartTimeMiliseconds
      timerStopTimeMiliseconds
      stage
      isGuided
      password
      showActionPlan
      voteCount
      voteCountWasSuggested
      discussCurrentCardId
      discussSortByTags
      discussionCardsSortingKey
      voteCount
    }
    cardBeingDiscussed {
      ...CardFragment
    }
    cardsOnDiscussion {
      ...CardFragment
    }
    lists {
      ...ListFragment
    }
  }
  ${CardFragmentFragmentDoc}
  ${ListFragmentFragmentDoc}
`;
export const BoardAsyncScheduleFragmentFragmentDoc = gql`
  fragment BoardAsyncScheduleFragment on BoardAsyncSchedule {
    id
    teamId
    name
    scheduleType
    rrule
    oneTimeSchedule
    collectionTime
    feedbackCollectionType
    isActive
    areQuestionsOptional
    questionType
    sendReminders
    retrospectiveFormatId
    feedbackType
    customQuestions {
      id
      question
      isOptional
      index
    }
  }
`;
export const InstalledOrganizationIntegrationsFragmentDoc = gql`
  fragment InstalledOrganizationIntegrations on InstalledOrganizationIntegrations {
    __typename
    slack
    jira
    github
    bitbucket
    slackHmacPublicDigest
  }
`;
export const LivePollFragmentFragmentDoc = gql`
  fragment LivePollFragment on LivePoll {
    id
    title
    boardId
    answeredUserIds
    skippedUserIds
    status
    result
    createdAt
    board {
      id
      onlineUserIds
    }
    improvementGoal {
      id
      target
      type {
        id
        name
        color
        targetType
      }
    }
  }
`;
export const InFlightWorkItemFragmentFragmentDoc = gql`
  fragment InFlightWorkItemFragment on InFlightWorkItem {
    title
    slug
    origin
    externalUrl
    externalStatusName
    createdAt
    wipTimeMs
    statusAgeQuality
    cycleTimeQuality
    staleForMs
    timeLeftToExternalStatusCycleTimeMs
    timeLeftToOverallCycleTimeMs
  }
`;
export const TeamTreeFragmentFragmentDoc = gql`
  fragment TeamTreeFragment on TeamTree {
    isWatcher
    team {
      ...MinimalTeamFragment
    }
    children {
      isWatcher
      team {
        ...MinimalTeamFragment
      }
      children {
        isWatcher
        team {
          ...MinimalTeamFragment
        }
        children {
          team {
            ...MinimalTeamFragment
          }
        }
      }
    }
  }
  ${MinimalTeamFragmentFragmentDoc}
`;
export const TeamFragmentFragmentDoc = gql`
  fragment TeamFragment on Team {
    id
    createdAt
    name
    inviteToken
    archived
    type
    organizationId
    userCount
    teamCount
    maxActiveImprovementGoals
    watchers {
      id
    }
    settings {
      id
      defaultVoteCount
      defaultPublicRetrospectives
      defaultGuidedRetrospectives
      tooltipHintsEnabled
      businessType
      jiraMetricsEnabled
      githubMetricsEnabled
      bitbucketMetricsEnabled
      cardCategories {
        name
        color
      }
    }
    members {
      ...MinimalUser
    }
    children {
      id
      name
      type
      members {
        id
        name
        email
      }
    }
    admin {
      id
      name
      email
    }
    parents {
      id
      name
    }
  }
  ${MinimalUserFragmentDoc}
`;
export const TeamWithOrgFragmentDoc = gql`
  fragment TeamWithOrg on Team {
    ...TeamFragment
    organization {
      id
      name
      userCount
      status
      prepaidSeatCount
    }
  }
  ${TeamFragmentFragmentDoc}
`;
export const OrgMembershipFragmentFragmentDoc = gql`
  fragment OrgMembershipFragment on OrganizationUserMembership {
    role
    trialEndsAt
    archived
    joinedAt
  }
`;
export const OrgMembershipWithUserFragmentDoc = gql`
  fragment OrgMembershipWithUser on OrganizationUserMembership {
    ...OrgMembershipFragment
    seatType
    user {
      id
      email
      name
      lastActiveAt
      createdAt
    }
  }
  ${OrgMembershipFragmentFragmentDoc}
`;
export const TeamInsightsFragmentFragmentDoc = gql`
  fragment TeamInsightsFragment on Team {
    insights {
      ... on TeamInsightFactCauseSolution {
        ...TeamInsightFactCauseSolutionFragment
      }
    }
  }
  ${TeamInsightFactCauseSolutionFragmentFragmentDoc}
`;
export const PlanFragmentFragmentDoc = gql`
  fragment PlanFragment on Plan {
    slug
    displayName
    blockExceedingSeats
    trialUserTimeSeconds
    programs
    integrationMicrosoftTeams
    integrationJira
    supportLevel
    sso
  }
`;
export const OrganizationFragmentFragmentDoc = gql`
  fragment OrganizationFragment on Organization {
    id
    adminOnlyTeamCreation
    ownerId
    name
    userCount
    prepaidSeatCount
    numberOfTeams
    numberOfPrograms
    isExceedingSeats
    numberOfRetrospectivesHeld
    averageTimeBetweenRetrospectives
    inviteToken
    domains
    hasSSO
    status
    trialEndsAt
    owner {
      ...MinimalUser
    }
    team {
      ...TeamFragment
    }
    plan {
      ...PlanFragment
    }
  }
  ${MinimalUserFragmentDoc}
  ${TeamFragmentFragmentDoc}
  ${PlanFragmentFragmentDoc}
`;
export const UserFragmentFragmentDoc = gql`
  fragment UserFragment on User {
    id
    name
    firstName
    lastName
    email
    role
    betaFeatures
    announcementsModalSeen
    team {
      ...TeamFragment
      ...TeamInsightsFragment
      betaFeatures
    }
    watchedTeams {
      ...TeamFragment
    }
    organization {
      ...OrganizationFragment
      betaFeatures
    }
    memberships {
      organization {
        hasSSO
        ...OrganizationFragment
      }
      ...OrgMembershipFragment
    }
    lastActiveAt
    createdAt
    verified
    facilitatorLabelsShown
    ssoControlled
    provider
  }
  ${TeamFragmentFragmentDoc}
  ${TeamInsightsFragmentFragmentDoc}
  ${OrganizationFragmentFragmentDoc}
  ${OrgMembershipFragmentFragmentDoc}
`;
export const CreateActionItemDocument = gql`
  mutation CreateActionItem(
    $boardId: String
    $teamId: String!
    $votes: [String]
    $title: String!
    $comments: JSON
    $groupId: String
    $dueDate: Date
    $description: String
    $assigneeIds: [String]
    $improvementGoals: [String]
    $jiraProjectKey: String
    $originalCardId: String
  ) {
    createActionItem(
      boardId: $boardId
      teamId: $teamId
      votes: $votes
      title: $title
      comments: $comments
      groupId: $groupId
      dueDate: $dueDate
      description: $description
      assigneeIds: $assigneeIds
      improvementGoals: $improvementGoals
      jiraProjectKey: $jiraProjectKey
      originalCardId: $originalCardId
    ) {
      ...ActionItemFragment
    }
  }
  ${ActionItemFragmentFragmentDoc}
`;
export type CreateActionItemMutationFn = Apollo.MutationFunction<
  CreateActionItemMutation,
  CreateActionItemMutationVariables
>;

/**
 * __useCreateActionItemMutation__
 *
 * To run a mutation, you first call `useCreateActionItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActionItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActionItemMutation, { data, loading, error }] = useCreateActionItemMutation({
 *   variables: {
 *      boardId: // value for 'boardId'
 *      teamId: // value for 'teamId'
 *      votes: // value for 'votes'
 *      title: // value for 'title'
 *      comments: // value for 'comments'
 *      groupId: // value for 'groupId'
 *      dueDate: // value for 'dueDate'
 *      description: // value for 'description'
 *      assigneeIds: // value for 'assigneeIds'
 *      improvementGoals: // value for 'improvementGoals'
 *      jiraProjectKey: // value for 'jiraProjectKey'
 *      originalCardId: // value for 'originalCardId'
 *   },
 * });
 */
export function useCreateActionItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateActionItemMutation,
    CreateActionItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateActionItemMutation,
    CreateActionItemMutationVariables
  >(CreateActionItemDocument, options);
}
export type CreateActionItemMutationHookResult = ReturnType<
  typeof useCreateActionItemMutation
>;
export type CreateActionItemMutationResult =
  Apollo.MutationResult<CreateActionItemMutation>;
export type CreateActionItemMutationOptions = Apollo.BaseMutationOptions<
  CreateActionItemMutation,
  CreateActionItemMutationVariables
>;
export const CreateActionItemCommentDocument = gql`
  mutation CreateActionItemComment($actionItemId: ID!, $text: String!) {
    createActionItemComment(actionItemId: $actionItemId, text: $text) {
      ...ActionItemCommentFragment
    }
  }
  ${ActionItemCommentFragmentFragmentDoc}
`;
export type CreateActionItemCommentMutationFn = Apollo.MutationFunction<
  CreateActionItemCommentMutation,
  CreateActionItemCommentMutationVariables
>;

/**
 * __useCreateActionItemCommentMutation__
 *
 * To run a mutation, you first call `useCreateActionItemCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActionItemCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActionItemCommentMutation, { data, loading, error }] = useCreateActionItemCommentMutation({
 *   variables: {
 *      actionItemId: // value for 'actionItemId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useCreateActionItemCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateActionItemCommentMutation,
    CreateActionItemCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateActionItemCommentMutation,
    CreateActionItemCommentMutationVariables
  >(CreateActionItemCommentDocument, options);
}
export type CreateActionItemCommentMutationHookResult = ReturnType<
  typeof useCreateActionItemCommentMutation
>;
export type CreateActionItemCommentMutationResult =
  Apollo.MutationResult<CreateActionItemCommentMutation>;
export type CreateActionItemCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateActionItemCommentMutation,
  CreateActionItemCommentMutationVariables
>;
export const DeleteActionItemCommentDocument = gql`
  mutation DeleteActionItemComment($commentId: ID!) {
    archiveActionItemComment(commentId: $commentId) {
      ...ActionItemCommentFragment
    }
  }
  ${ActionItemCommentFragmentFragmentDoc}
`;
export type DeleteActionItemCommentMutationFn = Apollo.MutationFunction<
  DeleteActionItemCommentMutation,
  DeleteActionItemCommentMutationVariables
>;

/**
 * __useDeleteActionItemCommentMutation__
 *
 * To run a mutation, you first call `useDeleteActionItemCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActionItemCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActionItemCommentMutation, { data, loading, error }] = useDeleteActionItemCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useDeleteActionItemCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteActionItemCommentMutation,
    DeleteActionItemCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteActionItemCommentMutation,
    DeleteActionItemCommentMutationVariables
  >(DeleteActionItemCommentDocument, options);
}
export type DeleteActionItemCommentMutationHookResult = ReturnType<
  typeof useDeleteActionItemCommentMutation
>;
export type DeleteActionItemCommentMutationResult =
  Apollo.MutationResult<DeleteActionItemCommentMutation>;
export type DeleteActionItemCommentMutationOptions = Apollo.BaseMutationOptions<
  DeleteActionItemCommentMutation,
  DeleteActionItemCommentMutationVariables
>;
export const UpdateActionItemDocument = gql`
  mutation UpdateActionItem($actionItemId: String!, $fields: JSON!) {
    updateActionItem(actionItemId: $actionItemId, fields: $fields) {
      ...ActionItemFragment
    }
  }
  ${ActionItemFragmentFragmentDoc}
`;
export type UpdateActionItemMutationFn = Apollo.MutationFunction<
  UpdateActionItemMutation,
  UpdateActionItemMutationVariables
>;

/**
 * __useUpdateActionItemMutation__
 *
 * To run a mutation, you first call `useUpdateActionItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActionItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActionItemMutation, { data, loading, error }] = useUpdateActionItemMutation({
 *   variables: {
 *      actionItemId: // value for 'actionItemId'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useUpdateActionItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateActionItemMutation,
    UpdateActionItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateActionItemMutation,
    UpdateActionItemMutationVariables
  >(UpdateActionItemDocument, options);
}
export type UpdateActionItemMutationHookResult = ReturnType<
  typeof useUpdateActionItemMutation
>;
export type UpdateActionItemMutationResult =
  Apollo.MutationResult<UpdateActionItemMutation>;
export type UpdateActionItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateActionItemMutation,
  UpdateActionItemMutationVariables
>;
export const ActionItemByIdDocument = gql`
  query ActionItemById($id: ID!) {
    actionItem(id: $id) {
      ...ActionItemFragment
      board {
        id
        name
        type
        createdAt
        format {
          displayName
          iconImageUrl
          description
        }
      }
    }
  }
  ${ActionItemFragmentFragmentDoc}
`;

/**
 * __useActionItemByIdQuery__
 *
 * To run a query within a React component, call `useActionItemByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionItemByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionItemByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActionItemByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActionItemByIdQuery,
    ActionItemByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActionItemByIdQuery, ActionItemByIdQueryVariables>(
    ActionItemByIdDocument,
    options
  );
}
export function useActionItemByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActionItemByIdQuery,
    ActionItemByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActionItemByIdQuery, ActionItemByIdQueryVariables>(
    ActionItemByIdDocument,
    options
  );
}
export type ActionItemByIdQueryHookResult = ReturnType<
  typeof useActionItemByIdQuery
>;
export type ActionItemByIdLazyQueryHookResult = ReturnType<
  typeof useActionItemByIdLazyQuery
>;
export type ActionItemByIdQueryResult = Apollo.QueryResult<
  ActionItemByIdQuery,
  ActionItemByIdQueryVariables
>;
export const ActionItemReviewAnalyticsDocument = gql`
  query ActionItemReviewAnalytics($teamId: String!) {
    actionItemReviewAnalytics(teamId: $teamId) {
      id
      averageTimeToResolveActionItemsInDays
      improvementGoalDistribution {
        percentage
        goal {
          id
          type {
            id
            name
            color
          }
        }
      }
    }
  }
`;

/**
 * __useActionItemReviewAnalyticsQuery__
 *
 * To run a query within a React component, call `useActionItemReviewAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionItemReviewAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionItemReviewAnalyticsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useActionItemReviewAnalyticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActionItemReviewAnalyticsQuery,
    ActionItemReviewAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ActionItemReviewAnalyticsQuery,
    ActionItemReviewAnalyticsQueryVariables
  >(ActionItemReviewAnalyticsDocument, options);
}
export function useActionItemReviewAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActionItemReviewAnalyticsQuery,
    ActionItemReviewAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ActionItemReviewAnalyticsQuery,
    ActionItemReviewAnalyticsQueryVariables
  >(ActionItemReviewAnalyticsDocument, options);
}
export type ActionItemReviewAnalyticsQueryHookResult = ReturnType<
  typeof useActionItemReviewAnalyticsQuery
>;
export type ActionItemReviewAnalyticsLazyQueryHookResult = ReturnType<
  typeof useActionItemReviewAnalyticsLazyQuery
>;
export type ActionItemReviewAnalyticsQueryResult = Apollo.QueryResult<
  ActionItemReviewAnalyticsQuery,
  ActionItemReviewAnalyticsQueryVariables
>;
export const ActionItemsByFiltersDocument = gql`
  query ActionItemsByFilters(
    $teamId: String!
    $boardId: String
    $status: ActionItemStatus
    $startDate: Date
    $endDate: Date
    $assigneeIds: [String]
    $dueDate: [Date]
    $improvementGoals: [String]
  ) {
    actionItemsByFilters(
      teamId: $teamId
      boardId: $boardId
      status: $status
      startDate: $startDate
      endDate: $endDate
      assigneeIds: $assigneeIds
      dueDate: $dueDate
      improvementGoals: $improvementGoals
    ) {
      ...ActionItemFragment
    }
  }
  ${ActionItemFragmentFragmentDoc}
`;

/**
 * __useActionItemsByFiltersQuery__
 *
 * To run a query within a React component, call `useActionItemsByFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionItemsByFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionItemsByFiltersQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      boardId: // value for 'boardId'
 *      status: // value for 'status'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      assigneeIds: // value for 'assigneeIds'
 *      dueDate: // value for 'dueDate'
 *      improvementGoals: // value for 'improvementGoals'
 *   },
 * });
 */
export function useActionItemsByFiltersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActionItemsByFiltersQuery,
    ActionItemsByFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ActionItemsByFiltersQuery,
    ActionItemsByFiltersQueryVariables
  >(ActionItemsByFiltersDocument, options);
}
export function useActionItemsByFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActionItemsByFiltersQuery,
    ActionItemsByFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ActionItemsByFiltersQuery,
    ActionItemsByFiltersQueryVariables
  >(ActionItemsByFiltersDocument, options);
}
export type ActionItemsByFiltersQueryHookResult = ReturnType<
  typeof useActionItemsByFiltersQuery
>;
export type ActionItemsByFiltersLazyQueryHookResult = ReturnType<
  typeof useActionItemsByFiltersLazyQuery
>;
export type ActionItemsByFiltersQueryResult = Apollo.QueryResult<
  ActionItemsByFiltersQuery,
  ActionItemsByFiltersQueryVariables
>;
export const ActionItemReviewAnalyticsUpdatesDocument = gql`
  subscription ActionItemReviewAnalyticsUpdates($teamId: String!) {
    actionItemReviewAnalytics(teamId: $teamId) {
      id
      averageTimeToResolveActionItemsInDays
      improvementGoalDistribution {
        percentage
        goal {
          id
          type {
            id
            name
            color
          }
        }
      }
    }
  }
`;

/**
 * __useActionItemReviewAnalyticsUpdatesSubscription__
 *
 * To run a query within a React component, call `useActionItemReviewAnalyticsUpdatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useActionItemReviewAnalyticsUpdatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionItemReviewAnalyticsUpdatesSubscription({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useActionItemReviewAnalyticsUpdatesSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ActionItemReviewAnalyticsUpdatesSubscription,
    ActionItemReviewAnalyticsUpdatesSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ActionItemReviewAnalyticsUpdatesSubscription,
    ActionItemReviewAnalyticsUpdatesSubscriptionVariables
  >(ActionItemReviewAnalyticsUpdatesDocument, options);
}
export type ActionItemReviewAnalyticsUpdatesSubscriptionHookResult = ReturnType<
  typeof useActionItemReviewAnalyticsUpdatesSubscription
>;
export type ActionItemReviewAnalyticsUpdatesSubscriptionResult =
  Apollo.SubscriptionResult<ActionItemReviewAnalyticsUpdatesSubscription>;
export const ActionItemsByFiltersSubscriptionDocument = gql`
  subscription ActionItemsByFiltersSubscription(
    $teamId: String!
    $boardId: String
    $startDate: Date
    $status: ActionItemStatus
  ) {
    actionItemsByFilters(
      teamId: $teamId
      boardId: $boardId
      startDate: $startDate
      status: $status
    ) {
      ...ActionItemFragment
    }
  }
  ${ActionItemFragmentFragmentDoc}
`;

/**
 * __useActionItemsByFiltersSubscriptionSubscription__
 *
 * To run a query within a React component, call `useActionItemsByFiltersSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useActionItemsByFiltersSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionItemsByFiltersSubscriptionSubscription({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      boardId: // value for 'boardId'
 *      startDate: // value for 'startDate'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useActionItemsByFiltersSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ActionItemsByFiltersSubscriptionSubscription,
    ActionItemsByFiltersSubscriptionSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ActionItemsByFiltersSubscriptionSubscription,
    ActionItemsByFiltersSubscriptionSubscriptionVariables
  >(ActionItemsByFiltersSubscriptionDocument, options);
}
export type ActionItemsByFiltersSubscriptionSubscriptionHookResult = ReturnType<
  typeof useActionItemsByFiltersSubscriptionSubscription
>;
export type ActionItemsByFiltersSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<ActionItemsByFiltersSubscriptionSubscription>;
export const CreateCommentForActivityFeedDocument = gql`
  mutation CreateCommentForActivityFeed($activityId: String!, $text: String!) {
    createCommentForActivityFeed(activityId: $activityId, text: $text) {
      ...ActivityFeedItemFragment
    }
  }
  ${ActivityFeedItemFragmentFragmentDoc}
`;
export type CreateCommentForActivityFeedMutationFn = Apollo.MutationFunction<
  CreateCommentForActivityFeedMutation,
  CreateCommentForActivityFeedMutationVariables
>;

/**
 * __useCreateCommentForActivityFeedMutation__
 *
 * To run a mutation, you first call `useCreateCommentForActivityFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentForActivityFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentForActivityFeedMutation, { data, loading, error }] = useCreateCommentForActivityFeedMutation({
 *   variables: {
 *      activityId: // value for 'activityId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useCreateCommentForActivityFeedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCommentForActivityFeedMutation,
    CreateCommentForActivityFeedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCommentForActivityFeedMutation,
    CreateCommentForActivityFeedMutationVariables
  >(CreateCommentForActivityFeedDocument, options);
}
export type CreateCommentForActivityFeedMutationHookResult = ReturnType<
  typeof useCreateCommentForActivityFeedMutation
>;
export type CreateCommentForActivityFeedMutationResult =
  Apollo.MutationResult<CreateCommentForActivityFeedMutation>;
export type CreateCommentForActivityFeedMutationOptions =
  Apollo.BaseMutationOptions<
    CreateCommentForActivityFeedMutation,
    CreateCommentForActivityFeedMutationVariables
  >;
export const ArchiveCommentForActivityFeedDocument = gql`
  mutation ArchiveCommentForActivityFeed(
    $activityId: String!
    $activityFeedCommentId: String!
  ) {
    archiveCommentForActivityFeed(
      activityId: $activityId
      activityFeedCommentId: $activityFeedCommentId
    ) {
      ...ActivityFeedItemFragment
    }
  }
  ${ActivityFeedItemFragmentFragmentDoc}
`;
export type ArchiveCommentForActivityFeedMutationFn = Apollo.MutationFunction<
  ArchiveCommentForActivityFeedMutation,
  ArchiveCommentForActivityFeedMutationVariables
>;

/**
 * __useArchiveCommentForActivityFeedMutation__
 *
 * To run a mutation, you first call `useArchiveCommentForActivityFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveCommentForActivityFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveCommentForActivityFeedMutation, { data, loading, error }] = useArchiveCommentForActivityFeedMutation({
 *   variables: {
 *      activityId: // value for 'activityId'
 *      activityFeedCommentId: // value for 'activityFeedCommentId'
 *   },
 * });
 */
export function useArchiveCommentForActivityFeedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveCommentForActivityFeedMutation,
    ArchiveCommentForActivityFeedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveCommentForActivityFeedMutation,
    ArchiveCommentForActivityFeedMutationVariables
  >(ArchiveCommentForActivityFeedDocument, options);
}
export type ArchiveCommentForActivityFeedMutationHookResult = ReturnType<
  typeof useArchiveCommentForActivityFeedMutation
>;
export type ArchiveCommentForActivityFeedMutationResult =
  Apollo.MutationResult<ArchiveCommentForActivityFeedMutation>;
export type ArchiveCommentForActivityFeedMutationOptions =
  Apollo.BaseMutationOptions<
    ArchiveCommentForActivityFeedMutation,
    ArchiveCommentForActivityFeedMutationVariables
  >;
export const ToggleReactionForActivityFeedDocument = gql`
  mutation ToggleReactionForActivityFeed(
    $activityId: String!
    $kind: ActivityReactionKind!
  ) {
    toggleReaction(activityId: $activityId, kind: $kind) {
      ...ActivityFeedItemFragment
    }
  }
  ${ActivityFeedItemFragmentFragmentDoc}
`;
export type ToggleReactionForActivityFeedMutationFn = Apollo.MutationFunction<
  ToggleReactionForActivityFeedMutation,
  ToggleReactionForActivityFeedMutationVariables
>;

/**
 * __useToggleReactionForActivityFeedMutation__
 *
 * To run a mutation, you first call `useToggleReactionForActivityFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleReactionForActivityFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleReactionForActivityFeedMutation, { data, loading, error }] = useToggleReactionForActivityFeedMutation({
 *   variables: {
 *      activityId: // value for 'activityId'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useToggleReactionForActivityFeedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleReactionForActivityFeedMutation,
    ToggleReactionForActivityFeedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleReactionForActivityFeedMutation,
    ToggleReactionForActivityFeedMutationVariables
  >(ToggleReactionForActivityFeedDocument, options);
}
export type ToggleReactionForActivityFeedMutationHookResult = ReturnType<
  typeof useToggleReactionForActivityFeedMutation
>;
export type ToggleReactionForActivityFeedMutationResult =
  Apollo.MutationResult<ToggleReactionForActivityFeedMutation>;
export type ToggleReactionForActivityFeedMutationOptions =
  Apollo.BaseMutationOptions<
    ToggleReactionForActivityFeedMutation,
    ToggleReactionForActivityFeedMutationVariables
  >;
export const GetActivityFeedDocument = gql`
  query GetActivityFeed(
    $teamId: String!
    $pageSize: Int!
    $cursor: String
    $typeFilters: [ActivityType!]
  ) {
    feed(
      teamId: $teamId
      pageSize: $pageSize
      cursor: $cursor
      typeFilters: $typeFilters
    ) {
      activities {
        ...ActivityFeedItemFragment
      }
      nextCursor
    }
  }
  ${ActivityFeedItemFragmentFragmentDoc}
`;

/**
 * __useGetActivityFeedQuery__
 *
 * To run a query within a React component, call `useGetActivityFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityFeedQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *      typeFilters: // value for 'typeFilters'
 *   },
 * });
 */
export function useGetActivityFeedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActivityFeedQuery,
    GetActivityFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActivityFeedQuery, GetActivityFeedQueryVariables>(
    GetActivityFeedDocument,
    options
  );
}
export function useGetActivityFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivityFeedQuery,
    GetActivityFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActivityFeedQuery,
    GetActivityFeedQueryVariables
  >(GetActivityFeedDocument, options);
}
export type GetActivityFeedQueryHookResult = ReturnType<
  typeof useGetActivityFeedQuery
>;
export type GetActivityFeedLazyQueryHookResult = ReturnType<
  typeof useGetActivityFeedLazyQuery
>;
export type GetActivityFeedQueryResult = Apollo.QueryResult<
  GetActivityFeedQuery,
  GetActivityFeedQueryVariables
>;
export const ActivityFeedNewActivityByTeamIdDocument = gql`
  subscription ActivityFeedNewActivityByTeamId($teamId: String!) {
    activityFeedNewActivityByTeamId(teamId: $teamId) {
      ...ActivityFeedItemFragment
    }
  }
  ${ActivityFeedItemFragmentFragmentDoc}
`;

/**
 * __useActivityFeedNewActivityByTeamIdSubscription__
 *
 * To run a query within a React component, call `useActivityFeedNewActivityByTeamIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useActivityFeedNewActivityByTeamIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityFeedNewActivityByTeamIdSubscription({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useActivityFeedNewActivityByTeamIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ActivityFeedNewActivityByTeamIdSubscription,
    ActivityFeedNewActivityByTeamIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ActivityFeedNewActivityByTeamIdSubscription,
    ActivityFeedNewActivityByTeamIdSubscriptionVariables
  >(ActivityFeedNewActivityByTeamIdDocument, options);
}
export type ActivityFeedNewActivityByTeamIdSubscriptionHookResult = ReturnType<
  typeof useActivityFeedNewActivityByTeamIdSubscription
>;
export type ActivityFeedNewActivityByTeamIdSubscriptionResult =
  Apollo.SubscriptionResult<ActivityFeedNewActivityByTeamIdSubscription>;
export const ActivityFeedUpdateByTeamIdDocument = gql`
  subscription ActivityFeedUpdateByTeamId($teamId: String!) {
    activityFeedUpdateByTeamId(teamId: $teamId) {
      ...ActivityFeedItemFragment
    }
  }
  ${ActivityFeedItemFragmentFragmentDoc}
`;

/**
 * __useActivityFeedUpdateByTeamIdSubscription__
 *
 * To run a query within a React component, call `useActivityFeedUpdateByTeamIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useActivityFeedUpdateByTeamIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityFeedUpdateByTeamIdSubscription({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useActivityFeedUpdateByTeamIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ActivityFeedUpdateByTeamIdSubscription,
    ActivityFeedUpdateByTeamIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ActivityFeedUpdateByTeamIdSubscription,
    ActivityFeedUpdateByTeamIdSubscriptionVariables
  >(ActivityFeedUpdateByTeamIdDocument, options);
}
export type ActivityFeedUpdateByTeamIdSubscriptionHookResult = ReturnType<
  typeof useActivityFeedUpdateByTeamIdSubscription
>;
export type ActivityFeedUpdateByTeamIdSubscriptionResult =
  Apollo.SubscriptionResult<ActivityFeedUpdateByTeamIdSubscription>;
export const ActionItemsAnalyticsDocument = gql`
  query ActionItemsAnalytics(
    $teamId: ID!
    $organizationTeamId: ID!
    $startDate: Date!
    $middleDate: Date!
    $endDate: Date!
    $recursive: Boolean!
  ) {
    current: actionItemAnalytics(
      filter: {
        teams: [$teamId]
        startDate: $middleDate
        endDate: $endDate
        recursive: $recursive
      }
    ) {
      averageTimeToResolve
      createdCount
      resolvedCount
    }
    past: actionItemAnalytics(
      filter: {
        teams: [$teamId]
        startDate: $startDate
        endDate: $middleDate
        recursive: $recursive
      }
    ) {
      averageTimeToResolve
      createdCount
      resolvedCount
    }
    organization: actionItemAnalytics(
      filter: {
        teams: [$organizationTeamId]
        startDate: $middleDate
        recursive: $recursive
      }
    ) {
      averageTimeToResolve
      averageCreatedCount
      averageResolvedCount
    }
  }
`;

/**
 * __useActionItemsAnalyticsQuery__
 *
 * To run a query within a React component, call `useActionItemsAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionItemsAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionItemsAnalyticsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      organizationTeamId: // value for 'organizationTeamId'
 *      startDate: // value for 'startDate'
 *      middleDate: // value for 'middleDate'
 *      endDate: // value for 'endDate'
 *      recursive: // value for 'recursive'
 *   },
 * });
 */
export function useActionItemsAnalyticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActionItemsAnalyticsQuery,
    ActionItemsAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ActionItemsAnalyticsQuery,
    ActionItemsAnalyticsQueryVariables
  >(ActionItemsAnalyticsDocument, options);
}
export function useActionItemsAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActionItemsAnalyticsQuery,
    ActionItemsAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ActionItemsAnalyticsQuery,
    ActionItemsAnalyticsQueryVariables
  >(ActionItemsAnalyticsDocument, options);
}
export type ActionItemsAnalyticsQueryHookResult = ReturnType<
  typeof useActionItemsAnalyticsQuery
>;
export type ActionItemsAnalyticsLazyQueryHookResult = ReturnType<
  typeof useActionItemsAnalyticsLazyQuery
>;
export type ActionItemsAnalyticsQueryResult = Apollo.QueryResult<
  ActionItemsAnalyticsQuery,
  ActionItemsAnalyticsQueryVariables
>;
export const BoardAnalyticsDocument = gql`
  query BoardAnalytics(
    $teamId: ID!
    $organizationTeamId: ID!
    $startDate: Date!
    $middleDate: Date!
    $endDate: Date!
    $recursive: Boolean!
  ) {
    current: boardAnalytics(
      filter: {
        teams: [$teamId]
        startDate: $middleDate
        endDate: $endDate
        recursive: $recursive
      }
    ) {
      averageTimeBetweenRetros
      sumBoardsCreated
      averageParticipationRate
    }
    past: boardAnalytics(
      filter: {
        teams: [$teamId]
        startDate: $startDate
        endDate: $middleDate
        recursive: $recursive
      }
    ) {
      averageTimeBetweenRetros
      sumBoardsCreated
      averageParticipationRate
    }
    organization: boardAnalytics(
      filter: {
        teams: [$organizationTeamId]
        startDate: $middleDate
        endDate: $endDate
        recursive: $recursive
      }
    ) {
      averageBoardsCreated
      averageTimeBetweenRetros
      averageParticipationRate
    }
  }
`;

/**
 * __useBoardAnalyticsQuery__
 *
 * To run a query within a React component, call `useBoardAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoardAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardAnalyticsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      organizationTeamId: // value for 'organizationTeamId'
 *      startDate: // value for 'startDate'
 *      middleDate: // value for 'middleDate'
 *      endDate: // value for 'endDate'
 *      recursive: // value for 'recursive'
 *   },
 * });
 */
export function useBoardAnalyticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BoardAnalyticsQuery,
    BoardAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BoardAnalyticsQuery, BoardAnalyticsQueryVariables>(
    BoardAnalyticsDocument,
    options
  );
}
export function useBoardAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BoardAnalyticsQuery,
    BoardAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BoardAnalyticsQuery, BoardAnalyticsQueryVariables>(
    BoardAnalyticsDocument,
    options
  );
}
export type BoardAnalyticsQueryHookResult = ReturnType<
  typeof useBoardAnalyticsQuery
>;
export type BoardAnalyticsLazyQueryHookResult = ReturnType<
  typeof useBoardAnalyticsLazyQuery
>;
export type BoardAnalyticsQueryResult = Apollo.QueryResult<
  BoardAnalyticsQuery,
  BoardAnalyticsQueryVariables
>;
export const DiscussionAnalyticsDocument = gql`
  query DiscussionAnalytics(
    $teamId: ID!
    $organizationTeamId: ID!
    $startDate: Date!
    $middleDate: Date!
    $endDate: Date!
    $recursive: Boolean!
  ) {
    current: discussionAnalytics(
      filter: {
        teams: [$teamId]
        startDate: $middleDate
        endDate: $endDate
        recursive: $recursive
      }
    ) {
      averageTimeToResolveMs
      avgTimeBetweenRetrosMs
      participationRatePercent
      positiveCardPercentage
      neutralCardPercentage
      negativeCardPercentage
      positiveKeywordExamples
      neutralKeywordExamples
      negativeKeywordExamples
      insight {
        ... on DiscussionAnalyticsInsightKeywordHighlight {
          sentiment
          keyword
        }
      }
    }
    past: discussionAnalytics(
      filter: {
        teams: [$teamId]
        startDate: $startDate
        endDate: $middleDate
        recursive: $recursive
      }
    ) {
      averageTimeToResolveMs
      avgTimeBetweenRetrosMs
      participationRatePercent
    }
    organization: discussionAnalytics(
      filter: {
        teams: [$organizationTeamId]
        startDate: $middleDate
        endDate: $endDate
        recursive: $recursive
      }
    ) {
      averageTimeToResolveMs
      avgTimeBetweenRetrosMs
      participationRatePercent
    }
  }
`;

/**
 * __useDiscussionAnalyticsQuery__
 *
 * To run a query within a React component, call `useDiscussionAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscussionAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscussionAnalyticsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      organizationTeamId: // value for 'organizationTeamId'
 *      startDate: // value for 'startDate'
 *      middleDate: // value for 'middleDate'
 *      endDate: // value for 'endDate'
 *      recursive: // value for 'recursive'
 *   },
 * });
 */
export function useDiscussionAnalyticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DiscussionAnalyticsQuery,
    DiscussionAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DiscussionAnalyticsQuery,
    DiscussionAnalyticsQueryVariables
  >(DiscussionAnalyticsDocument, options);
}
export function useDiscussionAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DiscussionAnalyticsQuery,
    DiscussionAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DiscussionAnalyticsQuery,
    DiscussionAnalyticsQueryVariables
  >(DiscussionAnalyticsDocument, options);
}
export type DiscussionAnalyticsQueryHookResult = ReturnType<
  typeof useDiscussionAnalyticsQuery
>;
export type DiscussionAnalyticsLazyQueryHookResult = ReturnType<
  typeof useDiscussionAnalyticsLazyQuery
>;
export type DiscussionAnalyticsQueryResult = Apollo.QueryResult<
  DiscussionAnalyticsQuery,
  DiscussionAnalyticsQueryVariables
>;
export const GoalFocusAnalyticsDocument = gql`
  query GoalFocusAnalytics($filter: GoalFocusAnalyticsFilter!) {
    goalFocusAnalytics(filter: $filter) {
      orgGoalsFocusData {
        goalName
        goalColor
        onTargetPercentage
        offTargetPercentage
        onTargetCount
        offTargetCount
      }
      programGoalsFocusData {
        goalName
        goalColor
        onTargetPercentage
        offTargetPercentage
        onTargetCount
        offTargetCount
      }
      teamGoalsFocusData {
        goalName
        goalColor
        onTargetPercentage
        offTargetPercentage
        onTargetCount
        offTargetCount
      }
    }
  }
`;

/**
 * __useGoalFocusAnalyticsQuery__
 *
 * To run a query within a React component, call `useGoalFocusAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoalFocusAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoalFocusAnalyticsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGoalFocusAnalyticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GoalFocusAnalyticsQuery,
    GoalFocusAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GoalFocusAnalyticsQuery,
    GoalFocusAnalyticsQueryVariables
  >(GoalFocusAnalyticsDocument, options);
}
export function useGoalFocusAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GoalFocusAnalyticsQuery,
    GoalFocusAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GoalFocusAnalyticsQuery,
    GoalFocusAnalyticsQueryVariables
  >(GoalFocusAnalyticsDocument, options);
}
export type GoalFocusAnalyticsQueryHookResult = ReturnType<
  typeof useGoalFocusAnalyticsQuery
>;
export type GoalFocusAnalyticsLazyQueryHookResult = ReturnType<
  typeof useGoalFocusAnalyticsLazyQuery
>;
export type GoalFocusAnalyticsQueryResult = Apollo.QueryResult<
  GoalFocusAnalyticsQuery,
  GoalFocusAnalyticsQueryVariables
>;
export const RetroSummaryAnalyticsDocument = gql`
  query RetroSummaryAnalytics($filters: AnalyticsFilters!) {
    rollupAnalyticsByFilters(filters: $filters) {
      averageCardsPerRetrospective
      averageTimePerRetrospectiveInDays
      averageTimeToResolveActionItemsInDays
      sentimentAnalysis {
        totalPositive
        totalNegative
        totalNeutral
      }
    }
  }
`;

/**
 * __useRetroSummaryAnalyticsQuery__
 *
 * To run a query within a React component, call `useRetroSummaryAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetroSummaryAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetroSummaryAnalyticsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useRetroSummaryAnalyticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RetroSummaryAnalyticsQuery,
    RetroSummaryAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RetroSummaryAnalyticsQuery,
    RetroSummaryAnalyticsQueryVariables
  >(RetroSummaryAnalyticsDocument, options);
}
export function useRetroSummaryAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RetroSummaryAnalyticsQuery,
    RetroSummaryAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RetroSummaryAnalyticsQuery,
    RetroSummaryAnalyticsQueryVariables
  >(RetroSummaryAnalyticsDocument, options);
}
export type RetroSummaryAnalyticsQueryHookResult = ReturnType<
  typeof useRetroSummaryAnalyticsQuery
>;
export type RetroSummaryAnalyticsLazyQueryHookResult = ReturnType<
  typeof useRetroSummaryAnalyticsLazyQuery
>;
export type RetroSummaryAnalyticsQueryResult = Apollo.QueryResult<
  RetroSummaryAnalyticsQuery,
  RetroSummaryAnalyticsQueryVariables
>;
export const BroadcastBoardMessageDocument = gql`
  mutation BroadcastBoardMessage(
    $boardMessageBroadcastInput: BoardMessageBroadcastInput!
  ) {
    boardMessageBroadcast(
      boardMessageBroadcastInput: $boardMessageBroadcastInput
    )
  }
`;
export type BroadcastBoardMessageMutationFn = Apollo.MutationFunction<
  BroadcastBoardMessageMutation,
  BroadcastBoardMessageMutationVariables
>;

/**
 * __useBroadcastBoardMessageMutation__
 *
 * To run a mutation, you first call `useBroadcastBoardMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBroadcastBoardMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [broadcastBoardMessageMutation, { data, loading, error }] = useBroadcastBoardMessageMutation({
 *   variables: {
 *      boardMessageBroadcastInput: // value for 'boardMessageBroadcastInput'
 *   },
 * });
 */
export function useBroadcastBoardMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BroadcastBoardMessageMutation,
    BroadcastBoardMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BroadcastBoardMessageMutation,
    BroadcastBoardMessageMutationVariables
  >(BroadcastBoardMessageDocument, options);
}
export type BroadcastBoardMessageMutationHookResult = ReturnType<
  typeof useBroadcastBoardMessageMutation
>;
export type BroadcastBoardMessageMutationResult =
  Apollo.MutationResult<BroadcastBoardMessageMutation>;
export type BroadcastBoardMessageMutationOptions = Apollo.BaseMutationOptions<
  BroadcastBoardMessageMutation,
  BroadcastBoardMessageMutationVariables
>;
export const CloneBoardDocument = gql`
  mutation CloneBoard($boardIdToClone: String!, $nameOfClone: String!) {
    cloneBoard(boardIdToClone: $boardIdToClone, nameOfClone: $nameOfClone) {
      ...BasicBoard
    }
  }
  ${BasicBoardFragmentDoc}
`;
export type CloneBoardMutationFn = Apollo.MutationFunction<
  CloneBoardMutation,
  CloneBoardMutationVariables
>;

/**
 * __useCloneBoardMutation__
 *
 * To run a mutation, you first call `useCloneBoardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneBoardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneBoardMutation, { data, loading, error }] = useCloneBoardMutation({
 *   variables: {
 *      boardIdToClone: // value for 'boardIdToClone'
 *      nameOfClone: // value for 'nameOfClone'
 *   },
 * });
 */
export function useCloneBoardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloneBoardMutation,
    CloneBoardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CloneBoardMutation, CloneBoardMutationVariables>(
    CloneBoardDocument,
    options
  );
}
export type CloneBoardMutationHookResult = ReturnType<
  typeof useCloneBoardMutation
>;
export type CloneBoardMutationResult =
  Apollo.MutationResult<CloneBoardMutation>;
export type CloneBoardMutationOptions = Apollo.BaseMutationOptions<
  CloneBoardMutation,
  CloneBoardMutationVariables
>;
export const CreateBoardDocument = gql`
  mutation CreateBoard(
    $teamId: String!
    $ownerId: String!
    $name: String!
    $listNames: [String]
    $public: Boolean!
    $guided: Boolean!
    $parkingLotItems: [ID!]
    $startOnStage: BoardStage
    $format: String
  ) {
    createBoard(
      teamId: $teamId
      ownerId: $ownerId
      name: $name
      listNames: $listNames
      public: $public
      guided: $guided
      parkingLotItems: $parkingLotItems
      format: $format
      startOnStage: $startOnStage
    ) {
      id
      name
      participationRate
      totalCards
      public
      updatedAt
      teamId
      archived
      formatSlug
    }
  }
`;
export type CreateBoardMutationFn = Apollo.MutationFunction<
  CreateBoardMutation,
  CreateBoardMutationVariables
>;

/**
 * __useCreateBoardMutation__
 *
 * To run a mutation, you first call `useCreateBoardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBoardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBoardMutation, { data, loading, error }] = useCreateBoardMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      ownerId: // value for 'ownerId'
 *      name: // value for 'name'
 *      listNames: // value for 'listNames'
 *      public: // value for 'public'
 *      guided: // value for 'guided'
 *      parkingLotItems: // value for 'parkingLotItems'
 *      startOnStage: // value for 'startOnStage'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useCreateBoardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBoardMutation,
    CreateBoardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBoardMutation, CreateBoardMutationVariables>(
    CreateBoardDocument,
    options
  );
}
export type CreateBoardMutationHookResult = ReturnType<
  typeof useCreateBoardMutation
>;
export type CreateBoardMutationResult =
  Apollo.MutationResult<CreateBoardMutation>;
export type CreateBoardMutationOptions = Apollo.BaseMutationOptions<
  CreateBoardMutation,
  CreateBoardMutationVariables
>;
export const CreateCardDocument = gql`
  mutation CreateCard($listId: String!, $text: String!) {
    createCard(listId: $listId, text: $text) {
      ...CardFragment
    }
  }
  ${CardFragmentFragmentDoc}
`;
export type CreateCardMutationFn = Apollo.MutationFunction<
  CreateCardMutation,
  CreateCardMutationVariables
>;

/**
 * __useCreateCardMutation__
 *
 * To run a mutation, you first call `useCreateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCardMutation, { data, loading, error }] = useCreateCardMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useCreateCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCardMutation,
    CreateCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCardMutation, CreateCardMutationVariables>(
    CreateCardDocument,
    options
  );
}
export type CreateCardMutationHookResult = ReturnType<
  typeof useCreateCardMutation
>;
export type CreateCardMutationResult =
  Apollo.MutationResult<CreateCardMutation>;
export type CreateCardMutationOptions = Apollo.BaseMutationOptions<
  CreateCardMutation,
  CreateCardMutationVariables
>;
export const CreateCardCommentDocument = gql`
  mutation CreateCardComment($cardId: ID!, $text: String!) {
    createCardComment(cardId: $cardId, text: $text) {
      ...CardCommentFragment
    }
  }
  ${CardCommentFragmentFragmentDoc}
`;
export type CreateCardCommentMutationFn = Apollo.MutationFunction<
  CreateCardCommentMutation,
  CreateCardCommentMutationVariables
>;

/**
 * __useCreateCardCommentMutation__
 *
 * To run a mutation, you first call `useCreateCardCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCardCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCardCommentMutation, { data, loading, error }] = useCreateCardCommentMutation({
 *   variables: {
 *      cardId: // value for 'cardId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useCreateCardCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCardCommentMutation,
    CreateCardCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCardCommentMutation,
    CreateCardCommentMutationVariables
  >(CreateCardCommentDocument, options);
}
export type CreateCardCommentMutationHookResult = ReturnType<
  typeof useCreateCardCommentMutation
>;
export type CreateCardCommentMutationResult =
  Apollo.MutationResult<CreateCardCommentMutation>;
export type CreateCardCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateCardCommentMutation,
  CreateCardCommentMutationVariables
>;
export const CreateGroupCardDocument = gql`
  mutation CreateGroupCard($dragCardId: String, $dropCardId: String) {
    createGroupCard(dragCardId: $dragCardId, dropCardId: $dropCardId) {
      ...BoardFragment
    }
  }
  ${BoardFragmentFragmentDoc}
`;
export type CreateGroupCardMutationFn = Apollo.MutationFunction<
  CreateGroupCardMutation,
  CreateGroupCardMutationVariables
>;

/**
 * __useCreateGroupCardMutation__
 *
 * To run a mutation, you first call `useCreateGroupCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupCardMutation, { data, loading, error }] = useCreateGroupCardMutation({
 *   variables: {
 *      dragCardId: // value for 'dragCardId'
 *      dropCardId: // value for 'dropCardId'
 *   },
 * });
 */
export function useCreateGroupCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGroupCardMutation,
    CreateGroupCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateGroupCardMutation,
    CreateGroupCardMutationVariables
  >(CreateGroupCardDocument, options);
}
export type CreateGroupCardMutationHookResult = ReturnType<
  typeof useCreateGroupCardMutation
>;
export type CreateGroupCardMutationResult =
  Apollo.MutationResult<CreateGroupCardMutation>;
export type CreateGroupCardMutationOptions = Apollo.BaseMutationOptions<
  CreateGroupCardMutation,
  CreateGroupCardMutationVariables
>;
export const CreateJiraIssueFromCardDocument = gql`
  mutation CreateJiraIssueFromCard($projectKey: String!, $cardId: ID!) {
    createJiraIssueFromCard(projectKey: $projectKey, cardId: $cardId) {
      id
    }
  }
`;
export type CreateJiraIssueFromCardMutationFn = Apollo.MutationFunction<
  CreateJiraIssueFromCardMutation,
  CreateJiraIssueFromCardMutationVariables
>;

/**
 * __useCreateJiraIssueFromCardMutation__
 *
 * To run a mutation, you first call `useCreateJiraIssueFromCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJiraIssueFromCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJiraIssueFromCardMutation, { data, loading, error }] = useCreateJiraIssueFromCardMutation({
 *   variables: {
 *      projectKey: // value for 'projectKey'
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useCreateJiraIssueFromCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateJiraIssueFromCardMutation,
    CreateJiraIssueFromCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateJiraIssueFromCardMutation,
    CreateJiraIssueFromCardMutationVariables
  >(CreateJiraIssueFromCardDocument, options);
}
export type CreateJiraIssueFromCardMutationHookResult = ReturnType<
  typeof useCreateJiraIssueFromCardMutation
>;
export type CreateJiraIssueFromCardMutationResult =
  Apollo.MutationResult<CreateJiraIssueFromCardMutation>;
export type CreateJiraIssueFromCardMutationOptions = Apollo.BaseMutationOptions<
  CreateJiraIssueFromCardMutation,
  CreateJiraIssueFromCardMutationVariables
>;
export const CreateListDocument = gql`
  mutation CreateList($boardId: String!, $name: String!) {
    createList(boardId: $boardId, name: $name) {
      ...ListFragment
    }
  }
  ${ListFragmentFragmentDoc}
`;
export type CreateListMutationFn = Apollo.MutationFunction<
  CreateListMutation,
  CreateListMutationVariables
>;

/**
 * __useCreateListMutation__
 *
 * To run a mutation, you first call `useCreateListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createListMutation, { data, loading, error }] = useCreateListMutation({
 *   variables: {
 *      boardId: // value for 'boardId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateListMutation,
    CreateListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateListMutation, CreateListMutationVariables>(
    CreateListDocument,
    options
  );
}
export type CreateListMutationHookResult = ReturnType<
  typeof useCreateListMutation
>;
export type CreateListMutationResult =
  Apollo.MutationResult<CreateListMutation>;
export type CreateListMutationOptions = Apollo.BaseMutationOptions<
  CreateListMutation,
  CreateListMutationVariables
>;
export const CreateWhiteboardDocument = gql`
  mutation CreateWhiteboard(
    $teamId: String!
    $ownerId: String!
    $name: String!
    $public: Boolean!
    $guided: Boolean!
    $format: String!
  ) {
    createWhiteboard(
      teamId: $teamId
      ownerId: $ownerId
      name: $name
      public: $public
      format: $format
      guided: $guided
    ) {
      ...WhiteboardFragment
    }
  }
  ${WhiteboardFragmentFragmentDoc}
`;
export type CreateWhiteboardMutationFn = Apollo.MutationFunction<
  CreateWhiteboardMutation,
  CreateWhiteboardMutationVariables
>;

/**
 * __useCreateWhiteboardMutation__
 *
 * To run a mutation, you first call `useCreateWhiteboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWhiteboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWhiteboardMutation, { data, loading, error }] = useCreateWhiteboardMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      ownerId: // value for 'ownerId'
 *      name: // value for 'name'
 *      public: // value for 'public'
 *      guided: // value for 'guided'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useCreateWhiteboardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWhiteboardMutation,
    CreateWhiteboardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateWhiteboardMutation,
    CreateWhiteboardMutationVariables
  >(CreateWhiteboardDocument, options);
}
export type CreateWhiteboardMutationHookResult = ReturnType<
  typeof useCreateWhiteboardMutation
>;
export type CreateWhiteboardMutationResult =
  Apollo.MutationResult<CreateWhiteboardMutation>;
export type CreateWhiteboardMutationOptions = Apollo.BaseMutationOptions<
  CreateWhiteboardMutation,
  CreateWhiteboardMutationVariables
>;
export const DuplicateCardDocument = gql`
  mutation DuplicateCard(
    $card: JSON!
    $ownerId: String
    $oldCardIdsOrder: [String]!
  ) {
    duplicateCard(
      card: $card
      ownerId: $ownerId
      oldCardIdsOrder: $oldCardIdsOrder
    ) {
      ...BoardFragment
    }
  }
  ${BoardFragmentFragmentDoc}
`;
export type DuplicateCardMutationFn = Apollo.MutationFunction<
  DuplicateCardMutation,
  DuplicateCardMutationVariables
>;

/**
 * __useDuplicateCardMutation__
 *
 * To run a mutation, you first call `useDuplicateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateCardMutation, { data, loading, error }] = useDuplicateCardMutation({
 *   variables: {
 *      card: // value for 'card'
 *      ownerId: // value for 'ownerId'
 *      oldCardIdsOrder: // value for 'oldCardIdsOrder'
 *   },
 * });
 */
export function useDuplicateCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateCardMutation,
    DuplicateCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DuplicateCardMutation,
    DuplicateCardMutationVariables
  >(DuplicateCardDocument, options);
}
export type DuplicateCardMutationHookResult = ReturnType<
  typeof useDuplicateCardMutation
>;
export type DuplicateCardMutationResult =
  Apollo.MutationResult<DuplicateCardMutation>;
export type DuplicateCardMutationOptions = Apollo.BaseMutationOptions<
  DuplicateCardMutation,
  DuplicateCardMutationVariables
>;
export const FinishBoardDocument = gql`
  mutation FinishBoard($boardId: ID!, $sendSummary: Boolean!) {
    finishBoard(id: $boardId, sendSummary: $sendSummary) {
      id
      state {
        stage
      }
    }
  }
`;
export type FinishBoardMutationFn = Apollo.MutationFunction<
  FinishBoardMutation,
  FinishBoardMutationVariables
>;

/**
 * __useFinishBoardMutation__
 *
 * To run a mutation, you first call `useFinishBoardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishBoardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishBoardMutation, { data, loading, error }] = useFinishBoardMutation({
 *   variables: {
 *      boardId: // value for 'boardId'
 *      sendSummary: // value for 'sendSummary'
 *   },
 * });
 */
export function useFinishBoardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinishBoardMutation,
    FinishBoardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FinishBoardMutation, FinishBoardMutationVariables>(
    FinishBoardDocument,
    options
  );
}
export type FinishBoardMutationHookResult = ReturnType<
  typeof useFinishBoardMutation
>;
export type FinishBoardMutationResult =
  Apollo.MutationResult<FinishBoardMutation>;
export type FinishBoardMutationOptions = Apollo.BaseMutationOptions<
  FinishBoardMutation,
  FinishBoardMutationVariables
>;
export const GenerateBoardTakeawaysDocument = gql`
  mutation GenerateBoardTakeaways($boardId: ID!) {
    generateBoardTakeaways(boardId: $boardId) {
      ...BoardFragment
    }
  }
  ${BoardFragmentFragmentDoc}
`;
export type GenerateBoardTakeawaysMutationFn = Apollo.MutationFunction<
  GenerateBoardTakeawaysMutation,
  GenerateBoardTakeawaysMutationVariables
>;

/**
 * __useGenerateBoardTakeawaysMutation__
 *
 * To run a mutation, you first call `useGenerateBoardTakeawaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateBoardTakeawaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateBoardTakeawaysMutation, { data, loading, error }] = useGenerateBoardTakeawaysMutation({
 *   variables: {
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useGenerateBoardTakeawaysMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateBoardTakeawaysMutation,
    GenerateBoardTakeawaysMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateBoardTakeawaysMutation,
    GenerateBoardTakeawaysMutationVariables
  >(GenerateBoardTakeawaysDocument, options);
}
export type GenerateBoardTakeawaysMutationHookResult = ReturnType<
  typeof useGenerateBoardTakeawaysMutation
>;
export type GenerateBoardTakeawaysMutationResult =
  Apollo.MutationResult<GenerateBoardTakeawaysMutation>;
export type GenerateBoardTakeawaysMutationOptions = Apollo.BaseMutationOptions<
  GenerateBoardTakeawaysMutation,
  GenerateBoardTakeawaysMutationVariables
>;
export const ImportBoardsDocument = gql`
  mutation ImportBoards($boardInputs: [BoardsInput]) {
    importBoards(boardsInput: $boardInputs) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export type ImportBoardsMutationFn = Apollo.MutationFunction<
  ImportBoardsMutation,
  ImportBoardsMutationVariables
>;

/**
 * __useImportBoardsMutation__
 *
 * To run a mutation, you first call `useImportBoardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportBoardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importBoardsMutation, { data, loading, error }] = useImportBoardsMutation({
 *   variables: {
 *      boardInputs: // value for 'boardInputs'
 *   },
 * });
 */
export function useImportBoardsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportBoardsMutation,
    ImportBoardsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportBoardsMutation,
    ImportBoardsMutationVariables
  >(ImportBoardsDocument, options);
}
export type ImportBoardsMutationHookResult = ReturnType<
  typeof useImportBoardsMutation
>;
export type ImportBoardsMutationResult =
  Apollo.MutationResult<ImportBoardsMutation>;
export type ImportBoardsMutationOptions = Apollo.BaseMutationOptions<
  ImportBoardsMutation,
  ImportBoardsMutationVariables
>;
export const InviteToBoardByEmailDocument = gql`
  mutation InviteToBoardByEmail($boardId: ID!, $email: String!) {
    inviteToBoardByEmail(boardId: $boardId, email: $email) {
      id
    }
  }
`;
export type InviteToBoardByEmailMutationFn = Apollo.MutationFunction<
  InviteToBoardByEmailMutation,
  InviteToBoardByEmailMutationVariables
>;

/**
 * __useInviteToBoardByEmailMutation__
 *
 * To run a mutation, you first call `useInviteToBoardByEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteToBoardByEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteToBoardByEmailMutation, { data, loading, error }] = useInviteToBoardByEmailMutation({
 *   variables: {
 *      boardId: // value for 'boardId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useInviteToBoardByEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteToBoardByEmailMutation,
    InviteToBoardByEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteToBoardByEmailMutation,
    InviteToBoardByEmailMutationVariables
  >(InviteToBoardByEmailDocument, options);
}
export type InviteToBoardByEmailMutationHookResult = ReturnType<
  typeof useInviteToBoardByEmailMutation
>;
export type InviteToBoardByEmailMutationResult =
  Apollo.MutationResult<InviteToBoardByEmailMutation>;
export type InviteToBoardByEmailMutationOptions = Apollo.BaseMutationOptions<
  InviteToBoardByEmailMutation,
  InviteToBoardByEmailMutationVariables
>;
export const MarkDiscussionReadinessDocument = gql`
  mutation MarkDiscussionReadiness($boardId: String!, $isReady: Boolean!) {
    markCardDiscussionReadiness(boardId: $boardId, isReady: $isReady) {
      id
      onlineUserIds
      discussReadyUserIds
    }
  }
`;
export type MarkDiscussionReadinessMutationFn = Apollo.MutationFunction<
  MarkDiscussionReadinessMutation,
  MarkDiscussionReadinessMutationVariables
>;

/**
 * __useMarkDiscussionReadinessMutation__
 *
 * To run a mutation, you first call `useMarkDiscussionReadinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkDiscussionReadinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markDiscussionReadinessMutation, { data, loading, error }] = useMarkDiscussionReadinessMutation({
 *   variables: {
 *      boardId: // value for 'boardId'
 *      isReady: // value for 'isReady'
 *   },
 * });
 */
export function useMarkDiscussionReadinessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkDiscussionReadinessMutation,
    MarkDiscussionReadinessMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkDiscussionReadinessMutation,
    MarkDiscussionReadinessMutationVariables
  >(MarkDiscussionReadinessDocument, options);
}
export type MarkDiscussionReadinessMutationHookResult = ReturnType<
  typeof useMarkDiscussionReadinessMutation
>;
export type MarkDiscussionReadinessMutationResult =
  Apollo.MutationResult<MarkDiscussionReadinessMutation>;
export type MarkDiscussionReadinessMutationOptions = Apollo.BaseMutationOptions<
  MarkDiscussionReadinessMutation,
  MarkDiscussionReadinessMutationVariables
>;
export const MoveCardIntoListDocument = gql`
  mutation MoveCardIntoList(
    $listId: String!
    $cardId: String
    $newCardIdsOrder: [String]!
  ) {
    moveCardIntoList(
      listId: $listId
      cardId: $cardId
      newCardIdsOrder: $newCardIdsOrder
    ) {
      ...BoardFragment
    }
  }
  ${BoardFragmentFragmentDoc}
`;
export type MoveCardIntoListMutationFn = Apollo.MutationFunction<
  MoveCardIntoListMutation,
  MoveCardIntoListMutationVariables
>;

/**
 * __useMoveCardIntoListMutation__
 *
 * To run a mutation, you first call `useMoveCardIntoListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveCardIntoListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveCardIntoListMutation, { data, loading, error }] = useMoveCardIntoListMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *      cardId: // value for 'cardId'
 *      newCardIdsOrder: // value for 'newCardIdsOrder'
 *   },
 * });
 */
export function useMoveCardIntoListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveCardIntoListMutation,
    MoveCardIntoListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MoveCardIntoListMutation,
    MoveCardIntoListMutationVariables
  >(MoveCardIntoListDocument, options);
}
export type MoveCardIntoListMutationHookResult = ReturnType<
  typeof useMoveCardIntoListMutation
>;
export type MoveCardIntoListMutationResult =
  Apollo.MutationResult<MoveCardIntoListMutation>;
export type MoveCardIntoListMutationOptions = Apollo.BaseMutationOptions<
  MoveCardIntoListMutation,
  MoveCardIntoListMutationVariables
>;
export const NotifyTeamDocument = gql`
  mutation NotifyTeam($boardId: ID!) {
    notifyTeam(boardId: $boardId)
  }
`;
export type NotifyTeamMutationFn = Apollo.MutationFunction<
  NotifyTeamMutation,
  NotifyTeamMutationVariables
>;

/**
 * __useNotifyTeamMutation__
 *
 * To run a mutation, you first call `useNotifyTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotifyTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notifyTeamMutation, { data, loading, error }] = useNotifyTeamMutation({
 *   variables: {
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useNotifyTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NotifyTeamMutation,
    NotifyTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<NotifyTeamMutation, NotifyTeamMutationVariables>(
    NotifyTeamDocument,
    options
  );
}
export type NotifyTeamMutationHookResult = ReturnType<
  typeof useNotifyTeamMutation
>;
export type NotifyTeamMutationResult =
  Apollo.MutationResult<NotifyTeamMutation>;
export type NotifyTeamMutationOptions = Apollo.BaseMutationOptions<
  NotifyTeamMutation,
  NotifyTeamMutationVariables
>;
export const PingBoardParticipationDocument = gql`
  mutation PingBoardParticipation(
    $input: PingBoardParticipationAsAuthenticatedUserInput
  ) {
    pingBoardParticipationAsAuthenticatedUser(input: $input) {
      success
    }
  }
`;
export type PingBoardParticipationMutationFn = Apollo.MutationFunction<
  PingBoardParticipationMutation,
  PingBoardParticipationMutationVariables
>;

/**
 * __usePingBoardParticipationMutation__
 *
 * To run a mutation, you first call `usePingBoardParticipationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePingBoardParticipationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pingBoardParticipationMutation, { data, loading, error }] = usePingBoardParticipationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePingBoardParticipationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PingBoardParticipationMutation,
    PingBoardParticipationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PingBoardParticipationMutation,
    PingBoardParticipationMutationVariables
  >(PingBoardParticipationDocument, options);
}
export type PingBoardParticipationMutationHookResult = ReturnType<
  typeof usePingBoardParticipationMutation
>;
export type PingBoardParticipationMutationResult =
  Apollo.MutationResult<PingBoardParticipationMutation>;
export type PingBoardParticipationMutationOptions = Apollo.BaseMutationOptions<
  PingBoardParticipationMutation,
  PingBoardParticipationMutationVariables
>;
export const PingBoardParticipationFacilitatorDocument = gql`
  mutation PingBoardParticipationFacilitator(
    $input: PingBoardParticipationAsAuthenticatedUserInput
  ) {
    pingBoardParticipationAsAuthenticatedUser(input: $input) {
      participations {
        ...BoardParticipationFragment
      }
    }
  }
  ${BoardParticipationFragmentFragmentDoc}
`;
export type PingBoardParticipationFacilitatorMutationFn =
  Apollo.MutationFunction<
    PingBoardParticipationFacilitatorMutation,
    PingBoardParticipationFacilitatorMutationVariables
  >;

/**
 * __usePingBoardParticipationFacilitatorMutation__
 *
 * To run a mutation, you first call `usePingBoardParticipationFacilitatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePingBoardParticipationFacilitatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pingBoardParticipationFacilitatorMutation, { data, loading, error }] = usePingBoardParticipationFacilitatorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePingBoardParticipationFacilitatorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PingBoardParticipationFacilitatorMutation,
    PingBoardParticipationFacilitatorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PingBoardParticipationFacilitatorMutation,
    PingBoardParticipationFacilitatorMutationVariables
  >(PingBoardParticipationFacilitatorDocument, options);
}
export type PingBoardParticipationFacilitatorMutationHookResult = ReturnType<
  typeof usePingBoardParticipationFacilitatorMutation
>;
export type PingBoardParticipationFacilitatorMutationResult =
  Apollo.MutationResult<PingBoardParticipationFacilitatorMutation>;
export type PingBoardParticipationFacilitatorMutationOptions =
  Apollo.BaseMutationOptions<
    PingBoardParticipationFacilitatorMutation,
    PingBoardParticipationFacilitatorMutationVariables
  >;
export const PromoteCardToProgramsDocument = gql`
  mutation PromoteCardToPrograms($cardId: String!, $programIds: [String!]!) {
    promoteCardToPrograms(cardId: $cardId, programIds: $programIds) {
      ...CardFragment
    }
  }
  ${CardFragmentFragmentDoc}
`;
export type PromoteCardToProgramsMutationFn = Apollo.MutationFunction<
  PromoteCardToProgramsMutation,
  PromoteCardToProgramsMutationVariables
>;

/**
 * __usePromoteCardToProgramsMutation__
 *
 * To run a mutation, you first call `usePromoteCardToProgramsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromoteCardToProgramsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promoteCardToProgramsMutation, { data, loading, error }] = usePromoteCardToProgramsMutation({
 *   variables: {
 *      cardId: // value for 'cardId'
 *      programIds: // value for 'programIds'
 *   },
 * });
 */
export function usePromoteCardToProgramsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PromoteCardToProgramsMutation,
    PromoteCardToProgramsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PromoteCardToProgramsMutation,
    PromoteCardToProgramsMutationVariables
  >(PromoteCardToProgramsDocument, options);
}
export type PromoteCardToProgramsMutationHookResult = ReturnType<
  typeof usePromoteCardToProgramsMutation
>;
export type PromoteCardToProgramsMutationResult =
  Apollo.MutationResult<PromoteCardToProgramsMutation>;
export type PromoteCardToProgramsMutationOptions = Apollo.BaseMutationOptions<
  PromoteCardToProgramsMutation,
  PromoteCardToProgramsMutationVariables
>;
export const ResetBoardVotesDocument = gql`
  mutation ResetBoardVotes($boardId: String!) {
    resetVotesBoard(boardId: $boardId) {
      ...BoardFragment
    }
  }
  ${BoardFragmentFragmentDoc}
`;
export type ResetBoardVotesMutationFn = Apollo.MutationFunction<
  ResetBoardVotesMutation,
  ResetBoardVotesMutationVariables
>;

/**
 * __useResetBoardVotesMutation__
 *
 * To run a mutation, you first call `useResetBoardVotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetBoardVotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetBoardVotesMutation, { data, loading, error }] = useResetBoardVotesMutation({
 *   variables: {
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useResetBoardVotesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetBoardVotesMutation,
    ResetBoardVotesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetBoardVotesMutation,
    ResetBoardVotesMutationVariables
  >(ResetBoardVotesDocument, options);
}
export type ResetBoardVotesMutationHookResult = ReturnType<
  typeof useResetBoardVotesMutation
>;
export type ResetBoardVotesMutationResult =
  Apollo.MutationResult<ResetBoardVotesMutation>;
export type ResetBoardVotesMutationOptions = Apollo.BaseMutationOptions<
  ResetBoardVotesMutation,
  ResetBoardVotesMutationVariables
>;
export const SetBoardCardBeingDiscussedDocument = gql`
  mutation SetBoardCardBeingDiscussed(
    $boardId: ID!
    $cardId: ID!
    $moveCurrentCard: CardDiscussionMovement!
  ) {
    setBoardCardBeingDiscussed(
      boardId: $boardId
      cardId: $cardId
      moveCurrentCard: $moveCurrentCard
    ) {
      ...BoardFragment
    }
  }
  ${BoardFragmentFragmentDoc}
`;
export type SetBoardCardBeingDiscussedMutationFn = Apollo.MutationFunction<
  SetBoardCardBeingDiscussedMutation,
  SetBoardCardBeingDiscussedMutationVariables
>;

/**
 * __useSetBoardCardBeingDiscussedMutation__
 *
 * To run a mutation, you first call `useSetBoardCardBeingDiscussedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBoardCardBeingDiscussedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBoardCardBeingDiscussedMutation, { data, loading, error }] = useSetBoardCardBeingDiscussedMutation({
 *   variables: {
 *      boardId: // value for 'boardId'
 *      cardId: // value for 'cardId'
 *      moveCurrentCard: // value for 'moveCurrentCard'
 *   },
 * });
 */
export function useSetBoardCardBeingDiscussedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetBoardCardBeingDiscussedMutation,
    SetBoardCardBeingDiscussedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetBoardCardBeingDiscussedMutation,
    SetBoardCardBeingDiscussedMutationVariables
  >(SetBoardCardBeingDiscussedDocument, options);
}
export type SetBoardCardBeingDiscussedMutationHookResult = ReturnType<
  typeof useSetBoardCardBeingDiscussedMutation
>;
export type SetBoardCardBeingDiscussedMutationResult =
  Apollo.MutationResult<SetBoardCardBeingDiscussedMutation>;
export type SetBoardCardBeingDiscussedMutationOptions =
  Apollo.BaseMutationOptions<
    SetBoardCardBeingDiscussedMutation,
    SetBoardCardBeingDiscussedMutationVariables
  >;
export const SortBoardDocument = gql`
  mutation SortBoard($boardId: String!, $type: String!) {
    sortBoard(boardId: $boardId, type: $type) {
      ...ListFragment
    }
  }
  ${ListFragmentFragmentDoc}
`;
export type SortBoardMutationFn = Apollo.MutationFunction<
  SortBoardMutation,
  SortBoardMutationVariables
>;

/**
 * __useSortBoardMutation__
 *
 * To run a mutation, you first call `useSortBoardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSortBoardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sortBoardMutation, { data, loading, error }] = useSortBoardMutation({
 *   variables: {
 *      boardId: // value for 'boardId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSortBoardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SortBoardMutation,
    SortBoardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SortBoardMutation, SortBoardMutationVariables>(
    SortBoardDocument,
    options
  );
}
export type SortBoardMutationHookResult = ReturnType<
  typeof useSortBoardMutation
>;
export type SortBoardMutationResult = Apollo.MutationResult<SortBoardMutation>;
export type SortBoardMutationOptions = Apollo.BaseMutationOptions<
  SortBoardMutation,
  SortBoardMutationVariables
>;
export const UpdateBoardDocument = gql`
  mutation UpdateBoard($boardId: String!, $fields: JSON!) {
    updateBoard(boardId: $boardId, fields: $fields) {
      ...BoardFragment
    }
  }
  ${BoardFragmentFragmentDoc}
`;
export type UpdateBoardMutationFn = Apollo.MutationFunction<
  UpdateBoardMutation,
  UpdateBoardMutationVariables
>;

/**
 * __useUpdateBoardMutation__
 *
 * To run a mutation, you first call `useUpdateBoardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBoardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBoardMutation, { data, loading, error }] = useUpdateBoardMutation({
 *   variables: {
 *      boardId: // value for 'boardId'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useUpdateBoardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBoardMutation,
    UpdateBoardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBoardMutation, UpdateBoardMutationVariables>(
    UpdateBoardDocument,
    options
  );
}
export type UpdateBoardMutationHookResult = ReturnType<
  typeof useUpdateBoardMutation
>;
export type UpdateBoardMutationResult =
  Apollo.MutationResult<UpdateBoardMutation>;
export type UpdateBoardMutationOptions = Apollo.BaseMutationOptions<
  UpdateBoardMutation,
  UpdateBoardMutationVariables
>;
export const UpdateCardDocument = gql`
  mutation UpdateCard($cardId: String!, $fields: JSON!) {
    updateCard(cardId: $cardId, fields: $fields) {
      ...CardFragment
    }
  }
  ${CardFragmentFragmentDoc}
`;
export type UpdateCardMutationFn = Apollo.MutationFunction<
  UpdateCardMutation,
  UpdateCardMutationVariables
>;

/**
 * __useUpdateCardMutation__
 *
 * To run a mutation, you first call `useUpdateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardMutation, { data, loading, error }] = useUpdateCardMutation({
 *   variables: {
 *      cardId: // value for 'cardId'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useUpdateCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCardMutation,
    UpdateCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCardMutation, UpdateCardMutationVariables>(
    UpdateCardDocument,
    options
  );
}
export type UpdateCardMutationHookResult = ReturnType<
  typeof useUpdateCardMutation
>;
export type UpdateCardMutationResult =
  Apollo.MutationResult<UpdateCardMutation>;
export type UpdateCardMutationOptions = Apollo.BaseMutationOptions<
  UpdateCardMutation,
  UpdateCardMutationVariables
>;
export const ArchiveCardCommentDocument = gql`
  mutation ArchiveCardComment($commentId: ID!) {
    archiveCardComment(commentId: $commentId) {
      ...CardCommentFragment
    }
  }
  ${CardCommentFragmentFragmentDoc}
`;
export type ArchiveCardCommentMutationFn = Apollo.MutationFunction<
  ArchiveCardCommentMutation,
  ArchiveCardCommentMutationVariables
>;

/**
 * __useArchiveCardCommentMutation__
 *
 * To run a mutation, you first call `useArchiveCardCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveCardCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveCardCommentMutation, { data, loading, error }] = useArchiveCardCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useArchiveCardCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveCardCommentMutation,
    ArchiveCardCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveCardCommentMutation,
    ArchiveCardCommentMutationVariables
  >(ArchiveCardCommentDocument, options);
}
export type ArchiveCardCommentMutationHookResult = ReturnType<
  typeof useArchiveCardCommentMutation
>;
export type ArchiveCardCommentMutationResult =
  Apollo.MutationResult<ArchiveCardCommentMutation>;
export type ArchiveCardCommentMutationOptions = Apollo.BaseMutationOptions<
  ArchiveCardCommentMutation,
  ArchiveCardCommentMutationVariables
>;
export const AddCardToGroupDocument = gql`
  mutation AddCardToGroup(
    $cardToAddId: String!
    $groupId: String!
    $newChildrenIdsOrder: [String!]!
  ) {
    addCardToGroup(
      cardToAddId: $cardToAddId
      groupId: $groupId
      newChildrenIdsOrder: $newChildrenIdsOrder
    ) {
      ...BoardFragment
    }
  }
  ${BoardFragmentFragmentDoc}
`;
export type AddCardToGroupMutationFn = Apollo.MutationFunction<
  AddCardToGroupMutation,
  AddCardToGroupMutationVariables
>;

/**
 * __useAddCardToGroupMutation__
 *
 * To run a mutation, you first call `useAddCardToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCardToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCardToGroupMutation, { data, loading, error }] = useAddCardToGroupMutation({
 *   variables: {
 *      cardToAddId: // value for 'cardToAddId'
 *      groupId: // value for 'groupId'
 *      newChildrenIdsOrder: // value for 'newChildrenIdsOrder'
 *   },
 * });
 */
export function useAddCardToGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCardToGroupMutation,
    AddCardToGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddCardToGroupMutation,
    AddCardToGroupMutationVariables
  >(AddCardToGroupDocument, options);
}
export type AddCardToGroupMutationHookResult = ReturnType<
  typeof useAddCardToGroupMutation
>;
export type AddCardToGroupMutationResult =
  Apollo.MutationResult<AddCardToGroupMutation>;
export type AddCardToGroupMutationOptions = Apollo.BaseMutationOptions<
  AddCardToGroupMutation,
  AddCardToGroupMutationVariables
>;
export const UpdateListDocument = gql`
  mutation UpdateList($listId: String!, $fields: JSON!) {
    updateList(listId: $listId, fields: $fields) {
      ...ListFragment
    }
  }
  ${ListFragmentFragmentDoc}
`;
export type UpdateListMutationFn = Apollo.MutationFunction<
  UpdateListMutation,
  UpdateListMutationVariables
>;

/**
 * __useUpdateListMutation__
 *
 * To run a mutation, you first call `useUpdateListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateListMutation, { data, loading, error }] = useUpdateListMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useUpdateListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateListMutation,
    UpdateListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateListMutation, UpdateListMutationVariables>(
    UpdateListDocument,
    options
  );
}
export type UpdateListMutationHookResult = ReturnType<
  typeof useUpdateListMutation
>;
export type UpdateListMutationResult =
  Apollo.MutationResult<UpdateListMutation>;
export type UpdateListMutationOptions = Apollo.BaseMutationOptions<
  UpdateListMutation,
  UpdateListMutationVariables
>;
export const UpdateListOrderDocument = gql`
  mutation UpdateListOrder($boardId: String!, $listIds: [String]!) {
    updateListOrder(boardId: $boardId, listIds: $listIds) {
      ...BoardFragment
    }
  }
  ${BoardFragmentFragmentDoc}
`;
export type UpdateListOrderMutationFn = Apollo.MutationFunction<
  UpdateListOrderMutation,
  UpdateListOrderMutationVariables
>;

/**
 * __useUpdateListOrderMutation__
 *
 * To run a mutation, you first call `useUpdateListOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateListOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateListOrderMutation, { data, loading, error }] = useUpdateListOrderMutation({
 *   variables: {
 *      boardId: // value for 'boardId'
 *      listIds: // value for 'listIds'
 *   },
 * });
 */
export function useUpdateListOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateListOrderMutation,
    UpdateListOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateListOrderMutation,
    UpdateListOrderMutationVariables
  >(UpdateListOrderDocument, options);
}
export type UpdateListOrderMutationHookResult = ReturnType<
  typeof useUpdateListOrderMutation
>;
export type UpdateListOrderMutationResult =
  Apollo.MutationResult<UpdateListOrderMutation>;
export type UpdateListOrderMutationOptions = Apollo.BaseMutationOptions<
  UpdateListOrderMutation,
  UpdateListOrderMutationVariables
>;
export const VoteCardDocument = gql`
  mutation VoteCard(
    $boardId: String
    $cardId: String!
    $userId: String
    $voted: Boolean!
    $anonymousUser: Boolean!
  ) {
    voteCard(
      boardId: $boardId
      cardId: $cardId
      userId: $userId
      voted: $voted
      anonymousUser: $anonymousUser
    ) {
      ...CardFragment
    }
  }
  ${CardFragmentFragmentDoc}
`;
export type VoteCardMutationFn = Apollo.MutationFunction<
  VoteCardMutation,
  VoteCardMutationVariables
>;

/**
 * __useVoteCardMutation__
 *
 * To run a mutation, you first call `useVoteCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoteCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voteCardMutation, { data, loading, error }] = useVoteCardMutation({
 *   variables: {
 *      boardId: // value for 'boardId'
 *      cardId: // value for 'cardId'
 *      userId: // value for 'userId'
 *      voted: // value for 'voted'
 *      anonymousUser: // value for 'anonymousUser'
 *   },
 * });
 */
export function useVoteCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VoteCardMutation,
    VoteCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VoteCardMutation, VoteCardMutationVariables>(
    VoteCardDocument,
    options
  );
}
export type VoteCardMutationHookResult = ReturnType<typeof useVoteCardMutation>;
export type VoteCardMutationResult = Apollo.MutationResult<VoteCardMutation>;
export type VoteCardMutationOptions = Apollo.BaseMutationOptions<
  VoteCardMutation,
  VoteCardMutationVariables
>;
export const AllRetroFormatsDocument = gql`
  query AllRetroFormats {
    retrospectiveFormats {
      slug
      displayName
      columnNames
      iconImageUrl
      description
      canvasImageUrl
      canvasImageWidth
      canvasImageHeight
      detailsMarkup
    }
  }
`;

/**
 * __useAllRetroFormatsQuery__
 *
 * To run a query within a React component, call `useAllRetroFormatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRetroFormatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRetroFormatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllRetroFormatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllRetroFormatsQuery,
    AllRetroFormatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllRetroFormatsQuery, AllRetroFormatsQueryVariables>(
    AllRetroFormatsDocument,
    options
  );
}
export function useAllRetroFormatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllRetroFormatsQuery,
    AllRetroFormatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllRetroFormatsQuery,
    AllRetroFormatsQueryVariables
  >(AllRetroFormatsDocument, options);
}
export type AllRetroFormatsQueryHookResult = ReturnType<
  typeof useAllRetroFormatsQuery
>;
export type AllRetroFormatsLazyQueryHookResult = ReturnType<
  typeof useAllRetroFormatsLazyQuery
>;
export type AllRetroFormatsQueryResult = Apollo.QueryResult<
  AllRetroFormatsQuery,
  AllRetroFormatsQueryVariables
>;
export const BoardByIdDocument = gql`
  query BoardById($id: ID) {
    board(id: $id) {
      ...BoardFragment
    }
  }
  ${BoardFragmentFragmentDoc}
`;

/**
 * __useBoardByIdQuery__
 *
 * To run a query within a React component, call `useBoardByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoardByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBoardByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<BoardByIdQuery, BoardByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BoardByIdQuery, BoardByIdQueryVariables>(
    BoardByIdDocument,
    options
  );
}
export function useBoardByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BoardByIdQuery,
    BoardByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BoardByIdQuery, BoardByIdQueryVariables>(
    BoardByIdDocument,
    options
  );
}
export type BoardByIdQueryHookResult = ReturnType<typeof useBoardByIdQuery>;
export type BoardByIdLazyQueryHookResult = ReturnType<
  typeof useBoardByIdLazyQuery
>;
export type BoardByIdQueryResult = Apollo.QueryResult<
  BoardByIdQuery,
  BoardByIdQueryVariables
>;
export const BoardNameByTeamInviteAndBoardIdDocument = gql`
  query BoardNameByTeamInviteAndBoardId(
    $teamInviteToken: String!
    $boardId: String!
  ) {
    boardNameByTeamInviteAndBoardId(
      teamInviteToken: $teamInviteToken
      boardId: $boardId
    )
  }
`;

/**
 * __useBoardNameByTeamInviteAndBoardIdQuery__
 *
 * To run a query within a React component, call `useBoardNameByTeamInviteAndBoardIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoardNameByTeamInviteAndBoardIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardNameByTeamInviteAndBoardIdQuery({
 *   variables: {
 *      teamInviteToken: // value for 'teamInviteToken'
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useBoardNameByTeamInviteAndBoardIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    BoardNameByTeamInviteAndBoardIdQuery,
    BoardNameByTeamInviteAndBoardIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BoardNameByTeamInviteAndBoardIdQuery,
    BoardNameByTeamInviteAndBoardIdQueryVariables
  >(BoardNameByTeamInviteAndBoardIdDocument, options);
}
export function useBoardNameByTeamInviteAndBoardIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BoardNameByTeamInviteAndBoardIdQuery,
    BoardNameByTeamInviteAndBoardIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BoardNameByTeamInviteAndBoardIdQuery,
    BoardNameByTeamInviteAndBoardIdQueryVariables
  >(BoardNameByTeamInviteAndBoardIdDocument, options);
}
export type BoardNameByTeamInviteAndBoardIdQueryHookResult = ReturnType<
  typeof useBoardNameByTeamInviteAndBoardIdQuery
>;
export type BoardNameByTeamInviteAndBoardIdLazyQueryHookResult = ReturnType<
  typeof useBoardNameByTeamInviteAndBoardIdLazyQuery
>;
export type BoardNameByTeamInviteAndBoardIdQueryResult = Apollo.QueryResult<
  BoardNameByTeamInviteAndBoardIdQuery,
  BoardNameByTeamInviteAndBoardIdQueryVariables
>;
export const IsUserAuthorizedForBoardDocument = gql`
  query IsUserAuthorizedForBoard($boardId: ID!) {
    isUserAuthorizedForBoard(id: $boardId)
  }
`;

/**
 * __useIsUserAuthorizedForBoardQuery__
 *
 * To run a query within a React component, call `useIsUserAuthorizedForBoardQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUserAuthorizedForBoardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUserAuthorizedForBoardQuery({
 *   variables: {
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useIsUserAuthorizedForBoardQuery(
  baseOptions: Apollo.QueryHookOptions<
    IsUserAuthorizedForBoardQuery,
    IsUserAuthorizedForBoardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IsUserAuthorizedForBoardQuery,
    IsUserAuthorizedForBoardQueryVariables
  >(IsUserAuthorizedForBoardDocument, options);
}
export function useIsUserAuthorizedForBoardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsUserAuthorizedForBoardQuery,
    IsUserAuthorizedForBoardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IsUserAuthorizedForBoardQuery,
    IsUserAuthorizedForBoardQueryVariables
  >(IsUserAuthorizedForBoardDocument, options);
}
export type IsUserAuthorizedForBoardQueryHookResult = ReturnType<
  typeof useIsUserAuthorizedForBoardQuery
>;
export type IsUserAuthorizedForBoardLazyQueryHookResult = ReturnType<
  typeof useIsUserAuthorizedForBoardLazyQuery
>;
export type IsUserAuthorizedForBoardQueryResult = Apollo.QueryResult<
  IsUserAuthorizedForBoardQuery,
  IsUserAuthorizedForBoardQueryVariables
>;
export const PublicBoardNameByBoardIdDocument = gql`
  query PublicBoardNameByBoardId($boardId: String!) {
    publicBoardNameByBoardId(boardId: $boardId)
  }
`;

/**
 * __usePublicBoardNameByBoardIdQuery__
 *
 * To run a query within a React component, call `usePublicBoardNameByBoardIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicBoardNameByBoardIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicBoardNameByBoardIdQuery({
 *   variables: {
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function usePublicBoardNameByBoardIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    PublicBoardNameByBoardIdQuery,
    PublicBoardNameByBoardIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PublicBoardNameByBoardIdQuery,
    PublicBoardNameByBoardIdQueryVariables
  >(PublicBoardNameByBoardIdDocument, options);
}
export function usePublicBoardNameByBoardIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PublicBoardNameByBoardIdQuery,
    PublicBoardNameByBoardIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PublicBoardNameByBoardIdQuery,
    PublicBoardNameByBoardIdQueryVariables
  >(PublicBoardNameByBoardIdDocument, options);
}
export type PublicBoardNameByBoardIdQueryHookResult = ReturnType<
  typeof usePublicBoardNameByBoardIdQuery
>;
export type PublicBoardNameByBoardIdLazyQueryHookResult = ReturnType<
  typeof usePublicBoardNameByBoardIdLazyQuery
>;
export type PublicBoardNameByBoardIdQueryResult = Apollo.QueryResult<
  PublicBoardNameByBoardIdQuery,
  PublicBoardNameByBoardIdQueryVariables
>;
export const BoardCreatedDocument = gql`
  subscription BoardCreated($teamId: ID!) {
    boardCreated(teamId: $teamId) {
      id
      name
      teamId
      ownerId
      participationRate
      totalCards
      public
      createdAt
      updatedAt
      archived
      type
      formatSlug
      format {
        iconImageUrl
      }
    }
  }
`;

/**
 * __useBoardCreatedSubscription__
 *
 * To run a query within a React component, call `useBoardCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBoardCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardCreatedSubscription({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useBoardCreatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    BoardCreatedSubscription,
    BoardCreatedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    BoardCreatedSubscription,
    BoardCreatedSubscriptionVariables
  >(BoardCreatedDocument, options);
}
export type BoardCreatedSubscriptionHookResult = ReturnType<
  typeof useBoardCreatedSubscription
>;
export type BoardCreatedSubscriptionResult =
  Apollo.SubscriptionResult<BoardCreatedSubscription>;
export const BoardDiscussStageUpdatesDocument = gql`
  subscription BoardDiscussStageUpdates($boardId: ID!) {
    boardDiscussStageUpdates(boardId: $boardId) {
      id
      discussReadyUserIds
      cardsOnDiscussion {
        id
        text
        wasDiscussed
        originalListBadge {
          color
          text
        }
      }
      cardBeingDiscussed {
        ...CardFragment
      }
    }
  }
  ${CardFragmentFragmentDoc}
`;

/**
 * __useBoardDiscussStageUpdatesSubscription__
 *
 * To run a query within a React component, call `useBoardDiscussStageUpdatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBoardDiscussStageUpdatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardDiscussStageUpdatesSubscription({
 *   variables: {
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useBoardDiscussStageUpdatesSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    BoardDiscussStageUpdatesSubscription,
    BoardDiscussStageUpdatesSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    BoardDiscussStageUpdatesSubscription,
    BoardDiscussStageUpdatesSubscriptionVariables
  >(BoardDiscussStageUpdatesDocument, options);
}
export type BoardDiscussStageUpdatesSubscriptionHookResult = ReturnType<
  typeof useBoardDiscussStageUpdatesSubscription
>;
export type BoardDiscussStageUpdatesSubscriptionResult =
  Apollo.SubscriptionResult<BoardDiscussStageUpdatesSubscription>;
export const BoardMessageBroadcastDocument = gql`
  subscription BoardMessageBroadcast($boardId: ID!) {
    boardMessageBroadcastSubscription(boardId: $boardId) {
      title
      message
      timeOut
      newFacilitatorUserId
    }
  }
`;

/**
 * __useBoardMessageBroadcastSubscription__
 *
 * To run a query within a React component, call `useBoardMessageBroadcastSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBoardMessageBroadcastSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardMessageBroadcastSubscription({
 *   variables: {
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useBoardMessageBroadcastSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    BoardMessageBroadcastSubscription,
    BoardMessageBroadcastSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    BoardMessageBroadcastSubscription,
    BoardMessageBroadcastSubscriptionVariables
  >(BoardMessageBroadcastDocument, options);
}
export type BoardMessageBroadcastSubscriptionHookResult = ReturnType<
  typeof useBoardMessageBroadcastSubscription
>;
export type BoardMessageBroadcastSubscriptionResult =
  Apollo.SubscriptionResult<BoardMessageBroadcastSubscription>;
export const BoardUpdatesDocument = gql`
  subscription BoardUpdates($id: ID) {
    boardUpdate(id: $id) {
      ...BoardFragment
    }
  }
  ${BoardFragmentFragmentDoc}
`;

/**
 * __useBoardUpdatesSubscription__
 *
 * To run a query within a React component, call `useBoardUpdatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBoardUpdatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardUpdatesSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBoardUpdatesSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    BoardUpdatesSubscription,
    BoardUpdatesSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    BoardUpdatesSubscription,
    BoardUpdatesSubscriptionVariables
  >(BoardUpdatesDocument, options);
}
export type BoardUpdatesSubscriptionHookResult = ReturnType<
  typeof useBoardUpdatesSubscription
>;
export type BoardUpdatesSubscriptionResult =
  Apollo.SubscriptionResult<BoardUpdatesSubscription>;
export const CardVoteUpdatesByBoardIdDocument = gql`
  subscription CardVoteUpdatesByBoardId($boardId: ID!) {
    cardVoteUpdatesByBoardId(boardId: $boardId) {
      id
      votes
    }
  }
`;

/**
 * __useCardVoteUpdatesByBoardIdSubscription__
 *
 * To run a query within a React component, call `useCardVoteUpdatesByBoardIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCardVoteUpdatesByBoardIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardVoteUpdatesByBoardIdSubscription({
 *   variables: {
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useCardVoteUpdatesByBoardIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    CardVoteUpdatesByBoardIdSubscription,
    CardVoteUpdatesByBoardIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CardVoteUpdatesByBoardIdSubscription,
    CardVoteUpdatesByBoardIdSubscriptionVariables
  >(CardVoteUpdatesByBoardIdDocument, options);
}
export type CardVoteUpdatesByBoardIdSubscriptionHookResult = ReturnType<
  typeof useCardVoteUpdatesByBoardIdSubscription
>;
export type CardVoteUpdatesByBoardIdSubscriptionResult =
  Apollo.SubscriptionResult<CardVoteUpdatesByBoardIdSubscription>;
export const NewCardsByBoardIdDocument = gql`
  subscription NewCardsByBoardId($boardId: String!) {
    cardUpdate(boardId: $boardId) {
      ...CardFragment
    }
  }
  ${CardFragmentFragmentDoc}
`;

/**
 * __useNewCardsByBoardIdSubscription__
 *
 * To run a query within a React component, call `useNewCardsByBoardIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewCardsByBoardIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewCardsByBoardIdSubscription({
 *   variables: {
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useNewCardsByBoardIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    NewCardsByBoardIdSubscription,
    NewCardsByBoardIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NewCardsByBoardIdSubscription,
    NewCardsByBoardIdSubscriptionVariables
  >(NewCardsByBoardIdDocument, options);
}
export type NewCardsByBoardIdSubscriptionHookResult = ReturnType<
  typeof useNewCardsByBoardIdSubscription
>;
export type NewCardsByBoardIdSubscriptionResult =
  Apollo.SubscriptionResult<NewCardsByBoardIdSubscription>;
export const ParticipationChangesDocument = gql`
  subscription ParticipationChanges($input: ParticipationChangedInput) {
    participationChanged(input: $input) {
      participationChanges {
        ...BoardParticipationFragment
      }
    }
  }
  ${BoardParticipationFragmentFragmentDoc}
`;

/**
 * __useParticipationChangesSubscription__
 *
 * To run a query within a React component, call `useParticipationChangesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useParticipationChangesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipationChangesSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useParticipationChangesSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    ParticipationChangesSubscription,
    ParticipationChangesSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ParticipationChangesSubscription,
    ParticipationChangesSubscriptionVariables
  >(ParticipationChangesDocument, options);
}
export type ParticipationChangesSubscriptionHookResult = ReturnType<
  typeof useParticipationChangesSubscription
>;
export type ParticipationChangesSubscriptionResult =
  Apollo.SubscriptionResult<ParticipationChangesSubscription>;
export const ArchiveBoardAsyncScheduleDocument = gql`
  mutation ArchiveBoardAsyncSchedule($id: ID!) {
    archiveBoardAsyncSchedule(id: $id) {
      ...BoardAsyncScheduleFragment
    }
  }
  ${BoardAsyncScheduleFragmentFragmentDoc}
`;
export type ArchiveBoardAsyncScheduleMutationFn = Apollo.MutationFunction<
  ArchiveBoardAsyncScheduleMutation,
  ArchiveBoardAsyncScheduleMutationVariables
>;

/**
 * __useArchiveBoardAsyncScheduleMutation__
 *
 * To run a mutation, you first call `useArchiveBoardAsyncScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveBoardAsyncScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveBoardAsyncScheduleMutation, { data, loading, error }] = useArchiveBoardAsyncScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveBoardAsyncScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveBoardAsyncScheduleMutation,
    ArchiveBoardAsyncScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveBoardAsyncScheduleMutation,
    ArchiveBoardAsyncScheduleMutationVariables
  >(ArchiveBoardAsyncScheduleDocument, options);
}
export type ArchiveBoardAsyncScheduleMutationHookResult = ReturnType<
  typeof useArchiveBoardAsyncScheduleMutation
>;
export type ArchiveBoardAsyncScheduleMutationResult =
  Apollo.MutationResult<ArchiveBoardAsyncScheduleMutation>;
export type ArchiveBoardAsyncScheduleMutationOptions =
  Apollo.BaseMutationOptions<
    ArchiveBoardAsyncScheduleMutation,
    ArchiveBoardAsyncScheduleMutationVariables
  >;
export const CreateBoardAsyncScheduleDocument = gql`
  mutation CreateBoardAsyncSchedule(
    $data: BoardAsyncScheduleInput!
    $customQuestions: [BoardAsyncScheduleCustomQuestionInput!]
  ) {
    createBoardAsyncSchedule(data: $data, customQuestions: $customQuestions) {
      ...BoardAsyncScheduleFragment
    }
  }
  ${BoardAsyncScheduleFragmentFragmentDoc}
`;
export type CreateBoardAsyncScheduleMutationFn = Apollo.MutationFunction<
  CreateBoardAsyncScheduleMutation,
  CreateBoardAsyncScheduleMutationVariables
>;

/**
 * __useCreateBoardAsyncScheduleMutation__
 *
 * To run a mutation, you first call `useCreateBoardAsyncScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBoardAsyncScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBoardAsyncScheduleMutation, { data, loading, error }] = useCreateBoardAsyncScheduleMutation({
 *   variables: {
 *      data: // value for 'data'
 *      customQuestions: // value for 'customQuestions'
 *   },
 * });
 */
export function useCreateBoardAsyncScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBoardAsyncScheduleMutation,
    CreateBoardAsyncScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateBoardAsyncScheduleMutation,
    CreateBoardAsyncScheduleMutationVariables
  >(CreateBoardAsyncScheduleDocument, options);
}
export type CreateBoardAsyncScheduleMutationHookResult = ReturnType<
  typeof useCreateBoardAsyncScheduleMutation
>;
export type CreateBoardAsyncScheduleMutationResult =
  Apollo.MutationResult<CreateBoardAsyncScheduleMutation>;
export type CreateBoardAsyncScheduleMutationOptions =
  Apollo.BaseMutationOptions<
    CreateBoardAsyncScheduleMutation,
    CreateBoardAsyncScheduleMutationVariables
  >;
export const TriggerBoardAsyncScheduleByIdDocument = gql`
  mutation triggerBoardAsyncScheduleById($id: ID!) {
    triggerBoardAsyncScheduleById(id: $id)
  }
`;
export type TriggerBoardAsyncScheduleByIdMutationFn = Apollo.MutationFunction<
  TriggerBoardAsyncScheduleByIdMutation,
  TriggerBoardAsyncScheduleByIdMutationVariables
>;

/**
 * __useTriggerBoardAsyncScheduleByIdMutation__
 *
 * To run a mutation, you first call `useTriggerBoardAsyncScheduleByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerBoardAsyncScheduleByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerBoardAsyncScheduleByIdMutation, { data, loading, error }] = useTriggerBoardAsyncScheduleByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTriggerBoardAsyncScheduleByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TriggerBoardAsyncScheduleByIdMutation,
    TriggerBoardAsyncScheduleByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TriggerBoardAsyncScheduleByIdMutation,
    TriggerBoardAsyncScheduleByIdMutationVariables
  >(TriggerBoardAsyncScheduleByIdDocument, options);
}
export type TriggerBoardAsyncScheduleByIdMutationHookResult = ReturnType<
  typeof useTriggerBoardAsyncScheduleByIdMutation
>;
export type TriggerBoardAsyncScheduleByIdMutationResult =
  Apollo.MutationResult<TriggerBoardAsyncScheduleByIdMutation>;
export type TriggerBoardAsyncScheduleByIdMutationOptions =
  Apollo.BaseMutationOptions<
    TriggerBoardAsyncScheduleByIdMutation,
    TriggerBoardAsyncScheduleByIdMutationVariables
  >;
export const UpdateBoardAsyncScheduleDocument = gql`
  mutation UpdateBoardAsyncSchedule(
    $id: ID!
    $data: BoardAsyncScheduleInput!
    $customQuestions: [BoardAsyncScheduleCustomQuestionInput!]
  ) {
    updateBoardAsyncSchedule(
      id: $id
      data: $data
      customQuestions: $customQuestions
    ) {
      ...BoardAsyncScheduleFragment
    }
  }
  ${BoardAsyncScheduleFragmentFragmentDoc}
`;
export type UpdateBoardAsyncScheduleMutationFn = Apollo.MutationFunction<
  UpdateBoardAsyncScheduleMutation,
  UpdateBoardAsyncScheduleMutationVariables
>;

/**
 * __useUpdateBoardAsyncScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateBoardAsyncScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBoardAsyncScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBoardAsyncScheduleMutation, { data, loading, error }] = useUpdateBoardAsyncScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *      customQuestions: // value for 'customQuestions'
 *   },
 * });
 */
export function useUpdateBoardAsyncScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBoardAsyncScheduleMutation,
    UpdateBoardAsyncScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBoardAsyncScheduleMutation,
    UpdateBoardAsyncScheduleMutationVariables
  >(UpdateBoardAsyncScheduleDocument, options);
}
export type UpdateBoardAsyncScheduleMutationHookResult = ReturnType<
  typeof useUpdateBoardAsyncScheduleMutation
>;
export type UpdateBoardAsyncScheduleMutationResult =
  Apollo.MutationResult<UpdateBoardAsyncScheduleMutation>;
export type UpdateBoardAsyncScheduleMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateBoardAsyncScheduleMutation,
    UpdateBoardAsyncScheduleMutationVariables
  >;
export const BoardAsyncSchedulesByTeamIdDocument = gql`
  query BoardAsyncSchedulesByTeamId($teamId: String!) {
    boardAsyncSchedulesByTeamId(teamId: $teamId) {
      ...BoardAsyncScheduleFragment
    }
  }
  ${BoardAsyncScheduleFragmentFragmentDoc}
`;

/**
 * __useBoardAsyncSchedulesByTeamIdQuery__
 *
 * To run a query within a React component, call `useBoardAsyncSchedulesByTeamIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoardAsyncSchedulesByTeamIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardAsyncSchedulesByTeamIdQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useBoardAsyncSchedulesByTeamIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    BoardAsyncSchedulesByTeamIdQuery,
    BoardAsyncSchedulesByTeamIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BoardAsyncSchedulesByTeamIdQuery,
    BoardAsyncSchedulesByTeamIdQueryVariables
  >(BoardAsyncSchedulesByTeamIdDocument, options);
}
export function useBoardAsyncSchedulesByTeamIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BoardAsyncSchedulesByTeamIdQuery,
    BoardAsyncSchedulesByTeamIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BoardAsyncSchedulesByTeamIdQuery,
    BoardAsyncSchedulesByTeamIdQueryVariables
  >(BoardAsyncSchedulesByTeamIdDocument, options);
}
export type BoardAsyncSchedulesByTeamIdQueryHookResult = ReturnType<
  typeof useBoardAsyncSchedulesByTeamIdQuery
>;
export type BoardAsyncSchedulesByTeamIdLazyQueryHookResult = ReturnType<
  typeof useBoardAsyncSchedulesByTeamIdLazyQuery
>;
export type BoardAsyncSchedulesByTeamIdQueryResult = Apollo.QueryResult<
  BoardAsyncSchedulesByTeamIdQuery,
  BoardAsyncSchedulesByTeamIdQueryVariables
>;
export const ImpactReportDocument = gql`
  query ImpactReport($filter: ImpactReportFilter!) {
    impactReport(filter: $filter) {
      badges {
        title
        icon
        value
        detail
      }
      cards {
        title
        description
        summary
        monetaryImpact
        badges {
          title
          icon
          value
          detail
        }
        items {
          id
          type
          title
          severity
          teamCount
          monetaryImpact
          occurrenceCount
          hoursAssociated
          summary
          topics
        }
      }
      insight
      engagementScoreOverTime {
        legend
        data {
          date
          value
        }
      }
      sentimentOverTime {
        legend
        data {
          date
          value
          positiveCount
          negativeCount
          neutralCount
        }
      }
    }
  }
`;

/**
 * __useImpactReportQuery__
 *
 * To run a query within a React component, call `useImpactReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useImpactReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImpactReportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useImpactReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    ImpactReportQuery,
    ImpactReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ImpactReportQuery, ImpactReportQueryVariables>(
    ImpactReportDocument,
    options
  );
}
export function useImpactReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImpactReportQuery,
    ImpactReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ImpactReportQuery, ImpactReportQueryVariables>(
    ImpactReportDocument,
    options
  );
}
export type ImpactReportQueryHookResult = ReturnType<
  typeof useImpactReportQuery
>;
export type ImpactReportLazyQueryHookResult = ReturnType<
  typeof useImpactReportLazyQuery
>;
export type ImpactReportQueryResult = Apollo.QueryResult<
  ImpactReportQuery,
  ImpactReportQueryVariables
>;
export const ImpactReportBoardSummaryDocument = gql`
  query ImpactReportBoardSummary($boardId: ID!) {
    impactReportBoardSummary(boardId: $boardId) {
      positiveCount
      neutralCount
      negativeCount
      summary
      engagementScore
      sentiment
      issuesInFeedback {
        id
        type
        title
        severity
        teamCount
        hoursAssociated
        occurrenceCount
        monetaryImpact
        summary
        topics
      }
    }
  }
`;

/**
 * __useImpactReportBoardSummaryQuery__
 *
 * To run a query within a React component, call `useImpactReportBoardSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useImpactReportBoardSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImpactReportBoardSummaryQuery({
 *   variables: {
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useImpactReportBoardSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ImpactReportBoardSummaryQuery,
    ImpactReportBoardSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ImpactReportBoardSummaryQuery,
    ImpactReportBoardSummaryQueryVariables
  >(ImpactReportBoardSummaryDocument, options);
}
export function useImpactReportBoardSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImpactReportBoardSummaryQuery,
    ImpactReportBoardSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ImpactReportBoardSummaryQuery,
    ImpactReportBoardSummaryQueryVariables
  >(ImpactReportBoardSummaryDocument, options);
}
export type ImpactReportBoardSummaryQueryHookResult = ReturnType<
  typeof useImpactReportBoardSummaryQuery
>;
export type ImpactReportBoardSummaryLazyQueryHookResult = ReturnType<
  typeof useImpactReportBoardSummaryLazyQuery
>;
export type ImpactReportBoardSummaryQueryResult = Apollo.QueryResult<
  ImpactReportBoardSummaryQuery,
  ImpactReportBoardSummaryQueryVariables
>;
export const ImpactReportIssueDrilldownDocument = gql`
  query ImpactReportIssueDrilldown($teamId: ID!, $issueId: ID!) {
    impactReportIssueDrilldown(teamId: $teamId, issueId: $issueId) {
      id
      color
      type
      title
      summary
      keywords
      estimatedCost
      mentionCount
      negativeMentionCount
      actionItemsCountRelated
      actionItemsCountResolved
      recurrenceSummary
      impactedTeamsSeverity
      nature
      impactedTeams {
        id
        name
        occurrenceCount
      }
      recurrenceData {
        date
        cardCount
        teamCount
      }
      possibleCauses {
        ... on ImpactReportIssueCauseTopic {
          __typename
          summary
          cards {
            text
            badgeColor
            badgeText
            retroName
            retroDate
          }
        }
        ... on ImpactReportIssueCauseInsight {
          __typename
          origin
          summary
          teamCount
        }
      }
    }
  }
`;

/**
 * __useImpactReportIssueDrilldownQuery__
 *
 * To run a query within a React component, call `useImpactReportIssueDrilldownQuery` and pass it any options that fit your needs.
 * When your component renders, `useImpactReportIssueDrilldownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImpactReportIssueDrilldownQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      issueId: // value for 'issueId'
 *   },
 * });
 */
export function useImpactReportIssueDrilldownQuery(
  baseOptions: Apollo.QueryHookOptions<
    ImpactReportIssueDrilldownQuery,
    ImpactReportIssueDrilldownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ImpactReportIssueDrilldownQuery,
    ImpactReportIssueDrilldownQueryVariables
  >(ImpactReportIssueDrilldownDocument, options);
}
export function useImpactReportIssueDrilldownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImpactReportIssueDrilldownQuery,
    ImpactReportIssueDrilldownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ImpactReportIssueDrilldownQuery,
    ImpactReportIssueDrilldownQueryVariables
  >(ImpactReportIssueDrilldownDocument, options);
}
export type ImpactReportIssueDrilldownQueryHookResult = ReturnType<
  typeof useImpactReportIssueDrilldownQuery
>;
export type ImpactReportIssueDrilldownLazyQueryHookResult = ReturnType<
  typeof useImpactReportIssueDrilldownLazyQuery
>;
export type ImpactReportIssueDrilldownQueryResult = Apollo.QueryResult<
  ImpactReportIssueDrilldownQuery,
  ImpactReportIssueDrilldownQueryVariables
>;
export const AddGoalDataDocument = gql`
  mutation AddGoalData(
    $goalId: ID!
    $reportingTeamId: ID!
    $value: Float!
    $date: Date!
  ) {
    addImprovementGoalData(
      improvementGoalId: $goalId
      reportingTeamId: $reportingTeamId
      value: $value
      date: $date
    ) {
      ...ImprovementGoalDataFragment
    }
  }
  ${ImprovementGoalDataFragmentFragmentDoc}
`;
export type AddGoalDataMutationFn = Apollo.MutationFunction<
  AddGoalDataMutation,
  AddGoalDataMutationVariables
>;

/**
 * __useAddGoalDataMutation__
 *
 * To run a mutation, you first call `useAddGoalDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGoalDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGoalDataMutation, { data, loading, error }] = useAddGoalDataMutation({
 *   variables: {
 *      goalId: // value for 'goalId'
 *      reportingTeamId: // value for 'reportingTeamId'
 *      value: // value for 'value'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useAddGoalDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGoalDataMutation,
    AddGoalDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddGoalDataMutation, AddGoalDataMutationVariables>(
    AddGoalDataDocument,
    options
  );
}
export type AddGoalDataMutationHookResult = ReturnType<
  typeof useAddGoalDataMutation
>;
export type AddGoalDataMutationResult =
  Apollo.MutationResult<AddGoalDataMutation>;
export type AddGoalDataMutationOptions = Apollo.BaseMutationOptions<
  AddGoalDataMutation,
  AddGoalDataMutationVariables
>;
export const ArchiveGoalDataDocument = gql`
  mutation ArchiveGoalData($goalDataId: ID!) {
    archiveImprovementGoalData(improvementGoalDataId: $goalDataId) {
      ...ImprovementGoalDataFragment
    }
  }
  ${ImprovementGoalDataFragmentFragmentDoc}
`;
export type ArchiveGoalDataMutationFn = Apollo.MutationFunction<
  ArchiveGoalDataMutation,
  ArchiveGoalDataMutationVariables
>;

/**
 * __useArchiveGoalDataMutation__
 *
 * To run a mutation, you first call `useArchiveGoalDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveGoalDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveGoalDataMutation, { data, loading, error }] = useArchiveGoalDataMutation({
 *   variables: {
 *      goalDataId: // value for 'goalDataId'
 *   },
 * });
 */
export function useArchiveGoalDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveGoalDataMutation,
    ArchiveGoalDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveGoalDataMutation,
    ArchiveGoalDataMutationVariables
  >(ArchiveGoalDataDocument, options);
}
export type ArchiveGoalDataMutationHookResult = ReturnType<
  typeof useArchiveGoalDataMutation
>;
export type ArchiveGoalDataMutationResult =
  Apollo.MutationResult<ArchiveGoalDataMutation>;
export type ArchiveGoalDataMutationOptions = Apollo.BaseMutationOptions<
  ArchiveGoalDataMutation,
  ArchiveGoalDataMutationVariables
>;
export const CreateImprovementGoalDocument = gql`
  mutation CreateImprovementGoal($input: ImprovementGoalInput!) {
    createImprovementGoal(input: $input) {
      ...ImprovementGoalFragment
    }
  }
  ${ImprovementGoalFragmentFragmentDoc}
`;
export type CreateImprovementGoalMutationFn = Apollo.MutationFunction<
  CreateImprovementGoalMutation,
  CreateImprovementGoalMutationVariables
>;

/**
 * __useCreateImprovementGoalMutation__
 *
 * To run a mutation, you first call `useCreateImprovementGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateImprovementGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createImprovementGoalMutation, { data, loading, error }] = useCreateImprovementGoalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateImprovementGoalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateImprovementGoalMutation,
    CreateImprovementGoalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateImprovementGoalMutation,
    CreateImprovementGoalMutationVariables
  >(CreateImprovementGoalDocument, options);
}
export type CreateImprovementGoalMutationHookResult = ReturnType<
  typeof useCreateImprovementGoalMutation
>;
export type CreateImprovementGoalMutationResult =
  Apollo.MutationResult<CreateImprovementGoalMutation>;
export type CreateImprovementGoalMutationOptions = Apollo.BaseMutationOptions<
  CreateImprovementGoalMutation,
  CreateImprovementGoalMutationVariables
>;
export const DeleteImprovementGoalDocument = gql`
  mutation DeleteImprovementGoal($id: ID!) {
    removeImprovementGoal(id: $id) {
      ...ImprovementGoalFragment
    }
  }
  ${ImprovementGoalFragmentFragmentDoc}
`;
export type DeleteImprovementGoalMutationFn = Apollo.MutationFunction<
  DeleteImprovementGoalMutation,
  DeleteImprovementGoalMutationVariables
>;

/**
 * __useDeleteImprovementGoalMutation__
 *
 * To run a mutation, you first call `useDeleteImprovementGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteImprovementGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteImprovementGoalMutation, { data, loading, error }] = useDeleteImprovementGoalMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteImprovementGoalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteImprovementGoalMutation,
    DeleteImprovementGoalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteImprovementGoalMutation,
    DeleteImprovementGoalMutationVariables
  >(DeleteImprovementGoalDocument, options);
}
export type DeleteImprovementGoalMutationHookResult = ReturnType<
  typeof useDeleteImprovementGoalMutation
>;
export type DeleteImprovementGoalMutationResult =
  Apollo.MutationResult<DeleteImprovementGoalMutation>;
export type DeleteImprovementGoalMutationOptions = Apollo.BaseMutationOptions<
  DeleteImprovementGoalMutation,
  DeleteImprovementGoalMutationVariables
>;
export const ImportImprovementGoalDataDocument = gql`
  mutation ImportImprovementGoalData(
    $improvementGoalId: ID!
    $data: [ImprovementGoalDataImportInput!]!
  ) {
    importImprovementGoalData(
      improvementGoalId: $improvementGoalId
      data: $data
    ) {
      id
    }
  }
`;
export type ImportImprovementGoalDataMutationFn = Apollo.MutationFunction<
  ImportImprovementGoalDataMutation,
  ImportImprovementGoalDataMutationVariables
>;

/**
 * __useImportImprovementGoalDataMutation__
 *
 * To run a mutation, you first call `useImportImprovementGoalDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportImprovementGoalDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importImprovementGoalDataMutation, { data, loading, error }] = useImportImprovementGoalDataMutation({
 *   variables: {
 *      improvementGoalId: // value for 'improvementGoalId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImportImprovementGoalDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportImprovementGoalDataMutation,
    ImportImprovementGoalDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportImprovementGoalDataMutation,
    ImportImprovementGoalDataMutationVariables
  >(ImportImprovementGoalDataDocument, options);
}
export type ImportImprovementGoalDataMutationHookResult = ReturnType<
  typeof useImportImprovementGoalDataMutation
>;
export type ImportImprovementGoalDataMutationResult =
  Apollo.MutationResult<ImportImprovementGoalDataMutation>;
export type ImportImprovementGoalDataMutationOptions =
  Apollo.BaseMutationOptions<
    ImportImprovementGoalDataMutation,
    ImportImprovementGoalDataMutationVariables
  >;
export const ReplaceGoalDataDocument = gql`
  mutation ReplaceGoalData($goalDataId: ID!, $newValue: Float!) {
    replaceImprovementGoalData(
      improvementGoalDataId: $goalDataId
      newValue: $newValue
    ) {
      ...ImprovementGoalDataFragment
    }
  }
  ${ImprovementGoalDataFragmentFragmentDoc}
`;
export type ReplaceGoalDataMutationFn = Apollo.MutationFunction<
  ReplaceGoalDataMutation,
  ReplaceGoalDataMutationVariables
>;

/**
 * __useReplaceGoalDataMutation__
 *
 * To run a mutation, you first call `useReplaceGoalDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceGoalDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceGoalDataMutation, { data, loading, error }] = useReplaceGoalDataMutation({
 *   variables: {
 *      goalDataId: // value for 'goalDataId'
 *      newValue: // value for 'newValue'
 *   },
 * });
 */
export function useReplaceGoalDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReplaceGoalDataMutation,
    ReplaceGoalDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReplaceGoalDataMutation,
    ReplaceGoalDataMutationVariables
  >(ReplaceGoalDataDocument, options);
}
export type ReplaceGoalDataMutationHookResult = ReturnType<
  typeof useReplaceGoalDataMutation
>;
export type ReplaceGoalDataMutationResult =
  Apollo.MutationResult<ReplaceGoalDataMutation>;
export type ReplaceGoalDataMutationOptions = Apollo.BaseMutationOptions<
  ReplaceGoalDataMutation,
  ReplaceGoalDataMutationVariables
>;
export const SetGoalRemindersDocument = gql`
  mutation SetGoalReminders(
    $improvementGoalId: ID!
    $userIds: [ID!]!
    $notificationTime: Date!
    $frequency: ImprovementGoalUserReminderFrequency!
  ) {
    setImprovementGoalUserReminders(
      improvementGoalId: $improvementGoalId
      userIds: $userIds
      notificationTime: $notificationTime
      frequency: $frequency
    ) {
      ...ImprovementGoalFragment
    }
  }
  ${ImprovementGoalFragmentFragmentDoc}
`;
export type SetGoalRemindersMutationFn = Apollo.MutationFunction<
  SetGoalRemindersMutation,
  SetGoalRemindersMutationVariables
>;

/**
 * __useSetGoalRemindersMutation__
 *
 * To run a mutation, you first call `useSetGoalRemindersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGoalRemindersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGoalRemindersMutation, { data, loading, error }] = useSetGoalRemindersMutation({
 *   variables: {
 *      improvementGoalId: // value for 'improvementGoalId'
 *      userIds: // value for 'userIds'
 *      notificationTime: // value for 'notificationTime'
 *      frequency: // value for 'frequency'
 *   },
 * });
 */
export function useSetGoalRemindersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetGoalRemindersMutation,
    SetGoalRemindersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetGoalRemindersMutation,
    SetGoalRemindersMutationVariables
  >(SetGoalRemindersDocument, options);
}
export type SetGoalRemindersMutationHookResult = ReturnType<
  typeof useSetGoalRemindersMutation
>;
export type SetGoalRemindersMutationResult =
  Apollo.MutationResult<SetGoalRemindersMutation>;
export type SetGoalRemindersMutationOptions = Apollo.BaseMutationOptions<
  SetGoalRemindersMutation,
  SetGoalRemindersMutationVariables
>;
export const UpdateImprovementGoalDocument = gql`
  mutation UpdateImprovementGoal($id: ID!, $input: ImprovementGoalInput!) {
    updateImprovementGoal(id: $id, input: $input) {
      ...ImprovementGoalFragment
    }
  }
  ${ImprovementGoalFragmentFragmentDoc}
`;
export type UpdateImprovementGoalMutationFn = Apollo.MutationFunction<
  UpdateImprovementGoalMutation,
  UpdateImprovementGoalMutationVariables
>;

/**
 * __useUpdateImprovementGoalMutation__
 *
 * To run a mutation, you first call `useUpdateImprovementGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateImprovementGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateImprovementGoalMutation, { data, loading, error }] = useUpdateImprovementGoalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateImprovementGoalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateImprovementGoalMutation,
    UpdateImprovementGoalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateImprovementGoalMutation,
    UpdateImprovementGoalMutationVariables
  >(UpdateImprovementGoalDocument, options);
}
export type UpdateImprovementGoalMutationHookResult = ReturnType<
  typeof useUpdateImprovementGoalMutation
>;
export type UpdateImprovementGoalMutationResult =
  Apollo.MutationResult<UpdateImprovementGoalMutation>;
export type UpdateImprovementGoalMutationOptions = Apollo.BaseMutationOptions<
  UpdateImprovementGoalMutation,
  UpdateImprovementGoalMutationVariables
>;
export const ImprovementGoalByIdDocument = gql`
  query ImprovementGoalById(
    $id: ID!
    $takeLastNPeriodsWithData: Int! = 0
    $utcOffsetMs: Int = 0
    $reportingDataTeamIds: [ImprovementGoalTeam!]!
    $fromDate: Date
    $toDate: Date
  ) {
    findImprovementGoalById(id: $id) {
      ...ImprovementGoalFragment
      team {
        ...MinimalTeamFragment
      }
      data(
        takeLastNPeriodsWithData: $takeLastNPeriodsWithData
        utcOffsetMs: $utcOffsetMs
        reportingDataTeamIds: $reportingDataTeamIds
        fromDate: $fromDate
        toDate: $toDate
      ) {
        ...ImprovementGoalDataFragment
      }
    }
  }
  ${ImprovementGoalFragmentFragmentDoc}
  ${MinimalTeamFragmentFragmentDoc}
  ${ImprovementGoalDataFragmentFragmentDoc}
`;

/**
 * __useImprovementGoalByIdQuery__
 *
 * To run a query within a React component, call `useImprovementGoalByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useImprovementGoalByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImprovementGoalByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      takeLastNPeriodsWithData: // value for 'takeLastNPeriodsWithData'
 *      utcOffsetMs: // value for 'utcOffsetMs'
 *      reportingDataTeamIds: // value for 'reportingDataTeamIds'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useImprovementGoalByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ImprovementGoalByIdQuery,
    ImprovementGoalByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ImprovementGoalByIdQuery,
    ImprovementGoalByIdQueryVariables
  >(ImprovementGoalByIdDocument, options);
}
export function useImprovementGoalByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImprovementGoalByIdQuery,
    ImprovementGoalByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ImprovementGoalByIdQuery,
    ImprovementGoalByIdQueryVariables
  >(ImprovementGoalByIdDocument, options);
}
export type ImprovementGoalByIdQueryHookResult = ReturnType<
  typeof useImprovementGoalByIdQuery
>;
export type ImprovementGoalByIdLazyQueryHookResult = ReturnType<
  typeof useImprovementGoalByIdLazyQuery
>;
export type ImprovementGoalByIdQueryResult = Apollo.QueryResult<
  ImprovementGoalByIdQuery,
  ImprovementGoalByIdQueryVariables
>;
export const ImprovementGoalSummaryDocument = gql`
  query ImprovementGoalSummary($teamId: ID!, $utcOffsetMs: Int = 0) {
    teamById(teamId: $teamId) {
      id
      improvementGoals {
        totalSize
        insight {
          ... on ImprovementGoalInsightFocus {
            percentage
            goalName
          }
        }
        improvementGoals {
          ...ImprovementGoalFragment
          data(takeLastNPeriodsWithData: 5, utcOffsetMs: $utcOffsetMs) {
            ...ImprovementGoalDataFragment
          }
        }
      }
    }
  }
  ${ImprovementGoalFragmentFragmentDoc}
  ${ImprovementGoalDataFragmentFragmentDoc}
`;

/**
 * __useImprovementGoalSummaryQuery__
 *
 * To run a query within a React component, call `useImprovementGoalSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useImprovementGoalSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImprovementGoalSummaryQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      utcOffsetMs: // value for 'utcOffsetMs'
 *   },
 * });
 */
export function useImprovementGoalSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ImprovementGoalSummaryQuery,
    ImprovementGoalSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ImprovementGoalSummaryQuery,
    ImprovementGoalSummaryQueryVariables
  >(ImprovementGoalSummaryDocument, options);
}
export function useImprovementGoalSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImprovementGoalSummaryQuery,
    ImprovementGoalSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ImprovementGoalSummaryQuery,
    ImprovementGoalSummaryQueryVariables
  >(ImprovementGoalSummaryDocument, options);
}
export type ImprovementGoalSummaryQueryHookResult = ReturnType<
  typeof useImprovementGoalSummaryQuery
>;
export type ImprovementGoalSummaryLazyQueryHookResult = ReturnType<
  typeof useImprovementGoalSummaryLazyQuery
>;
export type ImprovementGoalSummaryQueryResult = Apollo.QueryResult<
  ImprovementGoalSummaryQuery,
  ImprovementGoalSummaryQueryVariables
>;
export const ImprovementGoalTypesDocument = gql`
  query ImprovementGoalTypes {
    improvementGoalTypes {
      ...ImprovementGoalTypeFragment
    }
  }
  ${ImprovementGoalTypeFragmentFragmentDoc}
`;

/**
 * __useImprovementGoalTypesQuery__
 *
 * To run a query within a React component, call `useImprovementGoalTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useImprovementGoalTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImprovementGoalTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useImprovementGoalTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ImprovementGoalTypesQuery,
    ImprovementGoalTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ImprovementGoalTypesQuery,
    ImprovementGoalTypesQueryVariables
  >(ImprovementGoalTypesDocument, options);
}
export function useImprovementGoalTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImprovementGoalTypesQuery,
    ImprovementGoalTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ImprovementGoalTypesQuery,
    ImprovementGoalTypesQueryVariables
  >(ImprovementGoalTypesDocument, options);
}
export type ImprovementGoalTypesQueryHookResult = ReturnType<
  typeof useImprovementGoalTypesQuery
>;
export type ImprovementGoalTypesLazyQueryHookResult = ReturnType<
  typeof useImprovementGoalTypesLazyQuery
>;
export type ImprovementGoalTypesQueryResult = Apollo.QueryResult<
  ImprovementGoalTypesQuery,
  ImprovementGoalTypesQueryVariables
>;
export const ImprovementGoalsByTeamIdDocument = gql`
  query ImprovementGoalsByTeamId(
    $teamId: ID!
    $limit: Int! = 20
    $offset: Int! = 0
    $takeLastNPeriodsWithData: Int! = 0
    $utcOffsetMs: Int = 0
    $sort: JSON
  ) {
    teamById(teamId: $teamId) {
      id
      improvementGoals(limit: $limit, offset: $offset, sort: $sort) {
        totalSize
        improvementGoals {
          ...ImprovementGoalFragment
          data(
            takeLastNPeriodsWithData: $takeLastNPeriodsWithData
            utcOffsetMs: $utcOffsetMs
          ) {
            ...ImprovementGoalDataFragment
          }
        }
      }
    }
  }
  ${ImprovementGoalFragmentFragmentDoc}
  ${ImprovementGoalDataFragmentFragmentDoc}
`;

/**
 * __useImprovementGoalsByTeamIdQuery__
 *
 * To run a query within a React component, call `useImprovementGoalsByTeamIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useImprovementGoalsByTeamIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImprovementGoalsByTeamIdQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      takeLastNPeriodsWithData: // value for 'takeLastNPeriodsWithData'
 *      utcOffsetMs: // value for 'utcOffsetMs'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useImprovementGoalsByTeamIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ImprovementGoalsByTeamIdQuery,
    ImprovementGoalsByTeamIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ImprovementGoalsByTeamIdQuery,
    ImprovementGoalsByTeamIdQueryVariables
  >(ImprovementGoalsByTeamIdDocument, options);
}
export function useImprovementGoalsByTeamIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImprovementGoalsByTeamIdQuery,
    ImprovementGoalsByTeamIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ImprovementGoalsByTeamIdQuery,
    ImprovementGoalsByTeamIdQueryVariables
  >(ImprovementGoalsByTeamIdDocument, options);
}
export type ImprovementGoalsByTeamIdQueryHookResult = ReturnType<
  typeof useImprovementGoalsByTeamIdQuery
>;
export type ImprovementGoalsByTeamIdLazyQueryHookResult = ReturnType<
  typeof useImprovementGoalsByTeamIdLazyQuery
>;
export type ImprovementGoalsByTeamIdQueryResult = Apollo.QueryResult<
  ImprovementGoalsByTeamIdQuery,
  ImprovementGoalsByTeamIdQueryVariables
>;
export const ImprovementGoalsByTeamIdsDocument = gql`
  query ImprovementGoalsByTeamIds(
    $reportingDataTeamIds: [ImprovementGoalTeam!]!
    $improvementGoalTeamIds: [ID!]!
    $limit: Int! = 20
    $offset: Int! = 0
    $takeLastNPeriodsWithData: Int! = 0
    $utcOffsetMs: Int = 0
    $fromDate: Date
    $toDate: Date
    $sort: JSON
  ) {
    improvementGoalsByTeamIds(
      improvementGoalTeamIds: $improvementGoalTeamIds
      limit: $limit
      offset: $offset
      sort: $sort
    ) {
      totalSize
      improvementGoals {
        ...ImprovementGoalFragment
        data(
          takeLastNPeriodsWithData: $takeLastNPeriodsWithData
          utcOffsetMs: $utcOffsetMs
          reportingDataTeamIds: $reportingDataTeamIds
          fromDate: $fromDate
          toDate: $toDate
        ) {
          ...ImprovementGoalDataFragment
        }
        goalIssues(
          takeLastNPeriodsWithData: $takeLastNPeriodsWithData
          reportingDataTeamIds: $reportingDataTeamIds
          fromDate: $fromDate
          toDate: $toDate
        ) {
          id
          shortSummary
          summary
        }
      }
    }
  }
  ${ImprovementGoalFragmentFragmentDoc}
  ${ImprovementGoalDataFragmentFragmentDoc}
`;

/**
 * __useImprovementGoalsByTeamIdsQuery__
 *
 * To run a query within a React component, call `useImprovementGoalsByTeamIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useImprovementGoalsByTeamIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImprovementGoalsByTeamIdsQuery({
 *   variables: {
 *      reportingDataTeamIds: // value for 'reportingDataTeamIds'
 *      improvementGoalTeamIds: // value for 'improvementGoalTeamIds'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      takeLastNPeriodsWithData: // value for 'takeLastNPeriodsWithData'
 *      utcOffsetMs: // value for 'utcOffsetMs'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useImprovementGoalsByTeamIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ImprovementGoalsByTeamIdsQuery,
    ImprovementGoalsByTeamIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ImprovementGoalsByTeamIdsQuery,
    ImprovementGoalsByTeamIdsQueryVariables
  >(ImprovementGoalsByTeamIdsDocument, options);
}
export function useImprovementGoalsByTeamIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImprovementGoalsByTeamIdsQuery,
    ImprovementGoalsByTeamIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ImprovementGoalsByTeamIdsQuery,
    ImprovementGoalsByTeamIdsQueryVariables
  >(ImprovementGoalsByTeamIdsDocument, options);
}
export type ImprovementGoalsByTeamIdsQueryHookResult = ReturnType<
  typeof useImprovementGoalsByTeamIdsQuery
>;
export type ImprovementGoalsByTeamIdsLazyQueryHookResult = ReturnType<
  typeof useImprovementGoalsByTeamIdsLazyQuery
>;
export type ImprovementGoalsByTeamIdsQueryResult = Apollo.QueryResult<
  ImprovementGoalsByTeamIdsQuery,
  ImprovementGoalsByTeamIdsQueryVariables
>;
export const OnOffTargetTeamsByGoalIdDocument = gql`
  query OnOffTargetTeamsByGoalId(
    $id: ID!
    $reportingDataTeamIds: [ImprovementGoalTeam!]!
    $fromDate: Date
    $toDate: Date
  ) {
    onOffTargetTeamsByGoalId(
      id: $id
      reportingDataTeamIds: $reportingDataTeamIds
      fromDate: $fromDate
      toDate: $toDate
    ) {
      team {
        ...MinimalTeamFragment
      }
      lastValue
    }
  }
  ${MinimalTeamFragmentFragmentDoc}
`;

/**
 * __useOnOffTargetTeamsByGoalIdQuery__
 *
 * To run a query within a React component, call `useOnOffTargetTeamsByGoalIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnOffTargetTeamsByGoalIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnOffTargetTeamsByGoalIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      reportingDataTeamIds: // value for 'reportingDataTeamIds'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useOnOffTargetTeamsByGoalIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    OnOffTargetTeamsByGoalIdQuery,
    OnOffTargetTeamsByGoalIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OnOffTargetTeamsByGoalIdQuery,
    OnOffTargetTeamsByGoalIdQueryVariables
  >(OnOffTargetTeamsByGoalIdDocument, options);
}
export function useOnOffTargetTeamsByGoalIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OnOffTargetTeamsByGoalIdQuery,
    OnOffTargetTeamsByGoalIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OnOffTargetTeamsByGoalIdQuery,
    OnOffTargetTeamsByGoalIdQueryVariables
  >(OnOffTargetTeamsByGoalIdDocument, options);
}
export type OnOffTargetTeamsByGoalIdQueryHookResult = ReturnType<
  typeof useOnOffTargetTeamsByGoalIdQuery
>;
export type OnOffTargetTeamsByGoalIdLazyQueryHookResult = ReturnType<
  typeof useOnOffTargetTeamsByGoalIdLazyQuery
>;
export type OnOffTargetTeamsByGoalIdQueryResult = Apollo.QueryResult<
  OnOffTargetTeamsByGoalIdQuery,
  OnOffTargetTeamsByGoalIdQueryVariables
>;
export const ImprovementGoalDataUpdatesByTeamIdDocument = gql`
  subscription ImprovementGoalDataUpdatesByTeamId(
    $teamId: ID!
    $takeLastNPeriodsWithData: Int!
    $utcOffsetMs: Int!
  ) {
    improvementGoalDataUpdatesByTeamId(teamId: $teamId) {
      ...ImprovementGoalFragment
      data(
        takeLastNPeriodsWithData: $takeLastNPeriodsWithData
        utcOffsetMs: $utcOffsetMs
      ) {
        ...ImprovementGoalDataFragment
      }
    }
  }
  ${ImprovementGoalFragmentFragmentDoc}
  ${ImprovementGoalDataFragmentFragmentDoc}
`;

/**
 * __useImprovementGoalDataUpdatesByTeamIdSubscription__
 *
 * To run a query within a React component, call `useImprovementGoalDataUpdatesByTeamIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useImprovementGoalDataUpdatesByTeamIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImprovementGoalDataUpdatesByTeamIdSubscription({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      takeLastNPeriodsWithData: // value for 'takeLastNPeriodsWithData'
 *      utcOffsetMs: // value for 'utcOffsetMs'
 *   },
 * });
 */
export function useImprovementGoalDataUpdatesByTeamIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ImprovementGoalDataUpdatesByTeamIdSubscription,
    ImprovementGoalDataUpdatesByTeamIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ImprovementGoalDataUpdatesByTeamIdSubscription,
    ImprovementGoalDataUpdatesByTeamIdSubscriptionVariables
  >(ImprovementGoalDataUpdatesByTeamIdDocument, options);
}
export type ImprovementGoalDataUpdatesByTeamIdSubscriptionHookResult =
  ReturnType<typeof useImprovementGoalDataUpdatesByTeamIdSubscription>;
export type ImprovementGoalDataUpdatesByTeamIdSubscriptionResult =
  Apollo.SubscriptionResult<ImprovementGoalDataUpdatesByTeamIdSubscription>;
export const ImprovementGoalDataUpdatesByTeamIdsDocument = gql`
  subscription ImprovementGoalDataUpdatesByTeamIds(
    $improvementGoalTeamIds: [ID!]!
    $reportingDataTeamIds: [ImprovementGoalTeam!]!
    $takeLastNPeriodsWithData: Int!
    $utcOffsetMs: Int!
  ) {
    improvementGoalDataUpdatesByTeamIds(
      improvementGoalTeamIds: $improvementGoalTeamIds
    ) {
      ...ImprovementGoalFragment
      data(
        takeLastNPeriodsWithData: $takeLastNPeriodsWithData
        utcOffsetMs: $utcOffsetMs
        reportingDataTeamIds: $reportingDataTeamIds
      ) {
        ...ImprovementGoalDataFragment
      }
      goalIssues(
        takeLastNPeriodsWithData: $takeLastNPeriodsWithData
        reportingDataTeamIds: $reportingDataTeamIds
      ) {
        id
        shortSummary
        summary
      }
    }
  }
  ${ImprovementGoalFragmentFragmentDoc}
  ${ImprovementGoalDataFragmentFragmentDoc}
`;

/**
 * __useImprovementGoalDataUpdatesByTeamIdsSubscription__
 *
 * To run a query within a React component, call `useImprovementGoalDataUpdatesByTeamIdsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useImprovementGoalDataUpdatesByTeamIdsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImprovementGoalDataUpdatesByTeamIdsSubscription({
 *   variables: {
 *      improvementGoalTeamIds: // value for 'improvementGoalTeamIds'
 *      reportingDataTeamIds: // value for 'reportingDataTeamIds'
 *      takeLastNPeriodsWithData: // value for 'takeLastNPeriodsWithData'
 *      utcOffsetMs: // value for 'utcOffsetMs'
 *   },
 * });
 */
export function useImprovementGoalDataUpdatesByTeamIdsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ImprovementGoalDataUpdatesByTeamIdsSubscription,
    ImprovementGoalDataUpdatesByTeamIdsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ImprovementGoalDataUpdatesByTeamIdsSubscription,
    ImprovementGoalDataUpdatesByTeamIdsSubscriptionVariables
  >(ImprovementGoalDataUpdatesByTeamIdsDocument, options);
}
export type ImprovementGoalDataUpdatesByTeamIdsSubscriptionHookResult =
  ReturnType<typeof useImprovementGoalDataUpdatesByTeamIdsSubscription>;
export type ImprovementGoalDataUpdatesByTeamIdsSubscriptionResult =
  Apollo.SubscriptionResult<ImprovementGoalDataUpdatesByTeamIdsSubscription>;
export const ExportTeamInsightToConfluenceDocument = gql`
  mutation ExportTeamInsightToConfluence(
    $teamInsightId: ID!
    $confluenceSpaceKey: String!
  ) {
    exportTeamInsightToConfluence(
      teamInsightId: $teamInsightId
      confluenceSpaceKey: $confluenceSpaceKey
    ) {
      hostname
      spaceKey
      contentId
    }
  }
`;
export type ExportTeamInsightToConfluenceMutationFn = Apollo.MutationFunction<
  ExportTeamInsightToConfluenceMutation,
  ExportTeamInsightToConfluenceMutationVariables
>;

/**
 * __useExportTeamInsightToConfluenceMutation__
 *
 * To run a mutation, you first call `useExportTeamInsightToConfluenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportTeamInsightToConfluenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportTeamInsightToConfluenceMutation, { data, loading, error }] = useExportTeamInsightToConfluenceMutation({
 *   variables: {
 *      teamInsightId: // value for 'teamInsightId'
 *      confluenceSpaceKey: // value for 'confluenceSpaceKey'
 *   },
 * });
 */
export function useExportTeamInsightToConfluenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportTeamInsightToConfluenceMutation,
    ExportTeamInsightToConfluenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportTeamInsightToConfluenceMutation,
    ExportTeamInsightToConfluenceMutationVariables
  >(ExportTeamInsightToConfluenceDocument, options);
}
export type ExportTeamInsightToConfluenceMutationHookResult = ReturnType<
  typeof useExportTeamInsightToConfluenceMutation
>;
export type ExportTeamInsightToConfluenceMutationResult =
  Apollo.MutationResult<ExportTeamInsightToConfluenceMutation>;
export type ExportTeamInsightToConfluenceMutationOptions =
  Apollo.BaseMutationOptions<
    ExportTeamInsightToConfluenceMutation,
    ExportTeamInsightToConfluenceMutationVariables
  >;
export const LinkTeamInsightActionItemOriginDocument = gql`
  mutation linkTeamInsightActionItemOrigin(
    $teamInsightSolutionId: ID!
    $actionItemId: ID!
  ) {
    linkTeamInsightActionItemOrigin(
      teamInsightSolutionId: $teamInsightSolutionId
      actionItemId: $actionItemId
    ) {
      ... on TeamInsightFactCauseSolution {
        id
        solutions {
          ... on TeamInsightFactCauseSolutionSolutionActionItem {
            originatedActionItem {
              id
              title
              createdAt
            }
          }
        }
      }
    }
  }
`;
export type LinkTeamInsightActionItemOriginMutationFn = Apollo.MutationFunction<
  LinkTeamInsightActionItemOriginMutation,
  LinkTeamInsightActionItemOriginMutationVariables
>;

/**
 * __useLinkTeamInsightActionItemOriginMutation__
 *
 * To run a mutation, you first call `useLinkTeamInsightActionItemOriginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkTeamInsightActionItemOriginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkTeamInsightActionItemOriginMutation, { data, loading, error }] = useLinkTeamInsightActionItemOriginMutation({
 *   variables: {
 *      teamInsightSolutionId: // value for 'teamInsightSolutionId'
 *      actionItemId: // value for 'actionItemId'
 *   },
 * });
 */
export function useLinkTeamInsightActionItemOriginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkTeamInsightActionItemOriginMutation,
    LinkTeamInsightActionItemOriginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LinkTeamInsightActionItemOriginMutation,
    LinkTeamInsightActionItemOriginMutationVariables
  >(LinkTeamInsightActionItemOriginDocument, options);
}
export type LinkTeamInsightActionItemOriginMutationHookResult = ReturnType<
  typeof useLinkTeamInsightActionItemOriginMutation
>;
export type LinkTeamInsightActionItemOriginMutationResult =
  Apollo.MutationResult<LinkTeamInsightActionItemOriginMutation>;
export type LinkTeamInsightActionItemOriginMutationOptions =
  Apollo.BaseMutationOptions<
    LinkTeamInsightActionItemOriginMutation,
    LinkTeamInsightActionItemOriginMutationVariables
  >;
export const MarkTeamInsightAsSeenDocument = gql`
  mutation MarkTeamInsightAsSeen($teamInsightId: ID!) {
    markTeamInsightAsSeen(teamInsightId: $teamInsightId) {
      ... on TeamInsightFactCauseSolution {
        id
      }
    }
  }
`;
export type MarkTeamInsightAsSeenMutationFn = Apollo.MutationFunction<
  MarkTeamInsightAsSeenMutation,
  MarkTeamInsightAsSeenMutationVariables
>;

/**
 * __useMarkTeamInsightAsSeenMutation__
 *
 * To run a mutation, you first call `useMarkTeamInsightAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkTeamInsightAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markTeamInsightAsSeenMutation, { data, loading, error }] = useMarkTeamInsightAsSeenMutation({
 *   variables: {
 *      teamInsightId: // value for 'teamInsightId'
 *   },
 * });
 */
export function useMarkTeamInsightAsSeenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkTeamInsightAsSeenMutation,
    MarkTeamInsightAsSeenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkTeamInsightAsSeenMutation,
    MarkTeamInsightAsSeenMutationVariables
  >(MarkTeamInsightAsSeenDocument, options);
}
export type MarkTeamInsightAsSeenMutationHookResult = ReturnType<
  typeof useMarkTeamInsightAsSeenMutation
>;
export type MarkTeamInsightAsSeenMutationResult =
  Apollo.MutationResult<MarkTeamInsightAsSeenMutation>;
export type MarkTeamInsightAsSeenMutationOptions = Apollo.BaseMutationOptions<
  MarkTeamInsightAsSeenMutation,
  MarkTeamInsightAsSeenMutationVariables
>;
export const RateTeamInsightDocument = gql`
  mutation RateTeamInsight($teamInsightId: ID!, $rating: QualityName!) {
    rateTeamInsight(teamInsightId: $teamInsightId, rating: $rating) {
      ... on TeamInsightFactCauseSolution {
        id
        rated
      }
    }
  }
`;
export type RateTeamInsightMutationFn = Apollo.MutationFunction<
  RateTeamInsightMutation,
  RateTeamInsightMutationVariables
>;

/**
 * __useRateTeamInsightMutation__
 *
 * To run a mutation, you first call `useRateTeamInsightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateTeamInsightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateTeamInsightMutation, { data, loading, error }] = useRateTeamInsightMutation({
 *   variables: {
 *      teamInsightId: // value for 'teamInsightId'
 *      rating: // value for 'rating'
 *   },
 * });
 */
export function useRateTeamInsightMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RateTeamInsightMutation,
    RateTeamInsightMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RateTeamInsightMutation,
    RateTeamInsightMutationVariables
  >(RateTeamInsightDocument, options);
}
export type RateTeamInsightMutationHookResult = ReturnType<
  typeof useRateTeamInsightMutation
>;
export type RateTeamInsightMutationResult =
  Apollo.MutationResult<RateTeamInsightMutation>;
export type RateTeamInsightMutationOptions = Apollo.BaseMutationOptions<
  RateTeamInsightMutation,
  RateTeamInsightMutationVariables
>;
export const TeamInsightByIdDocument = gql`
  query TeamInsightById($teamInsightId: ID!) {
    teamInsightById(teamInsightId: $teamInsightId) {
      ... on TeamInsightFactCauseSolution {
        ...TeamInsightFactCauseSolutionFragment
      }
    }
  }
  ${TeamInsightFactCauseSolutionFragmentFragmentDoc}
`;

/**
 * __useTeamInsightByIdQuery__
 *
 * To run a query within a React component, call `useTeamInsightByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamInsightByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamInsightByIdQuery({
 *   variables: {
 *      teamInsightId: // value for 'teamInsightId'
 *   },
 * });
 */
export function useTeamInsightByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    TeamInsightByIdQuery,
    TeamInsightByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamInsightByIdQuery, TeamInsightByIdQueryVariables>(
    TeamInsightByIdDocument,
    options
  );
}
export function useTeamInsightByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamInsightByIdQuery,
    TeamInsightByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TeamInsightByIdQuery,
    TeamInsightByIdQueryVariables
  >(TeamInsightByIdDocument, options);
}
export type TeamInsightByIdQueryHookResult = ReturnType<
  typeof useTeamInsightByIdQuery
>;
export type TeamInsightByIdLazyQueryHookResult = ReturnType<
  typeof useTeamInsightByIdLazyQuery
>;
export type TeamInsightByIdQueryResult = Apollo.QueryResult<
  TeamInsightByIdQuery,
  TeamInsightByIdQueryVariables
>;
export const TeamInsightUpdateDocument = gql`
  subscription TeamInsightUpdate($teamId: ID!, $userId: ID!) {
    teamInsightUpdate(teamId: $teamId, userId: $userId) {
      id
      ...TeamInsightsFragment
    }
  }
  ${TeamInsightsFragmentFragmentDoc}
`;

/**
 * __useTeamInsightUpdateSubscription__
 *
 * To run a query within a React component, call `useTeamInsightUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTeamInsightUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamInsightUpdateSubscription({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useTeamInsightUpdateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    TeamInsightUpdateSubscription,
    TeamInsightUpdateSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    TeamInsightUpdateSubscription,
    TeamInsightUpdateSubscriptionVariables
  >(TeamInsightUpdateDocument, options);
}
export type TeamInsightUpdateSubscriptionHookResult = ReturnType<
  typeof useTeamInsightUpdateSubscription
>;
export type TeamInsightUpdateSubscriptionResult =
  Apollo.SubscriptionResult<TeamInsightUpdateSubscription>;
export const InstallBitbucketMetricsIntegrationDocument = gql`
  mutation installBitbucketMetricsIntegration($state: String!) {
    installBitbucketMetricsIntegration(state: $state) {
      id
    }
  }
`;
export type InstallBitbucketMetricsIntegrationMutationFn =
  Apollo.MutationFunction<
    InstallBitbucketMetricsIntegrationMutation,
    InstallBitbucketMetricsIntegrationMutationVariables
  >;

/**
 * __useInstallBitbucketMetricsIntegrationMutation__
 *
 * To run a mutation, you first call `useInstallBitbucketMetricsIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInstallBitbucketMetricsIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [installBitbucketMetricsIntegrationMutation, { data, loading, error }] = useInstallBitbucketMetricsIntegrationMutation({
 *   variables: {
 *      state: // value for 'state'
 *   },
 * });
 */
export function useInstallBitbucketMetricsIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InstallBitbucketMetricsIntegrationMutation,
    InstallBitbucketMetricsIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InstallBitbucketMetricsIntegrationMutation,
    InstallBitbucketMetricsIntegrationMutationVariables
  >(InstallBitbucketMetricsIntegrationDocument, options);
}
export type InstallBitbucketMetricsIntegrationMutationHookResult = ReturnType<
  typeof useInstallBitbucketMetricsIntegrationMutation
>;
export type InstallBitbucketMetricsIntegrationMutationResult =
  Apollo.MutationResult<InstallBitbucketMetricsIntegrationMutation>;
export type InstallBitbucketMetricsIntegrationMutationOptions =
  Apollo.BaseMutationOptions<
    InstallBitbucketMetricsIntegrationMutation,
    InstallBitbucketMetricsIntegrationMutationVariables
  >;
export const InstallJiraMetricsIntegrationDocument = gql`
  mutation InstallJiraMetricsIntegration($code: String!) {
    installJiraMetricsIntegration(code: $code) {
      id
    }
  }
`;
export type InstallJiraMetricsIntegrationMutationFn = Apollo.MutationFunction<
  InstallJiraMetricsIntegrationMutation,
  InstallJiraMetricsIntegrationMutationVariables
>;

/**
 * __useInstallJiraMetricsIntegrationMutation__
 *
 * To run a mutation, you first call `useInstallJiraMetricsIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInstallJiraMetricsIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [installJiraMetricsIntegrationMutation, { data, loading, error }] = useInstallJiraMetricsIntegrationMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useInstallJiraMetricsIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InstallJiraMetricsIntegrationMutation,
    InstallJiraMetricsIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InstallJiraMetricsIntegrationMutation,
    InstallJiraMetricsIntegrationMutationVariables
  >(InstallJiraMetricsIntegrationDocument, options);
}
export type InstallJiraMetricsIntegrationMutationHookResult = ReturnType<
  typeof useInstallJiraMetricsIntegrationMutation
>;
export type InstallJiraMetricsIntegrationMutationResult =
  Apollo.MutationResult<InstallJiraMetricsIntegrationMutation>;
export type InstallJiraMetricsIntegrationMutationOptions =
  Apollo.BaseMutationOptions<
    InstallJiraMetricsIntegrationMutation,
    InstallJiraMetricsIntegrationMutationVariables
  >;
export const SetupGithubIntegrationDocument = gql`
  mutation SetupGithubIntegration($state: String!) {
    installGithubIntegration(state: $state) {
      id
    }
  }
`;
export type SetupGithubIntegrationMutationFn = Apollo.MutationFunction<
  SetupGithubIntegrationMutation,
  SetupGithubIntegrationMutationVariables
>;

/**
 * __useSetupGithubIntegrationMutation__
 *
 * To run a mutation, you first call `useSetupGithubIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupGithubIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupGithubIntegrationMutation, { data, loading, error }] = useSetupGithubIntegrationMutation({
 *   variables: {
 *      state: // value for 'state'
 *   },
 * });
 */
export function useSetupGithubIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetupGithubIntegrationMutation,
    SetupGithubIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetupGithubIntegrationMutation,
    SetupGithubIntegrationMutationVariables
  >(SetupGithubIntegrationDocument, options);
}
export type SetupGithubIntegrationMutationHookResult = ReturnType<
  typeof useSetupGithubIntegrationMutation
>;
export type SetupGithubIntegrationMutationResult =
  Apollo.MutationResult<SetupGithubIntegrationMutation>;
export type SetupGithubIntegrationMutationOptions = Apollo.BaseMutationOptions<
  SetupGithubIntegrationMutation,
  SetupGithubIntegrationMutationVariables
>;
export const UninstallBitbucketMetricsIntegrationDocument = gql`
  mutation UninstallBitbucketMetricsIntegration {
    uninstallBitbucketMetricsIntegration {
      id
    }
  }
`;
export type UninstallBitbucketMetricsIntegrationMutationFn =
  Apollo.MutationFunction<
    UninstallBitbucketMetricsIntegrationMutation,
    UninstallBitbucketMetricsIntegrationMutationVariables
  >;

/**
 * __useUninstallBitbucketMetricsIntegrationMutation__
 *
 * To run a mutation, you first call `useUninstallBitbucketMetricsIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUninstallBitbucketMetricsIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uninstallBitbucketMetricsIntegrationMutation, { data, loading, error }] = useUninstallBitbucketMetricsIntegrationMutation({
 *   variables: {
 *   },
 * });
 */
export function useUninstallBitbucketMetricsIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UninstallBitbucketMetricsIntegrationMutation,
    UninstallBitbucketMetricsIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UninstallBitbucketMetricsIntegrationMutation,
    UninstallBitbucketMetricsIntegrationMutationVariables
  >(UninstallBitbucketMetricsIntegrationDocument, options);
}
export type UninstallBitbucketMetricsIntegrationMutationHookResult = ReturnType<
  typeof useUninstallBitbucketMetricsIntegrationMutation
>;
export type UninstallBitbucketMetricsIntegrationMutationResult =
  Apollo.MutationResult<UninstallBitbucketMetricsIntegrationMutation>;
export type UninstallBitbucketMetricsIntegrationMutationOptions =
  Apollo.BaseMutationOptions<
    UninstallBitbucketMetricsIntegrationMutation,
    UninstallBitbucketMetricsIntegrationMutationVariables
  >;
export const UninstallGithubIntegrationDocument = gql`
  mutation UninstallGithubIntegration {
    uninstallGithubIntegration {
      id
    }
  }
`;
export type UninstallGithubIntegrationMutationFn = Apollo.MutationFunction<
  UninstallGithubIntegrationMutation,
  UninstallGithubIntegrationMutationVariables
>;

/**
 * __useUninstallGithubIntegrationMutation__
 *
 * To run a mutation, you first call `useUninstallGithubIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUninstallGithubIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uninstallGithubIntegrationMutation, { data, loading, error }] = useUninstallGithubIntegrationMutation({
 *   variables: {
 *   },
 * });
 */
export function useUninstallGithubIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UninstallGithubIntegrationMutation,
    UninstallGithubIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UninstallGithubIntegrationMutation,
    UninstallGithubIntegrationMutationVariables
  >(UninstallGithubIntegrationDocument, options);
}
export type UninstallGithubIntegrationMutationHookResult = ReturnType<
  typeof useUninstallGithubIntegrationMutation
>;
export type UninstallGithubIntegrationMutationResult =
  Apollo.MutationResult<UninstallGithubIntegrationMutation>;
export type UninstallGithubIntegrationMutationOptions =
  Apollo.BaseMutationOptions<
    UninstallGithubIntegrationMutation,
    UninstallGithubIntegrationMutationVariables
  >;
export const UninstallJiraMetricsIntegrationDocument = gql`
  mutation UninstallJiraMetricsIntegration {
    uninstallJiraMetricsIntegration {
      id
    }
  }
`;
export type UninstallJiraMetricsIntegrationMutationFn = Apollo.MutationFunction<
  UninstallJiraMetricsIntegrationMutation,
  UninstallJiraMetricsIntegrationMutationVariables
>;

/**
 * __useUninstallJiraMetricsIntegrationMutation__
 *
 * To run a mutation, you first call `useUninstallJiraMetricsIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUninstallJiraMetricsIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uninstallJiraMetricsIntegrationMutation, { data, loading, error }] = useUninstallJiraMetricsIntegrationMutation({
 *   variables: {
 *   },
 * });
 */
export function useUninstallJiraMetricsIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UninstallJiraMetricsIntegrationMutation,
    UninstallJiraMetricsIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UninstallJiraMetricsIntegrationMutation,
    UninstallJiraMetricsIntegrationMutationVariables
  >(UninstallJiraMetricsIntegrationDocument, options);
}
export type UninstallJiraMetricsIntegrationMutationHookResult = ReturnType<
  typeof useUninstallJiraMetricsIntegrationMutation
>;
export type UninstallJiraMetricsIntegrationMutationResult =
  Apollo.MutationResult<UninstallJiraMetricsIntegrationMutation>;
export type UninstallJiraMetricsIntegrationMutationOptions =
  Apollo.BaseMutationOptions<
    UninstallJiraMetricsIntegrationMutation,
    UninstallJiraMetricsIntegrationMutationVariables
  >;
export const UninstallSlackIntegrationDocument = gql`
  mutation UninstallSlackIntegration {
    uninstallSlackIntegration
  }
`;
export type UninstallSlackIntegrationMutationFn = Apollo.MutationFunction<
  UninstallSlackIntegrationMutation,
  UninstallSlackIntegrationMutationVariables
>;

/**
 * __useUninstallSlackIntegrationMutation__
 *
 * To run a mutation, you first call `useUninstallSlackIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUninstallSlackIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uninstallSlackIntegrationMutation, { data, loading, error }] = useUninstallSlackIntegrationMutation({
 *   variables: {
 *   },
 * });
 */
export function useUninstallSlackIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UninstallSlackIntegrationMutation,
    UninstallSlackIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UninstallSlackIntegrationMutation,
    UninstallSlackIntegrationMutationVariables
  >(UninstallSlackIntegrationDocument, options);
}
export type UninstallSlackIntegrationMutationHookResult = ReturnType<
  typeof useUninstallSlackIntegrationMutation
>;
export type UninstallSlackIntegrationMutationResult =
  Apollo.MutationResult<UninstallSlackIntegrationMutation>;
export type UninstallSlackIntegrationMutationOptions =
  Apollo.BaseMutationOptions<
    UninstallSlackIntegrationMutation,
    UninstallSlackIntegrationMutationVariables
  >;
export const UpdateLinkedBitbucketRepositoriesDocument = gql`
  mutation UpdateLinkedBitbucketRepositories(
    $teamId: ID!
    $bitbucketRepositoryIds: [ID!]!
  ) {
    updateLinkedBitbucketRepositories(
      teamId: $teamId
      bitbucketRepositoryIds: $bitbucketRepositoryIds
    ) {
      id
      linkedBitbucketRepositories {
        id
        name
      }
    }
  }
`;
export type UpdateLinkedBitbucketRepositoriesMutationFn =
  Apollo.MutationFunction<
    UpdateLinkedBitbucketRepositoriesMutation,
    UpdateLinkedBitbucketRepositoriesMutationVariables
  >;

/**
 * __useUpdateLinkedBitbucketRepositoriesMutation__
 *
 * To run a mutation, you first call `useUpdateLinkedBitbucketRepositoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLinkedBitbucketRepositoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLinkedBitbucketRepositoriesMutation, { data, loading, error }] = useUpdateLinkedBitbucketRepositoriesMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      bitbucketRepositoryIds: // value for 'bitbucketRepositoryIds'
 *   },
 * });
 */
export function useUpdateLinkedBitbucketRepositoriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLinkedBitbucketRepositoriesMutation,
    UpdateLinkedBitbucketRepositoriesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLinkedBitbucketRepositoriesMutation,
    UpdateLinkedBitbucketRepositoriesMutationVariables
  >(UpdateLinkedBitbucketRepositoriesDocument, options);
}
export type UpdateLinkedBitbucketRepositoriesMutationHookResult = ReturnType<
  typeof useUpdateLinkedBitbucketRepositoriesMutation
>;
export type UpdateLinkedBitbucketRepositoriesMutationResult =
  Apollo.MutationResult<UpdateLinkedBitbucketRepositoriesMutation>;
export type UpdateLinkedBitbucketRepositoriesMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateLinkedBitbucketRepositoriesMutation,
    UpdateLinkedBitbucketRepositoriesMutationVariables
  >;
export const UpdateLinkedGithubRepositoriesDocument = gql`
  mutation UpdateLinkedGithubRepositories(
    $teamId: ID!
    $githubRepositoryIds: [ID!]!
  ) {
    updateLinkedGithubRepositories(
      teamId: $teamId
      githubRepositoryIds: $githubRepositoryIds
    ) {
      id
      linkedGithubRepositories {
        id
        name
      }
    }
  }
`;
export type UpdateLinkedGithubRepositoriesMutationFn = Apollo.MutationFunction<
  UpdateLinkedGithubRepositoriesMutation,
  UpdateLinkedGithubRepositoriesMutationVariables
>;

/**
 * __useUpdateLinkedGithubRepositoriesMutation__
 *
 * To run a mutation, you first call `useUpdateLinkedGithubRepositoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLinkedGithubRepositoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLinkedGithubRepositoriesMutation, { data, loading, error }] = useUpdateLinkedGithubRepositoriesMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      githubRepositoryIds: // value for 'githubRepositoryIds'
 *   },
 * });
 */
export function useUpdateLinkedGithubRepositoriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLinkedGithubRepositoriesMutation,
    UpdateLinkedGithubRepositoriesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLinkedGithubRepositoriesMutation,
    UpdateLinkedGithubRepositoriesMutationVariables
  >(UpdateLinkedGithubRepositoriesDocument, options);
}
export type UpdateLinkedGithubRepositoriesMutationHookResult = ReturnType<
  typeof useUpdateLinkedGithubRepositoriesMutation
>;
export type UpdateLinkedGithubRepositoriesMutationResult =
  Apollo.MutationResult<UpdateLinkedGithubRepositoriesMutation>;
export type UpdateLinkedGithubRepositoriesMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateLinkedGithubRepositoriesMutation,
    UpdateLinkedGithubRepositoriesMutationVariables
  >;
export const UpdateLinkedJiraBoardsDocument = gql`
  mutation UpdateLinkedJiraBoards($teamId: ID!, $jiraBoardIds: [ID!]!) {
    updateLinkedJiraBoards(teamId: $teamId, jiraBoardIds: $jiraBoardIds) {
      id
      linkedJiraBoards {
        id
        name
      }
    }
  }
`;
export type UpdateLinkedJiraBoardsMutationFn = Apollo.MutationFunction<
  UpdateLinkedJiraBoardsMutation,
  UpdateLinkedJiraBoardsMutationVariables
>;

/**
 * __useUpdateLinkedJiraBoardsMutation__
 *
 * To run a mutation, you first call `useUpdateLinkedJiraBoardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLinkedJiraBoardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLinkedJiraBoardsMutation, { data, loading, error }] = useUpdateLinkedJiraBoardsMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      jiraBoardIds: // value for 'jiraBoardIds'
 *   },
 * });
 */
export function useUpdateLinkedJiraBoardsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLinkedJiraBoardsMutation,
    UpdateLinkedJiraBoardsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLinkedJiraBoardsMutation,
    UpdateLinkedJiraBoardsMutationVariables
  >(UpdateLinkedJiraBoardsDocument, options);
}
export type UpdateLinkedJiraBoardsMutationHookResult = ReturnType<
  typeof useUpdateLinkedJiraBoardsMutation
>;
export type UpdateLinkedJiraBoardsMutationResult =
  Apollo.MutationResult<UpdateLinkedJiraBoardsMutation>;
export type UpdateLinkedJiraBoardsMutationOptions = Apollo.BaseMutationOptions<
  UpdateLinkedJiraBoardsMutation,
  UpdateLinkedJiraBoardsMutationVariables
>;
export const InstalledOrgIntegrationsDocument = gql`
  query InstalledOrgIntegrations($organizationId: ID!) {
    installedOrganizationIntegrations(organizationId: $organizationId) {
      ...InstalledOrganizationIntegrations
    }
  }
  ${InstalledOrganizationIntegrationsFragmentDoc}
`;

/**
 * __useInstalledOrgIntegrationsQuery__
 *
 * To run a query within a React component, call `useInstalledOrgIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstalledOrgIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstalledOrgIntegrationsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useInstalledOrgIntegrationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    InstalledOrgIntegrationsQuery,
    InstalledOrgIntegrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InstalledOrgIntegrationsQuery,
    InstalledOrgIntegrationsQueryVariables
  >(InstalledOrgIntegrationsDocument, options);
}
export function useInstalledOrgIntegrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InstalledOrgIntegrationsQuery,
    InstalledOrgIntegrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InstalledOrgIntegrationsQuery,
    InstalledOrgIntegrationsQueryVariables
  >(InstalledOrgIntegrationsDocument, options);
}
export type InstalledOrgIntegrationsQueryHookResult = ReturnType<
  typeof useInstalledOrgIntegrationsQuery
>;
export type InstalledOrgIntegrationsLazyQueryHookResult = ReturnType<
  typeof useInstalledOrgIntegrationsLazyQuery
>;
export type InstalledOrgIntegrationsQueryResult = Apollo.QueryResult<
  InstalledOrgIntegrationsQuery,
  InstalledOrgIntegrationsQueryVariables
>;
export const InstalledUserIntegrationsDocument = gql`
  query InstalledUserIntegrations {
    installedUserIntegrations {
      confluence
      jira
      miro
      trello
    }
  }
`;

/**
 * __useInstalledUserIntegrationsQuery__
 *
 * To run a query within a React component, call `useInstalledUserIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstalledUserIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstalledUserIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInstalledUserIntegrationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InstalledUserIntegrationsQuery,
    InstalledUserIntegrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InstalledUserIntegrationsQuery,
    InstalledUserIntegrationsQueryVariables
  >(InstalledUserIntegrationsDocument, options);
}
export function useInstalledUserIntegrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InstalledUserIntegrationsQuery,
    InstalledUserIntegrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InstalledUserIntegrationsQuery,
    InstalledUserIntegrationsQueryVariables
  >(InstalledUserIntegrationsDocument, options);
}
export type InstalledUserIntegrationsQueryHookResult = ReturnType<
  typeof useInstalledUserIntegrationsQuery
>;
export type InstalledUserIntegrationsLazyQueryHookResult = ReturnType<
  typeof useInstalledUserIntegrationsLazyQuery
>;
export type InstalledUserIntegrationsQueryResult = Apollo.QueryResult<
  InstalledUserIntegrationsQuery,
  InstalledUserIntegrationsQueryVariables
>;
export const LinkableBitbucketRepositoriesDocument = gql`
  query LinkableBitbucketRepositories($teamId: ID!, $search: String!) {
    team(teamId: $teamId) {
      id
      searchLinkableBitbucketRepositories(search: $search) {
        id
        name
      }
    }
  }
`;

/**
 * __useLinkableBitbucketRepositoriesQuery__
 *
 * To run a query within a React component, call `useLinkableBitbucketRepositoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkableBitbucketRepositoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkableBitbucketRepositoriesQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useLinkableBitbucketRepositoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    LinkableBitbucketRepositoriesQuery,
    LinkableBitbucketRepositoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LinkableBitbucketRepositoriesQuery,
    LinkableBitbucketRepositoriesQueryVariables
  >(LinkableBitbucketRepositoriesDocument, options);
}
export function useLinkableBitbucketRepositoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LinkableBitbucketRepositoriesQuery,
    LinkableBitbucketRepositoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LinkableBitbucketRepositoriesQuery,
    LinkableBitbucketRepositoriesQueryVariables
  >(LinkableBitbucketRepositoriesDocument, options);
}
export type LinkableBitbucketRepositoriesQueryHookResult = ReturnType<
  typeof useLinkableBitbucketRepositoriesQuery
>;
export type LinkableBitbucketRepositoriesLazyQueryHookResult = ReturnType<
  typeof useLinkableBitbucketRepositoriesLazyQuery
>;
export type LinkableBitbucketRepositoriesQueryResult = Apollo.QueryResult<
  LinkableBitbucketRepositoriesQuery,
  LinkableBitbucketRepositoriesQueryVariables
>;
export const LinkableGithubRepositoriesDocument = gql`
  query LinkableGithubRepositories($teamId: ID!, $search: String!) {
    team(teamId: $teamId) {
      id
      searchLinkableGithubRepositories(search: $search) {
        id
        name
      }
    }
  }
`;

/**
 * __useLinkableGithubRepositoriesQuery__
 *
 * To run a query within a React component, call `useLinkableGithubRepositoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkableGithubRepositoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkableGithubRepositoriesQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useLinkableGithubRepositoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    LinkableGithubRepositoriesQuery,
    LinkableGithubRepositoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LinkableGithubRepositoriesQuery,
    LinkableGithubRepositoriesQueryVariables
  >(LinkableGithubRepositoriesDocument, options);
}
export function useLinkableGithubRepositoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LinkableGithubRepositoriesQuery,
    LinkableGithubRepositoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LinkableGithubRepositoriesQuery,
    LinkableGithubRepositoriesQueryVariables
  >(LinkableGithubRepositoriesDocument, options);
}
export type LinkableGithubRepositoriesQueryHookResult = ReturnType<
  typeof useLinkableGithubRepositoriesQuery
>;
export type LinkableGithubRepositoriesLazyQueryHookResult = ReturnType<
  typeof useLinkableGithubRepositoriesLazyQuery
>;
export type LinkableGithubRepositoriesQueryResult = Apollo.QueryResult<
  LinkableGithubRepositoriesQuery,
  LinkableGithubRepositoriesQueryVariables
>;
export const LinkableJiraBoardsDocument = gql`
  query LinkableJiraBoards($teamId: ID!, $search: String!) {
    team(teamId: $teamId) {
      id
      linkableJiraBoards(search: $search) {
        id
        name
        isSoftwareDevProject
      }
    }
  }
`;

/**
 * __useLinkableJiraBoardsQuery__
 *
 * To run a query within a React component, call `useLinkableJiraBoardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkableJiraBoardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkableJiraBoardsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useLinkableJiraBoardsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LinkableJiraBoardsQuery,
    LinkableJiraBoardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LinkableJiraBoardsQuery,
    LinkableJiraBoardsQueryVariables
  >(LinkableJiraBoardsDocument, options);
}
export function useLinkableJiraBoardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LinkableJiraBoardsQuery,
    LinkableJiraBoardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LinkableJiraBoardsQuery,
    LinkableJiraBoardsQueryVariables
  >(LinkableJiraBoardsDocument, options);
}
export type LinkableJiraBoardsQueryHookResult = ReturnType<
  typeof useLinkableJiraBoardsQuery
>;
export type LinkableJiraBoardsLazyQueryHookResult = ReturnType<
  typeof useLinkableJiraBoardsLazyQuery
>;
export type LinkableJiraBoardsQueryResult = Apollo.QueryResult<
  LinkableJiraBoardsQuery,
  LinkableJiraBoardsQueryVariables
>;
export const LinkedBitbucketRepositoriesDocument = gql`
  query LinkedBitbucketRepositories($teamId: ID!) {
    team(teamId: $teamId) {
      id
      linkedBitbucketRepositories {
        id
        name
      }
    }
  }
`;

/**
 * __useLinkedBitbucketRepositoriesQuery__
 *
 * To run a query within a React component, call `useLinkedBitbucketRepositoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkedBitbucketRepositoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkedBitbucketRepositoriesQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useLinkedBitbucketRepositoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    LinkedBitbucketRepositoriesQuery,
    LinkedBitbucketRepositoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LinkedBitbucketRepositoriesQuery,
    LinkedBitbucketRepositoriesQueryVariables
  >(LinkedBitbucketRepositoriesDocument, options);
}
export function useLinkedBitbucketRepositoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LinkedBitbucketRepositoriesQuery,
    LinkedBitbucketRepositoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LinkedBitbucketRepositoriesQuery,
    LinkedBitbucketRepositoriesQueryVariables
  >(LinkedBitbucketRepositoriesDocument, options);
}
export type LinkedBitbucketRepositoriesQueryHookResult = ReturnType<
  typeof useLinkedBitbucketRepositoriesQuery
>;
export type LinkedBitbucketRepositoriesLazyQueryHookResult = ReturnType<
  typeof useLinkedBitbucketRepositoriesLazyQuery
>;
export type LinkedBitbucketRepositoriesQueryResult = Apollo.QueryResult<
  LinkedBitbucketRepositoriesQuery,
  LinkedBitbucketRepositoriesQueryVariables
>;
export const LinkedGithubRepositoriesDocument = gql`
  query LinkedGithubRepositories($teamId: ID!) {
    team(teamId: $teamId) {
      id
      linkedGithubRepositories {
        id
        name
      }
    }
  }
`;

/**
 * __useLinkedGithubRepositoriesQuery__
 *
 * To run a query within a React component, call `useLinkedGithubRepositoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkedGithubRepositoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkedGithubRepositoriesQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useLinkedGithubRepositoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    LinkedGithubRepositoriesQuery,
    LinkedGithubRepositoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LinkedGithubRepositoriesQuery,
    LinkedGithubRepositoriesQueryVariables
  >(LinkedGithubRepositoriesDocument, options);
}
export function useLinkedGithubRepositoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LinkedGithubRepositoriesQuery,
    LinkedGithubRepositoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LinkedGithubRepositoriesQuery,
    LinkedGithubRepositoriesQueryVariables
  >(LinkedGithubRepositoriesDocument, options);
}
export type LinkedGithubRepositoriesQueryHookResult = ReturnType<
  typeof useLinkedGithubRepositoriesQuery
>;
export type LinkedGithubRepositoriesLazyQueryHookResult = ReturnType<
  typeof useLinkedGithubRepositoriesLazyQuery
>;
export type LinkedGithubRepositoriesQueryResult = Apollo.QueryResult<
  LinkedGithubRepositoriesQuery,
  LinkedGithubRepositoriesQueryVariables
>;
export const LinkedJiraBoardsDocument = gql`
  query LinkedJiraBoards($teamId: ID!) {
    team(teamId: $teamId) {
      id
      linkedJiraBoards {
        id
        name
      }
    }
  }
`;

/**
 * __useLinkedJiraBoardsQuery__
 *
 * To run a query within a React component, call `useLinkedJiraBoardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkedJiraBoardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkedJiraBoardsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useLinkedJiraBoardsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LinkedJiraBoardsQuery,
    LinkedJiraBoardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LinkedJiraBoardsQuery, LinkedJiraBoardsQueryVariables>(
    LinkedJiraBoardsDocument,
    options
  );
}
export function useLinkedJiraBoardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LinkedJiraBoardsQuery,
    LinkedJiraBoardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LinkedJiraBoardsQuery,
    LinkedJiraBoardsQueryVariables
  >(LinkedJiraBoardsDocument, options);
}
export type LinkedJiraBoardsQueryHookResult = ReturnType<
  typeof useLinkedJiraBoardsQuery
>;
export type LinkedJiraBoardsLazyQueryHookResult = ReturnType<
  typeof useLinkedJiraBoardsLazyQuery
>;
export type LinkedJiraBoardsQueryResult = Apollo.QueryResult<
  LinkedJiraBoardsQuery,
  LinkedJiraBoardsQueryVariables
>;
export const OrgIntegrationsChangedDocument = gql`
  subscription OrgIntegrationsChanged($organizationId: ID!) {
    installedOrganizationIntegrationsUpdate(organizationId: $organizationId) {
      ...InstalledOrganizationIntegrations
    }
  }
  ${InstalledOrganizationIntegrationsFragmentDoc}
`;

/**
 * __useOrgIntegrationsChangedSubscription__
 *
 * To run a query within a React component, call `useOrgIntegrationsChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOrgIntegrationsChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgIntegrationsChangedSubscription({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrgIntegrationsChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OrgIntegrationsChangedSubscription,
    OrgIntegrationsChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OrgIntegrationsChangedSubscription,
    OrgIntegrationsChangedSubscriptionVariables
  >(OrgIntegrationsChangedDocument, options);
}
export type OrgIntegrationsChangedSubscriptionHookResult = ReturnType<
  typeof useOrgIntegrationsChangedSubscription
>;
export type OrgIntegrationsChangedSubscriptionResult =
  Apollo.SubscriptionResult<OrgIntegrationsChangedSubscription>;
export const DiscardLivePollDocument = gql`
  mutation DiscardLivePoll($livePollId: ID!) {
    discardLivePollResult(livePollId: $livePollId) {
      id
      status
      result
    }
  }
`;
export type DiscardLivePollMutationFn = Apollo.MutationFunction<
  DiscardLivePollMutation,
  DiscardLivePollMutationVariables
>;

/**
 * __useDiscardLivePollMutation__
 *
 * To run a mutation, you first call `useDiscardLivePollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscardLivePollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discardLivePollMutation, { data, loading, error }] = useDiscardLivePollMutation({
 *   variables: {
 *      livePollId: // value for 'livePollId'
 *   },
 * });
 */
export function useDiscardLivePollMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DiscardLivePollMutation,
    DiscardLivePollMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DiscardLivePollMutation,
    DiscardLivePollMutationVariables
  >(DiscardLivePollDocument, options);
}
export type DiscardLivePollMutationHookResult = ReturnType<
  typeof useDiscardLivePollMutation
>;
export type DiscardLivePollMutationResult =
  Apollo.MutationResult<DiscardLivePollMutation>;
export type DiscardLivePollMutationOptions = Apollo.BaseMutationOptions<
  DiscardLivePollMutation,
  DiscardLivePollMutationVariables
>;
export const FinishLivePollsByBoardIdDocument = gql`
  mutation FinishLivePollsByBoardId($boardId: ID!) {
    finishLivePollsByBoardId(boardId: $boardId) {
      id
      status
      result
    }
  }
`;
export type FinishLivePollsByBoardIdMutationFn = Apollo.MutationFunction<
  FinishLivePollsByBoardIdMutation,
  FinishLivePollsByBoardIdMutationVariables
>;

/**
 * __useFinishLivePollsByBoardIdMutation__
 *
 * To run a mutation, you first call `useFinishLivePollsByBoardIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishLivePollsByBoardIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishLivePollsByBoardIdMutation, { data, loading, error }] = useFinishLivePollsByBoardIdMutation({
 *   variables: {
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useFinishLivePollsByBoardIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinishLivePollsByBoardIdMutation,
    FinishLivePollsByBoardIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FinishLivePollsByBoardIdMutation,
    FinishLivePollsByBoardIdMutationVariables
  >(FinishLivePollsByBoardIdDocument, options);
}
export type FinishLivePollsByBoardIdMutationHookResult = ReturnType<
  typeof useFinishLivePollsByBoardIdMutation
>;
export type FinishLivePollsByBoardIdMutationResult =
  Apollo.MutationResult<FinishLivePollsByBoardIdMutation>;
export type FinishLivePollsByBoardIdMutationOptions =
  Apollo.BaseMutationOptions<
    FinishLivePollsByBoardIdMutation,
    FinishLivePollsByBoardIdMutationVariables
  >;
export const StartGoalsLivePollDocument = gql`
  mutation StartGoalsLivePoll($improvementGoalIds: [ID!]!, $boardId: ID!) {
    startGoalsLivePoll(
      improvementGoalIds: $improvementGoalIds
      boardId: $boardId
    ) {
      ...LivePollFragment
    }
  }
  ${LivePollFragmentFragmentDoc}
`;
export type StartGoalsLivePollMutationFn = Apollo.MutationFunction<
  StartGoalsLivePollMutation,
  StartGoalsLivePollMutationVariables
>;

/**
 * __useStartGoalsLivePollMutation__
 *
 * To run a mutation, you first call `useStartGoalsLivePollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartGoalsLivePollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startGoalsLivePollMutation, { data, loading, error }] = useStartGoalsLivePollMutation({
 *   variables: {
 *      improvementGoalIds: // value for 'improvementGoalIds'
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useStartGoalsLivePollMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartGoalsLivePollMutation,
    StartGoalsLivePollMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartGoalsLivePollMutation,
    StartGoalsLivePollMutationVariables
  >(StartGoalsLivePollDocument, options);
}
export type StartGoalsLivePollMutationHookResult = ReturnType<
  typeof useStartGoalsLivePollMutation
>;
export type StartGoalsLivePollMutationResult =
  Apollo.MutationResult<StartGoalsLivePollMutation>;
export type StartGoalsLivePollMutationOptions = Apollo.BaseMutationOptions<
  StartGoalsLivePollMutation,
  StartGoalsLivePollMutationVariables
>;
export const SubmitLivePollAnswerDocument = gql`
  mutation SubmitLivePollAnswer($livePollId: ID!, $answer: Int!) {
    submitLivePollAnswer(livePollId: $livePollId, answer: $answer) {
      id
      status
      answeredUserIds
    }
  }
`;
export type SubmitLivePollAnswerMutationFn = Apollo.MutationFunction<
  SubmitLivePollAnswerMutation,
  SubmitLivePollAnswerMutationVariables
>;

/**
 * __useSubmitLivePollAnswerMutation__
 *
 * To run a mutation, you first call `useSubmitLivePollAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitLivePollAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitLivePollAnswerMutation, { data, loading, error }] = useSubmitLivePollAnswerMutation({
 *   variables: {
 *      livePollId: // value for 'livePollId'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useSubmitLivePollAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitLivePollAnswerMutation,
    SubmitLivePollAnswerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitLivePollAnswerMutation,
    SubmitLivePollAnswerMutationVariables
  >(SubmitLivePollAnswerDocument, options);
}
export type SubmitLivePollAnswerMutationHookResult = ReturnType<
  typeof useSubmitLivePollAnswerMutation
>;
export type SubmitLivePollAnswerMutationResult =
  Apollo.MutationResult<SubmitLivePollAnswerMutation>;
export type SubmitLivePollAnswerMutationOptions = Apollo.BaseMutationOptions<
  SubmitLivePollAnswerMutation,
  SubmitLivePollAnswerMutationVariables
>;
export const SubmitLivePollSkipAnswerDocument = gql`
  mutation SubmitLivePollSkipAnswer(
    $livePollId: ID!
    $reason: LivePollSkipReason!
  ) {
    submitLivePollSkipAnswer(livePollId: $livePollId, reason: $reason) {
      id
      status
      skippedUserIds
    }
  }
`;
export type SubmitLivePollSkipAnswerMutationFn = Apollo.MutationFunction<
  SubmitLivePollSkipAnswerMutation,
  SubmitLivePollSkipAnswerMutationVariables
>;

/**
 * __useSubmitLivePollSkipAnswerMutation__
 *
 * To run a mutation, you first call `useSubmitLivePollSkipAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitLivePollSkipAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitLivePollSkipAnswerMutation, { data, loading, error }] = useSubmitLivePollSkipAnswerMutation({
 *   variables: {
 *      livePollId: // value for 'livePollId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useSubmitLivePollSkipAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitLivePollSkipAnswerMutation,
    SubmitLivePollSkipAnswerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitLivePollSkipAnswerMutation,
    SubmitLivePollSkipAnswerMutationVariables
  >(SubmitLivePollSkipAnswerDocument, options);
}
export type SubmitLivePollSkipAnswerMutationHookResult = ReturnType<
  typeof useSubmitLivePollSkipAnswerMutation
>;
export type SubmitLivePollSkipAnswerMutationResult =
  Apollo.MutationResult<SubmitLivePollSkipAnswerMutation>;
export type SubmitLivePollSkipAnswerMutationOptions =
  Apollo.BaseMutationOptions<
    SubmitLivePollSkipAnswerMutation,
    SubmitLivePollSkipAnswerMutationVariables
  >;
export const UndoLivePollAnswerDocument = gql`
  mutation UndoLivePollAnswer($livePollId: ID!) {
    removeLivePollAnswer(livePollId: $livePollId) {
      id
      status
      skippedUserIds
      answeredUserIds
    }
  }
`;
export type UndoLivePollAnswerMutationFn = Apollo.MutationFunction<
  UndoLivePollAnswerMutation,
  UndoLivePollAnswerMutationVariables
>;

/**
 * __useUndoLivePollAnswerMutation__
 *
 * To run a mutation, you first call `useUndoLivePollAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndoLivePollAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undoLivePollAnswerMutation, { data, loading, error }] = useUndoLivePollAnswerMutation({
 *   variables: {
 *      livePollId: // value for 'livePollId'
 *   },
 * });
 */
export function useUndoLivePollAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UndoLivePollAnswerMutation,
    UndoLivePollAnswerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UndoLivePollAnswerMutation,
    UndoLivePollAnswerMutationVariables
  >(UndoLivePollAnswerDocument, options);
}
export type UndoLivePollAnswerMutationHookResult = ReturnType<
  typeof useUndoLivePollAnswerMutation
>;
export type UndoLivePollAnswerMutationResult =
  Apollo.MutationResult<UndoLivePollAnswerMutation>;
export type UndoLivePollAnswerMutationOptions = Apollo.BaseMutationOptions<
  UndoLivePollAnswerMutation,
  UndoLivePollAnswerMutationVariables
>;
export const UpdateLivePollStatusDocument = gql`
  mutation UpdateLivePollStatus(
    $livePollId: ID!
    $pollStatus: LivePollStatus!
  ) {
    updateLivePollStatus(livePollId: $livePollId, pollStatus: $pollStatus) {
      id
      status
    }
  }
`;
export type UpdateLivePollStatusMutationFn = Apollo.MutationFunction<
  UpdateLivePollStatusMutation,
  UpdateLivePollStatusMutationVariables
>;

/**
 * __useUpdateLivePollStatusMutation__
 *
 * To run a mutation, you first call `useUpdateLivePollStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLivePollStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLivePollStatusMutation, { data, loading, error }] = useUpdateLivePollStatusMutation({
 *   variables: {
 *      livePollId: // value for 'livePollId'
 *      pollStatus: // value for 'pollStatus'
 *   },
 * });
 */
export function useUpdateLivePollStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLivePollStatusMutation,
    UpdateLivePollStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLivePollStatusMutation,
    UpdateLivePollStatusMutationVariables
  >(UpdateLivePollStatusDocument, options);
}
export type UpdateLivePollStatusMutationHookResult = ReturnType<
  typeof useUpdateLivePollStatusMutation
>;
export type UpdateLivePollStatusMutationResult =
  Apollo.MutationResult<UpdateLivePollStatusMutation>;
export type UpdateLivePollStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateLivePollStatusMutation,
  UpdateLivePollStatusMutationVariables
>;
export const LivePollsByBoardIdDocument = gql`
  query LivePollsByBoardId($boardId: ID!) {
    livePollsByBoardId(boardId: $boardId) {
      ...LivePollFragment
    }
  }
  ${LivePollFragmentFragmentDoc}
`;

/**
 * __useLivePollsByBoardIdQuery__
 *
 * To run a query within a React component, call `useLivePollsByBoardIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLivePollsByBoardIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLivePollsByBoardIdQuery({
 *   variables: {
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useLivePollsByBoardIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    LivePollsByBoardIdQuery,
    LivePollsByBoardIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LivePollsByBoardIdQuery,
    LivePollsByBoardIdQueryVariables
  >(LivePollsByBoardIdDocument, options);
}
export function useLivePollsByBoardIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LivePollsByBoardIdQuery,
    LivePollsByBoardIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LivePollsByBoardIdQuery,
    LivePollsByBoardIdQueryVariables
  >(LivePollsByBoardIdDocument, options);
}
export type LivePollsByBoardIdQueryHookResult = ReturnType<
  typeof useLivePollsByBoardIdQuery
>;
export type LivePollsByBoardIdLazyQueryHookResult = ReturnType<
  typeof useLivePollsByBoardIdLazyQuery
>;
export type LivePollsByBoardIdQueryResult = Apollo.QueryResult<
  LivePollsByBoardIdQuery,
  LivePollsByBoardIdQueryVariables
>;
export const LivePollUpdatesByBoardIdDocument = gql`
  subscription LivePollUpdatesByBoardId($boardId: ID!, $userId: ID!) {
    livePollUpdatesByBoardId(boardId: $boardId, userId: $userId) {
      ...LivePollFragment
    }
  }
  ${LivePollFragmentFragmentDoc}
`;

/**
 * __useLivePollUpdatesByBoardIdSubscription__
 *
 * To run a query within a React component, call `useLivePollUpdatesByBoardIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLivePollUpdatesByBoardIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLivePollUpdatesByBoardIdSubscription({
 *   variables: {
 *      boardId: // value for 'boardId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useLivePollUpdatesByBoardIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    LivePollUpdatesByBoardIdSubscription,
    LivePollUpdatesByBoardIdSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    LivePollUpdatesByBoardIdSubscription,
    LivePollUpdatesByBoardIdSubscriptionVariables
  >(LivePollUpdatesByBoardIdDocument, options);
}
export type LivePollUpdatesByBoardIdSubscriptionHookResult = ReturnType<
  typeof useLivePollUpdatesByBoardIdSubscription
>;
export type LivePollUpdatesByBoardIdSubscriptionResult =
  Apollo.SubscriptionResult<LivePollUpdatesByBoardIdSubscription>;
export const FlowMetricsDocument = gql`
  query FlowMetrics(
    $teamId: ID!
    $startDate: Date!
    $endDate: Date!
    $utcOffsetMs: Int!
  ) {
    flowMetrics(
      teamId: $teamId
      startDate: $startDate
      endDate: $endDate
      utcOffsetMs: $utcOffsetMs
    ) {
      isProcessing
      workItemsPerDay
      cycleTimeMs
      leadTimeMs
      predictabilityQuality
      lastIngestionAt
      statuses {
        name
        timeSpentMs
      }
      cycleTimeHistogram {
        value
        from
        to
      }
      insight {
        __typename
        ... on FlowInsightForecast {
          itemsOverCycleTime {
            slug
            wipTimeMs
            externalUrl
          }
          itemsNearCycleTime {
            slug
            wipTimeMs
            externalUrl
          }
          cycleTimeMs
        }
      }
    }
  }
`;

/**
 * __useFlowMetricsQuery__
 *
 * To run a query within a React component, call `useFlowMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlowMetricsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      utcOffsetMs: // value for 'utcOffsetMs'
 *   },
 * });
 */
export function useFlowMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FlowMetricsQuery,
    FlowMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FlowMetricsQuery, FlowMetricsQueryVariables>(
    FlowMetricsDocument,
    options
  );
}
export function useFlowMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FlowMetricsQuery,
    FlowMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FlowMetricsQuery, FlowMetricsQueryVariables>(
    FlowMetricsDocument,
    options
  );
}
export type FlowMetricsQueryHookResult = ReturnType<typeof useFlowMetricsQuery>;
export type FlowMetricsLazyQueryHookResult = ReturnType<
  typeof useFlowMetricsLazyQuery
>;
export type FlowMetricsQueryResult = Apollo.QueryResult<
  FlowMetricsQuery,
  FlowMetricsQueryVariables
>;
export const InFlightWorkItemsDocument = gql`
  query InFlightWorkItems($teamId: ID!) {
    inFlightWorkItems(teamId: $teamId) {
      workItems {
        ...InFlightWorkItemFragment
      }
      insight {
        __typename
        ... on FlowInsightForecast {
          itemsOverCycleTime {
            slug
            wipTimeMs
            externalUrl
          }
          itemsNearCycleTime {
            slug
            wipTimeMs
            externalUrl
          }
          cycleTimeMs
        }
      }
      statusNames
    }
  }
  ${InFlightWorkItemFragmentFragmentDoc}
`;

/**
 * __useInFlightWorkItemsQuery__
 *
 * To run a query within a React component, call `useInFlightWorkItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInFlightWorkItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInFlightWorkItemsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useInFlightWorkItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    InFlightWorkItemsQuery,
    InFlightWorkItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InFlightWorkItemsQuery,
    InFlightWorkItemsQueryVariables
  >(InFlightWorkItemsDocument, options);
}
export function useInFlightWorkItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InFlightWorkItemsQuery,
    InFlightWorkItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InFlightWorkItemsQuery,
    InFlightWorkItemsQueryVariables
  >(InFlightWorkItemsDocument, options);
}
export type InFlightWorkItemsQueryHookResult = ReturnType<
  typeof useInFlightWorkItemsQuery
>;
export type InFlightWorkItemsLazyQueryHookResult = ReturnType<
  typeof useInFlightWorkItemsLazyQuery
>;
export type InFlightWorkItemsQueryResult = Apollo.QueryResult<
  InFlightWorkItemsQuery,
  InFlightWorkItemsQueryVariables
>;
export const SourceControlMetricsDocument = gql`
  query SourceControlMetrics(
    $teamId: ID!
    $startDate: Date!
    $endDate: Date!
    $utcOffsetMs: Int!
  ) {
    sourceControlMetrics(
      teamId: $teamId
      startDate: $startDate
      endDate: $endDate
      utcOffsetMs: $utcOffsetMs
    ) {
      isProcessing
      prsPerDay
      cycleTimeMs
      pickupTimeMs
      approvalTimeMs
      mergeTimeMs
      consistencyQuality
      reviewLeaderboard {
        userName
        count
      }
      workItemLeaderboard {
        userName
        count
      }
      insight {
        __typename
        ... on SourceControlInsightReviewPercent {
          picUrl
          userName
          value
        }
      }
    }
  }
`;

/**
 * __useSourceControlMetricsQuery__
 *
 * To run a query within a React component, call `useSourceControlMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourceControlMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourceControlMetricsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      utcOffsetMs: // value for 'utcOffsetMs'
 *   },
 * });
 */
export function useSourceControlMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SourceControlMetricsQuery,
    SourceControlMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SourceControlMetricsQuery,
    SourceControlMetricsQueryVariables
  >(SourceControlMetricsDocument, options);
}
export function useSourceControlMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SourceControlMetricsQuery,
    SourceControlMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SourceControlMetricsQuery,
    SourceControlMetricsQueryVariables
  >(SourceControlMetricsDocument, options);
}
export type SourceControlMetricsQueryHookResult = ReturnType<
  typeof useSourceControlMetricsQuery
>;
export type SourceControlMetricsLazyQueryHookResult = ReturnType<
  typeof useSourceControlMetricsLazyQuery
>;
export type SourceControlMetricsQueryResult = Apollo.QueryResult<
  SourceControlMetricsQuery,
  SourceControlMetricsQueryVariables
>;
export const TeamMetricsOverTimeDocument = gql`
  query TeamMetricsOverTime(
    $teamId: ID!
    $startDate: Date!
    $endDate: Date!
    $utcOffsetMs: Int!
    $timeWindow: TimeWindow!
  ) {
    teamMetricsOverTime(
      teamId: $teamId
      startDate: $startDate
      endDate: $endDate
      utcOffsetMs: $utcOffsetMs
      timeWindow: $timeWindow
    ) {
      pullRequestsOpen {
        ...TimeSeriesFragment
      }
      pullRequestsMergedWithoutReview {
        ...TimeSeriesFragment
      }
    }
  }
  ${TimeSeriesFragmentFragmentDoc}
`;

/**
 * __useTeamMetricsOverTimeQuery__
 *
 * To run a query within a React component, call `useTeamMetricsOverTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMetricsOverTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMetricsOverTimeQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      utcOffsetMs: // value for 'utcOffsetMs'
 *      timeWindow: // value for 'timeWindow'
 *   },
 * });
 */
export function useTeamMetricsOverTimeQuery(
  baseOptions: Apollo.QueryHookOptions<
    TeamMetricsOverTimeQuery,
    TeamMetricsOverTimeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TeamMetricsOverTimeQuery,
    TeamMetricsOverTimeQueryVariables
  >(TeamMetricsOverTimeDocument, options);
}
export function useTeamMetricsOverTimeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamMetricsOverTimeQuery,
    TeamMetricsOverTimeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TeamMetricsOverTimeQuery,
    TeamMetricsOverTimeQueryVariables
  >(TeamMetricsOverTimeDocument, options);
}
export type TeamMetricsOverTimeQueryHookResult = ReturnType<
  typeof useTeamMetricsOverTimeQuery
>;
export type TeamMetricsOverTimeLazyQueryHookResult = ReturnType<
  typeof useTeamMetricsOverTimeLazyQuery
>;
export type TeamMetricsOverTimeQueryResult = Apollo.QueryResult<
  TeamMetricsOverTimeQuery,
  TeamMetricsOverTimeQueryVariables
>;
export const CancelSubscriptionDocument = gql`
  mutation CancelSubscription($organizationId: String!) {
    cancelSubscription(organizationId: $organizationId) {
      id
      status
      userCount
      prepaidSeatCount
      isExceedingSeats
      trialEndsAt
      plan {
        ...PlanFragment
      }
    }
  }
  ${PlanFragmentFragmentDoc}
`;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >(CancelSubscriptionDocument, options);
}
export type CancelSubscriptionMutationHookResult = ReturnType<
  typeof useCancelSubscriptionMutation
>;
export type CancelSubscriptionMutationResult =
  Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>;
export const CreateOrganizationDocument = gql`
  mutation CreateOrganization($orgName: String!) {
    createOrganization(accountName: $orgName) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useCreateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >(CreateOrganizationDocument, options);
}
export type CreateOrganizationMutationHookResult = ReturnType<
  typeof useCreateOrganizationMutation
>;
export type CreateOrganizationMutationResult =
  Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;
export const RemoveOrgMembershipDocument = gql`
  mutation RemoveOrgMembership($userId: ID!, $organizationId: ID!) {
    removeMembership(userId: $userId, organizationId: $organizationId) {
      ...OrgMembershipFragment
    }
  }
  ${OrgMembershipFragmentFragmentDoc}
`;
export type RemoveOrgMembershipMutationFn = Apollo.MutationFunction<
  RemoveOrgMembershipMutation,
  RemoveOrgMembershipMutationVariables
>;

/**
 * __useRemoveOrgMembershipMutation__
 *
 * To run a mutation, you first call `useRemoveOrgMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrgMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrgMembershipMutation, { data, loading, error }] = useRemoveOrgMembershipMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useRemoveOrgMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveOrgMembershipMutation,
    RemoveOrgMembershipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveOrgMembershipMutation,
    RemoveOrgMembershipMutationVariables
  >(RemoveOrgMembershipDocument, options);
}
export type RemoveOrgMembershipMutationHookResult = ReturnType<
  typeof useRemoveOrgMembershipMutation
>;
export type RemoveOrgMembershipMutationResult =
  Apollo.MutationResult<RemoveOrgMembershipMutation>;
export type RemoveOrgMembershipMutationOptions = Apollo.BaseMutationOptions<
  RemoveOrgMembershipMutation,
  RemoveOrgMembershipMutationVariables
>;
export const SubmitOnboardingSurveyDocument = gql`
  mutation SubmitOnboardingSurvey($questions: [QuestionAnswerInput]!) {
    organizationMoreInfo(questions: $questions) {
      id
    }
  }
`;
export type SubmitOnboardingSurveyMutationFn = Apollo.MutationFunction<
  SubmitOnboardingSurveyMutation,
  SubmitOnboardingSurveyMutationVariables
>;

/**
 * __useSubmitOnboardingSurveyMutation__
 *
 * To run a mutation, you first call `useSubmitOnboardingSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitOnboardingSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitOnboardingSurveyMutation, { data, loading, error }] = useSubmitOnboardingSurveyMutation({
 *   variables: {
 *      questions: // value for 'questions'
 *   },
 * });
 */
export function useSubmitOnboardingSurveyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitOnboardingSurveyMutation,
    SubmitOnboardingSurveyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitOnboardingSurveyMutation,
    SubmitOnboardingSurveyMutationVariables
  >(SubmitOnboardingSurveyDocument, options);
}
export type SubmitOnboardingSurveyMutationHookResult = ReturnType<
  typeof useSubmitOnboardingSurveyMutation
>;
export type SubmitOnboardingSurveyMutationResult =
  Apollo.MutationResult<SubmitOnboardingSurveyMutation>;
export type SubmitOnboardingSurveyMutationOptions = Apollo.BaseMutationOptions<
  SubmitOnboardingSurveyMutation,
  SubmitOnboardingSurveyMutationVariables
>;
export const BillingDetailsDocument = gql`
  query BillingDetails($organizationId: String!) {
    billingDetails(organizationId: $organizationId) {
      currentPeriodEndAt
      canceled
      nextBillingDate
      subscriptionStartsAt
    }
  }
`;

/**
 * __useBillingDetailsQuery__
 *
 * To run a query within a React component, call `useBillingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingDetailsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useBillingDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BillingDetailsQuery,
    BillingDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BillingDetailsQuery, BillingDetailsQueryVariables>(
    BillingDetailsDocument,
    options
  );
}
export function useBillingDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BillingDetailsQuery,
    BillingDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BillingDetailsQuery, BillingDetailsQueryVariables>(
    BillingDetailsDocument,
    options
  );
}
export type BillingDetailsQueryHookResult = ReturnType<
  typeof useBillingDetailsQuery
>;
export type BillingDetailsLazyQueryHookResult = ReturnType<
  typeof useBillingDetailsLazyQuery
>;
export type BillingDetailsQueryResult = Apollo.QueryResult<
  BillingDetailsQuery,
  BillingDetailsQueryVariables
>;
export const AddParkingLotListDocument = gql`
  mutation AddParkingLotList($boardId: ID!, $parkingLotItemIds: [ID!]) {
    addParkingLotList(
      boardId: $boardId
      parkingLotItemIds: $parkingLotItemIds
    ) {
      ...BoardFragment
    }
  }
  ${BoardFragmentFragmentDoc}
`;
export type AddParkingLotListMutationFn = Apollo.MutationFunction<
  AddParkingLotListMutation,
  AddParkingLotListMutationVariables
>;

/**
 * __useAddParkingLotListMutation__
 *
 * To run a mutation, you first call `useAddParkingLotListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddParkingLotListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addParkingLotListMutation, { data, loading, error }] = useAddParkingLotListMutation({
 *   variables: {
 *      boardId: // value for 'boardId'
 *      parkingLotItemIds: // value for 'parkingLotItemIds'
 *   },
 * });
 */
export function useAddParkingLotListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddParkingLotListMutation,
    AddParkingLotListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddParkingLotListMutation,
    AddParkingLotListMutationVariables
  >(AddParkingLotListDocument, options);
}
export type AddParkingLotListMutationHookResult = ReturnType<
  typeof useAddParkingLotListMutation
>;
export type AddParkingLotListMutationResult =
  Apollo.MutationResult<AddParkingLotListMutation>;
export type AddParkingLotListMutationOptions = Apollo.BaseMutationOptions<
  AddParkingLotListMutation,
  AddParkingLotListMutationVariables
>;
export const ArchiveParkingLotItemDocument = gql`
  mutation ArchiveParkingLotItem($itemId: ID!) {
    archiveParkingLotItem(id: $itemId) {
      ...ParkingLotItemFragment
    }
  }
  ${ParkingLotItemFragmentFragmentDoc}
`;
export type ArchiveParkingLotItemMutationFn = Apollo.MutationFunction<
  ArchiveParkingLotItemMutation,
  ArchiveParkingLotItemMutationVariables
>;

/**
 * __useArchiveParkingLotItemMutation__
 *
 * To run a mutation, you first call `useArchiveParkingLotItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveParkingLotItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveParkingLotItemMutation, { data, loading, error }] = useArchiveParkingLotItemMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useArchiveParkingLotItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveParkingLotItemMutation,
    ArchiveParkingLotItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveParkingLotItemMutation,
    ArchiveParkingLotItemMutationVariables
  >(ArchiveParkingLotItemDocument, options);
}
export type ArchiveParkingLotItemMutationHookResult = ReturnType<
  typeof useArchiveParkingLotItemMutation
>;
export type ArchiveParkingLotItemMutationResult =
  Apollo.MutationResult<ArchiveParkingLotItemMutation>;
export type ArchiveParkingLotItemMutationOptions = Apollo.BaseMutationOptions<
  ArchiveParkingLotItemMutation,
  ArchiveParkingLotItemMutationVariables
>;
export const CreateParkingLotItemDocument = gql`
  mutation CreateParkingLotItem($teamId: ID!, $text: String!) {
    createParkingLotCard(teamId: $teamId, text: $text) {
      ...ParkingLotItemFragment
    }
  }
  ${ParkingLotItemFragmentFragmentDoc}
`;
export type CreateParkingLotItemMutationFn = Apollo.MutationFunction<
  CreateParkingLotItemMutation,
  CreateParkingLotItemMutationVariables
>;

/**
 * __useCreateParkingLotItemMutation__
 *
 * To run a mutation, you first call `useCreateParkingLotItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateParkingLotItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createParkingLotItemMutation, { data, loading, error }] = useCreateParkingLotItemMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useCreateParkingLotItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateParkingLotItemMutation,
    CreateParkingLotItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateParkingLotItemMutation,
    CreateParkingLotItemMutationVariables
  >(CreateParkingLotItemDocument, options);
}
export type CreateParkingLotItemMutationHookResult = ReturnType<
  typeof useCreateParkingLotItemMutation
>;
export type CreateParkingLotItemMutationResult =
  Apollo.MutationResult<CreateParkingLotItemMutation>;
export type CreateParkingLotItemMutationOptions = Apollo.BaseMutationOptions<
  CreateParkingLotItemMutation,
  CreateParkingLotItemMutationVariables
>;
export const CreateParkingLotItemFromCardIdDocument = gql`
  mutation CreateParkingLotItemFromCardId(
    $boardId: ID!
    $teamId: ID!
    $ownerId: ID
    $cardId: ID!
  ) {
    createParkingLotItemFromCardId(
      boardId: $boardId
      teamId: $teamId
      ownerId: $ownerId
      cardId: $cardId
    ) {
      ...ParkingLotItemFragment
    }
  }
  ${ParkingLotItemFragmentFragmentDoc}
`;
export type CreateParkingLotItemFromCardIdMutationFn = Apollo.MutationFunction<
  CreateParkingLotItemFromCardIdMutation,
  CreateParkingLotItemFromCardIdMutationVariables
>;

/**
 * __useCreateParkingLotItemFromCardIdMutation__
 *
 * To run a mutation, you first call `useCreateParkingLotItemFromCardIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateParkingLotItemFromCardIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createParkingLotItemFromCardIdMutation, { data, loading, error }] = useCreateParkingLotItemFromCardIdMutation({
 *   variables: {
 *      boardId: // value for 'boardId'
 *      teamId: // value for 'teamId'
 *      ownerId: // value for 'ownerId'
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useCreateParkingLotItemFromCardIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateParkingLotItemFromCardIdMutation,
    CreateParkingLotItemFromCardIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateParkingLotItemFromCardIdMutation,
    CreateParkingLotItemFromCardIdMutationVariables
  >(CreateParkingLotItemFromCardIdDocument, options);
}
export type CreateParkingLotItemFromCardIdMutationHookResult = ReturnType<
  typeof useCreateParkingLotItemFromCardIdMutation
>;
export type CreateParkingLotItemFromCardIdMutationResult =
  Apollo.MutationResult<CreateParkingLotItemFromCardIdMutation>;
export type CreateParkingLotItemFromCardIdMutationOptions =
  Apollo.BaseMutationOptions<
    CreateParkingLotItemFromCardIdMutation,
    CreateParkingLotItemFromCardIdMutationVariables
  >;
export const PromoteParkingLotItemDocument = gql`
  mutation PromoteParkingLotItem($parkingLotItemId: ID!, $programId: ID!) {
    copyParkingLotItemToTeam(itemId: $parkingLotItemId, teamId: $programId) {
      ...ParkingLotItemFragment
    }
  }
  ${ParkingLotItemFragmentFragmentDoc}
`;
export type PromoteParkingLotItemMutationFn = Apollo.MutationFunction<
  PromoteParkingLotItemMutation,
  PromoteParkingLotItemMutationVariables
>;

/**
 * __usePromoteParkingLotItemMutation__
 *
 * To run a mutation, you first call `usePromoteParkingLotItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromoteParkingLotItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promoteParkingLotItemMutation, { data, loading, error }] = usePromoteParkingLotItemMutation({
 *   variables: {
 *      parkingLotItemId: // value for 'parkingLotItemId'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function usePromoteParkingLotItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PromoteParkingLotItemMutation,
    PromoteParkingLotItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PromoteParkingLotItemMutation,
    PromoteParkingLotItemMutationVariables
  >(PromoteParkingLotItemDocument, options);
}
export type PromoteParkingLotItemMutationHookResult = ReturnType<
  typeof usePromoteParkingLotItemMutation
>;
export type PromoteParkingLotItemMutationResult =
  Apollo.MutationResult<PromoteParkingLotItemMutation>;
export type PromoteParkingLotItemMutationOptions = Apollo.BaseMutationOptions<
  PromoteParkingLotItemMutation,
  PromoteParkingLotItemMutationVariables
>;
export const UpdateParkingLotItemTextDocument = gql`
  mutation UpdateParkingLotItemText($itemId: ID!, $text: String!) {
    changeParkingLotItemText(itemId: $itemId, text: $text) {
      ...ParkingLotItemFragment
    }
  }
  ${ParkingLotItemFragmentFragmentDoc}
`;
export type UpdateParkingLotItemTextMutationFn = Apollo.MutationFunction<
  UpdateParkingLotItemTextMutation,
  UpdateParkingLotItemTextMutationVariables
>;

/**
 * __useUpdateParkingLotItemTextMutation__
 *
 * To run a mutation, you first call `useUpdateParkingLotItemTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParkingLotItemTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParkingLotItemTextMutation, { data, loading, error }] = useUpdateParkingLotItemTextMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useUpdateParkingLotItemTextMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateParkingLotItemTextMutation,
    UpdateParkingLotItemTextMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateParkingLotItemTextMutation,
    UpdateParkingLotItemTextMutationVariables
  >(UpdateParkingLotItemTextDocument, options);
}
export type UpdateParkingLotItemTextMutationHookResult = ReturnType<
  typeof useUpdateParkingLotItemTextMutation
>;
export type UpdateParkingLotItemTextMutationResult =
  Apollo.MutationResult<UpdateParkingLotItemTextMutation>;
export type UpdateParkingLotItemTextMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateParkingLotItemTextMutation,
    UpdateParkingLotItemTextMutationVariables
  >;
export const ParkingLotByTeamIdAndTypeDocument = gql`
  query ParkingLotByTeamIdAndType($teamId: ID!) {
    parkingLotByTeamId(teamId: $teamId) {
      ...ParkingLotItemFragment
    }
  }
  ${ParkingLotItemFragmentFragmentDoc}
`;

/**
 * __useParkingLotByTeamIdAndTypeQuery__
 *
 * To run a query within a React component, call `useParkingLotByTeamIdAndTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useParkingLotByTeamIdAndTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParkingLotByTeamIdAndTypeQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useParkingLotByTeamIdAndTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    ParkingLotByTeamIdAndTypeQuery,
    ParkingLotByTeamIdAndTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ParkingLotByTeamIdAndTypeQuery,
    ParkingLotByTeamIdAndTypeQueryVariables
  >(ParkingLotByTeamIdAndTypeDocument, options);
}
export function useParkingLotByTeamIdAndTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ParkingLotByTeamIdAndTypeQuery,
    ParkingLotByTeamIdAndTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ParkingLotByTeamIdAndTypeQuery,
    ParkingLotByTeamIdAndTypeQueryVariables
  >(ParkingLotByTeamIdAndTypeDocument, options);
}
export type ParkingLotByTeamIdAndTypeQueryHookResult = ReturnType<
  typeof useParkingLotByTeamIdAndTypeQuery
>;
export type ParkingLotByTeamIdAndTypeLazyQueryHookResult = ReturnType<
  typeof useParkingLotByTeamIdAndTypeLazyQuery
>;
export type ParkingLotByTeamIdAndTypeQueryResult = Apollo.QueryResult<
  ParkingLotByTeamIdAndTypeQuery,
  ParkingLotByTeamIdAndTypeQueryVariables
>;
export const ParkingLotCardCreatedDocument = gql`
  subscription ParkingLotCardCreated($teamId: ID!) {
    parkingLotCardCreated(teamId: $teamId) {
      ...ParkingLotItemFragment
    }
  }
  ${ParkingLotItemFragmentFragmentDoc}
`;

/**
 * __useParkingLotCardCreatedSubscription__
 *
 * To run a query within a React component, call `useParkingLotCardCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useParkingLotCardCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParkingLotCardCreatedSubscription({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useParkingLotCardCreatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ParkingLotCardCreatedSubscription,
    ParkingLotCardCreatedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ParkingLotCardCreatedSubscription,
    ParkingLotCardCreatedSubscriptionVariables
  >(ParkingLotCardCreatedDocument, options);
}
export type ParkingLotCardCreatedSubscriptionHookResult = ReturnType<
  typeof useParkingLotCardCreatedSubscription
>;
export type ParkingLotCardCreatedSubscriptionResult =
  Apollo.SubscriptionResult<ParkingLotCardCreatedSubscription>;
export const ParkingLotCardUpdatedDocument = gql`
  subscription ParkingLotCardUpdated($teamId: ID!) {
    parkingLotCardUpdated(teamId: $teamId) {
      ...ParkingLotItemFragment
    }
  }
  ${ParkingLotItemFragmentFragmentDoc}
`;

/**
 * __useParkingLotCardUpdatedSubscription__
 *
 * To run a query within a React component, call `useParkingLotCardUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useParkingLotCardUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParkingLotCardUpdatedSubscription({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useParkingLotCardUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ParkingLotCardUpdatedSubscription,
    ParkingLotCardUpdatedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ParkingLotCardUpdatedSubscription,
    ParkingLotCardUpdatedSubscriptionVariables
  >(ParkingLotCardUpdatedDocument, options);
}
export type ParkingLotCardUpdatedSubscriptionHookResult = ReturnType<
  typeof useParkingLotCardUpdatedSubscription
>;
export type ParkingLotCardUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<ParkingLotCardUpdatedSubscription>;
export const ArchiveReportDocument = gql`
  mutation ArchiveReport($reportId: ID!) {
    archiveReport(reportId: $reportId) {
      id
      archived
    }
  }
`;
export type ArchiveReportMutationFn = Apollo.MutationFunction<
  ArchiveReportMutation,
  ArchiveReportMutationVariables
>;

/**
 * __useArchiveReportMutation__
 *
 * To run a mutation, you first call `useArchiveReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveReportMutation, { data, loading, error }] = useArchiveReportMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useArchiveReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveReportMutation,
    ArchiveReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveReportMutation,
    ArchiveReportMutationVariables
  >(ArchiveReportDocument, options);
}
export type ArchiveReportMutationHookResult = ReturnType<
  typeof useArchiveReportMutation
>;
export type ArchiveReportMutationResult =
  Apollo.MutationResult<ArchiveReportMutation>;
export type ArchiveReportMutationOptions = Apollo.BaseMutationOptions<
  ArchiveReportMutation,
  ArchiveReportMutationVariables
>;
export const GenerateReportDocument = gql`
  mutation GenerateReport($input: GenerateReportInput!) {
    generateReport(input: $input) {
      id
      type
      status
      createdAt
      updatedAt
      teamId
    }
  }
`;
export type GenerateReportMutationFn = Apollo.MutationFunction<
  GenerateReportMutation,
  GenerateReportMutationVariables
>;

/**
 * __useGenerateReportMutation__
 *
 * To run a mutation, you first call `useGenerateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateReportMutation, { data, loading, error }] = useGenerateReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateReportMutation,
    GenerateReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateReportMutation,
    GenerateReportMutationVariables
  >(GenerateReportDocument, options);
}
export type GenerateReportMutationHookResult = ReturnType<
  typeof useGenerateReportMutation
>;
export type GenerateReportMutationResult =
  Apollo.MutationResult<GenerateReportMutation>;
export type GenerateReportMutationOptions = Apollo.BaseMutationOptions<
  GenerateReportMutation,
  GenerateReportMutationVariables
>;
export const ReportDetailsDocument = gql`
  query ReportDetails($reportId: ID!) {
    reportDetails(reportId: $reportId) {
      id
      type
      status
      createdAt
      updatedAt
      completedAt
      inputData
      reportData
    }
  }
`;

/**
 * __useReportDetailsQuery__
 *
 * To run a query within a React component, call `useReportDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportDetailsQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useReportDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportDetailsQuery,
    ReportDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReportDetailsQuery, ReportDetailsQueryVariables>(
    ReportDetailsDocument,
    options
  );
}
export function useReportDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportDetailsQuery,
    ReportDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReportDetailsQuery, ReportDetailsQueryVariables>(
    ReportDetailsDocument,
    options
  );
}
export type ReportDetailsQueryHookResult = ReturnType<
  typeof useReportDetailsQuery
>;
export type ReportDetailsLazyQueryHookResult = ReturnType<
  typeof useReportDetailsLazyQuery
>;
export type ReportDetailsQueryResult = Apollo.QueryResult<
  ReportDetailsQuery,
  ReportDetailsQueryVariables
>;
export const ReportsByTeamIdDocument = gql`
  query ReportsByTeamId($teamId: ID!) {
    reportsByTeamId(teamId: $teamId) {
      id
      type
      status
      createdAt
      updatedAt
      completedAt
      inputData
      archived
    }
  }
`;

/**
 * __useReportsByTeamIdQuery__
 *
 * To run a query within a React component, call `useReportsByTeamIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsByTeamIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsByTeamIdQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useReportsByTeamIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReportsByTeamIdQuery,
    ReportsByTeamIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReportsByTeamIdQuery, ReportsByTeamIdQueryVariables>(
    ReportsByTeamIdDocument,
    options
  );
}
export function useReportsByTeamIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportsByTeamIdQuery,
    ReportsByTeamIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReportsByTeamIdQuery,
    ReportsByTeamIdQueryVariables
  >(ReportsByTeamIdDocument, options);
}
export type ReportsByTeamIdQueryHookResult = ReturnType<
  typeof useReportsByTeamIdQuery
>;
export type ReportsByTeamIdLazyQueryHookResult = ReturnType<
  typeof useReportsByTeamIdLazyQuery
>;
export type ReportsByTeamIdQueryResult = Apollo.QueryResult<
  ReportsByTeamIdQuery,
  ReportsByTeamIdQueryVariables
>;
export const AddTeamToProgramDocument = gql`
  mutation AddTeamToProgram($teamId: ID!, $programId: ID!) {
    addTeamToProgram(teamId: $teamId, programId: $programId) {
      id
      name
      children {
        id
        name
      }
    }
  }
`;
export type AddTeamToProgramMutationFn = Apollo.MutationFunction<
  AddTeamToProgramMutation,
  AddTeamToProgramMutationVariables
>;

/**
 * __useAddTeamToProgramMutation__
 *
 * To run a mutation, you first call `useAddTeamToProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTeamToProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTeamToProgramMutation, { data, loading, error }] = useAddTeamToProgramMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useAddTeamToProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTeamToProgramMutation,
    AddTeamToProgramMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddTeamToProgramMutation,
    AddTeamToProgramMutationVariables
  >(AddTeamToProgramDocument, options);
}
export type AddTeamToProgramMutationHookResult = ReturnType<
  typeof useAddTeamToProgramMutation
>;
export type AddTeamToProgramMutationResult =
  Apollo.MutationResult<AddTeamToProgramMutation>;
export type AddTeamToProgramMutationOptions = Apollo.BaseMutationOptions<
  AddTeamToProgramMutation,
  AddTeamToProgramMutationVariables
>;
export const ArchiveTeamDocument = gql`
  mutation ArchiveTeam($teamId: ID!) {
    archiveTeam(teamId: $teamId) {
      ...TeamFragment
    }
  }
  ${TeamFragmentFragmentDoc}
`;
export type ArchiveTeamMutationFn = Apollo.MutationFunction<
  ArchiveTeamMutation,
  ArchiveTeamMutationVariables
>;

/**
 * __useArchiveTeamMutation__
 *
 * To run a mutation, you first call `useArchiveTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveTeamMutation, { data, loading, error }] = useArchiveTeamMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useArchiveTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveTeamMutation,
    ArchiveTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveTeamMutation, ArchiveTeamMutationVariables>(
    ArchiveTeamDocument,
    options
  );
}
export type ArchiveTeamMutationHookResult = ReturnType<
  typeof useArchiveTeamMutation
>;
export type ArchiveTeamMutationResult =
  Apollo.MutationResult<ArchiveTeamMutation>;
export type ArchiveTeamMutationOptions = Apollo.BaseMutationOptions<
  ArchiveTeamMutation,
  ArchiveTeamMutationVariables
>;
export const CreateTeamDocument = gql`
  mutation CreateTeam($teamInput: TeamInput!) {
    createTeam(teamInput: $teamInput) {
      ...TeamFragment
    }
  }
  ${TeamFragmentFragmentDoc}
`;
export type CreateTeamMutationFn = Apollo.MutationFunction<
  CreateTeamMutation,
  CreateTeamMutationVariables
>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      teamInput: // value for 'teamInput'
 *   },
 * });
 */
export function useCreateTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTeamMutation,
    CreateTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(
    CreateTeamDocument,
    options
  );
}
export type CreateTeamMutationHookResult = ReturnType<
  typeof useCreateTeamMutation
>;
export type CreateTeamMutationResult =
  Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<
  CreateTeamMutation,
  CreateTeamMutationVariables
>;
export const InviteToTeamByEmailDocument = gql`
  mutation InviteToTeamByEmail($teamId: ID!, $email: String!) {
    inviteToTeamByEmail(teamId: $teamId, email: $email) {
      ...TeamFragment
    }
  }
  ${TeamFragmentFragmentDoc}
`;
export type InviteToTeamByEmailMutationFn = Apollo.MutationFunction<
  InviteToTeamByEmailMutation,
  InviteToTeamByEmailMutationVariables
>;

/**
 * __useInviteToTeamByEmailMutation__
 *
 * To run a mutation, you first call `useInviteToTeamByEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteToTeamByEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteToTeamByEmailMutation, { data, loading, error }] = useInviteToTeamByEmailMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useInviteToTeamByEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteToTeamByEmailMutation,
    InviteToTeamByEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteToTeamByEmailMutation,
    InviteToTeamByEmailMutationVariables
  >(InviteToTeamByEmailDocument, options);
}
export type InviteToTeamByEmailMutationHookResult = ReturnType<
  typeof useInviteToTeamByEmailMutation
>;
export type InviteToTeamByEmailMutationResult =
  Apollo.MutationResult<InviteToTeamByEmailMutation>;
export type InviteToTeamByEmailMutationOptions = Apollo.BaseMutationOptions<
  InviteToTeamByEmailMutation,
  InviteToTeamByEmailMutationVariables
>;
export const RemoveTeamFromProgramDocument = gql`
  mutation RemoveTeamFromProgram($teamId: ID!, $programId: ID!) {
    leaveProgram(teamId: $teamId, programId: $programId) {
      id
      name
      children {
        id
        name
      }
    }
  }
`;
export type RemoveTeamFromProgramMutationFn = Apollo.MutationFunction<
  RemoveTeamFromProgramMutation,
  RemoveTeamFromProgramMutationVariables
>;

/**
 * __useRemoveTeamFromProgramMutation__
 *
 * To run a mutation, you first call `useRemoveTeamFromProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTeamFromProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTeamFromProgramMutation, { data, loading, error }] = useRemoveTeamFromProgramMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useRemoveTeamFromProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveTeamFromProgramMutation,
    RemoveTeamFromProgramMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveTeamFromProgramMutation,
    RemoveTeamFromProgramMutationVariables
  >(RemoveTeamFromProgramDocument, options);
}
export type RemoveTeamFromProgramMutationHookResult = ReturnType<
  typeof useRemoveTeamFromProgramMutation
>;
export type RemoveTeamFromProgramMutationResult =
  Apollo.MutationResult<RemoveTeamFromProgramMutation>;
export type RemoveTeamFromProgramMutationOptions = Apollo.BaseMutationOptions<
  RemoveTeamFromProgramMutation,
  RemoveTeamFromProgramMutationVariables
>;
export const UpdateTeamDocument = gql`
  mutation UpdateTeam($teamId: ID!, $userId: String!, $fields: JSON!) {
    updateTeam(teamId: $teamId, userId: $userId, fields: $fields) {
      ...TeamFragment
    }
  }
  ${TeamFragmentFragmentDoc}
`;
export type UpdateTeamMutationFn = Apollo.MutationFunction<
  UpdateTeamMutation,
  UpdateTeamMutationVariables
>;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      userId: // value for 'userId'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useUpdateTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTeamMutation,
    UpdateTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(
    UpdateTeamDocument,
    options
  );
}
export type UpdateTeamMutationHookResult = ReturnType<
  typeof useUpdateTeamMutation
>;
export type UpdateTeamMutationResult =
  Apollo.MutationResult<UpdateTeamMutation>;
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeamMutation,
  UpdateTeamMutationVariables
>;
export const PaginatedTeamBoardsDocument = gql`
  query PaginatedTeamBoards(
    $teamId: String!
    $archived: Boolean!
    $offset: Int!
    $limit: Int!
  ) {
    teamBoardsPagination(
      teamId: $teamId
      archived: $archived
      offset: $offset
      limit: $limit
    ) {
      totalSize
      boards {
        ...BasicBoard
      }
    }
  }
  ${BasicBoardFragmentDoc}
`;

/**
 * __usePaginatedTeamBoardsQuery__
 *
 * To run a query within a React component, call `usePaginatedTeamBoardsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedTeamBoardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedTeamBoardsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      archived: // value for 'archived'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePaginatedTeamBoardsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaginatedTeamBoardsQuery,
    PaginatedTeamBoardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PaginatedTeamBoardsQuery,
    PaginatedTeamBoardsQueryVariables
  >(PaginatedTeamBoardsDocument, options);
}
export function usePaginatedTeamBoardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedTeamBoardsQuery,
    PaginatedTeamBoardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PaginatedTeamBoardsQuery,
    PaginatedTeamBoardsQueryVariables
  >(PaginatedTeamBoardsDocument, options);
}
export type PaginatedTeamBoardsQueryHookResult = ReturnType<
  typeof usePaginatedTeamBoardsQuery
>;
export type PaginatedTeamBoardsLazyQueryHookResult = ReturnType<
  typeof usePaginatedTeamBoardsLazyQuery
>;
export type PaginatedTeamBoardsQueryResult = Apollo.QueryResult<
  PaginatedTeamBoardsQuery,
  PaginatedTeamBoardsQueryVariables
>;
export const SearchTeamMembersDocument = gql`
  query SearchTeamMembers($teamId: String!, $term: String) {
    searchTeamMembers(teamId: $teamId, term: $term) {
      ...MinimalUser
    }
  }
  ${MinimalUserFragmentDoc}
`;

/**
 * __useSearchTeamMembersQuery__
 *
 * To run a query within a React component, call `useSearchTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTeamMembersQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      term: // value for 'term'
 *   },
 * });
 */
export function useSearchTeamMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchTeamMembersQuery,
    SearchTeamMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchTeamMembersQuery,
    SearchTeamMembersQueryVariables
  >(SearchTeamMembersDocument, options);
}
export function useSearchTeamMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchTeamMembersQuery,
    SearchTeamMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchTeamMembersQuery,
    SearchTeamMembersQueryVariables
  >(SearchTeamMembersDocument, options);
}
export type SearchTeamMembersQueryHookResult = ReturnType<
  typeof useSearchTeamMembersQuery
>;
export type SearchTeamMembersLazyQueryHookResult = ReturnType<
  typeof useSearchTeamMembersLazyQuery
>;
export type SearchTeamMembersQueryResult = Apollo.QueryResult<
  SearchTeamMembersQuery,
  SearchTeamMembersQueryVariables
>;
export const TeamByIdDocument = gql`
  query TeamById($teamId: ID!) {
    teamById(teamId: $teamId) {
      ...TeamFragment
    }
  }
  ${TeamFragmentFragmentDoc}
`;

/**
 * __useTeamByIdQuery__
 *
 * To run a query within a React component, call `useTeamByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamByIdQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamByIdQuery(
  baseOptions: Apollo.QueryHookOptions<TeamByIdQuery, TeamByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamByIdQuery, TeamByIdQueryVariables>(
    TeamByIdDocument,
    options
  );
}
export function useTeamByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamByIdQuery,
    TeamByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamByIdQuery, TeamByIdQueryVariables>(
    TeamByIdDocument,
    options
  );
}
export type TeamByIdQueryHookResult = ReturnType<typeof useTeamByIdQuery>;
export type TeamByIdLazyQueryHookResult = ReturnType<
  typeof useTeamByIdLazyQuery
>;
export type TeamByIdQueryResult = Apollo.QueryResult<
  TeamByIdQuery,
  TeamByIdQueryVariables
>;
export const TeamByInviteTokenDocument = gql`
  query TeamByInviteToken($inviteToken: String!) {
    teamByInviteToken(inviteToken: $inviteToken) {
      ...TeamWithOrg
    }
  }
  ${TeamWithOrgFragmentDoc}
`;

/**
 * __useTeamByInviteTokenQuery__
 *
 * To run a query within a React component, call `useTeamByInviteTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamByInviteTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamByInviteTokenQuery({
 *   variables: {
 *      inviteToken: // value for 'inviteToken'
 *   },
 * });
 */
export function useTeamByInviteTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    TeamByInviteTokenQuery,
    TeamByInviteTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TeamByInviteTokenQuery,
    TeamByInviteTokenQueryVariables
  >(TeamByInviteTokenDocument, options);
}
export function useTeamByInviteTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamByInviteTokenQuery,
    TeamByInviteTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TeamByInviteTokenQuery,
    TeamByInviteTokenQueryVariables
  >(TeamByInviteTokenDocument, options);
}
export type TeamByInviteTokenQueryHookResult = ReturnType<
  typeof useTeamByInviteTokenQuery
>;
export type TeamByInviteTokenLazyQueryHookResult = ReturnType<
  typeof useTeamByInviteTokenLazyQuery
>;
export type TeamByInviteTokenQueryResult = Apollo.QueryResult<
  TeamByInviteTokenQuery,
  TeamByInviteTokenQueryVariables
>;
export const TeamByPublicBoardIdDocument = gql`
  query TeamByPublicBoardId($boardId: String!) {
    teamByPublicBoardId(boardId: $boardId) {
      ...TeamFragment
      organization {
        id
        name
        userCount
        status
        prepaidSeatCount
      }
    }
  }
  ${TeamFragmentFragmentDoc}
`;

/**
 * __useTeamByPublicBoardIdQuery__
 *
 * To run a query within a React component, call `useTeamByPublicBoardIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamByPublicBoardIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamByPublicBoardIdQuery({
 *   variables: {
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useTeamByPublicBoardIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    TeamByPublicBoardIdQuery,
    TeamByPublicBoardIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TeamByPublicBoardIdQuery,
    TeamByPublicBoardIdQueryVariables
  >(TeamByPublicBoardIdDocument, options);
}
export function useTeamByPublicBoardIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamByPublicBoardIdQuery,
    TeamByPublicBoardIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TeamByPublicBoardIdQuery,
    TeamByPublicBoardIdQueryVariables
  >(TeamByPublicBoardIdDocument, options);
}
export type TeamByPublicBoardIdQueryHookResult = ReturnType<
  typeof useTeamByPublicBoardIdQuery
>;
export type TeamByPublicBoardIdLazyQueryHookResult = ReturnType<
  typeof useTeamByPublicBoardIdLazyQuery
>;
export type TeamByPublicBoardIdQueryResult = Apollo.QueryResult<
  TeamByPublicBoardIdQuery,
  TeamByPublicBoardIdQueryVariables
>;
export const TeamParentsByTeamIdDocument = gql`
  query TeamParentsByTeamId($teamId: ID!) {
    teamById(teamId: $teamId) {
      __typename
      id
      parents {
        __typename
        id
        name
        type
      }
    }
  }
`;

/**
 * __useTeamParentsByTeamIdQuery__
 *
 * To run a query within a React component, call `useTeamParentsByTeamIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamParentsByTeamIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamParentsByTeamIdQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamParentsByTeamIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    TeamParentsByTeamIdQuery,
    TeamParentsByTeamIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TeamParentsByTeamIdQuery,
    TeamParentsByTeamIdQueryVariables
  >(TeamParentsByTeamIdDocument, options);
}
export function useTeamParentsByTeamIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamParentsByTeamIdQuery,
    TeamParentsByTeamIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TeamParentsByTeamIdQuery,
    TeamParentsByTeamIdQueryVariables
  >(TeamParentsByTeamIdDocument, options);
}
export type TeamParentsByTeamIdQueryHookResult = ReturnType<
  typeof useTeamParentsByTeamIdQuery
>;
export type TeamParentsByTeamIdLazyQueryHookResult = ReturnType<
  typeof useTeamParentsByTeamIdLazyQuery
>;
export type TeamParentsByTeamIdQueryResult = Apollo.QueryResult<
  TeamParentsByTeamIdQuery,
  TeamParentsByTeamIdQueryVariables
>;
export const TeamTreeDocument = gql`
  query TeamTree {
    topLevelTeams {
      ...TeamTreeFragment
    }
    topLevelWatchedTeams {
      ...TeamTreeFragment
    }
  }
  ${TeamTreeFragmentFragmentDoc}
`;

/**
 * __useTeamTreeQuery__
 *
 * To run a query within a React component, call `useTeamTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamTreeQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamTreeQuery(
  baseOptions?: Apollo.QueryHookOptions<TeamTreeQuery, TeamTreeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamTreeQuery, TeamTreeQueryVariables>(
    TeamTreeDocument,
    options
  );
}
export function useTeamTreeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamTreeQuery,
    TeamTreeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamTreeQuery, TeamTreeQueryVariables>(
    TeamTreeDocument,
    options
  );
}
export type TeamTreeQueryHookResult = ReturnType<typeof useTeamTreeQuery>;
export type TeamTreeLazyQueryHookResult = ReturnType<
  typeof useTeamTreeLazyQuery
>;
export type TeamTreeQueryResult = Apollo.QueryResult<
  TeamTreeQuery,
  TeamTreeQueryVariables
>;
export const TeamsByOrganizationIdAndTypeDocument = gql`
  query TeamsByOrganizationIdAndType(
    $organizationId: String!
    $teamType: TeamType!
    $offset: Int = 0
    $limit: Int = 1000
  ) {
    teamsByOrganizationIdAndType(
      organizationId: $organizationId
      teamType: $teamType
      offset: $offset
      limit: $limit
    ) {
      totalSize
      teams {
        id
        name
        type
        createdAt
        organizationId
      }
    }
  }
`;

/**
 * __useTeamsByOrganizationIdAndTypeQuery__
 *
 * To run a query within a React component, call `useTeamsByOrganizationIdAndTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsByOrganizationIdAndTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsByOrganizationIdAndTypeQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      teamType: // value for 'teamType'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTeamsByOrganizationIdAndTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    TeamsByOrganizationIdAndTypeQuery,
    TeamsByOrganizationIdAndTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TeamsByOrganizationIdAndTypeQuery,
    TeamsByOrganizationIdAndTypeQueryVariables
  >(TeamsByOrganizationIdAndTypeDocument, options);
}
export function useTeamsByOrganizationIdAndTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamsByOrganizationIdAndTypeQuery,
    TeamsByOrganizationIdAndTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TeamsByOrganizationIdAndTypeQuery,
    TeamsByOrganizationIdAndTypeQueryVariables
  >(TeamsByOrganizationIdAndTypeDocument, options);
}
export type TeamsByOrganizationIdAndTypeQueryHookResult = ReturnType<
  typeof useTeamsByOrganizationIdAndTypeQuery
>;
export type TeamsByOrganizationIdAndTypeLazyQueryHookResult = ReturnType<
  typeof useTeamsByOrganizationIdAndTypeLazyQuery
>;
export type TeamsByOrganizationIdAndTypeQueryResult = Apollo.QueryResult<
  TeamsByOrganizationIdAndTypeQuery,
  TeamsByOrganizationIdAndTypeQueryVariables
>;
export const ChangeUserCurrentTeamAndAddIfNotMemberDocument = gql`
  mutation ChangeUserCurrentTeamAndAddIfNotMember($teamId: ID!, $userId: ID!) {
    changeUserCurrentTeamAndAddIfNotMember(teamId: $teamId, userId: $userId) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type ChangeUserCurrentTeamAndAddIfNotMemberMutationFn =
  Apollo.MutationFunction<
    ChangeUserCurrentTeamAndAddIfNotMemberMutation,
    ChangeUserCurrentTeamAndAddIfNotMemberMutationVariables
  >;

/**
 * __useChangeUserCurrentTeamAndAddIfNotMemberMutation__
 *
 * To run a mutation, you first call `useChangeUserCurrentTeamAndAddIfNotMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserCurrentTeamAndAddIfNotMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserCurrentTeamAndAddIfNotMemberMutation, { data, loading, error }] = useChangeUserCurrentTeamAndAddIfNotMemberMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useChangeUserCurrentTeamAndAddIfNotMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeUserCurrentTeamAndAddIfNotMemberMutation,
    ChangeUserCurrentTeamAndAddIfNotMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeUserCurrentTeamAndAddIfNotMemberMutation,
    ChangeUserCurrentTeamAndAddIfNotMemberMutationVariables
  >(ChangeUserCurrentTeamAndAddIfNotMemberDocument, options);
}
export type ChangeUserCurrentTeamAndAddIfNotMemberMutationHookResult =
  ReturnType<typeof useChangeUserCurrentTeamAndAddIfNotMemberMutation>;
export type ChangeUserCurrentTeamAndAddIfNotMemberMutationResult =
  Apollo.MutationResult<ChangeUserCurrentTeamAndAddIfNotMemberMutation>;
export type ChangeUserCurrentTeamAndAddIfNotMemberMutationOptions =
  Apollo.BaseMutationOptions<
    ChangeUserCurrentTeamAndAddIfNotMemberMutation,
    ChangeUserCurrentTeamAndAddIfNotMemberMutationVariables
  >;
export const CreateUserDocument = gql`
  mutation CreateUser($userInput: UserCreateInput) {
    createUser(userInput: $userInput) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options
  );
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>;
export type CreateUserMutationResult =
  Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const DeleteOwnAccountDocument = gql`
  mutation DeleteOwnAccount {
    deleteOwnAccount
  }
`;
export type DeleteOwnAccountMutationFn = Apollo.MutationFunction<
  DeleteOwnAccountMutation,
  DeleteOwnAccountMutationVariables
>;

/**
 * __useDeleteOwnAccountMutation__
 *
 * To run a mutation, you first call `useDeleteOwnAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOwnAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOwnAccountMutation, { data, loading, error }] = useDeleteOwnAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteOwnAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOwnAccountMutation,
    DeleteOwnAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteOwnAccountMutation,
    DeleteOwnAccountMutationVariables
  >(DeleteOwnAccountDocument, options);
}
export type DeleteOwnAccountMutationHookResult = ReturnType<
  typeof useDeleteOwnAccountMutation
>;
export type DeleteOwnAccountMutationResult =
  Apollo.MutationResult<DeleteOwnAccountMutation>;
export type DeleteOwnAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteOwnAccountMutation,
  DeleteOwnAccountMutationVariables
>;
export const ForgotPasswordDocument = gql`
  mutation ForgotPassword($email: String!, $isChangingProviders: Boolean!) {
    forgotPassword(email: $email, isChangingProviders: $isChangingProviders)
  }
`;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      isChangingProviders: // value for 'isChangingProviders'
 *   },
 * });
 */
export function useForgotPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >(ForgotPasswordDocument, options);
}
export type ForgotPasswordMutationHookResult = ReturnType<
  typeof useForgotPasswordMutation
>;
export type ForgotPasswordMutationResult =
  Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;
export const LeaveTeamDocument = gql`
  mutation LeaveTeam($teamId: ID!, $userId: String!) {
    leaveTeam(teamId: $teamId, userId: $userId) {
      ...TeamFragment
    }
  }
  ${TeamFragmentFragmentDoc}
`;
export type LeaveTeamMutationFn = Apollo.MutationFunction<
  LeaveTeamMutation,
  LeaveTeamMutationVariables
>;

/**
 * __useLeaveTeamMutation__
 *
 * To run a mutation, you first call `useLeaveTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveTeamMutation, { data, loading, error }] = useLeaveTeamMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useLeaveTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeaveTeamMutation,
    LeaveTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LeaveTeamMutation, LeaveTeamMutationVariables>(
    LeaveTeamDocument,
    options
  );
}
export type LeaveTeamMutationHookResult = ReturnType<
  typeof useLeaveTeamMutation
>;
export type LeaveTeamMutationResult = Apollo.MutationResult<LeaveTeamMutation>;
export type LeaveTeamMutationOptions = Apollo.BaseMutationOptions<
  LeaveTeamMutation,
  LeaveTeamMutationVariables
>;
export const LoginDocument = gql`
  mutation Login($loginInput: LoginInput) {
    login(loginInput: $loginInput) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      loginInput: // value for 'loginInput'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const OneTimePasswordLoginDocument = gql`
  mutation OneTimePasswordLogin(
    $oneTimePasswordLoginInput: OneTimePasswordLoginInput
  ) {
    oneTimePasswordLogin(
      oneTimePasswordLoginInput: $oneTimePasswordLoginInput
    ) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type OneTimePasswordLoginMutationFn = Apollo.MutationFunction<
  OneTimePasswordLoginMutation,
  OneTimePasswordLoginMutationVariables
>;

/**
 * __useOneTimePasswordLoginMutation__
 *
 * To run a mutation, you first call `useOneTimePasswordLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOneTimePasswordLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [oneTimePasswordLoginMutation, { data, loading, error }] = useOneTimePasswordLoginMutation({
 *   variables: {
 *      oneTimePasswordLoginInput: // value for 'oneTimePasswordLoginInput'
 *   },
 * });
 */
export function useOneTimePasswordLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OneTimePasswordLoginMutation,
    OneTimePasswordLoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OneTimePasswordLoginMutation,
    OneTimePasswordLoginMutationVariables
  >(OneTimePasswordLoginDocument, options);
}
export type OneTimePasswordLoginMutationHookResult = ReturnType<
  typeof useOneTimePasswordLoginMutation
>;
export type OneTimePasswordLoginMutationResult =
  Apollo.MutationResult<OneTimePasswordLoginMutation>;
export type OneTimePasswordLoginMutationOptions = Apollo.BaseMutationOptions<
  OneTimePasswordLoginMutation,
  OneTimePasswordLoginMutationVariables
>;
export const ResendVerificationCodeDocument = gql`
  mutation ResendVerificationCode($email: String!) {
    createVerificationToken(email: $email)
  }
`;
export type ResendVerificationCodeMutationFn = Apollo.MutationFunction<
  ResendVerificationCodeMutation,
  ResendVerificationCodeMutationVariables
>;

/**
 * __useResendVerificationCodeMutation__
 *
 * To run a mutation, you first call `useResendVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerificationCodeMutation, { data, loading, error }] = useResendVerificationCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendVerificationCodeMutation,
    ResendVerificationCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendVerificationCodeMutation,
    ResendVerificationCodeMutationVariables
  >(ResendVerificationCodeDocument, options);
}
export type ResendVerificationCodeMutationHookResult = ReturnType<
  typeof useResendVerificationCodeMutation
>;
export type ResendVerificationCodeMutationResult =
  Apollo.MutationResult<ResendVerificationCodeMutation>;
export type ResendVerificationCodeMutationOptions = Apollo.BaseMutationOptions<
  ResendVerificationCodeMutation,
  ResendVerificationCodeMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($resetToken: String!, $newPassword: String!) {
    resetPassword(resetToken: $resetToken, newPassword: $newPassword) {
      id
      email
      name
      provider
    }
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      resetToken: // value for 'resetToken'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const TrialRegisterQuestionsDocument = gql`
  mutation TrialRegisterQuestions(
    $email: String!
    $questions: [TrialQuestionAnswerInput]
    $score: Int!
  ) {
    trialRegisterQuestions(email: $email, questions: $questions, score: $score)
  }
`;
export type TrialRegisterQuestionsMutationFn = Apollo.MutationFunction<
  TrialRegisterQuestionsMutation,
  TrialRegisterQuestionsMutationVariables
>;

/**
 * __useTrialRegisterQuestionsMutation__
 *
 * To run a mutation, you first call `useTrialRegisterQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrialRegisterQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trialRegisterQuestionsMutation, { data, loading, error }] = useTrialRegisterQuestionsMutation({
 *   variables: {
 *      email: // value for 'email'
 *      questions: // value for 'questions'
 *      score: // value for 'score'
 *   },
 * });
 */
export function useTrialRegisterQuestionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrialRegisterQuestionsMutation,
    TrialRegisterQuestionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TrialRegisterQuestionsMutation,
    TrialRegisterQuestionsMutationVariables
  >(TrialRegisterQuestionsDocument, options);
}
export type TrialRegisterQuestionsMutationHookResult = ReturnType<
  typeof useTrialRegisterQuestionsMutation
>;
export type TrialRegisterQuestionsMutationResult =
  Apollo.MutationResult<TrialRegisterQuestionsMutation>;
export type TrialRegisterQuestionsMutationOptions = Apollo.BaseMutationOptions<
  TrialRegisterQuestionsMutation,
  TrialRegisterQuestionsMutationVariables
>;
export const TrialRegisterDocument = gql`
  mutation TrialRegister($email: String!) {
    trialRegister(email: $email)
  }
`;
export type TrialRegisterMutationFn = Apollo.MutationFunction<
  TrialRegisterMutation,
  TrialRegisterMutationVariables
>;

/**
 * __useTrialRegisterMutation__
 *
 * To run a mutation, you first call `useTrialRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrialRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trialRegisterMutation, { data, loading, error }] = useTrialRegisterMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useTrialRegisterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrialRegisterMutation,
    TrialRegisterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TrialRegisterMutation,
    TrialRegisterMutationVariables
  >(TrialRegisterDocument, options);
}
export type TrialRegisterMutationHookResult = ReturnType<
  typeof useTrialRegisterMutation
>;
export type TrialRegisterMutationResult =
  Apollo.MutationResult<TrialRegisterMutation>;
export type TrialRegisterMutationOptions = Apollo.BaseMutationOptions<
  TrialRegisterMutation,
  TrialRegisterMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($userInput: UserUpdateInput!) {
    updateUser(userInput: $userInput) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const VerifyEmailDocument = gql`
  mutation VerifyEmail($email: String!, $verificationToken: String!) {
    verifyEmail(email: $email, verificationToken: $verificationToken) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type VerifyEmailMutationFn = Apollo.MutationFunction<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      verificationToken: // value for 'verificationToken'
 *   },
 * });
 */
export function useVerifyEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyEmailMutation,
    VerifyEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(
    VerifyEmailDocument,
    options
  );
}
export type VerifyEmailMutationHookResult = ReturnType<
  typeof useVerifyEmailMutation
>;
export type VerifyEmailMutationResult =
  Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>;
export const CurrentUserDocument = gql`
  query CurrentUser {
    user {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  );
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  );
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<
  typeof useCurrentUserLazyQuery
>;
export type CurrentUserQueryResult = Apollo.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>;
export const IsLoggedInDocument = gql`
  query IsLoggedIn {
    isLoggedIn
  }
`;

/**
 * __useIsLoggedInQuery__
 *
 * To run a query within a React component, call `useIsLoggedInQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsLoggedInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsLoggedInQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsLoggedInQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IsLoggedInQuery,
    IsLoggedInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsLoggedInQuery, IsLoggedInQueryVariables>(
    IsLoggedInDocument,
    options
  );
}
export function useIsLoggedInLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsLoggedInQuery,
    IsLoggedInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsLoggedInQuery, IsLoggedInQueryVariables>(
    IsLoggedInDocument,
    options
  );
}
export type IsLoggedInQueryHookResult = ReturnType<typeof useIsLoggedInQuery>;
export type IsLoggedInLazyQueryHookResult = ReturnType<
  typeof useIsLoggedInLazyQuery
>;
export type IsLoggedInQueryResult = Apollo.QueryResult<
  IsLoggedInQuery,
  IsLoggedInQueryVariables
>;
export const MembershipsByOrgIdAndEmailDocument = gql`
  query MembershipsByOrgIdAndEmail(
    $organizationId: ID!
    $roleFilter: [MembershipRole!]
    $email: String
    $offset: Int
    $limit: Int
    $sort: JSON
  ) {
    organizationById(organizationId: $organizationId) {
      memberships(
        limit: $limit
        offset: $offset
        sort: $sort
        roleFilter: $roleFilter
        email: $email
      ) {
        totalSize
        memberships {
          ...OrgMembershipWithUser
        }
      }
    }
  }
  ${OrgMembershipWithUserFragmentDoc}
`;

/**
 * __useMembershipsByOrgIdAndEmailQuery__
 *
 * To run a query within a React component, call `useMembershipsByOrgIdAndEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipsByOrgIdAndEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipsByOrgIdAndEmailQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      roleFilter: // value for 'roleFilter'
 *      email: // value for 'email'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useMembershipsByOrgIdAndEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    MembershipsByOrgIdAndEmailQuery,
    MembershipsByOrgIdAndEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MembershipsByOrgIdAndEmailQuery,
    MembershipsByOrgIdAndEmailQueryVariables
  >(MembershipsByOrgIdAndEmailDocument, options);
}
export function useMembershipsByOrgIdAndEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MembershipsByOrgIdAndEmailQuery,
    MembershipsByOrgIdAndEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MembershipsByOrgIdAndEmailQuery,
    MembershipsByOrgIdAndEmailQueryVariables
  >(MembershipsByOrgIdAndEmailDocument, options);
}
export type MembershipsByOrgIdAndEmailQueryHookResult = ReturnType<
  typeof useMembershipsByOrgIdAndEmailQuery
>;
export type MembershipsByOrgIdAndEmailLazyQueryHookResult = ReturnType<
  typeof useMembershipsByOrgIdAndEmailLazyQuery
>;
export type MembershipsByOrgIdAndEmailQueryResult = Apollo.QueryResult<
  MembershipsByOrgIdAndEmailQuery,
  MembershipsByOrgIdAndEmailQueryVariables
>;
export const OtpDocument = gql`
  query Otp {
    otp
  }
`;

/**
 * __useOtpQuery__
 *
 * To run a query within a React component, call `useOtpQuery` and pass it any options that fit your needs.
 * When your component renders, `useOtpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOtpQuery({
 *   variables: {
 *   },
 * });
 */
export function useOtpQuery(
  baseOptions?: Apollo.QueryHookOptions<OtpQuery, OtpQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OtpQuery, OtpQueryVariables>(OtpDocument, options);
}
export function useOtpLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OtpQuery, OtpQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OtpQuery, OtpQueryVariables>(OtpDocument, options);
}
export type OtpQueryHookResult = ReturnType<typeof useOtpQuery>;
export type OtpLazyQueryHookResult = ReturnType<typeof useOtpLazyQuery>;
export type OtpQueryResult = Apollo.QueryResult<OtpQuery, OtpQueryVariables>;
export const SearchMembershipsByOrganizationIdAndEmailDocument = gql`
  query SearchMembershipsByOrganizationIdAndEmail(
    $organizationId: ID!
    $roleFilter: [MembershipRole!]
    $email: String
    $offset: Int
    $limit: Int
    $sort: JSON
  ) {
    organizationById(organizationId: $organizationId) {
      memberships(
        limit: $limit
        offset: $offset
        sort: $sort
        roleFilter: $roleFilter
        email: $email
      ) {
        totalSize
        memberships {
          ...OrgMembershipFragment
          seatType
          user {
            id
            email
            name
            lastActiveAt
            createdAt
          }
        }
      }
    }
  }
  ${OrgMembershipFragmentFragmentDoc}
`;

/**
 * __useSearchMembershipsByOrganizationIdAndEmailQuery__
 *
 * To run a query within a React component, call `useSearchMembershipsByOrganizationIdAndEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMembershipsByOrganizationIdAndEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMembershipsByOrganizationIdAndEmailQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      roleFilter: // value for 'roleFilter'
 *      email: // value for 'email'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSearchMembershipsByOrganizationIdAndEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchMembershipsByOrganizationIdAndEmailQuery,
    SearchMembershipsByOrganizationIdAndEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchMembershipsByOrganizationIdAndEmailQuery,
    SearchMembershipsByOrganizationIdAndEmailQueryVariables
  >(SearchMembershipsByOrganizationIdAndEmailDocument, options);
}
export function useSearchMembershipsByOrganizationIdAndEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchMembershipsByOrganizationIdAndEmailQuery,
    SearchMembershipsByOrganizationIdAndEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchMembershipsByOrganizationIdAndEmailQuery,
    SearchMembershipsByOrganizationIdAndEmailQueryVariables
  >(SearchMembershipsByOrganizationIdAndEmailDocument, options);
}
export type SearchMembershipsByOrganizationIdAndEmailQueryHookResult =
  ReturnType<typeof useSearchMembershipsByOrganizationIdAndEmailQuery>;
export type SearchMembershipsByOrganizationIdAndEmailLazyQueryHookResult =
  ReturnType<typeof useSearchMembershipsByOrganizationIdAndEmailLazyQuery>;
export type SearchMembershipsByOrganizationIdAndEmailQueryResult =
  Apollo.QueryResult<
    SearchMembershipsByOrganizationIdAndEmailQuery,
    SearchMembershipsByOrganizationIdAndEmailQueryVariables
  >;
export const UserEmailByResetPasswordTokenDocument = gql`
  query UserEmailByResetPasswordToken($resetPasswordToken: String!) {
    userEmailByResetPasswordToken(resetPasswordToken: $resetPasswordToken)
  }
`;

/**
 * __useUserEmailByResetPasswordTokenQuery__
 *
 * To run a query within a React component, call `useUserEmailByResetPasswordTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEmailByResetPasswordTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEmailByResetPasswordTokenQuery({
 *   variables: {
 *      resetPasswordToken: // value for 'resetPasswordToken'
 *   },
 * });
 */
export function useUserEmailByResetPasswordTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserEmailByResetPasswordTokenQuery,
    UserEmailByResetPasswordTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserEmailByResetPasswordTokenQuery,
    UserEmailByResetPasswordTokenQueryVariables
  >(UserEmailByResetPasswordTokenDocument, options);
}
export function useUserEmailByResetPasswordTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserEmailByResetPasswordTokenQuery,
    UserEmailByResetPasswordTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserEmailByResetPasswordTokenQuery,
    UserEmailByResetPasswordTokenQueryVariables
  >(UserEmailByResetPasswordTokenDocument, options);
}
export type UserEmailByResetPasswordTokenQueryHookResult = ReturnType<
  typeof useUserEmailByResetPasswordTokenQuery
>;
export type UserEmailByResetPasswordTokenLazyQueryHookResult = ReturnType<
  typeof useUserEmailByResetPasswordTokenLazyQuery
>;
export type UserEmailByResetPasswordTokenQueryResult = Apollo.QueryResult<
  UserEmailByResetPasswordTokenQuery,
  UserEmailByResetPasswordTokenQueryVariables
>;
export const UsersByIdsDocument = gql`
  query UsersByIds($userIds: [String!]!) {
    usersByIds(userIds: $userIds) {
      ...MinimalUser
    }
  }
  ${MinimalUserFragmentDoc}
`;

/**
 * __useUsersByIdsQuery__
 *
 * To run a query within a React component, call `useUsersByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersByIdsQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useUsersByIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UsersByIdsQuery,
    UsersByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersByIdsQuery, UsersByIdsQueryVariables>(
    UsersByIdsDocument,
    options
  );
}
export function useUsersByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersByIdsQuery,
    UsersByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersByIdsQuery, UsersByIdsQueryVariables>(
    UsersByIdsDocument,
    options
  );
}
export type UsersByIdsQueryHookResult = ReturnType<typeof useUsersByIdsQuery>;
export type UsersByIdsLazyQueryHookResult = ReturnType<
  typeof useUsersByIdsLazyQuery
>;
export type UsersByIdsQueryResult = Apollo.QueryResult<
  UsersByIdsQuery,
  UsersByIdsQueryVariables
>;
export const UsersByOrgIdDocument = gql`
  query UsersByOrgId(
    $organizationId: String!
    $roleFilter: MembershipRole!
    $offset: Int!
    $limit: Int!
    $sort: JSON
  ) {
    usersByOrganizationId(
      organizationId: $organizationId
      roleFilter: $roleFilter
      offset: $offset
      limit: $limit
      sort: $sort
    ) {
      totalSize
      users {
        ...UserFragment
      }
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useUsersByOrgIdQuery__
 *
 * To run a query within a React component, call `useUsersByOrgIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersByOrgIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersByOrgIdQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      roleFilter: // value for 'roleFilter'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useUsersByOrgIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    UsersByOrgIdQuery,
    UsersByOrgIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersByOrgIdQuery, UsersByOrgIdQueryVariables>(
    UsersByOrgIdDocument,
    options
  );
}
export function useUsersByOrgIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersByOrgIdQuery,
    UsersByOrgIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersByOrgIdQuery, UsersByOrgIdQueryVariables>(
    UsersByOrgIdDocument,
    options
  );
}
export type UsersByOrgIdQueryHookResult = ReturnType<
  typeof useUsersByOrgIdQuery
>;
export type UsersByOrgIdLazyQueryHookResult = ReturnType<
  typeof useUsersByOrgIdLazyQuery
>;
export type UsersByOrgIdQueryResult = Apollo.QueryResult<
  UsersByOrgIdQuery,
  UsersByOrgIdQueryVariables
>;
export const InstalledUserIntegrationsUpdateDocument = gql`
  subscription InstalledUserIntegrationsUpdate($userId: ID!) {
    installedUserIntegrationsUpdate(userId: $userId) {
      confluence
      jira
      miro
      trello
    }
  }
`;

/**
 * __useInstalledUserIntegrationsUpdateSubscription__
 *
 * To run a query within a React component, call `useInstalledUserIntegrationsUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInstalledUserIntegrationsUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstalledUserIntegrationsUpdateSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useInstalledUserIntegrationsUpdateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    InstalledUserIntegrationsUpdateSubscription,
    InstalledUserIntegrationsUpdateSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    InstalledUserIntegrationsUpdateSubscription,
    InstalledUserIntegrationsUpdateSubscriptionVariables
  >(InstalledUserIntegrationsUpdateDocument, options);
}
export type InstalledUserIntegrationsUpdateSubscriptionHookResult = ReturnType<
  typeof useInstalledUserIntegrationsUpdateSubscription
>;
export type InstalledUserIntegrationsUpdateSubscriptionResult =
  Apollo.SubscriptionResult<InstalledUserIntegrationsUpdateSubscription>;
